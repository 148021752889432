import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import {
  DateCarousel,
  GraficoFluxoCaixaMensal,
  TagsFilterSelector,
} from "../../../components";
import { withRouter } from "react-router-dom";

function FluxoDeCaixa(props) {
  const m = moment();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };
  const [tags, setaTags] = useState([]);

  const onChangeTags = (newValues) => {
    setaTags(newValues);
  };

  return (
    <div className="animated fadeIn">
      <Container className="">
        <Row className="mt-3 mt-md-0">
          <Col md={4} className="text-center">
            <TagsFilterSelector tagShow={true} onChange={onChangeTags} tags={tags} />
          </Col>
          <Col md={4} className="text-center">
            <DateCarousel
              mes={mes}
              ano={ano}
              onChange={onChangeDate}
            />
          </Col>
        </Row>
        <GraficoFluxoCaixaMensal mes={mes} ano={ano} tags={tags} />
      </Container>
    </div>
  );
}

export default withRouter(FluxoDeCaixa);
