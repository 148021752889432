import React from "react";
import { FieldTextMask } from "../FieldTextMask";

function FieldTelefoneCelular(props) {
  const mask = [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  return <FieldTextMask mask={mask} {...props} />;
}

export default FieldTelefoneCelular;
