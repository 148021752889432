import React, { useState } from "react";
import { Row, Col, Card, CardHeader, Table, CardBody } from "reactstrap";
import { GraficoRosca, LoadingSpinner } from "../../../components";
import palette from "google-palette";
import { toBRLColor, toPercentColor } from "../../../utils";
import PropTypes from "prop-types";

function GraficoReceitasPorTipo({
  isLoading,
  totalReceitaPrincipal,
  totalReceitaExtra,
  totalReceitaPassiva,
}) {
  const dados = [totalReceitaPrincipal, totalReceitaExtra, totalReceitaPassiva];
  const cores = palette("tol-rainbow", dados.length).map((hex) => "#" + hex);
  const legendas = ["Principal", "Extra", "Passiva"];
  const [itemSelecionado, setaItemSelecionado] = useState(null);

  const total = dados.reduce((acc, cur) => acc + cur, 0);

  const hoverCb = (index) => {
    let value;
    if (!isNaN(index) && index >= 0) {
      value = index;
    } else {
      value = null;
    }
    setaItemSelecionado(value);
  };

  return (
    <Row>
      <Col lg={6} xl={8} className="text-center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <GraficoRosca
            legendas={legendas}
            dados={dados}
            titulo={"Receita por tipo"}
            total={total}
            cores={cores}
            hoverCb={hoverCb}
          />
        )}
      </Col>
      <Col lg={6} xl={4} className="mt-4 mt-lg-0">
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <strong>Categorias</strong>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Table size="sm" borderless responsive>
                <tbody>
                  {dados.map((valor, i) => (
                    <tr
                      key={`categoria_${i}`}
                      className={itemSelecionado === i ? "table-light" : ""}
                    >
                      <td style={{ width: 30 }} className="text-center">
                        <i
                          className="fa fa-circle"
                          style={{
                            color: cores[i],
                            fontSize:
                              itemSelecionado === i ? "1.1rem" : "inherit",
                          }}
                        />
                      </td>
                      <td>{legendas[i]}</td>
                      <td className="text-right">{toBRLColor(valor)}</td>
                      <td className="text-right">
                        {total > 0 && toPercentColor(valor / total)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

GraficoReceitasPorTipo.propTypes = {
  isLoading: PropTypes.bool,
  totalReceitaPrincipal: PropTypes.number,
  totalReceitaExtra: PropTypes.number,
  totalReceitaPassiva: PropTypes.number,
};

export default GraficoReceitasPorTipo;
