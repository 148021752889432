import React from "react";

import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    CardTitle,
} from "reactstrap";

import { Doughnut } from "react-chartjs-2";
import { toBRL } from "../../utils";

function GraficoRelatorio({ data }) {
    const totais = {};
    const totalEsperado = {};
    let totalReceitas = 0;
    let totalEssencial = 0;
    let totalSuperfula = 0;
    let totalInvest = 0;
    let totalDespesas = 0;

    Object.keys(data).forEach((dat) => {
        totais[dat] = 0
        totais[dat] = 0
        totais[dat] = 0

        totalEsperado[dat] = 0
        totalEsperado[dat] = 0
        totalEsperado[dat] = 0
    })
    
    Object.keys(data).forEach((dat) => {
        totalReceitas += Number(data[dat]['totalReceitas'])
        totalEssencial += Number(data[dat]['totalDespesaEssencial'])
        totalSuperfula += Number(data[dat]['totalDespesaSuperfula'])
        totalInvest += Number(data[dat]['totalInvestimentos'])

        totais[dat] += Number(data[dat]['totalDespesaEssencial'])
        totais[dat] += Number(data[dat]['totalDespesaSuperfula'])
        totais[dat] += Number(data[dat]['totalInvestimentos'])
    })

    Object.keys(data).forEach((dat) => {
        totalEsperado[dat] += Number(data[dat]['totalDespesaEssencial'])*0.50
        totalEsperado[dat] += Number(data[dat]['totalDespesaSuperfula'])*0.30
        totalEsperado[dat] += Number(data[dat]['totalInvestimentos'])*20
    })

    totalDespesas += totalEssencial;
    totalDespesas += totalSuperfula;
    totalDespesas += totalInvest;
    
    const doughnutRealizado = {
        labels: ["Essenciais", "Livre / Supérfluas", "Investimentos"],
        datasets: [
          {
            label: "label",
            data: [totalEssencial, totalSuperfula, totalInvest],
            backgroundColor: ["#83ba6d", "#F2C53D", "#0896A6"],
            borderWidth: 0,
            hoverBorderWidth: 5,
          }
        ],
      };

    const doughnutEsperado = {
        labels: ["Essenciais", "Livre / Supérfluas", "Investimentos"],
        datasets: [
            {
                label: "esperado50",
                data: [totalReceitas*0.50, totalReceitas*0.30, totalReceitas*0.20],
                backgroundColor: ["#83ba6d", "#F2C53D", "#0896A6"]
            },
        ]
    }

    const graficoOpcoes = {
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    const { index } = tooltipItem;

                    let label = data.datasets[tooltipItem.datasetIndex].data[index] || "";
                    
                    if(label) {
                        if(data.labels[index] === "Essenciais") {
                            label = `${data.labels[index]}: ${toBRL(label)} (${Math.floor((totalEssencial/(totalDespesas  === 0 ? 1 : totalDespesas))*100)}%)`
                        } else if (data.labels[index] === "Livre / Supérfluas") {
                            label = `${data.labels[index]}: ${toBRL(label)} (${Math.floor((totalSuperfula/(totalDespesas  === 0 ? 1 : totalDespesas))*100)}%)`
                        } else if (data.labels[index] === "Investimentos") {
                            label = `${data.labels[index]}: ${toBRL(label)} (${Math.floor((totalInvest/(totalDespesas  === 0 ? 1 : totalDespesas))*100)}%)`
                        }
                    }
        
                    return label;
                }
            }
        },
    }

    const graficoOpcoesEsperado = {
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    const { index } = tooltipItem;

                    let label = data.datasets[tooltipItem.datasetIndex].data[index] || "";
                    if(label) {
                        if(data.labels[index] === "Essenciais") {
                            label = `Essenciais: ${toBRL(label)} (50%)`
                        } else if (data.labels[index] === "Livre / Supérfluas") {
                            label = `Livre / Supérfluas: ${toBRL(label)} (30%)`
                        } else if(data.labels[index] === "Investimentos") {
                            label = `Investimentos: ${toBRL(label)} (20%)`
                        }
                    }
                    return label;
                }
            }
        },
    }

    return (
        <Row>
            <Col lg={6}>
                <Card style={{ width: "100%" }}>
                    <CardHeader className="text-center">
                        <CardTitle>Esperado</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Doughnut
                            options={graficoOpcoesEsperado}
                            data={doughnutEsperado}
                        />
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card style={{ width: "100%" }}>
                    <CardHeader className="text-center">
                        <CardTitle>Realizado</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Doughnut
                            options={graficoOpcoes}
                            data={doughnutRealizado}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default GraficoRelatorio;