import React from "react";
import { FieldTextMask } from "../FieldTextMask";

function FieldCPF(props) {
  const mask = [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/
  ];

  const placeholder = "00.000.000/0001-00";

  return <FieldTextMask mask={mask} placeholder={placeholder} {...props} />;
}

export default FieldCPF;
