import React from "react";
import { Table } from "reactstrap";
import { toBRL } from "../../../utils";

function TabelaResumo({ data }) {
    const totais = {};
    const totalEsperado = {};
    let totalReceitas = 0;
    let totalEssencial = 0;
    let totalSuperfula = 0;
    let totalInvest = 0;
    // let totalDespesas = 0;

    Object.keys(data).forEach((dat) => {
        totais[dat] = 0
        totais[dat] = 0
        totais[dat] = 0

        totalEsperado[dat] = 0
        totalEsperado[dat] = 0
        totalEsperado[dat] = 0
    })
    
    Object.keys(data).forEach((dat) => {
        totalReceitas += Number(data[dat]['totalReceitas'])
        totalEssencial += Number(data[dat]['totalDespesaEssencial'])
        totalSuperfula += Number(data[dat]['totalDespesaSuperfula'])
        totalInvest += Number(data[dat]['totalInvestimentos'])

        totais[dat] += Number(data[dat]['totalDespesaEssencial'])
        totais[dat] += Number(data[dat]['totalDespesaSuperfula'])
        totais[dat] += Number(data[dat]['totalInvestimentos'])
    })

    Object.keys(data).forEach((dat) => {
        totalEsperado[dat] += Number(data[dat]['totalDespesaEssencial'])*0.50
        totalEsperado[dat] += Number(data[dat]['totalDespesaSuperfula'])*0.30
        totalEsperado[dat] += Number(data[dat]['totalInvestimentos'])*20
    })

    // totalDespesas += totalEssencial;
    // totalDespesas += totalSuperfula;
    // totalDespesas += totalInvest;
    
    return (
        <Table bordered>
            <thead>
                <th>#</th>
                <th>Esperado</th>
                <th>Realizado</th>
                <th>Total</th>
            </thead>
            <tbody>
                <tr>
                    <th>Essencial</th>
                    <td>{toBRL(totalReceitas*0.5)}</td>
                    <td>{toBRL(totalEssencial)}</td>
                    <td>{(totalReceitas*0.5) > (totalEssencial) 
                        ? <>
                            <span className="text text-success">
                                {toBRL((totalReceitas*0.5) - (totalEssencial))}
                            </span> 
                            <i className="fas fa-arrow-down ms-3" style={{ color: "green" }}></i>
                        </> 
                        : <>
                            <span className="text text-danger">
                                {toBRL(totalEssencial - (totalReceitas*0.5))}
                            </span>
                            <i className="fas fa-arrow-up" style={{ color: "red" }}></i>
                        </>
                    }</td>
                </tr>
                <tr>
                    <th>Livre / Supérflua</th>
                    <td>{toBRL(totalReceitas*0.3)}</td>
                    <td>{toBRL(totalSuperfula)}</td>
                    <td>{(totalReceitas*0.3) > (totalSuperfula) 
                        ? <>
                            <span className="text text-success">
                                {toBRL((totalReceitas*0.3)- (totalSuperfula))}
                            </span>
                            <i className="fas fa-arrow-down ms-3" style={{ color: "green" }}></i>
                        </> 
                        : <>
                            <span className="text text-danger">
                                {toBRL((totalSuperfula) - (totalReceitas*0.3))}
                            </span>
                            <i className="fas fa-arrow-up" style={{ color: "red" }}></i>
                        </>
                    }</td>
                </tr>
                <tr>
                    <th>Investimento</th>
                    <td>{toBRL(totalReceitas*0.2)}</td>
                    <td>{toBRL(totalInvest)}</td>
                    <td>{(totalReceitas*0.2) > (totalInvest)
                        ? <>
                            <span className="text text-danger">
                                {toBRL(totalReceitas*0.2 - totalInvest)}
                            </span>
                            <i className="fas fa-arrow-down ms-3" style={{ color: "red" }}></i>
                        </> 
                        : <>
                            <span className="text text-success">
                                {toBRL(totalInvest - totalReceitas*0.2)}
                            </span>
                            <i className="fas fa-arrow-up" style={{ color: "green" }}></i>
                        </>
                    }</td>
                </tr>
            </tbody>
        </Table>
    )
}

export default TabelaResumo;