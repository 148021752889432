import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert
} from "reactstrap";
import { FormErrMsg, FieldCPF, FieldDate } from "../../../components";
import {
  getAxiosAuth,
  toISO8601,
  fromISO8601,
  isDateValid,
  getTokenClientId
} from "../../../utils";
import { SEXO } from "../../../options";
import validator from "validator";
import validarCpf from "validar-cpf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";

function Conjuge(props) {
  const clienteId = getTokenClientId();
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [nomeCompleto, setaNomeCompleto] = useState("");
  const [nomeCompletoErro, setaNomeCompletoErro] = useState(null);
  const [cpf, setaCpf] = useState("");
  const [cpfErro, setaCpfErro] = useState(null);
  const [dataNascimento, setaDataNascimento] = useState("");
  const [dataNascimentoErro, setaDataNascimentoErro] = useState(null);
  const [ocupacao, setaOcupacao] = useState("");
  const [sexo, setaSexo] = useState(0);
  const [sexoErro, setaSexoErro] = useState(null);
  const [salvarConjuge, setaSalvarConjuge] = useState();
  const [removerConjuge, setaRemoverConjuge] = useState();

  const estaLimpo = () => {
    return (
      validator.isEmpty(nomeCompleto, { ignore_whitespace: true }) &&
      validator.isEmpty(cpf, { ignore_whitespace: true }) &&
      validator.isEmpty(dataNascimento, { ignore_whitespace: true }) &&
      validator.isEmpty(ocupacao, { ignore_whitespace: true })
    );
  };

  const onSubmitForm = e => {
    e.preventDefault();

    if (estaLimpo()) {
      setaNomeCompleto("");
      setaNomeCompletoErro(null);
      setaCpf("");
      setaCpfErro(null);
      setaDataNascimento("");
      setaDataNascimentoErro(null);
      setaOcupacao("");
      setaSexo(0);
      setaSexoErro(null);
      setaFormularioValido(null);
      setaFormularioErro("");

      setaRemoverConjuge(new Date());
    } else {
      const cpfValido = validaCpf();
      const dataNascimentoValida = validaDataNascimento();
      const sexoValido = validaSexo();

      if (cpfValido && dataNascimentoValida && sexoValido) {
        setaFormularioValido(true);
        setaSalvarConjuge(new Date());
      } else {
        setaFormularioValido(false);
        setaFormularioErro("Verifique os campos.");
      }
    }
  };

  // primeiro acesso: obtem dados do cônjuge
  useEffect(() => {
    const getConjuge = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/conjuge`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);
          props.history.push("/500");
          return;
        }

        if (response.status !== 200 && response.status !== 404) {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      const dados = response.data;
      // console.log(dados);

      setaNomeCompleto(dados.nomeCompleto || "");
      setaCpf(dados.cpf || "");
      setaDataNascimento(fromISO8601(dados.dataNascimento) || "");
      setaOcupacao(dados.ocupacao || "");
      setaSexo(dados.sexo || 0);
    };

    getConjuge();
  }, []);

  // salvar: quando houver cônjuge, dispara PUT
  useEffect(() => {
    const putConjuge = async () => {
      const dataNascimentoISO8601 = toISO8601(dataNascimento);

      const dados = {
        nomeCompleto,
        cpf: cpf || null,
        dataNascimento: dataNascimentoISO8601,
        ocupacao,
        sexo
      };

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/conjuge`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      if (response.status === 200) {
        let mensagem;

        if (sexo === SEXO.FEMININO) {
          mensagem = "Cônjuge salva com sucesso!";
        } else {
          mensagem = "Cônjuge salvo com sucesso!";
        }

        toast(mensagem);
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        const dados = response.data;

        if (
          dados.errors &&
          Array.isArray(dados.errors) &&
          dados.errors.length > 0
        ) {
          const entrada = dados.errors[0];

          if (entrada.field === "cpf" && entrada.error === "not_unique") {
            mensagem = "CPF já foi usado por outro cônjuge.";
            setaCpfErro(mensagem);
          }
        } else if (response.status === 500) {
          props.history.push("/500");
        } else {
          props.history.push("/404");
        }

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      }
    };

    if (formularioValido) {
      putConjuge();
    }
  }, [salvarConjuge]);

  // remover: quando não houver cônjuge, dispara DELETE
  useEffect(() => {
    const deleteConjuge = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/conjuge`;
      let response;

      try {
        response = await axiosInstance.delete(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      if (response.status === 200) {
        let mensagem;

        if (sexo === SEXO.FEMININO) {
          mensagem = "Cônjuge removida com sucesso!";
        } else {
          mensagem = "Cônjuge removido com sucesso!";
        }

        toast(mensagem);
      } else if (response.status === 400) {
        setaFormularioErro("Problema interno no servidor.");
        setaFormularioValido(false);
      } else if (response.status === 500) {
        props.history.push("/500");
      } else {
        props.history.push("/404");
      }
    };

    if (removerConjuge) {
      deleteConjuge();
    }
  }, [removerConjuge]);

  const validaNomeCompleto = () => {
    if (validator.isEmpty(nomeCompleto, { ignore_whitespace: true })) {
      setaNomeCompletoErro("Nome completo obrigatório.");
      return false;
    }

    setaNomeCompletoErro(false);
    return true;
  };

  const onChangeNomeCompleto = e => {
    setaNomeCompleto(e.target.value);
  };

  const onBlurNomeCompleto = () => {
    validaNomeCompleto();
  };

  const validaCpf = () => {
    if (validator.isEmpty(cpf, { ignore_whitespace: true })) {
      setaCpfErro(false);
      return true;
    }

    if (!validarCpf(cpf)) {
      setaCpfErro("CPF inválido.");
      return false;
    }

    setaCpfErro(false);
    return true;
  };

  const onChangeCpf = e => {
    setaCpf(e.target.value);
  };

  const onBlurCpf = () => {
    validaCpf();
  };

  const validaDataNascimento = () => {
    if (validator.isEmpty(dataNascimento, { ignore_whitespace: true })) {
      setaDataNascimentoErro("Data de nascimento obrigatória.");
      return false;
    }

    if (!isDateValid(dataNascimento)) {
      setaDataNascimentoErro("Data inválida.");
      return false;
    }

    setaDataNascimentoErro(false);
    return true;
  };

  const onChangeDataNascimento = e => {
    setaDataNascimento(e.target.value);
  };

  const onBlurDataNascimento = () => {
    validaDataNascimento();
  };

  const onChangeOcupacao = e => {
    setaOcupacao(e.target.value);
  };

  const validaSexo = () => {
    if (sexo !== SEXO.MASCULINO && sexo !== SEXO.FEMININO) {
      setaSexoErro("Campo obrigatório.");
      return false;
    }

    setaSexoErro(false);
    return true;
  };

  const onChangeSexo = (valor, e) => {
    setaSexo(valor);
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <Card>
          <CardHeader>
            <i className="far fa-edit" />
            <strong>Cônjuge</strong>
          </CardHeader>
          <CardBody>
            <Row form>
              <Col xs={12} xl={6}>
                <FormGroup>
                  <Label
                    htmlFor="nome"
                    className={!estaLimpo() ? "required" : null}
                  >
                    Nome
                  </Label>
                  <Input
                    type="text"
                    id="nome"
                    placeholder="Nome completo da/do cônjuge."
                    value={nomeCompleto}
                    onChange={onChangeNomeCompleto}
                    onBlur={onBlurNomeCompleto}
                  />
                  {nomeCompletoErro && (
                    <FormErrMsg>{nomeCompletoErro}</FormErrMsg>
                  )}
                </FormGroup>
              </Col>
              <Col xs={6} xl={3}>
                <FormGroup>
                  <Label htmlFor="cpf">CPF</Label>
                  <FieldCPF
                    id="cpf"
                    className="form-control"
                    value={cpf}
                    onChange={onChangeCpf}
                    onBlur={onBlurCpf}
                  />
                  {cpfErro && <FormErrMsg>{cpfErro}</FormErrMsg>}
                </FormGroup>
              </Col>
              <Col xs={6} xl={3}>
                <FormGroup>
                  <Label
                    htmlFor="nascimento"
                    className={!estaLimpo() ? "required" : ""}
                  >
                    Data de nascimento
                  </Label>
                  <FieldDate
                    value={dataNascimento}
                    onChange={onChangeDataNascimento}
                    onBlur={onBlurDataNascimento}
                  />
                  {dataNascimentoErro && (
                    <FormErrMsg>{dataNascimentoErro}</FormErrMsg>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} xl={3}>
                <FormGroup>
                  <Label htmlFor="ocupacao">Ocupação</Label>
                  <Input
                    type="text"
                    id="ocupacao"
                    value={ocupacao}
                    onChange={onChangeOcupacao}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} xl={3}>
                <Label className={!estaLimpo() ? "required" : ""}>Sexo</Label>
                <FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="radio"
                      name="sexo"
                      id="sexo-m"
                      value={SEXO.MASCULINO}
                      checked={sexo === SEXO.MASCULINO}
                      onChange={onChangeSexo.bind(this, SEXO.MASCULINO)}
                    />
                    <Label check htmlFor="sexo-m">
                      Masculino
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="radio"
                      name="sexo"
                      id="sexo-f"
                      value={SEXO.FEMININO}
                      checked={sexo === SEXO.FEMININO}
                      onChange={onChangeSexo.bind(this, SEXO.FEMININO)}
                    />
                    <Label check htmlFor="sexo-f">
                      Feminino
                    </Label>
                  </FormGroup>
                  {sexoErro && <FormErrMsg>{sexoErro}</FormErrMsg>}
                </FormGroup>
              </Col>
            </Row>
            {formularioValido === false && (
              <Alert color="danger" style={{ display: "inline-block" }}>
                Não foi possível salvar. {formularioErro}
              </Alert>
            )}
          </CardBody>
          <CardFooter>
            <Button color="primary" style={{ display: "block" }}>
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </div>
  );
}

export default withRouter(Conjuge);
