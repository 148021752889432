import React, { useState, useEffect } from "react";
import { Container, Table } from "reactstrap";
import { toBRL, getClienteId } from "../../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { getAxiosAuth, wasRequestCancelled } from "../../../utils";
import { DateCarousel } from "../../../components";
import { withRouter } from "react-router-dom";
import BtnModalFormReceita from "./BtnModalFormReceita";
import LinhaReceita from "./LinhaReceita";

function Receitas(props) {
  const m = moment();
  const [totalReceitas, setaTotalReceitas] = useState(0.0);
  const [totalDeducoes, setaTotalDeducoes] = useState(0.0);
  const [totalLiquido, setaTotalLiquido] = useState(0.0);
  const [receitas, setaReceitas] = useState([]);
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [inseriu, setaInseriu] = useState(null);
  const [atualizou, setaAtualizou] = useState(null);
  const [removeu, setaRemoveu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onAdd = () => {
    setaInseriu(new Date());
  };

  const onEdit = () => {
    setaAtualizou(new Date());
  };

  const onDelete = () => {
    setaRemoveu(new Date());
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  const getReceitas = async () => {
    const clienteId = getClienteId();
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/receitas`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    setIsLoading(false);

    if (response.data && "totalReceitas" in response.data) {
      setaTotalReceitas(response.data.totalReceitas);
    }

    if (response.data && "totalDeducoes" in response.data) {
      setaTotalDeducoes(response.data.totalDeducoes);
    }

    if (response.data && "totalLiquido" in response.data) {
      setaTotalLiquido(response.data.totalLiquido);
    }

    if (response.data && "items" in response.data) {
      setaReceitas(response.data.items);
    }
  };

  useEffect(() => {
    if (inseriu) {
      toast("Receita adicionada!");
      getReceitas();
    }
  }, [inseriu]);

  useEffect(() => {
    if (atualizou) {
      toast("Receita atualizada!");
      getReceitas();
    }
  }, [atualizou]);

  useEffect(() => {
    if (removeu) {
      toast("Receita removida!");
      getReceitas();
    }
  }, [removeu]);

  // primeiro acesso, e quando altera data
  useEffect(() => {
    getReceitas();
  }, [mes, ano]);

  return (
    <div className="animated fadeIn">
      <Container className="receitas-container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1049 }}
        />

        <DateCarousel
          mes={mes}
          ano={ano}
          onChange={onChangeDate}
        />
        <BtnModalFormReceita mes={mes} ano={ano} onAdd={onAdd} />

        <Table size="sm" hover>
          <thead className="thead-dark">
            <tr>
              <th className="text-left" colSpan={2}>
                Total Receitas
              </th>
              <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
              <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
              <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
              <th className="text-right text-nowrap" style={{ width: 120 }}>

                {toBRL(totalReceitas)}
              </th>
              <th className="d-none d-sm-table-cell" style={{ width: 80 }} />
            </tr>
            <tr>
              <th className="text-left text-small" colSpan={2}>
                Deduções
              </th>
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="text-right text-nowrap text-danger">
                <em>({toBRL(totalDeducoes)})</em>
              </th>
              <th className="d-none d-sm-table-cell" />
            </tr>
            <tr>
              <th className="text-left" colSpan={2}>
                Total Líquido
              </th>
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="text-right text-nowrap">{toBRL(totalLiquido)}</th>
              <th className="d-none d-sm-table-cell" />
            </tr>
          </thead>
          {isLoading && (
            <tbody>
              <tr className="table-success">
                <td colSpan={6}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && receitas.length === 0 && (
            <tbody>
              <tr className="table-success">
                <td colSpan={6}>
                  <p className="text-muted text-center mt-3">
                    Sem receitas no período.
                  </p>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading &&
            receitas.length > 0 &&
            receitas.map((receita) => (
              <LinhaReceita
                key={`receita_` + receita.id}
                onEdit={onEdit}
                onDelete={onDelete}
                {...receita}
              />
            ))}
        </Table>
      </Container>
    </div>
  );
}

export default withRouter(Receitas);
