import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import validator from "validator";
import {
  isPositiveBRL,
  getAxiosAuth,
  fromBRL,
  toBRLValueOnly,
  getTimestamp,
  getClienteId,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import { FormErrMsg, Monetary } from "../../../components";
import PropTypes from "prop-types";
import { maxDescriptionWidthText } from "../../../options";

function BtnModalFormDivida(props) {
  const clienteId = getClienteId();
  const [aberto, setaAberto] = useState(false);
  const [nome, setaNome] = useState("");
  const [nomeErro, setaNomeErro] = useState(null);
  const [credor, setaCredor] = useState("");
  const [credorErro, setaCredorErro] = useState(null);
  const [valorTotalHoje, setaValorTotalHoje] = useState("");
  const [valorTotalHojeErro, setaValorTotaHojeErro] = useState(null);
  const [obs, setaObs] = useState("");

  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState(null);
  const [continuarInserindo, setaContinuarInserindo] = useState(false);
  const isFirstRun = useRef(true);

  // --- eventos para o modal ---
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  }, []);

  const limparFormulario = () => {
    // limpando mensagens de erro e validação
    setaNomeErro(null);
    setaCredorErro(null);
    setaValorTotaHojeErro(null);

    // limpando campos
    setaNome("");
    setaCredor("");
    setaValorTotalHoje("");
    setaObs("");
  };

  const popularFormularioEditar = () => {
    setaNome(props.nome);
    setaCredor(props.credor);
    setaValorTotalHoje(toBRLValueOnly(props.valorTotalHoje));
    setaObs(props.obs);
  };

  const toggle = (e) => {
    e && e.stopPropagation();
    setaAberto(!aberto);

    if (!aberto) {
      // limpando estados para props de entrada
      limparFormulario();

      if (props.id) {
        popularFormularioEditar();
      }
    }
  };

  // --- eventos para o formulário ---
  const onSubmitKeepInsertingForm = (e) => {
    setaContinuarInserindo(true);

    return onSubmitForm(e);
  };

  const onSubmitCloseForm = (e) => {
    setaContinuarInserindo(false);

    return onSubmitForm(e);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    // validando campos
    const nomeValido = validaNome();
    const credorValido = validaCredor();
    const valorTotalHojeValido = validaValorTotalHoje();

    if (nomeValido && credorValido && valorTotalHojeValido) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos acima.");
      setaFormularioValido(false);
    }
  };

  useEffect(() => {
    const postDivida = async (axiosInstance, dados) => {
      const uri = `/clientes/${clienteId}/dividas`;
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      // limpa ou fecha modal
      if (continuarInserindo) {
        limparFormulario();
      } else {
        setaAberto(false);
      }

      props.onAdd && props.onAdd();
    };

    const putDivida = async (axiosInstance, dados, dividaId) => {
      const uri = `/clientes/${clienteId}/dividas/${dividaId}`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        props.history.push("/500");
        return;
      }

      setaAberto(false);
      props.onEdit && props.onEdit(props.id);
    };

    const salvarDados = async () => {
      // preparando dados para enviar
      const dados = {
        nome,
        credor,
        valorTotalHoje: fromBRL(valorTotalHoje),
        obs,
      };

      const axiosInstance = getAxiosAuth(() => {
        return props.history.push("/login");
      });

      if (props.id) {
        await putDivida(axiosInstance, dados, props.id);
      } else {
        await postDivida(axiosInstance, dados);
      }
    };

    if (formularioValido) {
      salvarDados();
    }
  }, [enviarFormulario]);

  // --- eventos para o campo Nome ---
  const onChangeNome = (e) => {
    setaNome(e.target.value);
  };

  const validaNome = () => {
    if (validator.isEmpty(nome, { ignore_whitespace: true })) {
      setaNomeErro("Campo obrigatório.");
      return false;
    }

    setaNomeErro(false);
    return true;
  };

  const onBlurNome = () => validaNome();

  // --- eventos para o campo Credor ---
  const onChangeCredor = (e) => {
    setaCredor(e.target.value);
  };

  const validaCredor = () => {
    if (validator.isEmpty(credor, { ignore_whitespace: true })) {
      setaCredorErro("Campo obrigatório.");
      return false;
    }

    setaCredorErro(false);
    return true;
  };

  const onBlurCredor = () => validaCredor();

  // --- eventos para o campo Valor total hoje ---
  const onChangeValorTotalHoje = (e) => {
    setaValorTotalHoje(e.target.value);
  };

  const validaValorTotalHoje = () => {
    if (validator.isEmpty(valorTotalHoje, { ignore_whitespace: true })) {
      setaValorTotaHojeErro("Campo obrigatório.");
      return false;
    }

    if (!isPositiveBRL(valorTotalHoje)) {
      setaValorTotaHojeErro("Valor monetário inválido.");
      return false;
    }

    setaValorTotaHojeErro(false);
    return true;
  };

  const onBlurValorTotalHoje = () => validaValorTotalHoje();

  // --- eventos para o campo Obs ---
  const onChangeObs = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxDescriptionWidthText) {
      setaObs(inputValue);
    }else {
      setaObs(inputValue.slice(0, maxDescriptionWidthText));
    }
  };

  // --- elementos ---
  const trigger = props.id ? (
    <Button size="sm" color="light" style={{ marginRight: 5 }} onClick={toggle}>
      <i className="fas fa-pen" />
    </Button>
  ) : (
    <Button color="primary" size="sm" className="float-right" onClick={toggle}>
      <i className="fa fa-plus" />
      Adicionar
    </Button>
  );

  return (
    <Fragment>
      {trigger}
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>
          {props.id ? "Editar Dívida" : "Adicionar Dívida"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <FormGroup>
              <Label className="required">Nome da dívida</Label>
              <Input
                type="text"
                maxLength={255}
                value={nome}
                onChange={onChangeNome}
                onBlur={onBlurNome}
                valid={nomeErro === false}
                invalid={!!nomeErro}
              />
              <FormErrMsg>{nomeErro}</FormErrMsg>
            </FormGroup>
            <FormGroup>
              <Label className="required">Credor</Label>
              <Input
                type="text"
                maxLength={255}
                value={credor}
                onChange={onChangeCredor}
                onBlur={onBlurCredor}
                valid={credorErro === false}
                invalid={!!credorErro}
              />
              <FormErrMsg>{credorErro}</FormErrMsg>
            </FormGroup>
            <FormGroup>
              <Label className="required">Valor total hoje</Label>
              <Monetary
                value={valorTotalHoje}
                onChange={onChangeValorTotalHoje}
                onBlur={onBlurValorTotalHoje}
                valid={valorTotalHojeErro === false}
                invalid={!!valorTotalHojeErro}
              />
              <FormErrMsg>{valorTotalHojeErro}</FormErrMsg>
            </FormGroup>
            <FormGroup>
              <Label>Observações</Label>
              <Input type="textarea" value={obs} onChange={onChangeObs}   onKeyPress={(e) => {
                const editedText = e.target.textContent + e.key;
                if (editedText.length > maxDescriptionWidthText) {
                  e.preventDefault(); // Impede a entrada de mais caracteres
                }
              }}/>
            </FormGroup>
            {formularioValido === false && (
              <Alert color="danger">
                Não foi possível salvar dívida. {formularioErro}
              </Alert>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          {!props.id && (
            <Fragment>
              <Button color="primary" onClick={onSubmitCloseForm}>
                Salvar e fechar
              </Button>
              <Button color="primary" onClick={onSubmitKeepInsertingForm}>
                Salvar e adicionar outra
              </Button>
            </Fragment>
          )}
          {props.id && (
            <Button color="primary" onClick={onSubmitCloseForm}>
              Salvar
            </Button>
          )}
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalFormDivida.propTypes = {
  id: PropTypes.number,
  nome: PropTypes.string,
  valorTotalHoje: PropTypes.number,
  obs: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
};

export default withRouter(BtnModalFormDivida);
