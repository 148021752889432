import React, { useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardFooter,
  Button,
  Alert,
} from "reactstrap";
import { MESES } from "../../../options";
import moment from "moment";
import validator from "validator";
import { getTokenClientId, getAxiosAuth } from "../../../utils";

function RelatorioMensal(props) {
  const clienteId = getTokenClientId();
  const [mes, setaMes] = useState(moment().format("M"));
  const [ano, setaAno] = useState(moment().format("YYYY"));
  const [formularioMensagemErro, setaFormularioMensagemErro] = useState("");
  const [isLoading, setaIsLoading] = useState(false);
  const [obterExcel, setaObterExcel] = useState(false);

  const validarData = useCallback(() => {
    if (validator.isEmpty(ano, { ignore_whitespace: true })) {
      setaFormularioMensagemErro("Ano é obrigatório");
      return false;
    }

    if (mes < 1 || mes > 12) {
      setaFormularioMensagemErro("Mês inválido.");
      return false;
    }

    const m = moment(`${ano}-${mes}-1`, "YYYY-M-D", true);
    if (!m.isValid()) {
      setaFormularioMensagemErro("Data inválida");
      return false;
    } else if (m.isAfter(moment().date(1))) {
      setaFormularioMensagemErro("Dados indisponíveis para a data escolhida");
      return false;
    }

    return true;
  }, [mes, ano]);

  const getRelatorioMensal = useCallback(async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/relatorios/relatorio-mensal`;
    const dados = {
      mes,
      ano,
      obterExcel,
    };
    let response;

    setaIsLoading(true);

    try {
      response = await axiosInstance.get(uri, {
        params: dados,
        responseType: "blob",
        timeout: 30000,
      });
    } catch (error) {
      console.error(error);
      props.history.push("/500");
      return;
    }
    setaIsLoading(false);
    processaBlob(
      response.data,
      obterExcel ? "relatorio-mensal.xlsx" : "relatorio-mensal.pdf"
    );
  }, [mes, ano, obterExcel]);

  const processaBlob = (responseDataBlob, fileName) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    const url = URL.createObjectURL(responseDataBlob);
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const mesesOpts = MESES.map((mes, i) => (
    <option value={i + 1} key={`mes_opt_${i}`}>
      {mes}
    </option>
  ));

  const mesInputHandler = (event) => {
    setaMes(event.target.value);
  };

  const anoInputHandler = (event) => {
    setaAno(event.target.value);
  };

  const anoBlurHandler = () => {
    validarData();
  };

  const submitInputHandler = async (event) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    const dataValida = validarData();

    if (dataValida) {
      setaFormularioMensagemErro("");
      await getRelatorioMensal();
    }
  };

  const formatoInputHandler = (obterExcel) => {
    setaObterExcel(obterExcel);
  };

  return (
    <div className="animated fadeIn">
      <Container>
        <Form onSubmit={submitInputHandler} disabled={isLoading}>
          <Card>
            <CardHeader>
              <i
                className={obterExcel ? "far fa-file-excel" : "far fa-file-pdf"}
              />
              <strong>Relatório Mensal</strong>
            </CardHeader>
            <CardBody>
              <Row form>
                <Col xs={2}>
                  <FormGroup>
                    <Label className="required">Mês</Label>
                    <Input
                      type="select"
                      value={mes}
                      onChange={mesInputHandler}
                      disabled={isLoading}
                    >
                      {mesesOpts}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={"auto"}>
                  <FormGroup>
                    <Label className="required">Ano</Label>
                    <Input
                      type="text"
                      onChange={anoInputHandler}
                      onBlur={anoBlurHandler}
                      value={ano}
                      style={{ maxWidth: 100 }}
                      invalid={!!formularioMensagemErro}
                      disabled={isLoading}
                    />
                  </FormGroup>
                </Col>
                <Col xs={"auto"}>
                  <FormGroup>
                    <Label className="required">Formato</Label>
                    <FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="radio"
                          checked={obterExcel === false}
                          onChange={formatoInputHandler.bind(this, false)}
                        />
                        <Label check>PDF</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="radio"
                          checked={obterExcel === true}
                          onChange={formatoInputHandler.bind(this, true)}
                        />
                        <Label check>Excel</Label>
                      </FormGroup>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>

              {formularioMensagemErro && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível obter relatório. {formularioMensagemErro}
                </Alert>
              )}
            </CardBody>
            <CardFooter>
              <Button color={"primary"} disabled={isLoading}>
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Carregando...
                  </>
                ) : (
                  "Gerar relatório"
                )}
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </div>
  );
}

export default withRouter(RelatorioMensal);
