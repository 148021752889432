import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormText,
  Col,
  Alert,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  isPositiveBRL,
  fromBRL,
  fromPercent,
  toBRLValueOnly,
  PGTO,
  NPER,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import { Monetary, Percent, FormErrMsg } from "../../../components";
import validator from "validator";

function BtnModalCalculadoraSonhos(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [calculoOpcao, setaCalculoOpcao] = useState();
  const [custoProjeto, setaCustoProjeto] = useState("");
  const [custoProjetoErro, setaCustoProjetoErro] = useState(null);
  const [prazoAnos, setaPrazoAnos] = useState("");
  const [prazoAnosErro, setaPrazoAnosErro] = useState(null);
  const [prazoMeses, setaPrazoMeses] = useState("");
  const [prazoMesesErro, setaPrazoMesesErro] = useState(null);
  const [juros, setaJuros] = useState("");
  const [jurosErro, setaJurosErro] = useState(null);
  const [valorPoupadoMes, setaValorPoupadoMes] = useState("");
  const [valorPoupadoMesErro, setaValorPoupadoMesErro] = useState(null);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [calcular, setaCalcular] = useState();
  const [calculado, setaCalculado] = useState();
  const [resultadoValorPoupadoMes, setaResultadoValorPoupadoMes] = useState();
  const [resultadoPrazoAnos, setaResultadoPrazoAnos] = useState();
  const [resultadoPrazoMeses, setaResultadoPrazoMeses] = useState();

  const toggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setaCustoProjeto(props.custoProjeto);
    setaCustoProjetoErro(null);
    setaPrazoAnos(props.prazoAnos);
    setaPrazoAnosErro(null);
    setaPrazoMeses(props.prazoMeses);
    setaPrazoMesesErro(null);
    setaJuros(props.juros);
    setaJurosErro(null);
    setaValorPoupadoMes(props.valorPoupadoMes);
    setaValorPoupadoMesErro(null);
    setaFormularioValido(null);
    setaFormularioErro("");
    setaCalcular(false);
    setaCalculado(null);
    setaResultadoValorPoupadoMes("");
    setaResultadoPrazoAnos("");
    setaResultadoPrazoMeses("");
  }, [
    props.custoProjeto,
    props.prazoAnos,
    props.prazoMeses,
    props.juros,
    props.valorPoupadoMes,
    isOpen,
  ]);

  const onChangeCustoProjeto = (e) => {
    setaCustoProjeto(e.target.value);
    setaCalculado(false);
  };

  const onChangeCalculoOpcao = (valor, e) => {
    setaCalculoOpcao(valor);
    setaCalculado(false);
  };

  const onChangeValorPoupadoMes = (e) => {
    setaValorPoupadoMes(e.target.value);
    setaCalculado(false);
  };

  const onChangeJuros = (e) => {
    setaJuros(e.target.value);
    setaCalculado(false);
  };

  const onChangePrazoAnos = (e) => {
    setaPrazoAnos(e.target.value);
    setaCalculado(false);
  };

  const onChangePrazoMeses = (e) => {
    setaPrazoMeses(e.target.value);
    setaCalculado(false);
  };

  const validaCustoProjeto = () => {
    if (validator.isEmpty(custoProjeto, { ignore_whitespace: true })) {
      setaCustoProjetoErro("Campo obrigatório");
      return false;
    }

    if (!isPositiveBRL(custoProjeto)) {
      setaCustoProjetoErro("Valor monetário inválido");
      return false;
    }

    setaCustoProjetoErro(false);
    return true;
  };

  const validaValorPoupadoMes = () => {
    if (calculoOpcao !== 1) {
      setaValorPoupadoMesErro(null);
      return true;
    }

    if (validator.isEmpty(valorPoupadoMes, { ignore_whitespace: true })) {
      setaValorPoupadoMesErro("Campo obrigatório");
      return false;
    }

    if (!isPositiveBRL(valorPoupadoMes)) {
      setaValorPoupadoMesErro("Valor monetário inválido");
      return false;
    }

    setaValorPoupadoMesErro(false);
    return true;
  };

  const validaJuros = () => {
    if (validator.isEmpty(juros, { ignore_whitespace: true })) {
      setaJurosErro("Campo obrigatório");
      return false;
    }

    if (!validator.isDecimal(juros, { locale: "pt-BR" })) {
      setaJurosErro("Porcentagem inválida.");
      return false;
    }

    setaJurosErro(false);
    return true;
  };

  const validaPrazoAnos = () => {
    if (calculoOpcao !== 2) {
      setaPrazoAnosErro(null);
      return true;
    }

    if (validator.isEmpty(prazoAnos, { ignore_whitespace: true })) {
      setaPrazoAnos("0");
    } else if (!validator.isInt(prazoAnos) || prazoAnos < 0) {
      setaPrazoAnosErro("Ano: Valor inválido");
      return false;
    }

    setaPrazoAnosErro(false);
    return true;
  };

  const validaPrazoMeses = () => {
    if (calculoOpcao !== 2) {
      setaPrazoMesesErro(null);
      return true;
    }

    if (validator.isEmpty(prazoMeses, { ignore_whitespace: true })) {
      setaPrazoMeses("0");
    } else if (!validator.isInt(prazoMeses) || prazoMeses < 0) {
      setaPrazoMesesErro("Mês: Valor inválido");
      return false;
    } else if (prazoMeses > 11) {
      setaPrazoMesesErro("Mês: maior que 11, use o campo anos.");
      return false;
    }

    setaPrazoMesesErro(false);
    return true;
  };

  const validaPrazo = () => {
    if (calculoOpcao !== 2) {
      setaPrazoAnosErro(null);
      return true;
    }
    if (
      (prazoMeses === "0" ||
        validator.isEmpty(prazoMeses, { ignore_whitespace: true })) &&
      (prazoAnos === "0" ||
        validator.isEmpty(prazoAnos, { ignore_whitespace: true }))
    ) {
      setaPrazoAnosErro("Prazo inválido");
      return false;
    }

    setaPrazoAnosErro(false);
    return true;
  };

  const onBlurCustoProjeto = () => {
    validaCustoProjeto();
  };

  const onBlurValorPoupadoMes = () => {
    validaValorPoupadoMes();
  };

  const onBlurJuros = () => {
    validaJuros();
  };

  const onBlurPrazoAnos = () => {
    validaPrazoAnos();
  };

  const onBlurPrazoMeses = () => {
    validaPrazoMeses();
  };

  const onSubmitForm = (e) => {
    e && e.preventDefault();

    const custoProjetoValido = validaCustoProjeto();
    const valorPoupadoMesValido = validaValorPoupadoMes();
    const jurosValido = validaJuros();
    const prazoAnosValido = validaPrazoAnos();
    const prazoMesesValido = validaPrazoMeses();
    const prazoValido = validaPrazo();

    if (
      custoProjetoValido &&
      valorPoupadoMesValido &&
      jurosValido &&
      prazoAnosValido &&
      prazoMesesValido &&
      prazoValido
    ) {
      setaFormularioValido(true);
      setaCalcular(new Date());
    } else {
      setaFormularioValido(false);
      setaCalcular(false);
      setaCalculado(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  useEffect(() => {
    if (!calcular) {
      return;
    }

    // https://fazaconta.com/financiamentos-pmt-rate-nper.htm
    if (calculoOpcao === 1) {
      const Taxa = fromPercent(juros) / 100;
      const Pmt = fromBRL(valorPoupadoMes);
      const VP = fromBRL(custoProjeto);
      const totalPrazo = Math.round(NPER(-Taxa, Pmt, -VP));
      const anos = Math.trunc(totalPrazo / 12);
      const meses = Math.round(totalPrazo % 12);
      setaResultadoPrazoAnos(String(anos));
      setaResultadoPrazoMeses(String(meses));
    } else if (calculoOpcao === 2) {
      const Taxa = fromPercent(juros) / 100;
      const NPer = parseInt(prazoAnos) * 12 + parseInt(prazoMeses);
      const VP = fromBRL(custoProjeto);
      const valorParcela = PGTO(-Taxa, NPer, -VP);
      setaResultadoValorPoupadoMes(toBRLValueOnly(valorParcela));
    }

    setaCalculado(new Date());
  }, [calcular]);

  const onClickBtnUsarValores = (e) => {
    e.preventDefault();

    let retPrazoAnos, retPrazoMeses, retValorPoupadoMes;

    if (calculoOpcao === 1) {
      retPrazoAnos = resultadoPrazoAnos;
      retPrazoMeses = resultadoPrazoMeses;
      retValorPoupadoMes = valorPoupadoMes;
    } else if (calculoOpcao === 2) {
      retPrazoAnos = prazoAnos;
      retPrazoMeses = prazoMeses;
      retValorPoupadoMes = resultadoValorPoupadoMes;
    }

    if (props.onUsarValores) {
      props.onUsarValores(
        custoProjeto,
        juros,
        retPrazoAnos,
        retPrazoMeses,
        retValorPoupadoMes
      );
      setIsOpen(false);
    }
  };

  const campoJuros = (
    <FormGroup>
      <Label>Taxa de juros</Label>
      <Percent
        value={juros}
        onChange={onChangeJuros}
        onBlur={onBlurJuros}
        valid={jurosErro === false}
        invalid={!!jurosErro}
      />
      <FormErrMsg>{jurosErro}</FormErrMsg>
      <FormText className="help-block">% ao mês</FormText>
    </FormGroup>
  );

  return (
    <Fragment>
      <Button color="warning" onClick={toggle} size="sm">
        <i className="fas fa-calculator" />
        &nbsp;Calculadora de Sonhos
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} fade>
        <ModalHeader>
          <i className="fas fa-calculator" />
          &nbsp; Calculadora de Sonhos
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <FormGroup>
              <Label className="required">Valor do sonho</Label>
              <Monetary
                value={custoProjeto}
                onChange={onChangeCustoProjeto}
                onBlur={onBlurCustoProjeto}
                valid={custoProjetoErro === false}
                invalid={!!custoProjetoErro}
              />
              <FormErrMsg>{custoProjetoErro}</FormErrMsg>
            </FormGroup>
            <FormGroup>
              <Label className="required">Quero saber</Label>
              <FormGroup>
                <FormGroup check inline>
                  <Input
                    type="radio"
                    id="opt2"
                    value={2}
                    checked={calculoOpcao === 2}
                    onChange={onChangeCalculoOpcao.bind(this, 2)}
                  />
                  <Label check htmlFor="opt2">
                    Quanto preciso guardar mensalmente para atingir o meu
                    objetivo?
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    type="radio"
                    id="opt1"
                    value={1}
                    checked={calculoOpcao === 1}
                    onChange={onChangeCalculoOpcao.bind(this, 1)}
                  />
                  <Label check htmlFor="opt1">
                    Quando vou realizar o meu sonho?
                  </Label>
                </FormGroup>
              </FormGroup>
            </FormGroup>
            {calculoOpcao === 1 && (
              <FormGroup row className="my-0">
                <Col xs={8}>
                  <FormGroup>
                    <Label className="required">
                      Quanto vou guardar por mês?
                    </Label>
                    <Monetary
                      value={valorPoupadoMes}
                      onChange={onChangeValorPoupadoMes}
                      onBlur={onBlurValorPoupadoMes}
                      valid={valorPoupadoMesErro === false}
                      invalid={!!valorPoupadoMesErro}
                    />
                    <FormErrMsg>{valorPoupadoMesErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col xs={4}>{campoJuros}</Col>
              </FormGroup>
            )}

            {calculoOpcao === 2 && (
              <FormGroup row className="my-0">
                <Col xs={8}>
                  <FormGroup>
                    <Label className="required">Em quanto tempo?</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        className="text-right"
                        style={{ maxWidth: 80 }}
                        value={prazoAnos}
                        onChange={onChangePrazoAnos}
                        onBlur={onBlurPrazoAnos}
                        valid={prazoAnosErro === false}
                        invalid={!!prazoAnosErro}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>anos</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        className="text-right"
                        style={{ maxWidth: 80 }}
                        value={prazoMeses}
                        onChange={onChangePrazoMeses}
                        onBlur={onBlurPrazoMeses}
                        valid={prazoMesesErro === false}
                        invalid={!!prazoMesesErro}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>meses</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormErrMsg>{prazoAnosErro}</FormErrMsg>
                    <FormErrMsg>{prazoMesesErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col xs={4}>{campoJuros}</Col>
              </FormGroup>
            )}
            {formularioValido === false && (
              <Alert color="danger" style={{ display: "inline-block" }}>
                Não foi possível calcular. {formularioErro}
              </Alert>
            )}
            {(calculoOpcao === 1 || calculoOpcao === 2) && !calculado && (
              <FormGroup row className="my-0">
                <Col xs="12" className="text-center">
                  <Button color="warning" onClick={onSubmitForm}>
                    Calcular
                  </Button>
                </Col>
              </FormGroup>
            )}
            {calculado && (
              <Fragment>
                <hr />
                <h5 className="mb-4 text-center">Resultado</h5>
                {calculoOpcao === 1 && (
                  <FormGroup row className="my-0">
                    <Col>
                      <FormGroup>
                        <Label>
                          Você conseguirá realizar este projeto em até
                        </Label>
                        <InputGroup>
                          <Input
                            type="text"
                            className="text-right"
                            style={{ maxWidth: 80 }}
                            value={resultadoPrazoAnos}
                            readOnly
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>anos</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="text-right"
                            style={{ maxWidth: 80 }}
                            value={resultadoPrazoMeses}
                            readOnly
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>meses</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                )}

                {calculoOpcao === 2 && (
                  <Fragment>
                    <FormGroup row className="my-0">
                      <Col>
                        <FormGroup>
                          <Label>Você deverá poupar mensalmente</Label>
                          <Monetary value={resultadoValorPoupadoMes} readOnly />
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          {calculado && (
            <Button color="primary" onClick={onClickBtnUsarValores}>
              Usar valores
            </Button>
          )}
          <Button color="link" onClick={toggle}>
            {calculado ? "Descartar e fechar" : "Fechar"}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalCalculadoraSonhos.propTypes = {
  custoProjeto: PropTypes.string.isRequired,
  prazoAnos: PropTypes.string,
  prazoMeses: PropTypes.string,
  juros: PropTypes.string,
  valorPoupadoMes: PropTypes.string,
  onUsarValores: PropTypes.func,
};

export default withRouter(BtnModalCalculadoraSonhos);
