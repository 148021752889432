import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

function AutocompleteInput(props) {
  const { options, ...otherProps } = props;
  return (
    <div className="autocomplete">
      <Input list="data" autoComplete="off" {...otherProps} />
      <datalist id="data">
        {options.map(option => (
          <option value={option} key={`opt_${option}`} />
        ))}
      </datalist>
    </div>
  );
}

AutocompleteInput.propTypes = {
  options: PropTypes.array.isRequired
};

export default AutocompleteInput;
