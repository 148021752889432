import React, { Fragment } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { FormErrMsg } from "../../../components";

function HistoricoFamiliarCampos(props) {
  return (
    <Fragment>
      <Row form>
        <Col sm={12}>
          <FormGroup>
            <Label className={props.required ? "required" : ""}>Nome</Label>
            <Input
              type="text"
              placeholder={props.placeholder}
              value={props.nome}
              valid={props.nomeErro === false}
              invalid={!!props.nomeErro}
              onChange={props.onChangeNome}
              onBlur={props.onBlurNome}
            />
            <FormErrMsg>{props.nomeErro}</FormErrMsg>
          </FormGroup>
        </Col>
        <Col sm={2} lg={3} xl={2}>
          <FormGroup>
            <Label className={props.required ? "required" : ""}>Idade</Label>
            <Input
              type="text"
              className="text-right"
              value={props.idade}
              valid={props.idadeErro === false}
              invalid={!!props.idadeErro}
              onChange={props.onChangeIdade}
              onBlur={props.onBlurIdade}
            />
            <FormErrMsg>{props.idadeErro}</FormErrMsg>
          </FormGroup>
        </Col>
        <Col sm={10} lg={9} xl={10}>
          <FormGroup>
            <Label>Estado de saúde</Label>
            <Input
              type="text"
              placeholder="Descreva"
              value={props.estadoSaude}
              valid={props.estadoSaudeErro === false}
              invalid={!!props.estadoSaudeErro}
              onChange={props.onChangeEstadoSaude}
              onBlur={props.onBlurEstadoSaude}
            />
            <FormErrMsg>{props.estadoSaudeErro}</FormErrMsg>
          </FormGroup>
        </Col>
        <Col sm={2} className="d-flex align-items-center">
          <FormGroup>
            <FormGroup check>
              <Input
                type="checkbox"
                value={true}
                checked={!!props.falecido}
                onChange={props.onChangeFalecido}
              />
              <Label>Falecido/a</Label>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>
      {props.mostrarCamposIrmao && (
        <Fragment>
          <Row form>
            <Col md={"auto"}>
              <FormGroup style={{ maxWidth: 120 }}>
                <Label>Número de filhos</Label>
                <Input
                  type="text"
                  value={props.numeroFilhos}
                  valid={props.numeroFilhosErro === false}
                  invalid={!!props.numeroFilhosErro}
                  onChange={props.onChangeNumeroFilhos}
                  onBlur={props.onBlurNumeroFilhos}
                />
                <FormErrMsg>{props.numeroFilhosErro}</FormErrMsg>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={12}>
              <FormGroup>
                <Label>Profissão/Observação:</Label>
                <Input
                  type="textarea"
                  placeholder="Descreva"
                  value={props.obs}
                  onChange={props.onChangeObs}
                />
              </FormGroup>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
}

HistoricoFamiliarCampos.propTypes = {
  required: PropTypes.bool,
  mostrarCamposIrmao: PropTypes.bool,
  nome: PropTypes.string,
  nomeErro: PropTypes.any,
  onChangeNome: PropTypes.func,
  onBlurNome: PropTypes.func,
  idade: PropTypes.string,
  idadeErro: PropTypes.any,
  onChangeIdade: PropTypes.func,
  onBlurIdade: PropTypes.func,
  estadoSaude: PropTypes.string,
  estadoSaudeErro: PropTypes.any,
  onChangeEstadoSaude: PropTypes.func,
  onBlurEstadoSaude: PropTypes.func,
  falecido: PropTypes.bool,
  onChangeFalecido: PropTypes.func,
  numeroFilhos: PropTypes.string,
  numeroFilhosErro: PropTypes.any,
  onChangeNumeroFilhos: PropTypes.func,
  onBlurNumeroFilhos: PropTypes.func,
  obs: PropTypes.string,
  onChangeObs: PropTypes.func
};

export default HistoricoFamiliarCampos;
