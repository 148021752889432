import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import validator from "validator";
import { getAxiosAuth, getTokenClientId, getTimestamp } from "../../../utils";
import { withRouter } from "react-router-dom";
import { FormErrMsg } from "../../../components";
import PropTypes from "prop-types";

function BtnModalFormTag(props) {
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);
  const [nome, setaNome] = useState("");
  const [nomeErro, setaNomeErro] = useState(null);

  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState(null);
  const [continuarInserindo, setaContinuarInserindo] = useState(false);
  const isFirstRun = useRef(true);

  // --- eventos para o modal ---
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  }, []);

  const limparFormulario = () => {
    // limpando mensagens de erro e validação
    setaNomeErro(null);
    setaFormularioErro("");
    setaFormularioValido(null);

    // limpando campos
    setaNome("");
  };

  const popularFormularioEditar = () => {
    setaNome(props.nome);
  };

  const toggle = e => {
    e && e.stopPropagation();
    setaAberto(!aberto);

    if (!aberto) {
      // limpando estados para props de entrada
      limparFormulario();

      if (props.id) {
        popularFormularioEditar();
      }
    }
  };

  // --- eventos para o formulário ---
  const onSubmitKeepInsertingForm = e => {
    setaContinuarInserindo(true);

    return onSubmitForm(e);
  };

  const onSubmitCloseForm = e => {
    setaContinuarInserindo(false);

    return onSubmitForm(e);
  };

  const onSubmitForm = e => {
    e.preventDefault();

    // validando campos
    const nomeValido = validaNome();

    if (nomeValido) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos acima.");
      setaFormularioValido(false);
    }
  };

  useEffect(() => {
    const postTag = async (axiosInstance, dados) => {
      const uri = `/clientes/${clienteId}/tags`;
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      if (response.status === 200) {
        // limpa ou fecha modal
        if (continuarInserindo) {
          limparFormulario();
        } else {
          setaAberto(false);
        }
        props.onAdd && props.onAdd();
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        const dados = response.data;

        if (
          dados.errors &&
          Array.isArray(dados.errors) &&
          dados.errors.length > 0
        ) {
          const entrada = dados.errors[0];

          if (entrada.field === "nome" && entrada.error === "not_unique") {
            mensagem = "Nome já foi usado em outra tag.";
            setaNomeErro(mensagem);
          }
        }

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        props.history.push("/500");
      } else {
        props.history.push("/404");
      }
    };

    const putTag = async (axiosInstance, dados, tagId) => {
      const uri = `/clientes/${clienteId}/tags/${tagId}`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      if (response.status === 200) {
        setaAberto(false);
        props.onEdit && props.onEdit(props.id);
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        const dados = response.data;

        if (
          dados.errors &&
          Array.isArray(dados.errors) &&
          dados.errors.length > 0
        ) {
          const entrada = dados.errors[0];

          if (entrada.field === "nome" && entrada.error === "not_unique") {
            mensagem = "Nome já foi usado em outra tag.";
            setaNomeErro(mensagem);
          }
        }

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        props.history.push("/500");
      } else {
        props.history.push("/404");
      }
    };

    const salvarDados = async () => {
      // preparando dados para enviar
      const dados = {
        nome
      };

      const axiosInstance = getAxiosAuth(() => {
        return props.history.push("/login");
      });

      if (props.id) {
        await putTag(axiosInstance, dados, props.id);
      } else {
        await postTag(axiosInstance, dados);
      }
    };

    if (formularioValido) {
      salvarDados();
    }
  }, [enviarFormulario]);

  // --- eventos para o campo Nome ---
  const onChangeNome = e => {
    setaNome(e.target.value);
  };

  const validaNome = () => {
    if (validator.isEmpty(nome, { ignore_whitespace: true })) {
      setaNomeErro("Campo obrigatório.");
      return false;
    }

    setaNomeErro(false);
    return true;
  };

  const onBlurNome = () => validaNome();

  // --- elementos ---
  const trigger = props.id ? (
    <Button size="sm" color="light" style={{ marginRight: 5 }} onClick={toggle}>
      <i className="fas fa-pen" />
    </Button>
  ) : (
    <Button color="primary" size="sm" className="float-right" onClick={toggle}>
      <i className="fa fa-plus" />
      Adicionar
    </Button>
  );

  return (
    <Fragment>
      {trigger}
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>{props.id ? "Editar Tag" : "Adicionar Tag"}</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <FormGroup>
              <Label className="required">Nome da tag</Label>
              <Input
                type="text"
                maxLength={255}
                value={nome}
                onChange={onChangeNome}
                onBlur={onBlurNome}
                valid={nomeErro === false}
                invalid={!!nomeErro}
              />
              <FormErrMsg>{nomeErro}</FormErrMsg>
            </FormGroup>
            {formularioValido === false && (
              <Alert color="danger">
                Não foi possível salvar tag. {formularioErro}
              </Alert>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          {!props.id && (
            <Fragment>
              <Button color="primary" onClick={onSubmitCloseForm}>
                Salvar e fechar
              </Button>
              <Button color="primary" onClick={onSubmitKeepInsertingForm}>
                Salvar e adicionar outra
              </Button>
            </Fragment>
          )}
          {props.id && (
            <Button color="primary" onClick={onSubmitCloseForm}>
              Salvar
            </Button>
          )}
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalFormTag.propTypes = {
  id: PropTypes.number,
  nome: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func
};

export default withRouter(BtnModalFormTag);
