import React from "react";
import { TextMask, InputAdapter } from "react-text-mask-hoc";
import PropTypes from "prop-types";

function FieldTextMask(props) {
  const { valid, invalid, className, ...otherProps } = props;

  const classes = ["form-control"];

  if (valid) {
    classes.push("is-valid");
  } else if (invalid) {
    classes.push("is-invalid");
  }

  const newClassName = (className || "") + " " + classes.join(" ");

  return (
    <TextMask
      Component={InputAdapter}
      className={newClassName}
      {...otherProps}
    />
  );
}

FieldTextMask.propTypes = {
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  mask: PropTypes.array.isRequired
};

export default FieldTextMask;
