import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Alert,
  CustomInput,
} from "reactstrap";
import { ConteudoTermos } from "../../../components";
import validator from "validator";
import owasp from "owasp-password-strength-test";
import { getAxios, getTimestamp } from "../../../utils";
import { Link } from "react-router-dom";

function Registrar(props) {
  const [nomeCompleto, setaNomeCompleto] = useState("");
  const [nomeCompletoErro, setaNomeCompletoErro] = useState(null);
  const [email, setaEmail] = useState("");
  const [emailErro, setaEmailErro] = useState(null);
  const [senha, setaSenha] = useState("");
  const [senhaInvalida, setaSenhaInvalida] = useState(null);
  const [senhaMensagem, setaSenhaMensagem] = useState("");
  const [senhaConfirmacao, setaSenhaConfirmacao] = useState("");
  const [senhaConfirmacaoErro, setaSenhaConfirmacaoErro] = useState(null);
  const isInitialMount = useRef(true);
  const [formularioValido, setaFormularioValido] = useState();
  const [formularioErro, setaFormularioErro] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState();
  const [leuTermosUso, setaLeuTermoUso] = useState(false);
  const [aceitouTermosUso, setaAceitouTermosUso] = useState(false);

  // --- eventos para o formulário ---
  const onSubmitForm = (e) => {
    e.preventDefault();

    const nomeCompletoValido = validaNomeCompleto();
    const emailValido = validaEmail();
    const senhaValida = validaSenha();
    const senhaConfirmacaoValida = validaSenhaConfirmacao();

    if (
      nomeCompletoValido &&
      emailValido &&
      senhaValida &&
      senhaConfirmacaoValida
    ) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos abaixo.");
      setaFormularioValido(false);
    }
  };

  useEffect(() => {
    const postDados = async () => {
      // preparando dados para enviar
      const dados = {
        nomeCompleto,
        email,
        senha,
      };

      const axiosInstance = getAxios();
      const uri = "/clientes";
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      if (response.status === 200) {
        props.history.push("/login", { email });
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        if (
          response.data.errors &&
          Array.isArray(response.data.errors) &&
          response.data.errors.length > 0
        ) {
          const entrada = response.data.errors[0];

          if (entrada.field === "email" && entrada.error === "not_unique") {
            mensagem = "Email já foi usado por outro cliente.";
            setaEmailErro(mensagem);
          }
        }

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        props.history.push("/500");
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      postDados();
    }
  }, [enviarFormulario]);

  // --- eventos para campo Nome completo ---
  const validaNomeCompleto = () => {
    if (validator.isEmpty(nomeCompleto, { ignore_whitespace: true })) {
      setaNomeCompletoErro("Nome completo é obrigatório.");
      return false;
    }

    if (validator.isLength(nomeCompleto, { min: 0, max: 3 })) {
      setaNomeCompletoErro("Nome completo muito curto.");
      return false;
    }

    setaNomeCompletoErro(false);
    return true;
  };

  const onChangeNomeCompleto = (e) => {
    setaNomeCompleto(e.target.value);
  };

  useEffect(() => {
    if (nomeCompletoErro !== null) {
      validaNomeCompleto();
    }
  }, [nomeCompleto]);

  const onBlurNomeCompleto = () => {
    validaNomeCompleto();
  };

  // --- eventos para campo Email ---
  const validaEmail = () => {
    if (validator.isEmpty(email, { ignore_whitespace: true })) {
      setaEmailErro("Email obrigatório.");
      return false;
    }

    if (!validator.isEmail(email)) {
      setaEmailErro("Email inválido.");
      return false;
    }

    setaEmailErro(false);
    return true;
  };

  const onChangeEmail = (e) => {
    setaEmail(e.target.value);
  };

  useEffect(() => {
    if (emailErro !== null) {
      validaEmail();
    }
  }, [email]);

  const onBlurEmail = () => {
    validaEmail();
  };

  // --- eventos para campo Senha ---
  const validaSenha = () => {
    if (validator.isEmpty(senha)) {
      setaSenhaInvalida(true);
      setaSenhaMensagem("Senha obrigatória.");
      return false;
    }

    // TODO: componentizar essa lógica, aqui e em MeuCadastro.js
    const MIN_PASSWORD_LENGTH = 8;

    owasp.config({
      minOptionalTestsToPass: 2,
      minLength: MIN_PASSWORD_LENGTH,
    });

    const resultado = owasp.test(senha);

    let mensagem = "";

    if (!resultado.strong) {
      mensagem = "Senha fraca. ";
    }

    if (!resultado.strong && resultado.failedTests.length > 0) {
      const teste = resultado.failedTests[0];

      switch (teste) {
        case 0:
          mensagem += `Deve ter no mínimo ${MIN_PASSWORD_LENGTH} caracteres.`;
          break;
        case 2:
          mensagem += `Não deve conter sequências de três ou mais caracteres repetidos.`;
          break;
        case 3:
          mensagem += `Deve conter pelo menos um caractere em caixa baixa.`;
          break;
        case 4:
          mensagem += `Deve conter pelo menos um caractere em caixa alta.`;
          break;
        case 5:
          mensagem += `Deve conter pelo menos um número.`;
          break;
        case 6:
          mensagem += `Deve conter pelo menos um caractere especial (#,$,%, etc.).`;
          break;
        default:
          break;
      }
    }

    setaSenhaInvalida(!resultado.strong);
    setaSenhaMensagem(mensagem);

    return resultado.strong;
  };

  const onChangeSenha = (e) => {
    setaSenha(e.target.value);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      validaSenha();
      validaSenhaConfirmacao();
    }
  }, [senha]);

  const onBlurSenha = () => {
    validaSenha();
  };

  // --- eventos para campo Confirmação de Senha ---
  const validaSenhaConfirmacao = () => {
    if (senha === "") {
      setaSenhaConfirmacaoErro(null);
      return false;
    } else if (senhaConfirmacao !== senha) {
      setaSenhaConfirmacaoErro("Senhas não conferem.");
      return false;
    }

    setaSenhaConfirmacaoErro(false);
    return true;
  };

  const onChangeSenhaConfirmacao = (e) => {
    setaSenhaConfirmacao(e.target.value);
  };

  useEffect(() => {
    if (senhaConfirmacaoErro !== null) {
      validaSenhaConfirmacao();
    }
  }, [senhaConfirmacao]);

  const onBlurSenhaConfirmacao = () => {
    validaSenhaConfirmacao();
  };

  const form = (
    <Form onSubmit={onSubmitForm}>
      <p className="text-muted">Crie sua conta</p>

      {formularioValido === false && (
        <Alert color="danger">
          Não foi possível criar sua conta. {formularioErro}
        </Alert>
      )}

      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fas fa-user" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          placeholder="Nome completo"
          autoComplete="off"
          value={nomeCompleto}
          onChange={onChangeNomeCompleto}
          onBlur={onBlurNomeCompleto}
          valid={nomeCompletoErro === false}
          invalid={!!nomeCompletoErro}
        />
        {nomeCompletoErro && <FormFeedback>{nomeCompletoErro}</FormFeedback>}
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>@</InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          placeholder="Email"
          autoComplete="off"
          value={email}
          onChange={onChangeEmail}
          onBlur={onBlurEmail}
          valid={emailErro === false}
          invalid={!!emailErro}
        />
        {emailErro && <FormFeedback>{emailErro}</FormFeedback>}
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fas fa-lock" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="password"
          placeholder="Senha"
          autoComplete="off"
          value={senha}
          onChange={onChangeSenha}
          onBlur={onBlurSenha}
          valid={senhaInvalida === false}
          invalid={senhaInvalida}
        />
        {senhaInvalida && <FormFeedback>{senhaMensagem}</FormFeedback>}
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fas fa-lock" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="password"
          placeholder="Repita a senha"
          autoComplete="off"
          value={senhaConfirmacao}
          onChange={onChangeSenhaConfirmacao}
          onBlur={onBlurSenhaConfirmacao}
          valid={senhaConfirmacaoErro === false}
          invalid={!!senhaConfirmacaoErro}
        />
        {senhaConfirmacaoErro && (
          <FormFeedback>{senhaConfirmacaoErro}</FormFeedback>
        )}
      </InputGroup>
      <Row className="justify-content-center">
        <Col xs={12} sm={6}>
          <Button block color="primary" type="submit">
            Criar Conta
          </Button>
        </Col>
        <Col cs={12} sm={6} className="text-right">
          <Link to="/">Cancelar</Link>
        </Col>
      </Row>
    </Form>
  );

  const aceitouTermosUsoInputHandler = (event) => {
    setaAceitouTermosUso(event.target.checked);
    setaLeuTermoUso(false);
  };

  const leuTermosUsoInputHandler = (event) => {
    event.preventDefault();
    setaLeuTermoUso(true);
  };

  const termos = (
    <>
      <Card
        style={{ width: "100%", height: 300, overflowY: "scroll", padding: 5 }}
        className="mb-2"
      >
        <ConteudoTermos />
      </Card>
      <CustomInput
        id="aceitouTermosUso"
        type="checkbox"
        className="mb-3"
        checked={aceitouTermosUso}
        onChange={aceitouTermosUsoInputHandler}
        label={
          <>
            Declaro que li e compreendi os{" "}
            <a
              href="/termos-de-uso-e-politica-de-privacidade"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Uso
            </a>{" "}
            e estou ciente de seu inteiro teor, aceitando suas condições.
          </>
        }
      />
      {leuTermosUso && !aceitouTermosUso && (
        <Alert color="danger">
          Para continuar é preciso aceitar os Termos de Uso.
        </Alert>
      )}
      <Row className="justify-content-center">
        <Col xs={6}>
          <Button
            color="primary"
            className="mr-2"
            onClick={leuTermosUsoInputHandler}
          >
            Continuar
          </Button>
        </Col>
        <Col xs={6} className="text-right">
          <Link to="/">Cancelar</Link>
        </Col>
      </Row>
    </>
  );

  return (
    <div className="app flex-row align-items-center login-bg">
      <Container>
        <Row className="justify-content-center animated fadeIn">
          <Col md={6}>
            <Card className="mx-4">
              <CardBody className="p-4">
                <h1>Registrar</h1>
                {leuTermosUso && aceitouTermosUso ? form : termos}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Registrar;
