import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { toBRLColor } from "../../../utils";
import BtnDownloadApolice from "./BtnDownloadApolice";
import moment from "moment";
import "moment/locale/pt-br";
import BtnModalFormProtecao from "./BtnModalFormProtecao";
import BtnModalRemoverProtecao from "./BtnModalRemoverProtecao";
import {
  TIPOS_PROTECOES_COM_CAPITAL_SEGURADO,
  TIPOS_PROTECOES_COM_MES_VENCIMENTO
} from "../../../options";

function TabelaProtecoesConteudo(props) {
  const btnAdd = (
    <BtnModalFormProtecao conjuge={props.conjuge} onAdd={props.onAdd} />
  );

  const checaExibirCapitalSegurado = protecao =>
    !!(
      protecao.tipo &&
      TIPOS_PROTECOES_COM_CAPITAL_SEGURADO.indexOf(protecao.tipo) >= 0
    );

  const checaExibirMesVencimento = protecao =>
    !!(
      protecao.tipo &&
      TIPOS_PROTECOES_COM_MES_VENCIMENTO.indexOf(protecao.tipo) >= 0 &&
      protecao.mesVencimento
    );

  const formataMesVencimento = protecao =>
    protecao.mesVencimento
      ? moment()
          .set("month", protecao.mesVencimento - 1)
          .format("MMMM")
      : null;

  const btns = protecao => (
    <Fragment>
      <BtnModalFormProtecao
        conjuge={props.conjuge}
        onEdit={props.onEdit}
        {...protecao}
      />
      <BtnModalRemoverProtecao
        id={protecao.id}
        seguradora={protecao.seguradora}
        onDelete={props.onDelete}
      />
    </Fragment>
  );

  return (
    <tbody>
      <tr className="table-primary">
        <th colSpan={7} className="text-center">
          {props.conjuge ? "Cônjuge" : "Principal"}
        </th>
      </tr>

      <tr className="table-secondary">
        <th className="text-left">Tipo</th>
        <th className="text-left">Seguradora</th>
        <th className="text-right d-none d-sm-table-cell">Prêmio Mensal</th>
        <th className="text-right d-none d-sm-table-cell">Capital Segurado</th>
        <th className="text-right d-none d-sm-table-cell">Mês de vencimento</th>
        <th className="text-right d-none d-sm-table-cell">{btnAdd}</th>
      </tr>

      <tr>
        <td colSpan={2} className="text-right d-table-cell d-sm-none">
          {btnAdd}
        </td>
      </tr>

      {props.isLoading && (
        <tr>
          <td colSpan={6}>
            <div className="text-muted text-center mt-3">
              <div className="spinner-border" role="status">
                <span className="sr-only">Carregando...</span>
              </div>
            </div>
          </td>
        </tr>
      )}

      {!props.isLoading && props.protecoes && props.protecoes.length === 0 && (
        <tr>
          <td colSpan={6}>
            <p className="text-muted text-center mt-3">
              Nenhuma proteção inserida {props.conjuge ? `para cônjuge` : null}.
            </p>
          </td>
        </tr>
      )}

      {!props.isLoading &&
        props.protecoes &&
        props.protecoes.length > 0 &&
        props.protecoes.map(protecao => (
          <tr key={`protecao_${protecao.id}`}>
            <td className="text-left">{protecao.tipo}</td>
            <td className="text-left">
              {protecao.seguradora}

              <span className="d-block d-sm-none">
                <strong>Prêmio Mensal:</strong>{" "}
                {toBRLColor(protecao.premioMensal)}
              </span>

              {checaExibirCapitalSegurado(protecao) && (
                <span className="d-block d-sm-none">
                  <strong>Capital Segurado:</strong>{" "}
                  {toBRLColor(protecao.capitalSegurado)}
                </span>
              )}

              {checaExibirMesVencimento(protecao) && (
                <span className="d-block d-sm-none">
                  <strong>Mês vencimento:</strong>{" "}
                  {formataMesVencimento(protecao)}
                </span>
              )}

              {protecao.upload && (
                <div style={{ display: "block" }}>
                  <BtnDownloadApolice
                    uploadId={protecao.upload.id}
                    uploadFilename={protecao.upload.filename}
                    clienteId={props.clienteId}
                  />
                </div>
              )}

              <span className="d-block d-sm-none mb-3">{btns(protecao)}</span>
            </td>
            <td className="text-right d-none d-sm-table-cell">
              {toBRLColor(protecao.premioMensal)}
            </td>
            <td className="text-right d-none d-sm-table-cell">
              {checaExibirCapitalSegurado(protecao) &&
                toBRLColor(protecao.capitalSegurado)}
            </td>
            <td className="text-right d-none d-sm-table-cell">
              {checaExibirMesVencimento(protecao) &&
                formataMesVencimento(protecao)}
            </td>
            <td className="text-right d-none d-sm-table-cell">
              {btns(protecao)}
            </td>
          </tr>
        ))}
    </tbody>
  );
}

TabelaProtecoesConteudo.propTypes = {
  isLoading: PropTypes.bool,
  protecoes: PropTypes.array,
  clienteId: PropTypes.string.isRequired,
  conjuge: PropTypes.bool,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default TabelaProtecoesConteudo;
