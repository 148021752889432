import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button
} from "reactstrap";
import {
  getAxiosAuth,
  wasRequestCancelled,
  toBRLColor,
  toPercentColor,
  getClienteId
} from "../../../utils";
import { withRouter } from "react-router-dom";
import palette from "google-palette";
import { GraficoRosca } from "../../../components";
import PropTypes from "prop-types";
import moment from "moment";

function DespesasPorCategorias(props) {
  const { ano, mes } = props;
  const clienteId = getClienteId();
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredId, hoverId] = useState(-1);
  const [selectedId, selectId] = useState(-1);
  const [titulo, setTitulo] = useState("");
  const [categorias, setaCategorias] = useState([]);
  const [legendas, setaLegendas] = useState([]);
  const [dados, setaDados] = useState([]);
  const [total, setaTotal] = useState(0.0);
  const [cores, setaCores] = useState([]);

  const getDespesas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/despesas`;
    const dados = {
      filtroMes: mes,
      filtroAno: ano,
      filtroTags: props.tags ? props.tags.map(tag => tag.value) : []
    };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    setIsLoading(false);
   
    if (response.data && "categorias" in response.data) {
      // filtrando despesas com valor igual a zero, e ordenando maiores primeiro
      const categorias = [...response.data.categorias]
        .filter(
          grupo =>
            grupo.despesas.filter(despesa => despesa.valor > 0).length > 0
        )
        .map(grupo => {
          grupo.totalDespesas = grupo.despesas.reduce(
            (acc, despesa) => acc + (despesa.valor || 0),
            0
          );
          grupo.despesas.sort((a, b) => b.valor - a.valor);
          return grupo;
        })
        .sort((a, b) => b.totalDespesas - a.totalDespesas);

      setaCategorias(categorias);
    }
  };

  useEffect(() => {
    getDespesas(); // primeiro acesso, e quando alterar data ou tags
  }, [mes, ano, props.tags]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let compLegendas, compDados, compTotal, compCores;

    if (selectedId >= 0) {
      const despesas =
        categorias[selectedId] && categorias[selectedId].nome === titulo
          ? categorias[selectedId].despesas.reduce((acc, cur) => {
              if (cur.valor) {
                acc.push(cur);
              }
              return acc;
            }, [])
          : [];
      compLegendas = despesas.reduce((acc, cur, i, a) => {
        if (acc.indexOf(cur.descricao) < 0) {
          acc.push(cur.descricao);
        }
        return acc;
      }, []);
      compDados = compLegendas.map(legenda =>
        despesas.reduce((acc, despesa) => {
            if (despesa.descricao === legenda) {
            // bloco de codigo para verificar se as parcelas foram pagas
            const dataDespesa = despesa.dataDespesa;
            
            const mesDespesa = moment(dataDespesa).format('M');
            const anoDespesa = moment(dataDespesa).format('YYYY');

            const formatDespesa = moment([anoDespesa, mesDespesa - 1]);
            const formatAtual = moment([ano, mes - 1]);

            if(despesa.despesaTemporaria) {
              // Pega a diferença entre datas, da atual para a da despesa
              const parcelasPagas = formatAtual.diff(formatDespesa, "month", true);
              if((((despesa.totalParcelas - despesa.parcela) - parcelasPagas) < 0)){
                // Se a despesa foi paga não adiciona o valor
                return acc;
              }
            }
            acc.push(despesa.valor);
          }
          return acc;
        }, [])
      );
      let pares = compLegendas
        .map((legenda, i) => {
          let numeroItems = compDados[i].length;
          return {
            legenda: numeroItems > 1 ? `${legenda} (${numeroItems})` : legenda,
            valor: compDados[i].reduce((acc, cur) => acc + cur, 0.0)
          };
        })
        .sort((a, b) => b.valor - a.valor);
      compLegendas = pares.map(item => item.legenda);
      compDados = pares.map(item => item.valor);
    } else {
      compLegendas = categorias.map(categoria => categoria.nome);
      compDados = categorias.map(categoria =>
        categoria.despesas.reduce((acc, cur) => {
          // bloco de codigo para verificar se as parcelas foram pagas
          const dataDespesa = cur.dataDespesa;
          
          const mesDespesa = moment(dataDespesa).format('M');
          const anoDespesa = moment(dataDespesa).format('YYYY');

          const formatDespesa = moment([anoDespesa, mesDespesa - 1]);
          const formatAtual = moment([ano, mes - 1]);

          if(cur.despesaTemporaria) {
            // Pega a diferença entre datas, da atual para a da despesa
            const parcelasPagas = formatAtual.diff(formatDespesa, "month", true);
            if((((cur.totalParcelas - cur.parcela) - parcelasPagas) < 0)){
              return acc;
            }
          }
          
          if (cur.valor) {
            acc += cur.valor;
          }
          return acc;
        }, 0)
      );
    }

    compTotal = compDados.reduce((acc, cur) => acc + cur, 0);
    compCores = palette("tol-rainbow", categorias.length).map(hex => "#" + hex);

    setaLegendas(compLegendas);
    setaDados(compDados);
    setaTotal(compTotal);
    setaCores(compCores);
  }, [categorias, selectedId, titulo]);

  const spinner = (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );

  const hoverCb = index => {
    if (!isNaN(index) && index >= 0) {
      hoverId(index);
    } else {
      hoverId(-1);
    }
  };

  const clickCb = index => {
    if (selectedId < 0) {
      const novoTitulo = legendas[index];
      selectId(index);
      setTitulo(novoTitulo);
    }
  };

  const backClickCb = e => {
    e.preventDefault();
    selectId(-1);
    setTitulo("");
  };
  
  return (
    <Row>
      <Col lg={6} xl={8} className="text-center">
        {isLoading && spinner}
        {!isLoading && (
          <Fragment>
            <GraficoRosca
              legendas={legendas}
              dados={dados}
              titulo={selectedId >= 0 ? titulo : "Todas as despesas"}
              total={total}
              cores={cores}
              hoverCb={hoverCb}
              clickCb={selectedId < 0 && clickCb}
            />
            {selectedId >= 0 && (
              <Button
                color="light"
                size="sm"
                onClick={backClickCb}
                className="mt-2 mb-4"
              >
                <i className="fas fa-arrow-left" />
                &nbsp;&nbsp;&nbsp;Todas as despesas
              </Button>
            )}
          </Fragment>
        )}
      </Col>
      <Col lg={6} xl={4} className="mt-4 mt-lg-0">
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <strong>Categorias</strong>
          </CardHeader>
          <CardBody>
            {isLoading && spinner}
            {!isLoading && (
              <Table size="sm" borderless responsive>
                <tbody>
                  {dados.map((valor, i) => (
                    <tr
                      key={`categoria_${i}`}
                      className={hoveredId === i ? "table-light" : ""}
                    >
                      <td style={{ width: 30 }} className="text-center">
                        <i
                          className="fa fa-circle"
                          style={{
                            color: cores[i],
                            fontSize: hoveredId === i ? "1.1rem" : "inherit"
                          }}
                        />
                      </td>
                      <td>{legendas[i]}</td>
                      <td className="text-right">{toBRLColor(valor)}</td>
                      <td className="text-right">
                        {toPercentColor(valor / total)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

DespesasPorCategorias.propTypes = {
  ano: PropTypes.number,
  mes: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.object)
};

export default withRouter(DespesasPorCategorias);
