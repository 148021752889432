import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

function ModalConfirmarAlteracoesNaoSalvas(props) {
  const [aberto, setaAberto] = useState(false);

  useEffect(() => {
    setaAberto(props.aberto);
  }, [props.aberto]);

  const toggle = e => {
    e && e.stopPropagation();
    setaAberto(!aberto);
  };

  const onClickConfirm = e => {
    e.preventDefault();

    if (props.onContinue) {
      props.onContinue();
    }

    setaAberto(false);
  };

  const onClickCancel = e => {
    e.preventDefault();

    if (props.onCancel) {
      props.onCancel();
    }

    setaAberto(false);
  };

  return (
    <Modal isOpen={aberto} toggle={toggle} fade zIndex={1090}>
      <ModalHeader>Sair</ModalHeader>
      <ModalBody>
        <p>
          Você fez alterações no formulário e está prestes a sair sem salvá-las.
          <br />
          Deseja <strong>descartar</strong> as alterações?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={onClickConfirm}>
          Sim
        </Button>
        <Button color="link" onClick={onClickCancel}>
          Não
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ModalConfirmarAlteracoesNaoSalvas.propTypes = {
  aberto: PropTypes.bool.isRequired,
  onContinue: PropTypes.func,
  onCancel: PropTypes.func
};

export default ModalConfirmarAlteracoesNaoSalvas;
