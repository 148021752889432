import React from "react";
import "moment/locale/pt-br";
import { Card, CardBody, CardTitle, Alert } from "reactstrap";
import { Bar } from "react-chartjs-2";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { toBRL } from "../../utils";
import { Link, withRouter } from "react-router-dom";

const GraficoInvestimentosMensais = (props) => {
    if (!props.investimentosMensais || props.investimentosMensais.length === 0) {
        return (
            <Alert color="light">
            <h4 className="alert-heading">Sem receitas no período</h4>
            <p>
                Não há receitas cadastradas para computar o seu progresso.{" "}
                <Link to="/organize/receitas">Cadastre algumas receitas</Link> para
                continuar.
            </p>
            <hr />
            </Alert>
        );
    }

    // let somaValores = 0;

    // Object.keys(props.investimentosMensais).map((data) => {
    //     somaValores += Number(props.investimentosMensais[data]['totalInvestimentos'])
    // })
    
    const graficoDados = {
        labels: props.meses,
        datasets: [{
            label: 'Esperado',
            data: props.meses.map((mes) => 20),
            backgroundColor: "#068DA9",
            borderColor: props.cores[0],
        }, {
            label: 'Realizado',
            data: Object.keys(props.investimentosMensais).map((data) => {
                const metaMes = (props.investimentosMensais[data]['totalInvestimentos'] * 100)/props.investimentosMensais[data]['totalReceitas']
                
                return metaMes;
            }),
            backgroundColor: "#AFD3E2",
            borderColor: "#00FF00",
        }],
    };
    
    const graficoOpcoes = {
        tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const { yLabel, index } = tooltipItem;

                const indices = Object.keys(props.investimentosMensais)

                let label = data.datasets[tooltipItem.datasetIndex].label || "";
      
                if(label) {
                    if(yLabel === 20) {
                        label = toBRL(props.investimentosMensais[indices[index]]['totalReceitas']*0.20) + ` (${Math.floor(yLabel)}%)`;
                    } else {
                        label = toBRL(props.investimentosMensais[indices[index]]['totalInvestimentos']) + ` (${Math.floor(yLabel)}%)`;
                    }
                }
      
                return label;
              }
            }
          },
        scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false
                }
              }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: (value) => value + "%",
                    }
                }
            ]
          },
        maintainAspectRatio: false,
    }

    return (
        <Card>
            <CardBody>
                <CardTitle className="text-center">Investimentos Mensais</CardTitle>

                <div className="chart-wrapper" style={{ height: 300, marginTop: 40 }}>
                    <Bar 
                        data={graficoDados}
                        options={graficoOpcoes}
                        plugins={[ChartAnnotation]}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export default withRouter(GraficoInvestimentosMensais)