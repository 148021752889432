import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { DateCarousel } from "../../../components";
import {
  getAxiosAuth,
  wasRequestCancelled,
  getTokenClientId
} from "../../../utils";
import palette from "google-palette";
import { LIQUIDEZ_INVESTIMENTOS, TIPOS_RISCOS } from "../../../options";
import GraficoDeInvestimentos from "./GraficoDeInvestimentos";

function CarteiraDeInvestimentos(props) {
  const clienteId = getTokenClientId();
  const m = moment();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [isLoading, setIsLoading] = useState(true);
  const [investimentos, setInvestimentos] = useState([]);

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };
  
  const ordenador = (a, b) => b.valor - a.valor;

  const getInvestimentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/investimentos`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "investimentos" in response.data) {
      setInvestimentos(response.data.investimentos.sort(ordenador));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
  }, [mes, ano]);

  useEffect(() => {
    if (isLoading) {
      getInvestimentos();
    }
  }, [isLoading]);

  let riscos, liquidezes;
  let paletaCores;
  paletaCores = "cb-Accent";

  let total = investimentos.reduce(
    (acc, cur) => acc + cur.valor,
    0
  );

  const getDados = (dados, valor) => Object.values(dados.map(dado => 
    investimentos.reduce(
      (acc, cur) => cur[valor] === dado ? acc + cur.valor : acc,
      0
    )
  ));

  const getCores = (dados) => palette(paletaCores, dados.length).map(hex => "#" + hex);

  riscos = {
    legendas: TIPOS_RISCOS,
    dados: getDados(TIPOS_RISCOS, "risco"),
    cores: getCores(TIPOS_RISCOS),
  }
  
  liquidezes = {
    legendas: LIQUIDEZ_INVESTIMENTOS,
    dados: getDados(LIQUIDEZ_INVESTIMENTOS, "liquidez"),
    cores: getCores(LIQUIDEZ_INVESTIMENTOS),
  }

  return (
    <div className="animated fadeIn">
      <Container>
        <Row>
          <Col lg={6} xl={8} className="text-center">
            <DateCarousel travaMesAtual ano={ano} mes={mes} onChange={onChangeDate} />
          </Col>
        </Row>
        <GraficoDeInvestimentos
          titulo="Risco"
          isLoading={isLoading}
          legendas={riscos.legendas}
          dados={riscos.dados}
          total={total}
          cores={riscos.cores}
          />
        <br />
        <br />
        <GraficoDeInvestimentos
          titulo="Liquidez"
          isLoading={isLoading}
          legendas={liquidezes.legendas}
          dados={liquidezes.dados}
          total={total}
          cores={liquidezes.cores}
        />
      </Container>
    </div>
  )
}

export default withRouter(CarteiraDeInvestimentos);