import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import {
  Monetary,
  AutocompleteInput,
  FormErrMsg,
  Percent,
  BtnModalFormPatrimonio
} from "../../../components";
import {
  CATEGORIAS_DESPESAS_OPCOES,
  TIPOS_DESPESAS,
  MESES,
} from "../../../options";
import validator from "validator";
import moment from "moment";
import {
  isPositiveBRL,
  pad,
  getAxiosAuth,
  fromBRL,
  toBRLValueOnly,
  getTokenClientId,
  getTimestamp,
  fromDecimal,
  toDecimal,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

function BtnModalFormDespesa(props) {
  const clienteId = getTokenClientId();
  const [cascade, setCascade] = useState(false);
  const [aberto, setaAberto] = useState(false);
  const [categoria, setaCategoria] = useState(props.categoria);
  const [tipo, setaTipo] = useState(TIPOS_DESPESAS[0]);
  const [dia, setaDia] = useState("");
  const [mes, setaMes] = useState(props.mes.toString());
  const [ano, setaAno] = useState(props.ano.toString());
  const [dataErro, setaDataErro] = useState(null);
  const [descricao, setaDescricao] = useState("");
  const [descricaoErro, setaDescricaoErro] = useState(null);
  const [valor, setaValor] = useState("");
  const [valorErro, setaValorErro] = useState(null);
  const [despesaFixa, setaDespesaFixa] = useState(false);
  const [despesaTemporaria, setaDespesaTemporaria] = useState(false);
  const [parcela, setaParcela] = useState("");
  const [parcelaErro, setaParcelaErro] = useState(null);
  const [totalParcelas, setaTotalParcelas] = useState("");
  const [totalParcelasErro, setaTotalParcelasErro] = useState(null);
  const [credor, setaCredor] = useState("");
  const [juros, setaJuros] = useState("");
  const [jurosErro, setaJurosErro] = useState(null);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState(null);
  const [continuarInserindo, setaContinuarInserindo] = useState(false);
  const diaEl = useRef(null);
  const descricaoEl = useRef(null);
  const [sugestoes, setaSugestoes] = useState([]);
  const [patrimonios, setaPatrimonios] = useState([]);
  const [patrimonio, setaPatrimonio] = useState("");
  const [tagsOpcoes, setaTagsOpcoes] = useState("");
  const [tags, setaTags] = useState([]);
  const [atualizaPatrimonios, setaAtualizaPatrimonios] = useState(false);
  const [wasPatrimonioUuid, setWasPatrimonioUuid] = useState(false)
  // --- eventos da lista de sugestoes ----
  useEffect(() => {
   
    const getSugestoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/sugestoes/despesas`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        return;
      }

      if (response && response.data && "sugestoes" in response.data) {
        setaSugestoes(response.data.sugestoes);
      }
    };

    if (aberto) {
      getSugestoes();
    }
  }, [enviarFormulario, aberto]);

  // --- eventos para o modal ---
  const limparFormulario = () => {
    // limpando mensagens de erro
    setaDataErro(null);
    setaDescricaoErro(null);
    setaValorErro(null);
    setaParcelaErro(null);
    setaTotalParcelasErro(null);
    setaFormularioValido(null);
    setaFormularioErro("");
    setaEnviarFormulario(null);

    // limpando campos
    setaContinuarInserindo(false);
    setaDescricao("");
    setaValor("");
    setaParcela("");
    setaTotalParcelas("");
    setaDespesaFixa(false);
    setaDespesaTemporaria(false);
    setaMes(props.mes.toString());
    setaAno(props.ano.toString());

    // tags
    setaTags([]);
  };

  const popularFormularioEditar = () => {
    setaCategoria(props.categoria);
    setaTipo(props.tipo);
    setaDia(props.dia.toString());
    setaMes(props.mes.toString());
    setaAno(props.ano.toString());
    setaDescricao(props.descricao);
    setaValor(toBRLValueOnly(props.valor));
    setaDespesaFixa(!!props.despesaFixa);
    setaDespesaTemporaria(!!props.despesaTemporaria);
    setaParcela((props.parcela && props.parcela.toString()) || "");
    setaTotalParcelas(
      (props.totalParcelas && props.totalParcelas.toString()) || ""
    );
    setaCredor(props.credor || "");
    setaJuros((props.juros && toDecimal(props.juros)) || "");
    if (props.patrimonioId) {
      setaPatrimonio(props.patrimonioId.toString());
    }

    if (props.tags && Array.isArray(props.tags) && props.tags.length >= 1) {
      setaTags(props.tags.map((tag) => ({ label: tag.nome, value: tag.id })));
    }
  };

  const toggle = (e) => {
    e && e.stopPropagation();
    setaAberto(!aberto);

    if (!aberto) {
      // limpando estados para props de entrada
      limparFormulario();

      if (props.id) {
        popularFormularioEditar();
      }
    }
    setWasPatrimonioUuid(false);
  };

  useEffect(() => {

    if (aberto) {
      diaEl.current.focus();
    }
  }, [aberto]);

  useEffect(() => {
    if (continuarInserindo) {
      descricaoEl.current.focus();
    }
  }, [continuarInserindo]);

  // --- eventos para o formulário ---
  const onSubmitKeepInsertingForm = (e) => {
    setaContinuarInserindo(true);

    return onSubmitForm(e);
  };

  const handleSubmitCascade = (e) => {
    setaContinuarInserindo(false);
    setCascade(true);

    return onSubmitForm(e);
  }

  const onSubmitCloseForm = (e) => {
    setaContinuarInserindo(false);
    return onSubmitForm(e);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    // validando campos
    const dataValida = validaData();
    const descricaoValida = validaDescricao();
    const valorValido = validaValor();
    const parcelaValida = validaParcela();
    const totalParcelasValidas = validaTotalParcelas();
    const jurosValidos = validaJuros();

    if (
      dataValida &&
      descricaoValida &&
      valorValido &&
      parcelaValida &&
      totalParcelasValidas &&
      jurosValidos
    ) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos acima.");
      setaFormularioValido(false);
    }
  };

  useEffect(() => {
    const adicionaDados = async (axiosInstance, dados) => {
      const uri = `/clientes/${clienteId}/despesas`;
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      // limpa ou fecha modal
      if (continuarInserindo) {
        limparFormulario();
      } else {
        setaAberto(false);
      }

      props.onAdd && props.onAdd();
    };

    const editaDados = async (axiosInstance, dados, despesaId) => {
      const uri = `/clientes/${clienteId}/despesas/${despesaId}`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      setaAberto(false);
      props.onEdit && props.onEdit(props.id);
    };

    const salvaDados = async () => {
      if (formularioValido) {
        let anoPad = pad(ano, 4);
        let mesPad = pad(mes, 2);
        let diaPad = pad(dia, 2);
        const dataDespesa = `${anoPad}-${mesPad}-${diaPad}`;
        const valorLimpo = fromBRL(valor);

        // preparando dados para enviar
        let dados = {
          dataDespesa,
          descricao,
          categoria,
          tipo,
          valor: valorLimpo,
          despesaFixa,
          despesaTemporaria,
          patrimonioId:patrimonio || null,
          tags:
            tags.map((tag) => ({
              id: tag.__isNew__ ? null : tag.value,
              nome: tag.label,
            })) || null,
          cascade,
        };

        if (despesaTemporaria) {
          let valorJuros = fromDecimal(juros);
          dados = {
            ...dados,
            parcela: parseInt(parcela),
            totalParcelas: parseInt(totalParcelas),
            credor,
            juros: valorJuros,
          };
        }

        const axiosInstance = getAxiosAuth(() => {
          // callback: redireciona não conseguir renovar tokens
          props.history.push("/login");
        });

        if (props.id) {
          await editaDados(axiosInstance, dados, props.id);
        } else {
          await adicionaDados(axiosInstance, dados);
        }
      }
    };

    if (formularioValido) {
      salvaDados();
    }
  }, [enviarFormulario]);

  // --- eventos para o campo Categoria ---
  const onChangeCategoria = (e) => {
    setaCategoria(e.target.value);
  };

  // --- eventos para o campo Tipo ---
  const onChangeTipo = (e) => {
    setaTipo(e.target.value);
  };

  // --- eventos para os campos de data ---
  const validaData = () => {
    if (validator.isEmpty(dia, { ignore_whitespace: true })) {
      setaDataErro("Dia obrigatório.");
      return false;
    }

    if (validator.isEmpty(ano, { ignore_whitespace: true })) {
      setaDataErro("Ano é obrigatório.");
      return false;
    }

    if (!validator.isInt(dia, { min: 1, max: 31 })) {
      setaDataErro("Dia inválido.");
      return false;
    }

    if (mes < 1 || mes > 12) {
      setaDataErro("Mês inválido.");
      return false;
    }

    if (!validator.isInt(ano)) {
      setaDataErro("Ano inválido.");
      return false;
    }

    const dataSelecionada = moment([Number(ano), Number(mes) - 1, dia])
    
    if(!despesaTemporaria && dataSelecionada.diff(moment(), "months") > 12) {
      setaDataErro("Despesas fixas não podem ser criadas a mais de 12 meses");
      return false;
    }

    let anoPad = pad(ano, 4);
    let mesPad = pad(mes, 2);
    let diaPad = pad(dia, 2);

    const data = `${anoPad}-${mesPad}-${diaPad}`;
    const m = moment(data, "YYYY-MM-DD", true);
    if (!m.isValid()) {
      setaDataErro("Data inválida.");
      return false;
    }

    setaDataErro(false);
    return true;
  };

  useEffect(() => {
    if (dataErro !== null) {
      validaData();
    }
  }, [dia, mes, ano, despesaFixa, despesaTemporaria]);

  // --- eventos para o campo Dia ---
  const onChangeDia = (e) => {
    setaDia(e.target.value);
  };

  const onBlurDia = () => {
    validaData();
  };

  // --- eventos para o campo Mes ---
  const onChangeMes = (e) => {
    setaMes(e.target.value);
  };

  // --- eventos para o campo Ano ---
  const onChangeAno = (e) => {
    setaAno(e.target.value);
  };

  const onBlurAno = (e) => {
    validaData();
  };

  // --- eventos para o campo Descrição ---
  const validaDescricao = () => {
    if (validator.isEmpty(descricao, { ignore_whitespace: true })) {
      setaDescricaoErro("Descrição obrigatória.");
      return false;
    }

    if (!validator.isLength(descricao, { min: 3 })) {
      setaDescricaoErro("Descrição muito curta. Mínimo 3 caracteres.");
      return false;
    }

    if (!validator.isLength(descricao, { max: 255 })) {
      setaDescricaoErro("Descrição muito longa. Máximo 255 caracteres.");
      return false;
    }

    setaDescricaoErro(false);
    return true;
  };

  const onChangeDescricao = (e) => {
    setaDescricao(e.target.value);
  };

  const onBlurDescricao = () => {
    validaDescricao();
  };

  useEffect(() => {
    if (descricaoErro !== null) {
      validaDescricao();
    }
  }, [descricao]);

  // --- eventos para o campo Valor ---
  const validaValor = () => {
    if (validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaValorErro("Valor obrigatório.");
      return false;
    }

    if (!isPositiveBRL(valor)) {
      setaValorErro("Valor mometário inválido.");
      return false;
    }

    setaValorErro(false);
    return true;
  };

  const onChangeValor = (e) => {
    setaValor(e.target.value);
  };

  const onBlurValor = () => {
    validaValor();
  };

  useEffect(() => {
    if (valorErro !== null) {
      validaValor();
    }
  }, [valor]);

  // --- eventos para o campo Despesa Fixa ---
  const onChangeDespesaFixa = (e) => {
    setaDespesaFixa(e.target.checked);

    if ((e.target.checked && despesaTemporaria)) {
      setaDespesaTemporaria(false);
    }
  };

  // --- eventos para o campo Despesa Temporária ---
  const onChangeDespesaTemporaria = (e) => {
    setaDespesaTemporaria(e.target.checked);

    if ((e.target.checked && despesaFixa)) {
      setaDespesaFixa(false);
    }
  };

  // --- eventos para os campos de parcelas
  const validaParcela = () => {
    if (!despesaTemporaria) {
      setaParcelaErro(null);
      return true;
    }

    if (validator.isEmpty(parcela, { ignore_whitespace: true })) {
      setaParcelaErro("Número da parcela é obrigatório.");
      return false;
    }

    let opts = { min: 1 };

    if (totalParcelas) {
      opts = { ...opts, max: Number(totalParcelas) };
    }

    if (!validator.isInt(parcela, opts)) {
      setaParcelaErro("Número da parcela inválido.");
      return false;
    }

    setaParcelaErro(false);
    return true;
  };

  const onChangeParcela = (e) => {
    setaParcela(e.target.value);
  };

  const onBlurParcela = () => {
    validaParcela();
  };

  const validaTotalParcelas = () => {
    if (!despesaTemporaria) {
      setaTotalParcelasErro(null);
      return true;
    }

    if (validator.isEmpty(totalParcelas, { ignore_whitespace: true })) {
      setaTotalParcelasErro("Total de parcelas é obrigatório.");
      return false;
    }

    if (!validator.isInt(totalParcelas, { min: 1 })) {
      setaTotalParcelasErro("Total de parcelas inválido.");
      return false;
    }

    setaTotalParcelasErro(false);
    return true;
  };

  const onChangeTotalParcelas = (e) => {
    setaTotalParcelas(e.target.value);
  };

  const onBlurTotalParcelas = () => {
    validaParcela();
    validaTotalParcelas();
  };

  // --- eventos para o campo Credor
  const onChangeCredor = (e) => {
    setaCredor(e.target.value);
  };

  // --- eventos para o campo Juros
  const validaJuros = () => {
    if (
      despesaTemporaria &&
      !validator.isEmpty(juros, { ignore_whitespace: true }) &&
      !validator.isDecimal(juros, { locale: "pt-BR" })
    ) {
      setaJurosErro("Porcentagem inválida.");
      return false;
    } else {
      setaJurosErro(null);
      return true;
    }
  };

  const onChangeJuros = (e) => {
    setaJuros(e.target.value);
  };

  const onBlurJuros = () => {
    validaJuros();
  };

  // --- eventos para o campo Tags ---
  useEffect(() => {
    const getTagsOpcoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/tags`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        return;
      }

      if (response && response.data && "tags" in response.data) {
        const novasTagsOpcoes = response.data.tags.map((tag) => ({
          value: tag.id,
          label: tag.nome,
        }));

        setaTagsOpcoes(novasTagsOpcoes);
      }
    };

    if (aberto) {
      getTagsOpcoes();
    }
  }, [enviarFormulario, aberto]);

  const onChangeTags = (newValue, actionMeta) => {
    if (newValue === null) {
      newValue = [];
    }
    setaTags(newValue);
  };

  // --- eventos para o campo Patrimônio ---
  const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    

    const uri = `/clientes/${clienteId}/patrimonios`;
    let response;
    let dados = {
      filtroMes: mes,
      filtroAno: ano,
      wasPatrimonioUuid: wasPatrimonioUuid,
      patrimonioVinculadoUuid: props.patrimonioUuid
    };

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      return;
    }

    if (response && response.data && "patrimonios" in response.data) {
      const novosPatrimonios = response.data.patrimonios.map((p) => {
        const dataPatrimonio = new Date(p.dataPatrimonio);
        const mesPatrimonio = dataPatrimonio.getUTCMonth() + 1; // Adiciona 1 para corresponder a 1 a 12
        const anoPatrimonio = dataPatrimonio.getUTCFullYear();

        if (!(anoPatrimonio === parseInt(ano, 10) && mesPatrimonio === parseInt(mes, 10))) {
          setaPatrimonio(p.id.toString());
        }

        return {
          id: p.id.toString(),
          descricao: p.descricao,
          uuid: p.uuid.toString(),
          dataPatrimonio: p.dataPatrimonio,
          patrimonioAproximado: !(anoPatrimonio === parseInt(ano, 10) && mesPatrimonio === parseInt(mes, 10)),
        };
      });
      

      setaPatrimonios(novosPatrimonios);
      if (patrimonio && !novosPatrimonios.find((p) => p.id === patrimonio)) {
        setaPatrimonio("");
      }
    }
  };

  useEffect(() => {
    if (aberto || atualizaPatrimonios) {
      getPatrimonios();
    }
  }, [wasPatrimonioUuid,enviarFormulario, aberto, mes, ano, atualizaPatrimonios]);


  useEffect(() => {
    if (atualizaPatrimonios) {
      setaPatrimonio(patrimonios.find(p => p.descricao === atualizaPatrimonios).id);
    }
  }, [patrimonios])

  const onChangePatrimonio =  async (e) => {
    setaPatrimonio(e.target.value);
    setWasPatrimonioUuid(true);
  };


  const onClickBtnAdicionarPatrimono = (e) => {
    setaAtualizaPatrimonios(e);
  };

  
  // --- elementos ---
  const trigger = props.id ? (
    <Button size="sm" color="light" style={{ marginRight: 5 }} onClick={toggle}>
      <i className="fas fa-pen" />
    </Button>
  ) : (
    <Button color="primary" size="sm" onClick={toggle}>
      <i className="fas fa-plus" /> Adicionar
    </Button>
  );

  return (
    <Fragment>
      {trigger}
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>
          {props.id ? "Editar Despesa" : "Adicionar Despesa"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitCloseForm}>
            
            
            <Row form>
              <Col xs={3}>
                <FormGroup>
                  <Label className="required">Dia</Label>
                  <Input
                    type="text"
                    value={dia}
                    onChange={onChangeDia}
                    onBlur={onBlurDia}
                    valid={dataErro === false}
                    invalid={!!dataErro}
                    innerRef={diaEl}
                  />
                  <FormErrMsg>{dataErro}</FormErrMsg>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label className="required">Mês</Label>
                  <Input
                    type="select"
                    value={mes}
                    onChange={onChangeMes}
                    valid={dataErro === false}
                    invalid={!!dataErro}
                    disabled={despesaFixa===true || despesaTemporaria===true}
                  >
                    {MESES.map((mes, i) => (
                      <option value={i + 1} key={`mes_opt_${i}`}>
                        {mes}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={3}>
                <Label className="required">Ano</Label>
                <Input
                  type="text"
                  value={ano}
                  onChange={onChangeAno}
                  onBlur={onBlurAno}
                  valid={dataErro === false}
                  invalid={!!dataErro}
                  disabled={despesaFixa===true || despesaTemporaria===true}
                />
              </Col>
            </Row>
            <FormGroup>
              <Label className="required">Descrição</Label>
              <AutocompleteInput
                type="text"
                value={descricao}
                onChange={onChangeDescricao}
                onBlur={onBlurDescricao}
                valid={descricaoErro === false}
                invalid={!!descricaoErro}
                maxLength={255}
                innerRef={descricaoEl}
                options={sugestoes}
              />
              <FormErrMsg>{descricaoErro}</FormErrMsg>
            </FormGroup>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label className="required">Valor</Label>
                  <Monetary
                    value={valor}
                    onChange={onChangeValor}
                    onBlur={onBlurValor}
                    valid={valorErro === false}
                    invalid={!!valorErro}
                  />
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {valorErro}
                  </div>
                </FormGroup>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <Label className="d-none d-md-inline-block"> </Label>
                  <FormGroup check>
                    <CustomInput
                      type="checkbox"
                      label="Despesa recorrente"
                      name="opcao"
                      id="opcao_despesaFixa"
                      checked={!!despesaFixa}
                      onChange={onChangeDespesaFixa}
                    />
                    <CustomInput
                      type="checkbox"
                      label="Despesa temporária"
                      name="opcao"
                      id="opcao_despesaTemporaria"
                      checked={!!despesaTemporaria}
                      onChange={onChangeDespesaTemporaria}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            {despesaTemporaria && (
              <Fragment>
                <Row form>
                  <Col xs={6}>
                    <FormGroup>
                      <Label className="required">Número da parcela</Label>
                      <Input
                        type="text"
                        value={parcela}
                        onChange={onChangeParcela}
                        onBlur={onBlurParcela}
                        valid={parcelaErro === false}
                        invalid={!!parcelaErro}
                      />
                      <FormErrMsg>{parcelaErro}</FormErrMsg>
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label className="required">Total de parcelas</Label>
                      <Input
                        type="text"
                        value={totalParcelas}
                        onChange={onChangeTotalParcelas}
                        onBlur={onBlurTotalParcelas}
                        valid={totalParcelasErro === false}
                        invalid={!!totalParcelasErro}
                      />
                      <FormErrMsg>{totalParcelasErro}</FormErrMsg>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col xs={9}>
                    <FormGroup>
                      <Label>Nome do credor</Label>
                      <Input
                        type="text"
                        value={credor}
                        onChange={onChangeCredor}
                      />
                      <FormText className="help-block">
                        Preencha se for um financiamento
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Label>Juros</Label>
                      <Percent
                        value={juros}
                        onChange={onChangeJuros}
                        onBlur={onBlurJuros}
                        valid={jurosErro === false}
                        invalid={!!jurosErro}
                      />
                      <FormErrMsg>{jurosErro}</FormErrMsg>
                      <FormText className="help-block">% ao mês</FormText>
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>
            )}
          <Row form>
          <Col xs={6}> 
            <FormGroup>
              <Label className="required">Categoria</Label>
              <Input
                type="select"
                value={categoria}
                onChange={onChangeCategoria}
              >
                {CATEGORIAS_DESPESAS_OPCOES.map((categoria) => (
                  <option value={categoria} key={`categoria_opt_${categoria}`}>
                    {categoria}
                  </option>
                ))}
              </Input>
            </FormGroup>
            </Col>
            <Col xs={6}> 
            <FormGroup>
              <Label className="required">Tipo</Label>
              <Input type="select" value={tipo} onChange={onChangeTipo}>
                {TIPOS_DESPESAS.map((tipoDespesa) => (
                  <option
                    value={tipoDespesa}
                    key={`tipoDespesa_opt_${tipoDespesa}`}
                  >
                    {tipoDespesa}
                  </option>
                ))}
              </Input>
            </FormGroup>
            </Col>
          </Row> 
            <FormGroup>
              <Label>Tags</Label>
              <CreatableSelect
                isMulti
                options={tagsOpcoes}
                onChange={onChangeTags}
                value={tags}
                placeholder="Selecione..."
                noOptionsMessage={() => "Crie uma tag!"}
                formatCreateLabel={(t) => (
                  <span>
                    Criar nova tag <strong>{t}</strong>...
                  </span>
                )}
              ></CreatableSelect>
            </FormGroup>
            <FormGroup>
              <Label>Vinculada a um patrimônio</Label>
              <Row>
                <Col sm={10}>
                <Input
                  type="select"
                  value={patrimonio}
                  onChange={onChangePatrimonio}
                >   
                  <option value="">
                    {props.patrimonioUuid ? "Nenhum" : "Selecione"}
                  </option>
                  {patrimonios.map((p) => (
                    <option value={p.id} key={`patrimonio_${p.id}`} >
                      {p.descricao.length > 30 ? `${p.descricao.substring(0, 30)}...` : p.descricao}
                    </option>
                  ))}
                </Input>
                </Col>
                <Col sm={2}>
                  <BtnModalFormPatrimonio 
                    mes={mes} 
                    ano={ano} 
                    onAdd={onClickBtnAdicionarPatrimono} 
                  />
                </Col>
              </Row>
            </FormGroup>
            {formularioValido === false && (
              <Alert color="danger">
                Não foi possível salvar despesa. {formularioErro}
              </Alert>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          {!props.id && (
            <Fragment>
              <Button color="primary" onClick={onSubmitCloseForm}>
                Salvar e fechar
              </Button>
              <Button color="primary" onClick={onSubmitKeepInsertingForm}>
                Salvar e adicionar outra
              </Button>
            </Fragment>
          )}
          {props.id && (
            <Button color="primary" onClick={onSubmitCloseForm}>
              Salvar
            </Button>
          )}
          {(props.id && (props.despesaFixa || props.despesaTemporaria)) && (
            <Button color="primary" onClick={handleSubmitCascade}>
              Salvar Para Todas as Futuras
            </Button>
          )}
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalFormDespesa.propTypes = {
  categoria: PropTypes.oneOf(CATEGORIAS_DESPESAS_OPCOES).isRequired,
  mes: PropTypes.number.isRequired,
  ano: PropTypes.number.isRequired,
  id: PropTypes.number,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  patrimonioId: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(BtnModalFormDespesa);
