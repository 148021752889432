import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert,
  FormFeedback
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { getAxios } from "../../../utils";
import { withRouter } from "react-router-dom";
import validator from "validator";
import owasp from "owasp-password-strength-test";

function RedefinirSenha(props) {
  const { token } = props.match.params;
  const [tokenValidado, setaTokenValidado] = useState(false);
  const [tokenValido, setaTokenValido] = useState(false);
  const [redefinicaoSucesso, setaRedefinicaoSucesso] = useState(false);
  const [enviar, setaEnviar] = useState(null);
  const [enviado, setaEnviado] = useState(false);
  const [senha, setaSenha] = useState("");
  const [senhaInvalida, setaSenhaInvalida] = useState(null);
  const [senhaMensagem, setaSenhaMensagem] = useState("");
  const [senhaConfirmacao, setaSenhaConfirmacao] = useState("");
  const [senhaConfirmacaoErro, setaSenhaConfirmacaoErro] = useState(null);

  // --- primeiro load: verificar o access token ---
  useEffect(() => {
    const validarToken = async () => {
      const params = {
        token
      };

      const axiosInstance = getAxios();
      const uri = "/recuperar-senha";
      let response;

      try {
        response = await axiosInstance.get(uri, { params });
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      setaTokenValidado(true);

      if (response.status === 200) {
        setaTokenValido(true);
      } else if (response.status === 403) {
        setaTokenValido(false);
      } else {
        props.history.push("/404");
      }
    };

    validarToken();
  }, [token]);

  // --- eventos para o formulário ---
  const onSubmitForm = e => {
    e.preventDefault();

    const senhaValida = validaSenha();
    const senhaConfirmacaoValida = validaSenhaConfirmacao();

    if (senhaValida && senhaConfirmacaoValida) {
      setaEnviar(new Date());
    }
  };

  useEffect(() => {
    const postDados = async () => {
      const dados = {
        senha
      };

      const axiosInstance = getAxios({
        "x-access-token": token
      });
      const uri = "/recuperar-senha";
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      setaEnviado(true);

      if (response.status === 200) {
        setaRedefinicaoSucesso(true);
      } else if (response.status === 403) {
        setaTokenValido(false);
      } else {
        props.history.push("/404");
      }
    };

    if (senhaInvalida === false && senhaConfirmacaoErro === false) {
      postDados();
    }
  }, [enviar]);

  // --- eventos para campo Senha ---
  const validaSenha = () => {
    if (validator.isEmpty(senha)) {
      setaSenhaInvalida(true);
      setaSenhaMensagem("Senha obrigatória.");
      return false;
    }

    // TODO: componentizar essa lógica, aqui e em MeuCadastro.js
    const MIN_PASSWORD_LENGTH = 8;

    owasp.config({
      minOptionalTestsToPass: 2,
      minLength: MIN_PASSWORD_LENGTH
    });

    const resultado = owasp.test(senha);

    let mensagem = "";

    if (!resultado.strong) {
      mensagem = "Senha fraca. ";
    }

    if (!resultado.strong && resultado.failedTests.length > 0) {
      const teste = resultado.failedTests[0];

      switch (teste) {
        case 0:
          mensagem += `Deve ter no mínimo ${MIN_PASSWORD_LENGTH} caracteres.`;
          break;
        case 2:
          mensagem += `Não deve conter sequências de três ou mais caracteres repetidos.`;
          break;
        case 3:
          mensagem += `Deve conter pelo menos um caractere em caixa baixa.`;
          break;
        case 4:
          mensagem += `Deve conter pelo menos um caractere em caixa alta.`;
          break;
        case 5:
          mensagem += `Deve conter pelo menos um número.`;
          break;
        case 6:
          mensagem += `Deve conter pelo menos um caractere especial (#,$,%, etc.).`;
          break;
        default:
          break;
      }
    }

    setaSenhaInvalida(!resultado.strong);
    setaSenhaMensagem(mensagem);

    return resultado.strong;
  };

  const onChangeSenha = e => {
    setaSenha(e.target.value);
  };

  useEffect(() => {
    if (senha) {
      validaSenha();
    }

    if (senhaConfirmacao) {
      validaSenhaConfirmacao();
    }
  }, [senha]);

  useEffect(() => {
    if (senhaConfirmacao) {
      validaSenhaConfirmacao();
    }
  }, [senhaConfirmacao]);

  const onBlurSenha = () => {
    validaSenha();
  };

  // --- eventos para campo Confirmação de Senha ---
  const validaSenhaConfirmacao = () => {
    if (senha === "") {
      setaSenhaConfirmacaoErro(null);
      return false;
    } else if (senhaConfirmacao !== senha) {
      setaSenhaConfirmacaoErro("Senhas não conferem.");
      return false;
    }

    setaSenhaConfirmacaoErro(false);
    return true;
  };

  const onChangeSenhaConfirmacao = e => {
    setaSenhaConfirmacao(e.target.value);

    validaSenhaConfirmacao();
  };

  useEffect(() => {
    if (senhaConfirmacaoErro !== null) {
      validaSenhaConfirmacao();
    }
  }, [senhaConfirmacao]);

  const onBlurSenhaConfirmacao = () => {
    validaSenhaConfirmacao();
  };

  return (
    <div className="app flex-row align-items-center login-bg">
      <Container>
        <Row className="justify-content-center animated fadeIn">
          <Col md={6}>
            <Card className="mx-4">
              <CardBody className="p-4">
                <Form onSubmit={onSubmitForm}>
                  <h1>Redefinir senha</h1>
                  <p className="text-muted">Crie uma nova senha</p>

                  {!tokenValidado && (
                    <div className="text-muted text-center mt-3">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Carregando...</span>
                      </div>
                    </div>
                  )}

                  {tokenValidado && !tokenValido && (
                    <Fragment>
                      <Alert color="danger">
                        <i className="far fa-times-circle"></i> &nbsp; Pedido
                        inválido ou já utilizado. <br />
                        <br />
                        Solicite uma nova{" "}
                        <a href="/esqueci-minha-senha">
                          redefinição de senha
                        </a>{" "}
                        para continuar.
                      </Alert>
                    </Fragment>
                  )}

                  {tokenValido && !enviado && (
                    <Fragment>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Nova senha"
                          autoComplete="off"
                          value={senha}
                          onChange={onChangeSenha}
                          onBlur={onBlurSenha}
                          valid={senhaInvalida === false}
                          invalid={senhaInvalida}
                        />
                        {senhaInvalida && (
                          <FormFeedback>{senhaMensagem}</FormFeedback>
                        )}
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Repita a senha"
                          autoComplete="off"
                          value={senhaConfirmacao}
                          onChange={onChangeSenhaConfirmacao}
                          onBlur={onBlurSenhaConfirmacao}
                          valid={senhaConfirmacaoErro === false}
                          invalid={!!senhaConfirmacaoErro}
                        />
                        {senhaConfirmacaoErro && (
                          <FormFeedback>{senhaConfirmacaoErro}</FormFeedback>
                        )}
                      </InputGroup>

                      <Row className="justify-content-center">
                        <Col xs={12} sm={6}>
                          <Button block color="primary" type="submit">
                            Redefinir senha
                          </Button>
                        </Col>
                      </Row>
                    </Fragment>
                  )}

                  {redefinicaoSucesso && (
                    <Alert color="success">
                      <i className="far fa-check-circle"></i>
                      &nbsp; Senha alterada com sucesso!
                      <br />
                      <br />
                      Agora você já pode usá-la para{" "}
                      <a href="/">
                        <strong>entrar</strong>
                      </a>
                      .
                    </Alert>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(RedefinirSenha);
