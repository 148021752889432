import DefaultLayout from "./containers/DefaultLayout";
import Dashboard from "./views/Dashboard";
import DadosPessoais from "./views/DadosPessoais";
import Organize from "./views/Organize";
import Equilibre from "./views/Equilibre";
import Planeje from "./views/Planeje";
import PlanejamentoFinanceiro from "./views/PlanejamentoFinanceiro";
import Proteja from "./views/Proteja";
import Invista from "./views/Invista";
import Configuracoes from "./views/Configuracoes";
import Atendimento from "./views/Atendimento";

const routes = [
  { path: "/", name: "Página inicial", component: DefaultLayout, exact: true },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/configuracoes/acesso",
    name: "Dados de Acesso",
    component: Configuracoes.DadosAcesso,
  },
  {
    path: "/configuracoes/tags",
    name: "Gerenciar Tags",
    component: Configuracoes.Tags,
  },
  {
    path: "/dados-pessoais/meu-cadastro",
    name: "Meu Cadastro",
    component: DadosPessoais.MeuCadastro,
  },
  {
    path: "/dados-pessoais/conjuge",
    name: "Cônjuge",
    component: DadosPessoais.Conjuge,
  },
  {
    path: "/dados-pessoais/filhos",
    name: "Filhos",
    component: DadosPessoais.Filhos,
  },
  {
    path:"/dados-pessoais/perfil-financeiro",
    name:"Perfil Financeiro",
    component: Planeje.TestePerfil,
  },
  {
    path: "/organize/receitas",
    name: "Receitas",
    component: Organize.Receitas,
  },
  {
    path: "/organize/despesas",
    name: "Despesas",
    component: Organize.Despesas,
  },
  {
    path: "/organize/UploadDeExtratos",
    name: "UploadDeExtratos",
    component: Organize.UploadDeExtratos
  },
  {
    path: "/equilibre/financiamentos",
    name: "Financiamentos",
    component: Equilibre.Financiamentos,
  },
  {
    path: "/equilibre/dividas",
    name: "Dívidas",
    component: Equilibre.Dividas,
  },
  {
    path: "/equilibre/reduzir-despesas",
    name: "Reduzir Despesas",
    component: Equilibre.ReduzirDespesas,
    exact: true,
  },
  {
    path: "/equilibre/reduzir-despesas/especifique",
    name: "Especifique",
    component: Equilibre.EspecifiqueReduzirDespesas,
    exact: true,
  },
  {
    path: "/equilibre/aumentar-receitas",
    name: "Aumentar Receitas",
    component: Equilibre.AumentarReceitas,
    exact: true,
  },
  {
    path: "/equilibre/aumentar-receitas/especifique",
    name: "Especifique",
    component: Equilibre.EspecifiqueAumentarReceitas,
    exact: true,
  },
  {
    path: "/planeje/projetos-de-vida",
    name: "Projetos de Vida",
    component: Planeje.ProjetosVida,
    exact: true,
  },
  {
    path: "/planeje/projetos-de-vida/especifique",
    name: "Especifique",
    component: Planeje.Especifique,
    exact: true,
  },
  {
    path: "/planeje/progresso",
    name: "Progresso",
    component: Planeje.Progresso,
  },
  {
    path: "/planeje/projetos-de-vida/especifique/:id",
    name: "Especifique",
    component: Planeje.Especifique,
  },
  {
    path: "/planeje/aposentadoria",
    name: "Aposentadoria",
    component: Planeje.Aposentadoria,
  },
  {
    path: "/proteja/seguros-e-protecoes",
    name: "Seguros e Proteções",
    component: Proteja.SegurosProtecoes,
  },
  {
    path: "/proteja/historico-de-saude",
    name: "Histórico de Saúde",
    component: Proteja.HistoricoSaude,
  },
  {
    path: "/invista/patrimonios",
    name: "Patrimônios",
    component: Invista.Patrimonios,
  },
  {
    path: "/invista/carteira",
    name: "Minha Carteira",
    component: Invista.Carteira,
  },
  {
    path: "/invista/investimentos-mensais",
    name: "Investimentos mensais",
    component: Invista.InvestimentosMensais,
  },
  {
    path: "/planejamento-financeiro/relatorio-mensal",
    name: "Relatório Mensal",
    component: PlanejamentoFinanceiro.RelatorioMensal,
  },
  {
    path: "/planejamento-financeiro/indices",
    name: "Índices",
    component: PlanejamentoFinanceiro.Indices,
  },
  {
    path: "/planejamento-financeiro/historico-indices",
    name: "Histórico Índices",
    component: PlanejamentoFinanceiro.HistoricoIndices,
  },
  {
    path: "/planejamento-financeiro/dre",
    name: "DRE",
    component: PlanejamentoFinanceiro.DRE,
  },
  {
    path: "/planejamento-financeiro/relatorio50",
    name: "Relatório 50/30/20",
    component: PlanejamentoFinanceiro.Relatorio50,
  },
  {
    path: "/planejamento-financeiro/fluxo-de-caixa",
    name: "Fluxo de Caixa",
    component: PlanejamentoFinanceiro.FluxoDeCaixa,
  },
  {
    path: "/planejamento-financeiro/receitas",
    name: "Receitas",
    component: PlanejamentoFinanceiro.Receitas,
  },
  {
    path: "/planejamento-financeiro/despesas",
    name: "Despesas",
    component: PlanejamentoFinanceiro.Despesas,
  },
  {
    path: "/planejamento-financeiro/gestao-patrimonial/balanco-patrimonial",
    name: "Balanço Patrimonial",
    component: PlanejamentoFinanceiro.BalancoPatrimonial,
  },
  {
    path: "/planejamento-financeiro/gestao-patrimonial/distribuicao",
    name: "Distribuição",
    component: PlanejamentoFinanceiro.Distribuicao,
  },
  {
    path: "/planejamento-financeiro/gestao-patrimonial/ativos-reais-x-passivos",
    name: "Ativos Reais X Passivos",
    component: PlanejamentoFinanceiro.AtivosReaisXPassivos,
  },
  {
    path: "/planejamento-financeiro/gestao-patrimonial/carteira-de-investimentos",
    name: "Carteira de Investimentos",
    component: PlanejamentoFinanceiro.CarteiraDeInvestimentos,
  },
  {
    path: "/atendimento",
    name: "Atendimento",
    component: Atendimento.Atendimento,
    exact: true,
  },
  {
    path: "/atendimento/novo-chamado",
    name: "Novo Chamado",
    component: Atendimento.NovoChamado,
    exact: true,
  },
  {
    path: "/atendimento/andamento/:id",
    name: "Andamento",
    component: Atendimento.Andamento,
    exact: true,
  },
];

export default routes;
