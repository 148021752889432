import { RelatorioMensal } from "./RelatorioMensal";
import { Despesas } from "./Despesas";
import { DRE } from "./DRE";
import { BalancoPatrimonial } from "./BalancoPatrimonial";
import { Distribuicao } from "./Distribuicao";
import { AtivosReaisXPassivos } from "./AtivosReaisXPassivos";
import { Indices } from "./Indices";
import { HistoricoIndices } from "./HistoricoIndices";
import { Receitas } from "./Receitas";
import { FluxoDeCaixa } from "./FluxoDeCaixa";
import { CarteiraDeInvestimentos } from "./CarteiraDeInvestimentos";
import { Relatorio50 } from "./Relatorio50";

export default {
  RelatorioMensal,
  Despesas,
  DRE,
  BalancoPatrimonial,
  Distribuicao,
  AtivosReaisXPassivos,
  Indices,
  HistoricoIndices,
  Receitas,
  FluxoDeCaixa,
  CarteiraDeInvestimentos,
  Relatorio50
};
