import React, { useEffect, useState } from "react";
import { getAxiosAuth, getClienteId } from "../../utils";

function ClienteName(props) {
  const clienteId = getClienteId();
  const [nomeCompleto, setaNomeCompleto] = useState('');
  
  const axiosInstance = getAxiosAuth(() => {
    props.history.push("/login");
  });

  useEffect(() => {
    const obtemDados = async () => {
      const uri = `/clientes/${clienteId}`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          props.history.push("/500");
          return;
        }
      }

      const dados = response.data;

      setaNomeCompleto(dados.nomeCompleto || "");
    };

    obtemDados();
  }, []);

  return nomeCompleto.length > 0 && (
    <span>
      Olá <strong>{nomeCompleto}</strong>
    </span>
  )
}

export default ClienteName;