import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Badge,
} from "reactstrap";
import {
  toPercentColor,
} from "../../utils";
import parse from 'html-react-parser';

const TIPO_PLANEJAR = 1;
const TIPO_INVESTIR = 2;

function BlocoIndice(props) {
const css = ["mb-0"];
let color = "";

if (props.tipo === TIPO_PLANEJAR) {
    css.push("card-accent-warning");
    color = "warning";
} else if (props.tipo === TIPO_INVESTIR) {
    css.push("card-accent-primary");
    color = "primary";
} else {
    css.push("card-accent-success");
    color = "success";
}

return (
    <Card className={css.join(" ")} style={{ height: "100%" }}>
    <CardHeader>
        <h5 className="float-left mb-0 font-weight-bold">
        {props.titulo}
        <small className="text-muted d-block mt-2">{props.subtitulo}</small>
        </h5>
        <div className="card-header-actions">
        <Badge
            color={color}
            pill
            className="float-right p-2"
            style={{ fontSize: "1.2rem" }}
        >
            {!isNaN(props.valor) && toPercentColor(props.valor)}
            {isNaN(props.valor) && props.valor}
        </Badge>
        </div>
    </CardHeader>
    <CardBody className="text-justify card-body-indices">
        {props.children}
    </CardBody>
    </Card>
);
}

const ResumoIndice = ({ tipo, titulo, subtitulo, texto }) => {
    return (
            <Card outline={false} className="mb-0 border-0">
                <BlocoIndice
                    tipo={tipo}
                    titulo={titulo}
                    subtitulo={subtitulo}
                >
                    {parse(texto)}
                </BlocoIndice>
            </Card>
    )
}

export default ResumoIndice;