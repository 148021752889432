import React from "react";
import { Card, CardBody,CardTitle } from "reactstrap";
import "moment/locale/pt-br";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import { toPercent } from "../../utils";
import { LoadingSpinner } from "../LoadingSpinner";

function GraficoIndiceLinha({
  titulo,
  months,
  indice,
 }) {
  const labels = months;
  
  const date = {
    labels: labels,
    datasets: [{
      label: 'Índice pelo mes',
      data: indice,
      backgroundColor: "transparent",
      borderColor: "#84C027",
      pointHoverBackgroundColor: "#fff",
      borderWidth: 3
    }]
  };

  const config = {
    type: 'bar',
    data: date,
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    },
  };

  let options;

  if(titulo === "Reserva Estratégica de Segurança") {
    options = {
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            const { yLabel } = tooltipItem;
  
            let label = data.datasets[tooltipItem.datasetIndex].label || "";
  
            if(label) {
              label = Math.round(yLabel*10);
            }
  
            return label;
          }
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              callback: function(value) {
                return value*10
              },
            }
          }
        ]
      },
      elements: {
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3
        }
      }
    }
  } else {
    options = {
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            const { yLabel } = tooltipItem;
  
            let label = data.datasets[tooltipItem.datasetIndex].label || "";
  
            if(label) {
              label = toPercent(yLabel);
            }
  
            return label;
          }
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              callback: function(value) {
                return toPercent(value)
              },
            }
          }
        ]
      },
      elements: {
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3
        }
      }
    }
  }
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="text-center">{titulo}</CardTitle>
        {indice.length === 0 && <LoadingSpinner />}
        <div className="chart-wrapper mt-3 mb-5" style={{ minHeight: 350 }}>
          <Line data={date} options={options} config={config}/>
        </div>
      </CardBody>
    </Card>
  );
}

GraficoIndiceLinha.propTypes = {
    titulo: PropTypes.string.isRequired,
    months: PropTypes.array.isRequired,
    indice: PropTypes.array.isRequired,
};

export default GraficoIndiceLinha;
