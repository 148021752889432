export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-tachometer-alt",
    },
    {
      title: true,
      name: "Minhas informações",
      // class: 'nav-item-title',
    },
    {
      name: "Dados pessoais",
      url: "/dados-pessoais",
      icon: "fas fa-user",
      children: [
        {
          name: "Meu cadastro",
          icon: "fa fa-file-alt",
          url: "/dados-pessoais/meu-cadastro",
        },
        {
          name: "Cônjuge",
          icon: "fas fa-heart",
          url: "/dados-pessoais/conjuge",
        },
        {
          name: "Filhos",
          icon: "fa fa-child",
          url: "/dados-pessoais/filhos",
        },
        {
          name:"Perfil Financeiro",
          icon:"fab fa-readme",
          url:"/dados-pessoais/perfil-financeiro",
        },
      ],
    },
    {
      name: "Organize",
      url: "/organize",
      icon: "fas fa-table",
      children: [
        {
          name: "Receitas",
          icon: "fas fa-hand-holding-usd",
          url: "/organize/receitas",
        },
        {
          name: "Despesas",
          icon: "fa fa-cut",
          url: "/organize/despesas",
        },
        {
          name: "Upload de extratos",
          icon: "fa fa-upload",
          url: "/organize/UploadDeExtratos",
          badge: {
            variant:"danger",
            text:"beta"
          }
        },
      ],
    },
    {
      name: "Equilibre",
      url: "/equilibre",
      icon: "fas fa-balance-scale",
      children: [
        {
          name: "Financiamentos",
          icon: "fas fa-hourglass-start",
          url: "/equilibre/financiamentos",
        },
        {
          name: "Dívidas",
          icon: "fa fa-bomb",
          url: "/equilibre/dividas",
        },
        {
          name: "Reduzir Despesas",
          icon: "fas fa-fill-drip",
          url: "/equilibre/reduzir-despesas",
        },
        {
          name: "Aumentar Receitas",
          icon: "fas fa-seedling",
          url: "/equilibre/aumentar-receitas",
        },
      ],
    },
    {
      name: "Planeje",
      url: "/planeje",
      icon: "fas fa-map",
      children: [
        {
          name: "Projetos de Vida",
          icon: "fa fa-umbrella-beach",
          url: "/planeje/projetos-de-vida",
        },
        {
          name: "Progresso",
          icon: "fa fa-circle-notch",
          url: "/planeje/progresso",
        },
        {
          name: "Aposentadoria",
          icon: "fas fa-coins",
          url: "/planeje/aposentadoria",
        },
      ],
    },
    {
      name: "Proteja",
      url: "/proteja",
      icon: "fas fa-shield-alt",
      children: [
        {
          name: "Seguros e Proteções",
          icon: "fas fa-umbrella",
          url: "/proteja/seguros-e-protecoes",
        },
        {
          name: "Histórico de saúde",
          icon: "fas fa-file-medical",
          url: "/proteja/historico-de-saude",
        },
      ],
    },
    {
      name: "Invista",
      url: "/invista",
      icon: "fas fa-dollar-sign",
      children: [
        {
          name: "Patrimônios",
          icon: "fa fa-home",
          url: "/invista/patrimonios",
        },
        {
          name: "Minha Carteira",
          icon: "fas fa-wallet",
          url: "/invista/carteira",
        },
        {
          name: "Investimentos mensais",
          icon: "fas fa-piggy-bank",
          url: "/invista/investimentos-mensais",
        },
      ],
    },
    {
      title: true,
      name: "Relatórios",
      class: "nav-title-relatorios",
    },
    {
      name: "Gestão Orçamentária",
      icon: "fas fa-seedling",
      class: "nav-item-relatorios",
      children: [
        {
          name: "DRE",
          icon: "fas fa-balance-scale",
          url: "/planejamento-financeiro/dre",
          class: "nav-item-relatorios",
        },
        {
          name: "Fluxo de Caixa",
          icon: "fas fa-chart-bar",
          url: "/planejamento-financeiro/fluxo-de-caixa",
          class: "nav-item-relatorios",
        },
        {
          name: "Receitas",
          icon: "fas fa-chart-pie",
          url: "/planejamento-financeiro/receitas",
          class: "nav-item-relatorios",
        },
        {
          name: "Despesas",
          icon: "fas fa-chart-pie",
          url: "/planejamento-financeiro/despesas",
          class: "nav-item-relatorios",
        },
        {
          name: "Relatório 50/30/20",
          icon: "fas fa-chart-bar",
          url: "/planejamento-financeiro/relatorio50",
          class: "nav-item-relatorios",
        },
      ]
    },
    {
      name: "Gestão Patrimonial",
      icon: "fas fa-landmark",
      url: "/planejamento-financeiro/gestao-patrimonial",
      class: "nav-item-relatorios",
      children: [
        {
          name: "Balanço Patrimonial",
          icon: "fas fa-balance-scale",
          url:
            "/planejamento-financeiro/gestao-patrimonial/balanco-patrimonial",
          class: "nav-item-relatorios",
        },
        {
          name: "Distribuição",
          icon: "fas fa-chart-pie",
          url: "/planejamento-financeiro/gestao-patrimonial/distribuicao",
          class: "nav-item-relatorios",
        },
        {
          name: "Ativos Reais X Passivos",
          icon: "fas fa-search-dollar",
          url:
            "/planejamento-financeiro/gestao-patrimonial/ativos-reais-x-passivos",
          class: "nav-item-relatorios",
        },
        {
          name: "Carteira de Investimentos",
          icon: "fas fa-wallet",
          url:
            "/planejamento-financeiro/gestao-patrimonial/carteira-de-investimentos",
          class: "nav-item-relatorios",
        },
      ],
    },
    {
      name: "Índices",
      icon: "fas fa-notes-medical",
      url: "/planejamento-financeiro/indices",
      class: "nav-item-relatorios",
    },
  ],
};

export const mobileNav = {
  items: [
    {
      title: true,
      name: "Configurações",
    },
    {
      name: "Sair",
      icon: "fas fa-lock",
      url: "/sair",
    },
  ],
};
