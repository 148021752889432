import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Alert,
  Button,
  Row,
  Col,
  Jumbotron,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getAxiosAuth, getTokenClientId } from "../../utils";
import BadgeSituacao from "./BadgeSituacao";
import validator from "validator";
import { FormErrMsg } from "../../components";
import { ToastContainer, toast } from "react-toastify";

const quebrarLinhas = (texto) =>
  texto.split("\n").map((text, key) => (
    <React.Fragment key={key}>
      {text}
      <br />
    </React.Fragment>
  ));

function Andamento(props) {
  const [atendimentoId, setaAtendimentoId] = useState();
  const [carregandoDados, setaCarregandoDados] = useState(true);
  const [erroObterDados, setaErroObterDados] = useState(false);
  const [dadosAtendimento, setaDadosAtendimento] = useState();
  const [submetendoResposta, setaSubmetendoResposta] = useState(false);
  const [erroSubmeterResposta, setaErroSubmeterResposta] = useState(false);
  const [mensagemErroResposta, setaMensagemErroResposta] = useState(null);
  const [resposta, setaResposta] = useState("");
  const [exibirModalCancelarChamado, setaExibirModalCancelarChamado] = useState(
    false
  );
  const [cancelandoChamado, setaCancelandoChamado] = useState(false);
  const [erroCancelarChamado, setaErroCancelarChamado] = useState(false);

  const respostaInputHandler = (event) => {
    setaResposta(event.target.value);
    setaMensagemErroResposta(null);
  };

  const respostaBlurHandler = () => {
    validarResposta(resposta);
  };

  const validarResposta = (resposta) => {
    if (!resposta || validator.isEmpty(resposta, { ignore_whitespace: true })) {
      setaMensagemErroResposta("Obrigatório");
      return false;
    } else {
      setaMensagemErroResposta(false);
    }
    return true;
  };

  const obterSetarDadosAtendimento = async (atendimentoId) => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/atendimento/${atendimentoId}`;

    const response = await axiosInstance.get(uri);

    if (response && response.status === 200) {
      setaDadosAtendimento(response.data);
    }
  };

  const obterSetarDados = async () => {
    if (!atendimentoId) {
      return;
    }
    setaErroObterDados(false);
    setaCarregandoDados(true);

    try {
      await obterSetarDadosAtendimento(atendimentoId);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        props.history.push("/404");
      } else {
        setaErroObterDados(error.response ? error.response.status : true);
      }
    } finally {
      setaCarregandoDados(false);
    }
  };

  useEffect(() => {
    if (props.match && props.match.params && props.match.params.id) {
      setaAtendimentoId(props.match.params.id);
    } else {
      props.history.push("/404");
    }
  }, [props.match, props.history]);

  useEffect(() => {
    obterSetarDados();
  }, [atendimentoId]);

  const submeterDados = async () => {
    if (!atendimentoId) {
      return;
    }
    setaErroSubmeterResposta(false);

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/atendimento/${atendimentoId}`;
    let dados = {
      resposta,
    };

    setaSubmetendoResposta(true);

    let response;
    try {
      response = await axiosInstance.post(uri, dados);
    } catch (error) {
      setaErroSubmeterResposta(error.response ? error.response.status : true);
    } finally {
      setaSubmetendoResposta(false);
    }

    if (response && response.status === 200) {
      obterSetarDados();
    }
  };

  const submitInputHandler = (event) => {
    event.preventDefault();

    let valido = validarResposta(resposta);

    if (valido) {
      submeterDados().then(() => toast("Resposta enviada!"));
      setaMensagemErroResposta(null);
    }
  };

  const toggleModalCancelarChamado = (event) => {
    event && event.stopPropagation();
    setaExibirModalCancelarChamado(!exibirModalCancelarChamado);
    setaErroCancelarChamado(false);
  };

  const submeterCancelarChamado = async () => {
    if (!atendimentoId) {
      return;
    }
    setaErroCancelarChamado(false);

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/atendimento/${atendimentoId}`;

    setaCancelandoChamado(true);

    let response;
    try {
      response = await axiosInstance.patch(uri, {});
    } catch (error) {
      setaErroCancelarChamado(error.response ? error.response.status : true);
    } finally {
      setaCancelandoChamado(false);
    }

    if (response && response.status === 200) {
      await obterSetarDados();
      toast("Chamado cancelado!");
      setaExibirModalCancelarChamado(false);
    }
  };

  const submitCancelarChamadoHandler = (event) => {
    event.preventDefault();

    submeterCancelarChamado();
  };

  const btnCancelarChamado = dadosAtendimento &&
    dadosAtendimento.situacao !== "Cancelado" &&
    dadosAtendimento.situacao !== "Finalizado" && (
      <Button color="danger" onClick={toggleModalCancelarChamado}>
        Cancelar chamado
      </Button>
    );

  const btnVoltarInputHandler = (event) => {
    event && event.preventDefault();
    props.history.push("/atendimento");
  };

  let criouSucesso = false;

  if (props.history && props.history.location && props.history.location.state) {
    criouSucesso = true;
  }

  useEffect(() => {
    if (criouSucesso) {
      toast("Chamado criado com sucesso!");
    }
  }, []);

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 2999 }}
      />
      {carregandoDados && (
        <Container>
          <div className="shimmer line br animate mb-2"></div>
          <div className="shimmer line br animate"></div>
          <div className="shimmer line br animate"></div>
          <div className="shimmer textarea br animate"></div>
          <div className="shimmer line br animate"></div>
          <div className="shimmer textarea br animate"></div>
        </Container>
      )}
      {!carregandoDados && dadosAtendimento && (
        <Container>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  Chamado <strong>#{dadosAtendimento.codigo}</strong>
                </Col>
                <Col className="text-right">
                  <Button
                    color="light"
                    size="sm"
                    onClick={btnVoltarInputHandler}
                  >
                    <i className="fas fa-arrow-left" />
                    &nbsp;&nbsp;&nbsp; Todos os chamados
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>Situação</Col>
                <Col>
                  <h5>
                    <BadgeSituacao situacao={dadosAtendimento.situacao} />
                  </h5>
                </Col>
                <Col sm={2}>Criado em</Col>
                <Col>{dadosAtendimento.criadoEm}</Col>
              </Row>
              <hr />
              <Row>
                <Col sm={2}>Nome</Col>
                <Col>{dadosAtendimento.nome}</Col>
                {dadosAtendimento.telefoneNumero && (
                  <>
                    <Col sm={2}>Telefone</Col>
                    <Col>
                      {dadosAtendimento.telefoneNumero} (
                      {dadosAtendimento.telefoneTipo})
                    </Col>
                  </>
                )}
              </Row>
              <Row className="mt-3">
                <Col sm={2}>Email</Col>
                <Col>{dadosAtendimento.email}</Col>
                <Col className="text-right"></Col>
              </Row>
              <hr />
              <Row>
                <Col>Título</Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <h5 className="pl-2">{dadosAtendimento.titulo}</h5>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>Descrição</Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="pl-2">
                    {quebrarLinhas(dadosAtendimento.descricao)}
                  </p>
                </Col>
              </Row>
              {dadosAtendimento.andamento.map((mensagem) => (
                <div key={`mensagem_${mensagem.id}`}>
                  <hr />
                  <Row>
                    {mensagem.idConsultor ? (
                      <>
                        <Col sm={2}>Respondido por</Col>
                        <Col>{mensagem.nomeConsultor}</Col>
                      </>
                    ) : (
                      <>
                        <Col sm={2}>
                          <strong>Sua resposta</strong>
                        </Col>
                        <Col></Col>
                      </>
                    )}

                    <Col sm={2}>Respondido em</Col>
                    <Col>{mensagem.criadoEm}</Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <p className="pl-2">{quebrarLinhas(mensagem.mensagem)}</p>
                    </Col>
                  </Row>
                </div>
              ))}
              {dadosAtendimento.situacao === "Em análise" && (
                <Jumbotron fluid className="mt-3 rounded">
                  <p className="display-4 text-center">
                    <i className="far fa-clock" />
                  </p>
                  <h4 className="text-center">Em análise</h4>
                  <p className="lead text-center">
                    Nosso tempo médio de resposta é de até 1 dia útil
                  </p>
                </Jumbotron>
              )}
              {dadosAtendimento.situacao === "Aguardando Cliente" && (
                <Jumbotron fluid className="mt-3 rounded">
                  <p className="display-4 text-center">
                    <i className="far fa-comment-dots" />
                  </p>
                  <h4 className="text-center">Aguardando Cliente</h4>
                  <p className="lead text-center">
                    Para continuar, precisamos da sua resposta
                  </p>
                </Jumbotron>
              )}
              {dadosAtendimento.situacao === "Finalizado" && (
                <Jumbotron fluid className="mt-3 rounded">
                  <p className="display-4 text-center">
                    <i className="far fa-check-circle" />
                  </p>
                  <h4 className="text-center">Chamado Finalizado</h4>
                  <p className="lead text-center">
                    O chamado foi encerrado e marcado como finalizado.
                  </p>
                </Jumbotron>
              )}
              {dadosAtendimento.situacao === "Cancelado" && (
                <Jumbotron fluid className="mt-3 rounded">
                  <p className="display-4 text-center">
                    <i className="far fa-trash-alt" />
                  </p>
                  <h4 className="text-center">Chamado Cancelado</h4>
                  <p className="lead text-center">
                    O chamado foi cancelado e não será mais respondido.
                  </p>
                </Jumbotron>
              )}
              {dadosAtendimento.situacao === "Aguardando Cliente" ? (
                <Form onSubmit={submitInputHandler}>
                  <FormGroup>
                    <Label className="required">Responder</Label>
                    <Input
                      type="textarea"
                      rows={10}
                      value={resposta}
                      onChange={respostaInputHandler}
                      onBlur={respostaBlurHandler}
                      valid={mensagemErroResposta === false}
                      invalid={!!mensagemErroResposta}
                    />
                    <FormErrMsg>{mensagemErroResposta}</FormErrMsg>
                  </FormGroup>
                  {erroSubmeterResposta && (
                    <Alert color="danger" style={{ display: "inline-block" }}>
                      Não foi possível responder o chamado.
                      {erroSubmeterResposta === true
                        ? "Problema com a conexão."
                        : `Problema interno no servidor (código ${erroSubmeterResposta}).`}
                    </Alert>
                  )}
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={submetendoResposta}
                      >
                        {submetendoResposta && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Enviar chamado
                      </Button>
                    </Col>
                    <Col className="text-right">{btnCancelarChamado}</Col>
                  </Row>
                </Form>
              ) : (
                <Row>
                  <Col className="text-right">{btnCancelarChamado}</Col>
                </Row>
              )}
            </CardBody>
          </Card>
          <Modal
            isOpen={exibirModalCancelarChamado}
            toggle={toggleModalCancelarChamado}
            fade
          >
            <Form onSubmit={submitCancelarChamadoHandler}>
              <ModalHeader>Cancelar Chamado</ModalHeader>
              <ModalBody>
                <p>
                  Você deseja mesmo <strong>Cancelar</strong> o chamado?
                </p>
                <p>Ele não será respondido pelo planejador.</p>
                {erroCancelarChamado && (
                  <Alert color="danger">
                    Não foi possível cancelar o chamado.
                    {erroCancelarChamado === true
                      ? " Problema com a conexão."
                      : ` Problema interno no servidor (código ${erroCancelarChamado})`}
                  </Alert>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  color="warning"
                  disabled={cancelandoChamado}
                >
                  {cancelandoChamado && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Sim: Cancelar chamado
                </Button>
                <Button color="link" onClick={toggleModalCancelarChamado}>
                  Não
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </Container>
      )}
      {erroObterDados && (
        <>
          <Alert color="danger" style={{ display: "inline-block" }}>
            Não foi possível obter o chamado.
            {erroObterDados === true
              ? " Problema com a conexão."
              : ` Problema interno no servidor (código ${erroObterDados})`}
          </Alert>
          <p>
            <Button onClick={obterSetarDados}>Tentar novamente</Button>
          </p>
        </>
      )}
    </div>
  );
}

export default withRouter(Andamento);
