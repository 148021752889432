import React, { useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getTokenClientId, getAxiosAuth } from "../../../utils";
import PropTypes from "prop-types";

function BtnModalRemoverMeta(props) {
  const [aberto, setaAberto] = useState(false);
  const [submetendo, setaSubmetendo] = useState(false);
  const [erroSubmeterDados, setaErroSubmeterDados] = useState(false);

  const buttonClickInputHandler = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setaAberto(!aberto);
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/reducao`;

    setaErroSubmeterDados(false);
    setaSubmetendo(true);

    try {
      await axiosInstance.delete(uri);
      setaAberto(false);
      props.onDelete();
    } catch (error) {
      setaErroSubmeterDados(error.response ? error.response.status : true);
    } finally {
      setaSubmetendo(false);
    }
  };

  return (
    <>
      <Button
        color="light"
        title="Remover meta"
        onClick={buttonClickInputHandler}
        className="ml-2"
      >
        Remover
      </Button>
      <Form onSubmit={formSubmitHandler}>
        <Modal isOpen={aberto} toggle={buttonClickInputHandler} fade>
          <ModalHeader>Remover Meta</ModalHeader>
          <ModalBody>
            <p>Confirma a remoção da meta?</p>

            {erroSubmeterDados && (
              <Alert color="danger">
                Não foi possível remover sua meta.
                {erroSubmeterDados === true
                  ? " Problema com a conexão."
                  : ` Problema interno no servidor (código ${erroSubmeterDados}).`}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              onClick={formSubmitHandler}
              disabled={submetendo}
            >
              {submetendo && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {erroSubmeterDados ? "Tentar novamente" : "Remover"}
            </Button>
            <Button color="link" onClick={buttonClickInputHandler}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </Form>
    </>
  );
}

BtnModalRemoverMeta.propTypes = {
  onDelete: PropTypes.func.isRequired,
}

export default withRouter(BtnModalRemoverMeta);
