import React, { Fragment } from "react";
import { toBRLColor } from "../../../utils";
import ItemAtivo from "./ItemAtivo";
import ItemPassivo from "./ItemPassivo";

function LinhaPatrimonio(props) {
  return (
    <Fragment>
      <tr className="table-warning">
        <td className="pl-4 font-weight-bold">{props.grupo}</td>
        <td className="d-none d-sm-table-cell text-right text-nowrap font-weight-bold">
          {toBRLColor(props.totalValor)}
        </td>
        <td className="d-none d-sm-table-cell text-right text-nowrap font-weight-bold">
          {toBRLColor(props.totalManutencao * -1)}
        </td>
        <td className="d-none d-sm-table-cell text-right text-nowrap font-weight-bold">
          {toBRLColor(props.totalReceita)}
        </td>
        <td className="d-none d-sm-table-cell text-right" />
      </tr>
      {props.items && (
        <Fragment>
          {props.items.map(item => (
            <tr key={`patrimonio_` + props.grupo + "_" + item.id}>
              <td className="text-small pl-5">
                <p className="limit-text-line">{item.descricao}</p>

                <span className="d-block d-sm-none pl-4 mt-2">
                  <strong>Valor:</strong>
                  &nbsp;&nbsp;{toBRLColor(item.valor)}
                </span>

                <span className="d-block d-sm-none pl-4">
                  <strong>Custo de Manutenção:</strong>
                  &nbsp;&nbsp;
                  <span className="text-danger">
                    {toBRLColor(item.custoMensal * -1)}
                  </span>
                </span>

                <span className="d-block d-sm-none pl-4">
                  <strong>Receita Atual:</strong>
                  &nbsp;&nbsp;{toBRLColor(item.receitaMensal)}
                </span>

                {item.isAtivo && (
                  <span className="d-block d-sm-none pl-4 mb-2">
                    <ItemAtivo />
                  </span>
                )}
                {!item.isAtivo && (
                  <span className="d-block d-sm-none pl-4 mb-2">
                    <ItemPassivo />
                  </span>
                )}
              </td>
              <td className="d-none d-sm-table-cell text-right text-nowrap">
                {toBRLColor(item.valor)}
              </td>
              <td className="d-none d-sm-table-cell text-right text-nowrap">
                {toBRLColor(item.custoMensal * -1)}
              </td>
              <td className="d-none d-sm-table-cell text-right text-nowrap">
                {toBRLColor(item.receitaMensal)}
              </td>
              <td className="d-none d-sm-table-cell text-center">
                {item.isAtivo && <ItemAtivo />}
                {!item.isAtivo && <ItemPassivo />}
              </td>
            </tr>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
}

export default LinhaPatrimonio;
