import React from "react";

const Titulo = ({ children }) => (
  <h6 className="font-weight-bold">{children}</h6>
);

const PrimaryList = ({ children }) => (
  <ol className="list-unstyled">{children}</ol>
);

const SecondaryList = ({ children }) => {
  return (
    <ul className="list-unstyled mb-3 lista-termos-secundaria">{children}</ul>
  );
};

const TertiaryList = ({ children }) => (
  <ol style={{ listStyleType: "lower-alpha" }}>{children}</ol>
);

function ConteudoTermos() {
  return (
    <>
      <h4 className="mb-3">Termos de Uso</h4>
      <PrimaryList>
        <li>
          <Titulo>1. VISÃO GERAL DA EMPRESA</Titulo>
          <SecondaryList>
            <li>
              1.1. O presente Termo de Uso foi desenvolvido pela{" "}
              <strong>SOLUTION FOR LIFE ASSESSORIA FINANCEIRA LTDA.</strong>,
              inscrita no CNPJ sob o no 12.552.044/0001-03, denominada apenas
              SFL, e tem por objetivo principal regulamentar as condições de
              prestação dos serviços de planejamento financeiro pessoal para os
              clientes do Programa Solution Experience.
            </li>
            <li>
              1.2. O <strong>SOLUTION MONEY</strong> é uma plataforma de gestão
              financeira pessoal desenvolvida para auxiliar o usuário no
              controle orçamentário e patrimonial, e trilhar caminhos para seus
              objetivos, tudo por meio de um celular, tablet ou computador.
            </li>
            <li>
              1.3. O <strong>USUÁRIO</strong> é toda a pessoa física, capaz
              civilmente, que aceita as condições de prestação dos serviços
              elencados no presente Termo de Uso, entendendo os direitos e as
              obrigações inerentes.
            </li>
            <li>
              1.4. Para que o Usuário possa ter acesso aos benefícios da
              plataforma SOLUTION MONEY, deverá ler o presente termo, aceitar
              suas condições, por meio da opção “Aceito os Termos de Uso e
              Política de Privacidade”, e, por fim, realizar seu cadastro.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>2. DA UTILIZAÇÃO DO SOLUTION MONEY</Titulo>
          <SecondaryList>
            <li>
              2.1. Para começar a ter acesso aos benefícios da plataforma, o
              Usuário deverá, além de aceitar este Termo de Uso e a Política de
              Privacidade, se cadastrar e inserir seus dados, não sendo a 
              <strong>SFL</strong>
               responsável pela veracidade dessas informações.
            </li>
            <li>
              2.2. O Usuário terá acesso ao SOLUTION MONEY durante o tempo
              contratado em seu programa Solution Experience e poderá realizar
              renovação do seu programa após o término deste prazo.
            </li>
            <li>
              2.3. Recomenda-se que o Usuário utilize senha de acesso segura,
              inserindo caracteres maiúsculos e minúsculas, números e símbolos,
              sendo de sua inteira responsabilidade a manutenção do segredo
              sobre ela.
            </li>
            <li>
              2.4. Caso o Usuário necessite de ajuda, poderá encontrá-la
              facilmente na Central de Ajuda da plataforma, no e-mail{" "}
              <a href="mailto:suporte@solutionforlife.com.br">
                <strong>suporte@solutionforlife.com.br</strong>
              </a>
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>3. DOS SERVIÇOS SOLUTION EXPERIENCE</Titulo>
          <SecondaryList>
            <li>
              3.1. A SFL disponibiliza 03 (três) tipos de planos para
              atendimento do usuário plataforma, o Plano GOLD, VIP e o PLANO
              PREMIUM, de acordo com os serviços e benefícios que o Usuário
              deseja ter acesso, dispostos no site 
              <a
                href="https://experience.solutionforlife.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>https://experience.solutionforlife.com.br/</strong>
              </a>
            </li>
            <li>
              3.2. As vantagens de cada plano poderão ser modificadas, mediante
              aviso prévio ao Usuário, por meio de publicação na própria
              plataforma ou por e-mail, apresentando as novas condições.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>4. DAS RESPONSABILIDADES</Titulo>
          <SecondaryList>
            <li>
              4.1. O Usuário é o único responsável por todos os dados e
              informações que ele inserir em sua conta, não sendo encargo da 
              <strong>SFL</strong> verificar sua veracidade.
            </li>
            <li>
              4.2. Apesar de a <strong>SFL</strong> empregar todos os esforços
              para melhorar a experiência e o modo de armazenamento de dados,
              não é possível garantir que as informações cadastradas ficarão
              disponíveis durante todo o tempo de uso da plataforma, sendo
              possível que elas sejam excluídas, eventualmente, sem prévio
              aviso. Em razão disso, compete única e exclusivamente ao Usuário
              manter cópia de todas os dados e relatórios emitidos pelo 
              <strong>SOLUTION MONEY</strong> que desejar guardar.
            </li>
            <li>
              4.3. A <strong>SFL</strong> não se responsabilizará, independente
              de qual plano o Usuário opte, em nenhuma hipótese por quaisquer
              danos decorrentes de:
              <TertiaryList>
                <li>mau uso da plataforma por parte do Usuário;</li>
                <li>má conexão com a internet;</li>
                <li>
                  interrupção da transmissão, difusão, disponibilização,
                  recepção, obtenção e acesso à informação; e
                </li>
                <li>
                  quaisquer outras interrupções de acesso à plataforma,
                  independentemente do que ou de quem lhe deu causa.
                </li>
              </TertiaryList>
            </li>
            <li>
              4.4. A <strong>SFL</strong> não será́ responsabilizada em qualquer
              hipótese pela tomada de decisão do Usuário baseada em qualquer
              relatório ou dado emitido pela plataforma, uma vez que não é sua
              obrigação deliberar sobre as escolhas do Usuário.
            </li>
            <li>
              4.5. É dever dos usuários, na utilização da plataforma (web e
              mobile), sempre seguir a boa-fé́ nas relações civis, seguindo o
              disposto e a legislação, regulamentos, portarias e demais normas
              jurídicas vigentes, não sendo permitido, por exemplo:
              <TertiaryList>
                <li>
                  divulgar conteúdo ou praticar qualquer ato que infrinja ou
                  viole os direitos de terceiros ou a lei;
                </li>
                <li>
                  divulgar materiais ofensivos, pornográficos, ou que promovam
                  ou façam apologia a terrorismo, violência ou qualquer forma de
                  discriminação, seja racial, sexual, de origem, religiosa, ou
                  que, mesmo de outras formas, atente contra direitos humanos; e
                </li>
                <li>
                  divulgar documentos de identificação ou informações
                  financeiras confidenciais de terceiros.
                </li>
              </TertiaryList>
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>5. DO PAGAMENTO</Titulo>
          <SecondaryList>
            <li>
              5.1. O acesso a plataforma <strong>SOLUTION MONEY</strong> será
              liberado quando a <strong>SFL</strong> verificar o pagamento da
              quantia, podendo essa verificação se estender por até 02 (dois)
              dias úteis.
            </li>
            <li>
              5.2. Caso o Usuário opte por não renovar sua assinatura do
              Programa Solution Experience, após o término da vigência do plano,
              os serviços exclusivos serão automaticamente parados.
            </li>
            <li>
              5.3. Em caso de eventuais responsabilizações da 
              <strong>SFL</strong>, o valor a ser pago por ela não será́ superior
              ao montante pago pelo Usuário pelos serviços prestados no programa
              escolhido.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>6. PROPRIEDADE INDUSTRIAL</Titulo>
          <SecondaryList>
            <li>
              6.1. Todos os direitos industriais relacionados à plataforma 
              <strong>SOLUTION MONEY</strong>, tais como: operações, software,
              hardware, domínio, logomarcas, emblemas, logotipos, design de
              páginas e peças publicitárias, estrutura, conteúdos, ferramentas,
              símbolos e expressões que caracterizem os serviços da 
              <strong>SFL</strong> são de inteira propriedade desta, não sendo
              concedida nenhuma extensão de uso aos usuários, apenas sendo
              possibilitada em caso de previa autorização, expressa e por
              escrito, dos representantes legais da empresa.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>7. MODIFICAÇÃO DOS TERMOS</Titulo>
          <SecondaryList>
            <li>
              7.1. A <strong>SFL</strong> poderá modificar, a qualquer momento,
              os termos deste acordo, com previa notificação das alterações ao
              Usuário por meio de publicação na própria plataforma, apresentando
              os novos termos e garantindo sempre o tempo razoável e suficiente
              para sua vigência.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>8. ACEITAÇÃO</Titulo>
          <SecondaryList>
            <li>
              8.1. A <strong>SFL</strong> compreende que o preenchimento do
              botão (Aceito os Termos de Uso) no campo indicado implica na
              declaração do usuário de que leu, compreendeu e aceitou os
              presentes Termos de Uso em todos seus aspectos e condições, de
              forma livre e independente de qualquer dolo, coação, fraude ou
              reserva mental e que sua intenção de cadastrar-se no 
              <strong>SOLUTION MONEY</strong> não está relacionada com alguma
              outra manifestação, garantia, ou declaração que não sejam as
              estabelecidas no instrumento.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>9. DISPOSIÇÕES GERAIS</Titulo>
          <SecondaryList>
            <li>
              9.1. Caso alguma ou algumas das cláusulas ou disposições do
              presente termo ser considerada inválida, a mesma não será
              utilizada, mas tal invalidade não prejudica a validade e a
              aplicação do restante das disposições.
            </li>
            <li>
              9.2. A violação explicita ou não de qualquer disposição do
              presente termo por parte do Usuário determina a interrupção, com
              efeitos imediatos, do acesso à plataforma.
            </li>
            <li>
              9.3. O presente termo constitui o acordo integral entre a 
              <strong>SFL</strong> e os usuários, substituindo todos os demais
              documentos precedentes, incluindo comunicações, trocas de
              intenções ou outro tipo de acordos respeitantes ao mesmo conteúdo.
            </li>
            <li>
              9.4. As alterações dos termos do presente Acordo de Utilização
              serão escritas e levadas ao conhecimento do usuário, presumindo-se
              a aceitação se, após tomada de conhecimento, a utilização do
              website se mantiver.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>10. ENDEREÇO PARA COMUNICAÇÃO</Titulo>
          <SecondaryList>
            <li>
              10.1. Fica estipulado que toda e qualquer comunicação será́
              realizada por meio do e-mail{" "}
              <a href="mailto:suporte@solutionforlife.com.br">
                <strong>suporte@solutionforlife.com.br</strong>
              </a>{" "}
              ou no site da plataforma, no campo disponibilizado para o contato
              com a <strong>SFL</strong>, sendo de inteira responsabilidade do
              usuário as informações nela inseridas.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>11. FORO</Titulo>
          <SecondaryList>
            <li>
              11.1. O presente documento possui vigência em total consonância
              com a legislação ao tempo da adesão do usuário, elegendo o foro da
              cidade de São Bernardo do Campo, no estado do São Paulo, para
              dirimir quaisquer dúvidas ou litígios decorrentes do instrumento.
            </li>
          </SecondaryList>
        </li>
      </PrimaryList>
      <h4 className="mb-3">Política de Privacidade</h4>
      <PrimaryList>
        <li>
          <Titulo>1. DA PRIVACIDADE</Titulo>
          <SecondaryList>
            <li>
              1.1. A SFL, por meio desta Política de Privacidade, informa ao
              Usuário que seus dados pessoais e sua identidade estão protegidos
              e seguros por sistema de criptografia, seguindo, assim, os
              parâmetros de segurança estabelecidos pela Lei 13.709/18, Lei
              Geral de Proteção de Dados (LGPD), que ensejam a implementação de
              tratamento de informação e de medidas de inibição de perda, mau
              uso, alteração, acesso não autorizado ou subtração indevida dos
              dados pessoais do Usuário. Não obstante, o Usuário deve estar
              ciente de que as medidas de segurança relativas à Internet não são
              integralmente infalíveis.
            </li>
            <li>
              1.2. Recomenda-se que o Usuário, caso acesse os links que podem
              ser fornecidos pelo site <strong>Solution Money</strong> e seja
              redirecionado para outros sites externos, leia suas respectivas
              Políticas de Privacidade, uma vez que não estão relacionados à 
              <strong>SFL</strong> e, por isso, esta não se responsabilizará
              pelo conteúdo produzido e pelos dados recolhidos por terceiros.
            </li>
            <li>
              1.3. Atenção: o registro e subsequente navegação e uso do 
              <strong>Solution Money</strong> depende da aceitação das previsões
              desta Política de Privacidade. Dessa forma, caso o Usuário não
              concorde com alguma das condições previstas, recomenda-se que
              desabilite a página sem realizar o cadastro.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>2. TRATAMENTO DE DADOS PESSOAIS</Titulo>
          <SecondaryList>
            <li>
              2.1. A <strong>SFL</strong> poderá coletar e armazenar os dados
              recolhidos no ato do cadastro e, posteriormente, no registro de
              informações adicionais oferecidos espontaneamente pelo próprio
              Usuário, tais como, mas não se limitando a:
              <TertiaryList>
                <li>nome completo;</li>
                <li>número do Cadastro de Pessoas Físicas;</li>
                <li>endereço de e-mail;</li>
                <li>número de telefone;</li>
                <li>cidade e estado;</li>
                <li>profissão;</li>
                <li>sexo.</li>
              </TertiaryList>
            </li>
            <li>
              2.2. Alguns dados poderão ser coletados e armazenados
              automaticamente sem a autorização do usuário, tais como, mas não
              se limitando a, características do dispositivo de acesso e do
              navegador, número de IP (Internet Protocol, número de
              identificação de dispositivo de rede) com informação de data e
              hora, origem do IP, funcionalidades acessadas, informações sobre
              cliques e preferência de navegação, geolocalização, entre outros.
            </li>
            <li>
              2.3. Os dados poderão ser armazenados em servidores localizados no
              Brasil e/ou em território internacional.
            </li>
            <li>
              2.4. Os dados pessoais do Usuário poderão ser utilizados para os
              seguintes fins:
              <TertiaryList>
                <li>
                  aprimorar a experiência do site e do aplicativo de dispositivo
                  móvel;
                </li>
                <li>emitir relatórios, gráficos e tabelas de gastos;</li>
                <li>criar alertas e eventos;</li>
                <li>
                  enviar ao Usuário comunicações em suporte papel de caráter
                  publicitário sobre a SFL e os serviços prestados;
                </li>
                <li>
                  realização de atividades comerciais, operativas e estatísticas
                  e envio de email NewsLetter
                </li>
                <li>
                  realização de análises comerciais e construção de jornada de
                  experiência dos atuais e potenciais Usuários.
                </li>
              </TertiaryList>
            </li>
            <li>
              2.5. A <strong>SFL</strong> informa que as medidas de segurança
              tomadas buscam inibir e prevenir problemas que envolvam o
              tratamento dos dados fornecidos, ainda que não exista sistema 100%
              (cem por cento) seguro de proteção contra atuações de hackers ou
              terceiros munidos de métodos de obtenção de informações de forma
              indevida. Em razão disso, a SFL não se responsabiliza por acessos
              ilícitos, bem como por atos de terceiros que logrem êxito em
              coletar ou utilizar, por quaisquer meios, dados cadastrais e
              informações disponibilizadas na plataforma pelo Usuário.
            </li>
            <li>
              2.6. Os dados pessoais mencionados poderão ser partilhados para
              cumprir obrigação legal, contratual ou administrativa, aperfeiçoar
              os produtos ou serviços ofertados pela <strong>SFL</strong>.
            </li>
            <li>
              2.7. Os dados do Usuário poderão, ainda, ser repassados a
              terceiros de modo oneroso ou não, em formato de dado estatístico e
              anônimo, sem informações individualizadas, e utilizados para fins
              publicitários, garantindo-se que não será possível a identificação
              do Usuário por meio do anúncio.
            </li>
            <li>
              2.8. Os dados serão mantidos pela <strong>SFL</strong> durante a
              vigência da relação com os Usuários na plataforma e,
              posteriormente a isso, apenas dentro do prazo legal estabelecido.
            </li>
            <li>
              2.9. O Usuário poderá solicitar, a qualquer momento, a exclusão de
              todos os dados incluídos no Solution Money, por meio do e-mail ou
              do site, fato que implica na impossibilidade de acesso a qualquer
              das funcionalidades, assim como sua posterior recuperação, exceto
              aquelas cujo armazenamento é obrigatório pela lei.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>3. POLÍTICA DE E-MAIL MARKETING</Titulo>
          <SecondaryList>
            <li>
              3.1. O envio de e-mail marketing está dentro dos padrões
              estabelecidos pela legislação e codificação pertinente, inclusive
              nos termos do Código de Autorregulamentação para Prática de E-
              mail Marketing (CAPEM), o qual prevê a utilização desta ferramenta
              de marketing com base na ética e na responsabilidade, sem prejuízo
              da concomitante aplicação da legislação vigente aplicável.
            </li>
            <li>
              3.2. A <strong>SFL</strong> é contra o envio de e-mails sem
              autorização prévia (Política Anti-SPAM). Perfaz-se inexistente a
              utilização, por parte da empresa mencionada, da confirmação de
              dados/cadastro com anexos executáveis (extensão exe, com, scr,
              bat) e links para download/arquivamento.
            </li>
          </SecondaryList>
        </li>
        <li>
          <Titulo>4. ACEITAÇÃO</Titulo>
          <SecondaryList>
            <li>
              4.1. A SFL compreende que o preenchimento do botão (Aceito os
              Termos de Uso e a Política de Privacidade) no campo indicado
              implica na declaração do usuário de que leu, compreendeu e aceitou
              a presente Política de Privacidade e os Termos de Uso em todos
              seus aspectos e condições, de forma livre e independente de
              qualquer dolo, coação, fraude ou reserva mental e que sua intenção
              de cadastrar-se no SOLUTION MONEY não está relacionada com alguma
              outra manifestação, garantia, ou declaração que não sejam as
              estabelecidas no instrumento.
            </li>
          </SecondaryList>
        </li>
      </PrimaryList>
    </>
  );
}

export default ConteudoTermos;
