import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody
} from "reactstrap";
import PropTypes from "prop-types";
import { pad } from "../../utils";
import moment from "moment";
import "moment/locale/pt-br";

function DateCarousel(props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [mes, setaMes] = useState(props.mes);
  const [ano, setaAno] = useState(props.ano);

  const toggle = e => {
    setPopoverOpen(!popoverOpen);
    e.stopPropagation();
  };

  let className = "date-carousel";

  if (props.className) {
    className += ` ${props.className}`;
  }

  useEffect(() => {
    if (Number(mes) !== props.mes || Number(ano) !== props.ano) {
      props.onChange && props.onChange(mes, ano);
    }
  }, [mes, ano]);

  useEffect(() => {
    setaMes(props.mes);
    setaAno(props.ano);
  }, [props.mes, props.ano, props.refresh]);

  const anoPad = pad(ano.toString(), 4);
  const mesPad = pad(mes.toString(), 2);
  const dataPesquisa = `${anoPad}-${mesPad}-01`;

  const m = moment(dataPesquisa);
  const mesTexto = m.month(mes - 1).format("MMMM");
  const anoTexto = m.month(mes - 1).format("YYYY");
  const label = `${mesTexto}/${anoTexto}`;

  const updateParts = m => {
    setaMes(Number(m.format("M")));
    setaAno(Number(m.format("Y")));
  };

  const onPrevMonthBtnClick = e => {
    e && e.stopPropagation();
    const prev = moment(dataPesquisa).subtract(1, "month");

    updateParts(prev);
  };

  const onNextMonthBtnClick = e => {
    e && e.stopPropagation();
    const next = moment(dataPesquisa).add(1, "month");

    if(props.lockMomentMonth){
      const now = moment();
      if(next.isSameOrBefore(now, "month") && next.isSameOrBefore(now, "year")){
        updateParts(next);
      }
    } else {
      updateParts(next);
    }
  };

  const next = moment(dataPesquisa).add(1, "month");
  let isNextMonthEnabled

  if(props.travaMesAtual) {
    isNextMonthEnabled = props.futureNavEnabled || next.isSameOrBefore(moment());
  } else {
    isNextMonthEnabled = props.futureNavEnabled || next.isSameOrBefore(moment().add(1, "year"));
  }

  const onPrevYearBtnClick = e => {
    e && e.stopPropagation();

    const prev = moment(dataPesquisa).subtract(1, "year");

    updateParts(prev);
  };

  const onNextYearBtnClick = e => {
    e && e.stopPropagation();
    const next = moment(dataPesquisa).add(1, "year");

    updateParts(next);
  };

  const onMonthBtnClick = (curMoment, e) => {
    e && e.stopPropagation();

    updateParts(curMoment);
  };

  const criaMesesLinksLinha = offset => {
    let agora;

    if(props.travaMesAtual) {
      agora = moment();
    } else {
      agora = moment().add(1, 'year');
    }

    return Array.from(Array(4).keys()).map(i => {
      const mesPad = pad((i + 1 + offset).toString(), 2);
      const curData = `${anoPad}-${mesPad}-01`;
      const curMoment = moment(curData);
      const color =
        agora.isSame(curMoment, "year") && agora.isSame(curMoment, "month")
          ? "primary"
          : "link";

      const isActive =
        props.futureNavEnabled || curMoment.isSameOrBefore(agora);

      return (
        <Col key={`mes_${i}_${offset}`}>
          <Button
            color={color}
            onClick={onMonthBtnClick.bind(this, curMoment)}
            disabled={!isActive}
          >
            {curMoment.format("MMM")}
          </Button>
        </Col>
      );
    });
  };

  const MESES_LINHA_1 = criaMesesLinksLinha(0);
  const MESES_LINHA_2 = criaMesesLinksLinha(4);
  const MESES_LINHA_3 = criaMesesLinksLinha(8);

  return (
    <ButtonGroup style={{ marginBottom: 20 }} className={className}>
      <Button size="md" color="light" onClick={onPrevMonthBtnClick}>
        <i className="fas fa-chevron-circle-left" style={{ fontSize: 15 }} />
      </Button>
      <Button size="md" color="link" onClick={toggle} id="popover-data">
        <span className="h5">{label}</span>
      </Button>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        toggle={toggle}
        target="popover-data"
      >
        <PopoverHeader>
          <Row form>
            <Col>
              <Button size="sm" color="link" onClick={onPrevYearBtnClick}>
                <i
                  className="fas fa-chevron-circle-left"
                  style={{ fontSize: 15 }}
                />
              </Button>
            </Col>
            <Col className="text-center">
              <span className="h5">{anoTexto}</span>
            </Col>
            <Col className="text-right">
              <Button size="sm" color="link" onClick={onNextYearBtnClick}>
                <i
                  className="fas fa-chevron-circle-right"
                  style={{ fontSize: 15 }}
                />
              </Button>
            </Col>
          </Row>
        </PopoverHeader>
        <PopoverBody>
          <Row form>{MESES_LINHA_1}</Row>
          <Row form>{MESES_LINHA_2}</Row>
          <Row form>{MESES_LINHA_3}</Row>
        </PopoverBody>
      </Popover>
      {props.lockMomentMonth && mes === moment().month() + 1 ? (
      // Hide the next month button when restriction is active
      null
    ) : (
      <Button
        size="md"
        color="light"
        onClick={onNextMonthBtnClick}
        style={isNextMonthEnabled ? {} : { visibility: "hidden" }}
      >
        <i className="fas fa-chevron-circle-right" style={{ fontSize: 15 }} />
      </Button>
    )}
    </ButtonGroup>
  );
}

DateCarousel.propTypes = {
  mes: PropTypes.number.isRequired,
  ano: PropTypes.number.isRequired,
  refresh: PropTypes.any,
  onChange: PropTypes.func,
  futureNavEnabled: PropTypes.bool,
  lockMomentMonth: PropTypes.bool
};

export default DateCarousel;
