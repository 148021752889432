import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Table
} from "reactstrap";
import palette from "google-palette";
import { GraficoRosca } from "../../../components";
import { toBRLColor, toPercentColor } from "../../../utils";

function GraficoAtivosXPassivos(props) {
  const MOSTRAR_TODOS = 0;
  const MOSTRAR_ATIVOS = 1;
  const MOSTRAR_PASSIVOS = 2;

  const [mostrar, setaMostrar] = useState(MOSTRAR_TODOS);
  const [idSelecionado, selecionarId] = useState(null);
  const [idHovered, setaHoverId] = useState(null);

  let cores;
  let titulo = [];
  let legendas = [],
    dados = [],
    total = 0,
    paletaCores;

  const totalAtivos = props.patrimonios.reduce((acc, grupo) => {
    return (
      acc +
      grupo.items.reduce((acc, item) => {
        if (item.isAtivo) {
          return acc + item.valor;
        } else {
          return acc;
        }
      }, 0)
    );
  }, 0);

  const totalPassivos = props.patrimonios.reduce((acc, grupo) => {
    return (
      acc +
      grupo.items.reduce((acc, item) => {
        if (!item.isAtivo) {
          return acc + item.valor;
        } else {
          return acc;
        }
      }, 0)
    );
  }, 0);

  if (mostrar === MOSTRAR_ATIVOS) {
    const grupoAtivos = props.patrimonios.reduce((acc, grupo) => {
      const items = grupo.items.reduce((acc, item) => {
        if (item.isAtivo) {
          acc.push(item);
        }

        return acc;
      }, []);

      if (items.length > 0) {
        const grupoAtivo = { ...grupo };
        const total = items.reduce((acc, item) => acc + item.valor, 0);

        grupoAtivo.items = items;
        grupoAtivo.total = total;

        acc.push(grupoAtivo);
      }

      return acc;
    }, []);

    if (idSelecionado === null) {
      titulo = "Ativos";
      total = totalAtivos;
      legendas = grupoAtivos.map(grupo => grupo.grupo);
      dados = grupoAtivos.map(grupo => grupo.total);
    } else if (grupoAtivos[idSelecionado]) {
      titulo = grupoAtivos[idSelecionado].grupo;
      total = grupoAtivos[idSelecionado].total;
      legendas = grupoAtivos[idSelecionado].items.map(item => item.descricao);
      dados = grupoAtivos[idSelecionado].items.map(item => item.valor);
    }
    paletaCores = "tol-rainbow";
    cores = palette(paletaCores, dados.length).map(hex => "#" + hex);
  } else if (mostrar === MOSTRAR_PASSIVOS) {
    const grupoPassivos = props.patrimonios.reduce((acc, grupo) => {
      const items = grupo.items.reduce((acc, item) => {
        if (!item.isAtivo) {
          acc.push(item);
        }

        return acc;
      }, []);

      if (items.length > 0) {
        const grupoPassivo = { ...grupo };
        const total = items.reduce((acc, item) => acc + item.valor, 0);

        grupoPassivo.items = items;
        grupoPassivo.total = total;

        acc.push(grupoPassivo);
      }

      return acc;
    }, []);

    if (idSelecionado === null) {
      titulo = "Passivos";
      total = totalPassivos;
      legendas = grupoPassivos.map(grupo => grupo.grupo);
      dados = grupoPassivos.map(grupo => grupo.total);
    } else if (grupoPassivos[idSelecionado]) {
      titulo = grupoPassivos[idSelecionado].grupo;
      total = grupoPassivos[idSelecionado].total;
      legendas = grupoPassivos[idSelecionado].items.map(item => item.descricao);
      dados = grupoPassivos[idSelecionado].items.map(item => item.valor);
    }
    paletaCores = "tol-dv";
    cores = palette(paletaCores, dados.length).map(hex => "#" + hex);
  } else {
    titulo = "Total";
    total = totalAtivos + totalPassivos;
    paletaCores = "tol-dv";

    if (totalAtivos < totalPassivos) {
      legendas = ["Passivos", "Ativos"];
      dados = [totalPassivos, totalAtivos];
    } else {
      legendas = ["Ativos", "Passivos"];
      dados = [totalAtivos, totalPassivos];
    }

    cores = palette(paletaCores, dados.length).map(hex => "#" + hex);

    if (totalAtivos < totalPassivos) {
      cores = cores.sort((a, b) => -1);
    }
  }

  const hoverCb = index => {
    if (index !== null && index >= 0) {
      setaHoverId(index);
    } else {
      setaHoverId(null);
    }
  };

  const clickCb = index => {
    if (mostrar === MOSTRAR_ATIVOS) {
      if (idSelecionado === null) {
        selecionarId(index);
      }
    } else if (mostrar === MOSTRAR_PASSIVOS) {
      if (idSelecionado === null) {
        selecionarId(index);
      }
    } else {
      if (totalAtivos < totalPassivos) {
        if (index === 1) {
          setaMostrar(MOSTRAR_ATIVOS);
        } else if (index === 0) {
          setaMostrar(MOSTRAR_PASSIVOS);
        }
      } else {
        if (index === 0) {
          setaMostrar(MOSTRAR_ATIVOS);
        } else if (index === 1) {
          setaMostrar(MOSTRAR_PASSIVOS);
        }
      }
    }
  };

  const backClickCb = e => {
    e.preventDefault();

    if (idSelecionado === null) {
      setaMostrar(MOSTRAR_TODOS);
    } else {
      selecionarId(null);
    }
  };

  return (
    <Row className="mb-5">
      <Col lg={6} xl={8} className="text-center mt-3">
        <GraficoRosca
          legendas={legendas}
          dados={dados}
          titulo={titulo}
          total={total}
          cores={cores}
          hoverCb={hoverCb}
          clickCb={idSelecionado === null ? clickCb : null}
        />
        {mostrar !== MOSTRAR_TODOS && (
          <Button
            color="light"
            size="sm"
            onClick={backClickCb}
            className="mt-2 mb-4"
          >
            <i className="fas fa-arrow-left" />
            &nbsp;&nbsp;&nbsp;Voltar
          </Button>
        )}
      </Col>
      <Col lg={6} xl={4} className="text-center mt-4 mb-4">
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <strong>Categorias</strong>
          </CardHeader>
          <CardBody>
            <Table size="sm" borderless responsive>
              <tbody>
                {dados.map((valor, i) => (
                  <tr
                    key={`categoria_${i}`}
                    className={idHovered === i ? "table-light" : ""}
                  >
                    <td style={{ width: 30 }} className="text-center">
                      <i
                        className="fa fa-circle"
                        style={{
                          color: cores[i],
                          fontSize: idHovered === i ? "1.1rem" : "inherit"
                        }}
                      />
                    </td>
                    <td>{legendas[i]}</td>
                    <td className="text-right">{toBRLColor(valor)}</td>
                    <td className="text-right">
                      {toPercentColor(valor / total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default GraficoAtivosXPassivos;
