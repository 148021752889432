import React from "react";
import { Container, Card, CardHeader, CardBody } from "reactstrap";
import { ConteudoTermos } from "../../../components";

function Termos(props) {
  return (
    <Container>
      <h1>
        <img src="/assets/img/logo.png" alt="Solution for Life" />
      </h1>
      <Card className="mt-2">
        <CardHeader>
          <h4 className="text-center">
            TERMOS DE USO E POLÍTICA DE PRIVACIDADE
          </h4>
        </CardHeader>
        <CardBody>
          <ConteudoTermos />
        </CardBody>
      </Card>
    </Container>
  );
}

export default Termos;
