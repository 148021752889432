import { Dividas } from "./Dividas";
import { Financiamentos } from "./Financiamentos";
import { ReduzirDespesas, EspecifiqueReduzirDespesas } from "./ReduzirDespesas";
import {
  AumentarReceitas,
  EspecifiqueAumentarReceitas,
} from "./AumentarReceitas";

export default {
  Dividas,
  Financiamentos,
  ReduzirDespesas,
  EspecifiqueReduzirDespesas,
  AumentarReceitas,
  EspecifiqueAumentarReceitas,
};
