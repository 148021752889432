import React from "react";
import PropTypes from "prop-types";
import "moment/locale/pt-br";
import moment from "moment";
import { Card, CardBody, CardTitle, Alert } from "reactstrap";
import { Bar } from "react-chartjs-2";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { toBRL } from "../../utils";
import { Link, withRouter } from "react-router-dom";

const COR_SUPERIOR = "#FF0029";
const COR_INFERIOR = "#7FC97F";
const COR_LINHA = "#000000";

function GraficoReduzirDespesas(props) {
  if (!props.despesasMensais || props.despesasMensais.length === 0) {
    return (
      <Alert color="light">
        <h4 className="alert-heading">Sem despesas no período</h4>
        <p>
          Não há despesas cadastradas para computar o seu progresso.{" "}
          <Link to="/organize/despesas">Cadastre algumas despesas</Link> para
          continuar.
        </p>
        <hr />
      </Alert>
    );
  }

  const graficoDados = {
    labels: props.despesasMensais.map((despesa) =>
      moment()
        .date(1)
        .month(despesa.mes - 1)
        .year(despesa.ano)
        .format("MMMM")
    ),
    datasets: [
      {
        label: "Despesas Totais",
        data: props.despesasMensais.map((despesa) => despesa.valor),
        barPercentage: 0.5,
        maxBarThickness: 100,
        backgroundColor: props.despesasMensais.map((despesa) =>
          despesa.valor > props.valorMetaTotal ? COR_SUPERIOR : COR_INFERIOR
        ),
      },
    ],
  };

  const graficoOpcoes = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const { datasetIndex } = tooltipItem;
          let label = data.datasets[datasetIndex].label;
          return `${label}: ${toBRL(tooltipItem.yLabel)}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: (value) => toBRL(value),
            suggestedMax: props.valorMetaTotal + 0.1 * props.valorMetaTotal,
          },
        },
      ],
    },
    annotation: {
      enabled: true,
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: props.valorMetaTotal,
          borderColor: COR_LINHA,
          borderWidth: 2,
          label: {
            backgroundColor: COR_LINHA,
            fontFamily: "sans-serif",
            fontSize: 12,
            fontStyle: "bold",
            fontColor: "#fff",
            xPadding: 6,
            yPadding: 6,
            cornerRadius: 6,
            position: "left",
            enabled: true,
            content: "Limite: " + toBRL(props.valorMetaTotal),
          },
        },
      ],
    },
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="text-center">Despesas Totais</CardTitle>

        <div className="chart-wrapper" style={{ height: 300, marginTop: 40 }}>
          <Bar
            data={graficoDados}
            options={graficoOpcoes}
            plugins={[ChartAnnotation]}
          />
        </div>
      </CardBody>
    </Card>
  );
}

GraficoReduzirDespesas.propTypes = {
  valorMetaTotal: PropTypes.number.isRequired,
  despesasMensais: PropTypes.arrayOf(
    PropTypes.shape({
      ano: PropTypes.number.isRequired,
      mes: PropTypes.number.isRequired,
      valor: PropTypes.number.isRequired,
    })
  ),
};

export default withRouter(GraficoReduzirDespesas);
