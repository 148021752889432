import React from "react";

function FormErrMsg(props) {
  const { children, ...others } = props;
  return (
    <div className="invalid-feedback" style={{ display: "block" }} {...others}>
      {children}
    </div>
  );
}

export default FormErrMsg;
