import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  Table,
  CardFooter,
  Button,
  Alert,
} from "reactstrap";
import { Monetary, FormErrMsg } from "../../../components";
import { MESES, CATEGORIAS_DESPESAS } from "../../../options";
import moment from "moment";
import "moment/locale/pt-br";
import {
  getAxiosAuth,
  getTokenClientId,
  toBRL,
  toBRLValueOnly,
  fromBRL,
  pad,
  isPositiveBRL,
} from "../../../utils";
import validator from "validator";

function EspecifiqueReduzirDespesas(props) {
  const [especificarPorCategoria, setaEspecificarPorCategoria] = useState(
    false
  );
  const [carregandoDados, setaCarregandoDados] = useState(true);
  const [erroObterDados, setaErroObterDados] = useState(false);
  const [erroSubmeterDados, setaErroSubmeterDados] = useState(false);
  const [submetendoDados, setaSubmetendoDados] = useState(false);
  const [despesas, setaDespesas] = useState([]);
  const [meses, setaMeses] = useState([]);
  const [mesesNomes, setaMesesNomes] = useState([]);
  const [mediasDespesas, setaMediasDespesas] = useState([]);
  const [totaisDespesas, setaTotaisDespesas] = useState([]);
  const [totalDespesasMedia, setaTotalDespesasMedia] = useState(0.0);
  const [valorMetaTotal, setaValorMetaTotal] = useState("");
  const [valorMetaPorCategoria, setaValorMetaPorCategoria] = useState(
    CATEGORIAS_DESPESAS.map(() => "")
  );
  const [mensagemErroDataInicio, setaMensagemErroDataInicio] = useState(null);
  const [mensagemErroValorMetaTotal, setaMensagemErroValorMetaTotal] = useState(
    null
  );
  const [
    mensagensErroValorMetaPorCategoria,
    setaMensagensErroValorMetaPorCategoria,
  ] = useState(CATEGORIAS_DESPESAS.map(() => null));
  const [mensagemErroFormulario, setaMensagemErroFormulario] = useState(null);
  const [inicioProjetoMes, setaInicioProjetoMes] = useState(
    moment().format("M")
  );
  const [inicioProjetoAno, setaInicioProjetoAno] = useState(
    moment().format("YYYY")
  );

  const planoInputHandler = (value) => {
    setaEspecificarPorCategoria(value);
  };

  useEffect(() => {  //EFFECT INICIO PROJETO ANO E MES
    validarInicioProjeto(inicioProjetoMes); 
    if (inicioProjetoAno && inicioProjetoMes) {
        getDespesas(inicioProjetoAno, inicioProjetoMes);
    }
  }, [inicioProjetoAno, inicioProjetoMes]);

  const obterDataInicioProjetoMoment = (inicioProjetoMes, inicioProjetoAno) => {
    const dataCompleta = `01/${pad(inicioProjetoMes, 2)}/${inicioProjetoAno}`;
    return moment(dataCompleta, "DD/MM/YYYY", true);
  };

  const validarInicioProjeto = async (inicioProjetoMes) => {
    let dataCompletaMoment = obterDataInicioProjetoMoment(
      inicioProjetoMes,
      inicioProjetoAno
    );
    let valido = dataCompletaMoment.isValid();
    if (!valido) {
      setaMensagemErroDataInicio("Data inválida.");
    } else if (dataCompletaMoment.isAfter(moment().date(1))) {
      valido = false;
      setaMensagemErroDataInicio("Sua meta não deve iniciar no futuro.");
    } else {
      setaMensagemErroDataInicio(false);
    }

    return valido;
  };


  const inicioProjetoMesInputHandler = (event) => {
    const novoMes = event.target.value;
    setaInicioProjetoMes(novoMes);
  };

  const inicioProjetoAnoInputHandler = (event) => {
    const novoAno = event.target.value;
    setaInicioProjetoAno(novoAno);
  };


  const inicioProjetoAnoBlurHandler = () => {
    if (/^\d{4}$/.test(inicioProjetoAno)) {
      validarInicioProjeto(inicioProjetoMes).then(valido => {
        if (valido) {
          getDespesas(inicioProjetoAno, inicioProjetoMes);
        }
      });
    }
  };

  const validarValorMetaTotal = (valorMetaTotal) => {
    if (especificarPorCategoria) {
      setaMensagemErroValorMetaTotal(null);
      return true;
    }

    if (validator.isEmpty(valorMetaTotal, { ignore_whitespace: true })) {
      setaMensagemErroValorMetaTotal("Obrigatório");
      return false;
    } else if (!isPositiveBRL(valorMetaTotal)) {
      setaMensagemErroValorMetaTotal("Valor inválido");
      return false;
    } else {
      setaMensagemErroValorMetaTotal(false);
    }
    return true;
  };

  const valorMetaTotalInputHandler = (event) => {
    setaValorMetaTotal(event.target.value);
    setaMensagemErroValorMetaTotal(null);
  };

  const valorMetaTotalBlurHandler = () => {
    validarValorMetaTotal(valorMetaTotal);
  };

  const valorMetaCategoriaInputHandler = (idx, event) => {
    let { value } = event.target;
    setaValorMetaPorCategoria((prev) => {
      const novosValores = [...prev];
      novosValores[idx] = value;
      return novosValores;
    });

    setaMensagensErroValorMetaPorCategoria((prev) => {
      const novosValores = [...prev];
      novosValores[idx] = null;
      return novosValores;
    });
  };

  const validarValorMetaCategoria = (idx, valor) => {
    let mensagemErro = null;

    if (!validator.isEmpty(valor, { ignore_whitespace: true })) {
      if (!isPositiveBRL(valor)) {
        mensagemErro = "Valor inválido";
      } else {
        mensagemErro = false;
      }
    }

    setaMensagensErroValorMetaPorCategoria((prev) => {
      const novosValores = [...prev];
      novosValores[idx] = mensagemErro;
      return novosValores;
    });

    return !mensagemErro;
  };

  const valorMetaCategoriaBlurHandler = (idx) => {
    validarValorMetaCategoria(idx, valorMetaPorCategoria[idx]);
  };

  const submeterDados = async () => {
    setaErroSubmeterDados(false);

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/reducao`;
    let dados = {
      inicio: obterDataInicioProjetoMoment(
        inicioProjetoMes,
        inicioProjetoAno
      ).format("YYYY-MM-DD"),
      valorMetaTotal: fromBRL(valorMetaTotal),
    };

    if (especificarPorCategoria) {
      dados = {
        ...dados,
        valoresMetaPorCategorias: valorMetaPorCategoria.map((valor) =>
          fromBRL(valor)
        ),
      };
    }

    setaSubmetendoDados(true);

    let response;
    try {
      response = await axiosInstance.put(uri, dados);
    } catch (error) {
      setaErroSubmeterDados(error.response ? error.response.status : true);
    } finally {
      setaSubmetendoDados(false);
    }

    if (response && response.status === 200) {
      props.history.push("/equilibre/reduzir-despesas", {
        salvouSucesso: true,
      });
    }
  };

  const submitInputHandler = (event) => {
    event.preventDefault();

    let valido = true;

    const inicioProjetoValido = validarInicioProjeto(inicioProjetoMes);
    const valorMetaTotalValido = validarValorMetaTotal(valorMetaTotal);
    let todosValoresCategoriaValidos = true;

    if (especificarPorCategoria) {
      todosValoresCategoriaValidos = !valorMetaPorCategoria.find(
        (valor, categoriaIdx) => !validarValorMetaCategoria(categoriaIdx, valor)
      );
    }

    if (
      !inicioProjetoValido ||
      !valorMetaTotalValido ||
      !todosValoresCategoriaValidos
    ) {
      valido = false;
    }

    if (valido) {
      submeterDados();
      setaMensagemErroFormulario(null);
    } else {
      setaMensagemErroFormulario("Verifique os campos.");
    }
  };

  const processaDespesas = (despesasPorCategorias) => {
    let meses = Array.from(
      despesasPorCategorias.reduce((acc, despesa) => {
        acc.add(
          JSON.stringify({
            ano: despesa.ano,
            mes: despesa.mes,
          })
        );
        return acc;
      }, new Set())
    ).map((text) => JSON.parse(text));

   

    const inicioAnoNum = parseInt(inicioProjetoAno,10);
    const inicioMesNum = parseInt(inicioProjetoMes,10);

    let mesesAnteriores = [];
    for ( let i = 1; i<=3; i++){
      const dataAnterior = moment({year: inicioAnoNum, month: inicioMesNum - 1}).subtract(i,'months');
      mesesAnteriores.push({ ano: dataAnterior.year(), mes: dataAnterior.month() + 1 });
    }

     // Verifica se os meses anteriores já estão presentes e os adiciona se necessário
    mesesAnteriores.forEach((mesAnterior) => {
      if (!meses.find((mes) => mes.ano === mesAnterior.ano && mes.mes === mesAnterior.mes)) {
        meses.push(mesAnterior);
      }
    });

    meses.sort((a, b) => {
      // Primeiro, compara por ano
      if (a.ano > b.ano) return 1;
      if (a.ano < b.ano) return -1;
      // Se os anos forem iguais, compara por mês
      return a.mes - b.mes;
    });

    setaMeses(meses);

  

    let mesesNomes = meses.map((data) =>
      moment()
        .date(1)
        .month(data.mes - 1)
        .year(data.ano)
        .format("MMMM")
    );
    setaMesesNomes(mesesNomes);
  

    let despesas = CATEGORIAS_DESPESAS.reduce(
      (acc, categoria) =>
        acc.concat(
          meses.map((data) => ({
            categoria: categoria.nome,
            ano: data.ano,
            mes: data.mes,
          }))
        ),
      []
    ).map((registro) => {
      registro.total = despesasPorCategorias
        .filter(
          (despesa) =>
            despesa.categoria === registro.categoria &&
            despesa.ano === registro.ano &&
            registro.mes === despesa.mes
        )
        .reduce((acc, despesa) => acc + despesa.valor, 0.0);

      return registro;
    });

    setaDespesas(despesas);

    if (meses.length > 0) {
      let totaisDespesas = meses.map((data) =>
        despesas
          .filter(
            (despesa) => despesa.ano === data.ano && despesa.mes === data.mes
          )
          .reduce((acc, despesa) => despesa.total + acc, 0.0)
      );
      setaTotaisDespesas(totaisDespesas);

      let totalDespesasMedia =
        totaisDespesas.reduce((acc, totalMes) => totalMes + acc, 0.0) /
        meses.length;
      setaTotalDespesasMedia(totalDespesasMedia);

      let mediasDespesas = CATEGORIAS_DESPESAS.map((categoria) => {
        const valorMediaCategoria =
          despesas
            .filter((despesa) => despesa.categoria === categoria.nome)
            .reduce((acc, despesa) => acc + despesa.total, 0) / meses.length;

        return {
          categoria: categoria.nome,
          valorMedia: valorMediaCategoria,
        };
      });
      setaMediasDespesas(mediasDespesas);
    }
  };

  const getDespesas = async () => {
    const inicioProjetoMoment = moment(`${inicioProjetoAno}-${inicioProjetoMes}-01`, "YYYY-MM-DD");
    const filtroDataFinal = inicioProjetoMoment.clone().subtract(1,"months").endOf('month').format("YYYY-MM-DD");
    const filtroDataInicial = inicioProjetoMoment.clone().subtract(3, 'months').startOf('month').format("YYYY-MM-DD");
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/relatorios/dre`;
    const dados = {
      filtroDataInicial,
      filtroDataFinal,
    };

    let response = await axiosInstance.get(uri, { params: dados });

    if (
      response &&
      response.status === 200 &&
      "despesasPorCategorias" in response.data
    ) {
      processaDespesas(response.data.despesasPorCategorias);
    }
  };

  const processaMetaAtual = (dados) => {
    const especificarPorCategoria =
      "categorias" in dados &&
      dados.categorias.length === CATEGORIAS_DESPESAS.length;
    setaEspecificarPorCategoria(especificarPorCategoria);

    const inicioMoment = moment(dados.inicio);
    setaInicioProjetoMes(inicioMoment.format("M"));
    setaInicioProjetoAno(inicioMoment.format("YYYY"));

    if (especificarPorCategoria) {
      setaValorMetaPorCategoria(
        dados.categorias.map((categoria) =>
          toBRLValueOnly(categoria.valorMetaCategoria)
        )
      );
    } else {
      setaValorMetaTotal(toBRLValueOnly(dados.valorMetaTotal));
    }
  };

  const getMetaAtual = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/reducao`;

    let response = await axiosInstance.get(uri);

    if (response && response.status === 200) {
      processaMetaAtual(response.data);
    }
  };

  const getDados = async () => {
    setaErroObterDados(false);
    setaCarregandoDados(true);

    try {
      await getDespesas(inicioProjetoAno, inicioProjetoMes);
    } catch (error) {
      setaCarregandoDados(false);
      setaErroObterDados(error.response ? error.response.status : true);
      return;
    }

    try {
      await getMetaAtual();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return;
      }
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }
  };

  useEffect(() => {
    getDados();
  }, []);

  useEffect(() => {
    if (mediasDespesas.length === CATEGORIAS_DESPESAS.length) {
      setaValorMetaPorCategoria(
        mediasDespesas.map((media) => toBRLValueOnly(media.valorMedia))
      );
    }
  }, [mediasDespesas]);

  useEffect(() => {
    setaValorMetaTotal(
      toBRLValueOnly(
        valorMetaPorCategoria.reduce((acc, valor) => {
          let valorNumerico = fromBRL(valor);
          if (!isNaN(valorNumerico)) {
            acc += valorNumerico;
          }
          return acc;
        }, 0.0)
      )
    );
    if (especificarPorCategoria) {
      setaMensagemErroValorMetaTotal(null);
    }
  }, [especificarPorCategoria, valorMetaPorCategoria]);

  const cancelarClickInputHandler = (event) => {
    event.preventDefault();
    const { history } = props;
    history.push("/equilibre/reduzir-despesas");
  };

  return (
    <div className="animated fadeIn">
      <Container>
        <Form onSubmit={submitInputHandler}>
          <Card>
            <CardHeader>
              <i className="fas fa-fill-drip" />
              <strong>Reduzir Despesas</strong>
            </CardHeader>
            <CardBody>
              {carregandoDados && (
                <>
                  <div className="shimmer line br w60 animate"></div>
                  <div className="shimmer line br w60 animate"></div>
                  <div className="shimmer line br animate"></div>
                  <div className="shimmer line br animate"></div>
                  <div className="shimmer line br animate"></div>
                  <div className="shimmer line br animate"></div>
                </>
              )}

              {!carregandoDados && !erroObterDados && (
                <>
                  <FormGroup row>
                    <Col md={2}>
                      <Label className="required">Escolha um plano</Label>
                    </Col>
                    <Col>
                      <FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="plano"
                            checked={especificarPorCategoria === false}
                            onChange={planoInputHandler.bind(this, false)}
                          />
                          <Label check>Reduzir todas as despesas</Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="plano"
                            checked={especificarPorCategoria === true}
                            onChange={planoInputHandler.bind(this, true)}
                          />
                          <Label check>Reduzir por categoria</Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md={2}>
                      <Label className="required">Iniciar meta em</Label>
                    </Col>
                    <Col>
                      <FormGroup className="form-inline">
                        <Input
                          type="select"
                          onChange={inicioProjetoMesInputHandler}
                          value={inicioProjetoMes}
                        >
                          {MESES.map((mes, i) => (
                            <option value={i + 1} key={`mes_opt_${i}`}>
                              {mes}
                            </option>
                          ))}
                        </Input>
                        <Input
                          type="text"
                          className="text-right"
                          style={{ maxWidth: 100 }}
                          onChange={inicioProjetoAnoInputHandler}
                          value={inicioProjetoAno}
                          onBlur={inicioProjetoAnoBlurHandler}
                          valid={mensagemErroDataInicio === false}
                          invalid={!!mensagemErroDataInicio}
                        />
                        <FormErrMsg>{mensagemErroDataInicio}</FormErrMsg>
                      </FormGroup>
                    </Col>
                  </FormGroup>

                  <Table size="sm" striped bordered>
                    <thead>
                      <tr>
                        <th style={{ width: "auto" }}>Categoria</th>
                        {mesesNomes.map((mesNome) => (
                          <th className="text-right" key={`col_${mesNome}`}>
                            {mesNome}
                          </th>
                        ))}
                        {mesesNomes.length === 0 &&
                        <th>
                        </th>
                        }
                        <th className="text-right table-warning">Média</th>
                        <th
                          className="text-right text-nowrap table-primary"
                          style={{ minWidth: "20%", width: 200 }}
                        >
                          Defina sua Meta
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <th className="align-middle">Total Despesas</th>
                        {mesesNomes.length > 0 ? (
                          totaisDespesas.map((valor, idx) => (
                            <td className="align-middle text-nowrap text-right" key={`col_total_mes_${idx}`}>
                              {toBRL(valor)}
                            </td>
                          ))
                        ) : (
                          // Renderiza uma célula vazia se não há meses
                          <td className="align-middle text-nowrap text-right" key={`col_total_mes_empty`}>
                            {toBRL(0)}
                          </td>
                        )}
                        <td className="align-middle text-nowrap text-right table-warning">
                          {toBRL(totalDespesasMedia)}
                        </td>
                        <td className="align-middle text-nowrap text-right table-primary">
                          <Monetary
                            style={{ display: "inline-flex" }}
                            value={valorMetaTotal}
                            onChange={valorMetaTotalInputHandler}
                            readOnly={especificarPorCategoria}
                            onBlur={valorMetaTotalBlurHandler}
                            valid={mensagemErroValorMetaTotal === false}
                            invalid={!!mensagemErroValorMetaTotal}
                          />
                          <FormErrMsg>{mensagemErroValorMetaTotal}</FormErrMsg>
                        </td>
                      </tr>
                      {CATEGORIAS_DESPESAS.map((categoria, categoriaIdx) => (
                        <tr key={`categoria_${categoria.nome}`}>
                          <th className="align-middle">
                            <i
                              className={categoria.icone}
                              style={{ width: "1rem", marginRight: "0.5rem" }}
                            />
                            {categoria.nome}
                          </th>
                          {mesesNomes.length > 0 ? (
                              meses.map((data) =>
                                despesas.filter(
                                  (despesa) =>
                                    despesa.categoria === categoria.nome &&
                                    despesa.ano === data.ano &&
                                    despesa.mes === data.mes
                                ).map((despesa, index) => (
                                  <td className="align-middle text-nowrap text-right" key={`despesa_${categoria.nome}_${data.ano}_${data.mes}_${index}`}>
                                    {toBRL(despesa.total)}
                                  </td>
                                ))
                              )
                            ) : (
                              <td></td> // Renderiza uma célula vazia se não há meses. Ajuste conforme necessário.
                            )}
                          <td className="align-middle text-nowrap text-right table-warning">
                            {mesesNomes.length > 0 && mediasDespesas.find((media) => media.categoria === categoria.nome) ? (
                              toBRL(mediasDespesas.find((media) => media.categoria === categoria.nome).valorMedia)
                            ) :    toBRL(0)}
                          </td>
                          <td className="align-middle text-right table-primary">
                          {especificarPorCategoria ? (
                            <>
                              <Monetary
                                style={{ display: "inline-flex" }}
                                value={valorMetaPorCategoria[categoriaIdx]}
                                onChange={(event) => valorMetaCategoriaInputHandler(categoriaIdx, event)}
                                onBlur={() => valorMetaCategoriaBlurHandler(categoriaIdx)}
                                invalid={!!mensagensErroValorMetaPorCategoria[categoriaIdx]}
                                valid={mensagensErroValorMetaPorCategoria[categoriaIdx] === false}
                              />
                              <FormErrMsg>
                                {mensagensErroValorMetaPorCategoria[categoriaIdx]}
                              </FormErrMsg>
                            </>
                          ) : ""}
                        </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
              {mensagemErroFormulario && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível salvar. {mensagemErroFormulario}
                </Alert>
              )}
              {erroObterDados && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível obter suas despesas.
                  {erroObterDados === true
                    ? " Problema com a conexão."
                    : ` Problema interno no servidor (código ${erroObterDados}).`}
                </Alert>
              )}
              {erroSubmeterDados && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível salvar sua meta.
                  {erroSubmeterDados === true
                    ? " Problema com a conexão."
                    : ` Problema interno no servidor (código ${erroSubmeterDados}).`}
                </Alert>
              )}
            </CardBody>
            <CardFooter>
              {!carregandoDados && !erroObterDados && (
                <Button
                  type="submit"
                  color="primary"
                  disabled={submetendoDados}
                >
                  {submetendoDados && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Salvar
                </Button>
              )}

              {erroObterDados && (
                <Button type="button" onClick={() => getDados()}>
                  Tentar novamente
                </Button>
              )}

              <Button color="link" onClick={cancelarClickInputHandler}>
                Cancelar
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </div>
  );
}

export default withRouter(EspecifiqueReduzirDespesas);
