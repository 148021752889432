import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  Table,
  CardFooter,
  Button,
  Alert,
} from "reactstrap";
import { Monetary, FormErrMsg } from "../../../components";
import { MESES } from "../../../options";
import moment from "moment";
import "moment/locale/pt-br";
import {
  getAxiosAuth,
  getTokenClientId,
  toBRL,
  toBRLValueOnly,
  fromBRL,
  pad,
  isPositiveBRL,
} from "../../../utils";
import validator from "validator";

function EspecifiqueAumentarReceitas(props) {
  const [carregandoDados, setaCarregandoDados] = useState(true);
  const [erroObterDados, setaErroObterDados] = useState(false);
  const [erroSubmeterDados, setaErroSubmeterDados] = useState(false);
  const [submetendoDados, setaSubmetendoDados] = useState(false);
  const [mesesNomes, setaMesesNomes] = useState([]);
  const [totaisReceitas, setaTotaisReceitas] = useState([]);
  const [totalReceitasMedia, setaTotalReceitasMedia] = useState(0.0);
  const [valorMetaTotal, setaValorMetaTotal] = useState("");
  const [mensagemErroDataInicio, setaMensagemErroDataInicio] = useState(null);
  const [mensagemErroValorMetaTotal, setaMensagemErroValorMetaTotal] = useState(
    null
  );
  const [mensagemErroFormulario, setaMensagemErroFormulario] = useState(null);
  const [inicioProjetoMes, setaInicioProjetoMes] = useState(
    moment().format("M")
  );
  const [inicioProjetoAno, setaInicioProjetoAno] = useState(
    moment().format("YYYY")
  );

  const obterDataInicioProjetoMoment = (inicioProjetoMes, inicioProjetoAno) => {
    const dataCompleta = `01/${pad(inicioProjetoMes, 2)}/${inicioProjetoAno}`;
    return moment(dataCompleta, "DD/MM/YYYY", true);
  };

  const validarInicioProjeto = (inicioProjetoMes) => {
    let dataCompletaMoment = obterDataInicioProjetoMoment(
      inicioProjetoMes,
      inicioProjetoAno
    );
    let valido = dataCompletaMoment.isValid();

    if (!valido) {
      setaMensagemErroDataInicio("Data inválida.");
    } else if (dataCompletaMoment.isAfter(moment().date(1))) {
      valido = false;
      setaMensagemErroDataInicio("Sua meta não deve iniciar no futuro.");
    } else {
      setaMensagemErroDataInicio(false);
    }

    return valido;
  };

  const inicioProjetoMesInputHandler = (event) => {
    setaInicioProjetoMes(event.target.value);
    validarInicioProjeto(event.target.value);
    setaMensagemErroDataInicio(null);
  };

  const inicioProjetoAnoInputHandler = (event) => {
    setaInicioProjetoAno(event.target.value);
    setaMensagemErroDataInicio(null);
  };

  const inicioProjetoAnoBlurHandler = () => {
    validarInicioProjeto(inicioProjetoMes);
  };

  const validarValorMetaTotal = (valorMetaTotal) => {
    if (validator.isEmpty(valorMetaTotal, { ignore_whitespace: true })) {
      setaMensagemErroValorMetaTotal("Obrigatório");
      return false;
    } else if (!isPositiveBRL(valorMetaTotal)) {
      setaMensagemErroValorMetaTotal("Valor inválido");
      return false;
    } else {
      setaMensagemErroValorMetaTotal(false);
    }
    return true;
  };

  const valorMetaTotalInputHandler = (event) => {
    setaValorMetaTotal(event.target.value);
    setaMensagemErroValorMetaTotal(null);
  };

  const valorMetaTotalBlurHandler = () => {
    validarValorMetaTotal(valorMetaTotal);
  };

  const submeterDados = async () => {
    setaErroSubmeterDados(false);

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/aumento`;
    let dados = {
      inicio: obterDataInicioProjetoMoment(
        inicioProjetoMes,
        inicioProjetoAno
      ).format("YYYY-MM-DD"),
      valorMetaTotal: fromBRL(valorMetaTotal),
    };

    setaSubmetendoDados(true);

    let response;
    try {
      response = await axiosInstance.put(uri, dados);
    } catch (error) {
      setaErroSubmeterDados(error.response ? error.response.status : true);
    } finally {
      setaSubmetendoDados(false);
    }

    if (response && response.status === 200) {
      props.history.push("/equilibre/aumentar-receitas", {
        salvouSucesso: true,
      });
    }
  };

  const submitInputHandler = (event) => {
    event.preventDefault();

    let valido = true;

    const inicioProjetoValido = validarInicioProjeto(inicioProjetoMes);
    const valorMetaTotalValido = validarValorMetaTotal(valorMetaTotal);

    if (!inicioProjetoValido || !valorMetaTotalValido) {
      valido = false;
    }

    if (valido) {
      submeterDados();
      setaMensagemErroFormulario(null);
    } else {
      setaMensagemErroFormulario("Verifique os campos.");
    }
  };

  const processaReceitas = (receitasLiquidas) => {
    // console.log(receitasLiquidas);

    let meses = receitasLiquidas.reduce((acc, receita) => {
      let registro = acc.find(
        (registro) =>
          registro.ano === receita.ano && registro.mes === receita.mes
      );

      if (!registro) {
        acc.push({ ano: receita.ano, mes: receita.mes });
      }
      return acc;
    }, []);

    let mesesNomes = meses.map((data) =>
      moment()
        .date(1)
        .month(data.mes - 1)
        .year(data.ano)
        .format("MMMM")
    );
    setaMesesNomes(mesesNomes);

    if (meses.length > 0) {
      let totaisReceitas = meses.map((data) => {
        const total = receitasLiquidas
          .filter(
            (receita) => receita.ano === data.ano && receita.mes === data.mes
          )
          .reduce((acc, receita) => acc + receita.valor_liquido, 0.0);
        return { ...data, total };
      }, []);
      setaTotaisReceitas(totaisReceitas);

      let totalReceitasMedia =
        totaisReceitas.reduce((acc, registro) => registro.total + acc, 0.0) /
        meses.length;
      setaTotalReceitasMedia(totalReceitasMedia);
    }
  };

  const getReceitas = async () => {
    let filtroDataInicial, filtroDataFinal;

    filtroDataInicial = moment().date(1).subtract(2, "M").format("YYYY-MM-DD");
    filtroDataFinal = moment().endOf("month").format("YYYY-MM-DD");

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/relatorios/dre`;
    const dados = {
      filtroDataInicial,
      filtroDataFinal,
    };

    let response = await axiosInstance.get(uri, { params: dados });

    if (
      response &&
      response.status === 200 &&
      "receitasLiquidas" in response.data
    ) {
      processaReceitas(response.data.receitasLiquidas);
    }
  };

  const processaMetaAtual = (dados) => {
    const inicioMoment = moment(dados.inicio);
    setaInicioProjetoMes(inicioMoment.format("M"));
    setaInicioProjetoAno(inicioMoment.format("YYYY"));
    setaValorMetaTotal(toBRLValueOnly(dados.valorMetaTotal));
  };

  const getMetaAtual = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/aumento`;

    let response = await axiosInstance.get(uri);

    if (response && response.status === 200) {
      processaMetaAtual(response.data);
    }
  };

  const getDados = async () => {
    setaErroObterDados(false);
    setaCarregandoDados(true);

    try {
      await getReceitas();
    } catch (error) {
      setaCarregandoDados(false);
      setaErroObterDados(error.response ? error.response.status : true);
      return;
    }

    try {
      await getMetaAtual();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return;
      }
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }
  };

  useEffect(() => {
    getDados();
  }, []);

  const cancelarClickInputHandler = (event) => {
    event.preventDefault();
    const { history } = props;
    history.push("/equilibre/aumentar-receitas");
  };

  return (
    <div className="animated fadeIn">
      <Container>
        <Form onSubmit={submitInputHandler}>
          <Card>
            <CardHeader>
              <i className="fas fa-seedling" />
              <strong>Aumentar Receitas</strong>
            </CardHeader>
            <CardBody>
              {carregandoDados && (
                <>
                  <div className="shimmer line br w60 animate"></div>
                  <div className="shimmer line br animate"></div>
                </>
              )}

              {!carregandoDados && !erroObterDados && (
                <>
                  <FormGroup row>
                    <Col md={2}>
                      <Label className="required">Iniciar meta em</Label>
                    </Col>
                    <Col>
                      <FormGroup className="form-inline">
                        <Input
                          type="select"
                          onChange={inicioProjetoMesInputHandler}
                          value={inicioProjetoMes}
                        >
                          {MESES.map((mes, i) => (
                            <option value={i + 1} key={`mes_opt_${i}`}>
                              {mes}
                            </option>
                          ))}
                        </Input>
                        <Input
                          type="text"
                          className="text-right"
                          style={{ maxWidth: 100 }}
                          onChange={inicioProjetoAnoInputHandler}
                          value={inicioProjetoAno}
                          onBlur={inicioProjetoAnoBlurHandler}
                          valid={mensagemErroDataInicio === false}
                          invalid={!!mensagemErroDataInicio}
                        />
                        <FormErrMsg>{mensagemErroDataInicio}</FormErrMsg>
                      </FormGroup>
                    </Col>
                  </FormGroup>

                  <Table size="sm" striped bordered>
                    <thead>
                      <tr>
                        <th></th>
                        {mesesNomes.map((mesNome) => (
                          <th className="text-right" key={`col_${mesNome}`}>
                            {mesNome}
                          </th>
                        ))}
                        <th className="text-right table-warning">Média</th>
                        <th
                          className="text-right text-nowrap table-primary"
                          style={{ minWidth: "20%", width: 200 }}
                        >
                          Defina sua Meta
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="align-middle">
                          Total receitas líquidas
                        </th>
                        {totaisReceitas.map((registro, idx) => (
                          <td
                            className="align-middle text-nowrap text-right"
                            key={`col_total_mes_${idx}`}
                          >
                            {toBRL(registro.total)}
                          </td>
                        ))}
                        <td className="align-middle text-nowrap text-right table-warning">
                          {toBRL(totalReceitasMedia)}
                        </td>
                        <td className="align-middle text-nowrap text-right table-primary">
                          <Monetary
                            style={{ display: "inline-flex" }}
                            value={valorMetaTotal}
                            onChange={valorMetaTotalInputHandler}
                            onBlur={valorMetaTotalBlurHandler}
                            valid={mensagemErroValorMetaTotal === false}
                            invalid={!!mensagemErroValorMetaTotal}
                          />
                          <FormErrMsg>{mensagemErroValorMetaTotal}</FormErrMsg>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
              {mensagemErroFormulario && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível salvar. {mensagemErroFormulario}
                </Alert>
              )}
              {erroObterDados && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível obter suas despesas.
                  {erroObterDados === true
                    ? " Problema com a conexão."
                    : ` Problema interno no servidor (código ${erroObterDados}).`}
                </Alert>
              )}
              {erroSubmeterDados && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível salvar sua meta.
                  {erroSubmeterDados === true
                    ? " Problema com a conexão."
                    : ` Problema interno no servidor (código ${erroSubmeterDados}).`}
                </Alert>
              )}
            </CardBody>
            <CardFooter>
              {!carregandoDados && !erroObterDados && (
                <Button
                  type="submit"
                  color="primary"
                  disabled={submetendoDados}
                >
                  {submetendoDados && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Salvar
                </Button>
              )}

              {erroObterDados && (
                <Button type="button" onClick={() => getDados()}>
                  Tentar novamente
                </Button>
              )}

              <Button color="link" onClick={cancelarClickInputHandler}>
                Cancelar
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </div>
  );
}

export default withRouter(EspecifiqueAumentarReceitas);
