import React from "react";
import { FieldTextMask } from "../FieldTextMask";

function FieldDate(props) {
  const mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  const placeholder = "99/99/9999";

  return <FieldTextMask mask={mask} placeholder={placeholder} {...props} />;
}

export default FieldDate;
