import React, { Component } from "react";
import { Nav } from "reactstrap";
import PropTypes from "prop-types";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
// import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";

import logoSmall from "../../assets/img/logoSmall.png";
import DropdownAtendimento from "./DropdownAtendimento";
import DropdownNotas from "./DropdownNotas";
import ClienteName from "./ClienteName";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: "/assets/img/logo.png",
            /*width: 200, height: 55,*/ alt: "Solution for Life",
          }}
          minimized={{
            src: logoSmall,
            /*width: 25, height: 30,*/ alt: "Solution for Life",
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          {/* <DefaultHeaderDropdown atendimento /> */}
          <ClienteName />
          <DropdownAtendimento />
          <DropdownNotas/>
          <DefaultHeaderDropdown accnt />
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
