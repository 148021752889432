import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import {
  getAxiosAuth,
  wasRequestCancelled,
  toBRLColor,
  toPercentColor,
  getClienteId,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import palette from "google-palette";
import { GraficoRosca } from "../../../components";
import PropTypes from "prop-types";
import moment from "moment";

function DespesasPorTipos(props) {
  const { ano, mes } = props;
  const clienteId = getClienteId();
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredId, hoverId] = useState(-1);
  const [selectedTipo, selectTipo] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [categorias, setaCategorias] = useState([]);
  const [totalDespesas, setaTotalDespesas] = useState(0.0);
  const [legendas, setaLegendas] = useState([]);
  const [dados, setaDados] = useState([]);
  const [total, setaTotal] = useState(0.0);
  const [cores, setaCores] = useState([]);

  const getDespesas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/despesas`;
    const dados = {
      filtroMes: mes,
      filtroAno: ano,
      filtroTags: props.tags ? props.tags.map((tag) => tag.value) : [],
    };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    setIsLoading(false);

    if (response.data) {
      if ("totalDespesas" in response.data) {
        setaTotalDespesas(response.data.totalDespesas);
      }

      if ("categorias" in response.data) {
        setaCategorias(response.data.categorias);
      }
    }
  };

  useEffect(() => {
    getDespesas(); // primeiro acesso, e quando alterar data ou tags
  }, [mes, ano, props.tags]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let compLegendas, compDados, compTotal, compCores;

    if (selectedTipo) {
      const despesas = categorias.reduce((acc, categoria) => {
        categoria.despesas
          .filter((despesa) => despesa.tipo === selectedTipo)
          .forEach((despesa) => {
            if (despesa.valor) {
              const dataDespesa = despesa.dataDespesa;
              
              const mesDespesa = moment(dataDespesa).format('M');
              const anoDespesa = moment(dataDespesa).format('YYYY');

              const formatDespesa = moment([anoDespesa, mesDespesa - 1]);
              const formatAtual = moment([ano, mes - 1]);

              const parcelasPagas = formatAtual.diff(formatDespesa, "month", true);

              if(despesa.despesaTemporaria){
                if(!(((despesa.totalParcelas - despesa.parcela) - parcelasPagas) < 0)) {
                  acc.push(despesa);
                }
              } else {
                acc.push(despesa);
              }
            }
          });
        return acc;
      }, []);
 
      compLegendas = despesas.reduce((acc, cur, i, a) => {
        if (acc.indexOf(cur.descricao) < 0) {
          acc.push(cur.descricao);
        }
        return acc;
      }, []);
      
      compDados = compLegendas.map((legenda) =>
        despesas.reduce((acc, despesa) => {
          if (despesa.descricao === legenda) {
            acc.push(despesa.valor)
          }
          return acc;
        }, [])
      );
      let pares = compLegendas
        .map((legenda, i) => {
          let numeroItems = compDados[i].length;
          return {
            legenda: numeroItems > 1 ? `${legenda} (${numeroItems})` : legenda,
            valor: compDados[i].reduce((acc, cur) => acc + cur, 0.0),
          };
        })
        .sort((a, b) => b.valor - a.valor);
      compLegendas = pares.map((item) => item.legenda);
      compDados = pares.map((item) => item.valor);
      compTotal = compDados.reduce((acc, cur) => acc + cur, 0.0);
    } else {
      compLegendas = categorias.reduce((acc, categoria) => {
        categoria.despesas
          .map((despesa) => despesa.tipo)
          .forEach((tipo) => {
            if (acc.indexOf(tipo) < 0) {
              acc.push(tipo);
            }
          });

        return acc;
      }, []);

      compDados = categorias.reduce(
        (acc, categoria) => {
          categoria.despesas.forEach((despesa) => {
            const tipoIdx = compLegendas.indexOf(despesa.tipo);

            // bloco de codigo para verificar se as parcelas foram pagas
            const dataDespesa = despesa.dataDespesa;
              
            const mesDespesa = moment(dataDespesa).format('M');
            const anoDespesa = moment(dataDespesa).format('YYYY');

            const formatDespesa = moment([anoDespesa, mesDespesa - 1]);
            const formatAtual = moment([ano, mes - 1]);

            // Pega a diferença entre datas, da atual para a da despesa
            const parcelasPagas = formatAtual.diff(formatDespesa, "month", true);

            if (tipoIdx >= 0 && tipoIdx < compLegendas.length) {
              if(despesa.despesaTemporaria) {
                if((((despesa.totalParcelas - despesa.parcela) - parcelasPagas) < 0)){
                  acc[tipoIdx] += 0;
                } else {
                  acc[tipoIdx] += despesa.valor;
                }
              } else {
                acc[tipoIdx] += despesa.valor;
              }
            }
          });

          return acc;
        },
        [...Array(compLegendas.length).keys()].map(() => 0.0)
      );

      compTotal = compDados.reduce((acc, cur) => acc + cur, 0);;

      const pares = compLegendas
        .map((legenda, idx) => ({
          legenda,
          valor: compDados[idx],
        }))
        .sort((a, b) => b.valor - a.valor);

      compLegendas = pares.map((par) => par.legenda);
      compDados = pares.map((par) => par.valor);
    }

    compCores = palette("tol-rainbow", compDados.length).map(
      (hex) => "#" + hex
    );

    compCores = compCores.map((color) => {
      if(color === "#781c81"){
        return "#3077ff"
      } else {
        return color
      }
    })

    setaLegendas(compLegendas);
    setaDados(compDados);
    setaTotal(compTotal);
    setaCores(compCores);
  }, [categorias, totalDespesas, selectedTipo, titulo]);
  
  const spinner = (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );

  const hoverCb = (index) => {
    if (!isNaN(index) && index >= 0) {
      hoverId(index);
    } else {
      hoverId(-1);
    }
  };

  const clickCb = (index) => {
    if (selectedTipo === null && index >= 0 && index < legendas.length) {
      const novoTitulo = legendas[index];
      selectTipo(novoTitulo);
      setTitulo(novoTitulo);
    }
  };

  const backClickCb = (e) => {
    e.preventDefault();
    selectTipo(null);
    setTitulo("");
  };

  return (
    <Row>
      <Col lg={6} xl={8} className="text-center">
        {isLoading && spinner}
        {!isLoading && (
          <Fragment>
            <GraficoRosca
              legendas={legendas}
              dados={dados}
              titulo={selectedTipo !== null ? titulo : "Todas as despesas"}
              total={total}
              cores={cores}
              hoverCb={hoverCb}
              clickCb={selectedTipo === null && clickCb}
            />
            {selectedTipo !== null && (
              <Button
                color="light"
                size="sm"
                onClick={backClickCb}
                className="mt-2 mb-4"
              >
                <i className="fas fa-arrow-left" />
                &nbsp;&nbsp;&nbsp;Todas as despesas
              </Button>
            )}
          </Fragment>
        )}
      </Col>
      <Col lg={6} xl={4} className="mt-4 mt-lg-0">
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <strong>Tipos</strong>
          </CardHeader>
          <CardBody>
            {isLoading && spinner}
            {!isLoading && (
              <Table size="sm" borderless responsive>
                <tbody>
                  {dados.map((valor, i) => (
                    <tr
                      key={`categoria_${i}`}
                      className={hoveredId === i ? "table-light" : ""}
                    >
                      <td style={{ width: 30 }} className="text-center">
                        <i
                          className="fa fa-circle"
                          style={{
                            color: cores[i],
                            fontSize: hoveredId === i ? "1.1rem" : "inherit",
                          }}
                        />
                      </td>
                      <td>{legendas[i]}</td>
                      <td className="text-right">{toBRLColor(valor)}</td>
                      <td className="text-right">
                        {total > 0 && toPercentColor(valor / total)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

DespesasPorTipos.propTypes = {
  ano: PropTypes.number,
  mes: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(DespesasPorTipos);
