import React from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

function Percent(props) {
  return (
    <InputGroup className="percent">
      <Input type="text" className="text-right" {...props} />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          %
        </InputGroupText>
      </InputGroupAddon>      
    </InputGroup>
  )
}

export default Percent;