import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import {
  getAxiosAuth,
  wasRequestCancelled,
  toBRLColor,
  toPercentColor,
  getClienteId,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import palette from "google-palette";
import { GraficoRosca } from "../../../components";
import PropTypes from "prop-types";
import moment from "moment";

function DespesasPorFixasVariaveis(props) {
  const { ano, mes } = props;
  const clienteId = getClienteId();
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredId, hoverId] = useState(-1);
  const [selectedFixa, selectFixa] = useState(null);
  const [titulo, setaTitulo] = useState("");
  const [categorias, setaCategorias] = useState([]);
  const [opcoes, setaOpcoes] = useState([]);
  const [legendas, setaLegendas] = useState([]);
  const [dados, setaDados] = useState([]);
  const [total, setaTotal] = useState(0.0);
  const [cores, setaCores] = useState([]);

  const getDespesas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/despesas`;
    const dados = {
      filtroMes: mes,
      filtroAno: ano,
      filtroTags: props.tags ? props.tags.map((tag) => tag.value) : [],
    };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    setIsLoading(false);

    if (response.data) {
      if ("categorias" in response.data) {
        setaCategorias(response.data.categorias);
      }
    }
  };

  useEffect(() => {
    getDespesas(); // primeiro acesso, e quando alterar data ou tags
  }, [mes, ano, props.tags]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let compLegendas, compDados, compTotal, compCores, compVariveis;
    let compOpcoes = [];

    let compFixa = categorias.reduce((acc, categoria) => {
      categoria.despesas
        .map((despesa) => despesa.despesaFixa)
        .forEach((despesaFixa) => {
          if (acc.indexOf("recorrente") < 0) {
            acc.push("recorrente");
          }
        });
      return acc;
    }, []);

    compVariveis = categorias.reduce((acc, categoria) => {
      categoria.despesas
        .map((despesa) => (!despesa.despesaFixa && !despesa.despesaTemporaria))
        .forEach((despesaVariavel) => {
          if (acc.indexOf("variavel") < 0) {
            acc.push("variavel")
          }
        });
        return acc;
    }, [])

    let compTemp = categorias.reduce((acc, categoria) => {
      categoria.despesas
        .map((despesa) => despesa.despesaTemporaria)
        .forEach((despesaTemporaria) => {
          if(acc.indexOf("temporaria") < 0) {
            acc.push("temporaria");
          }
        });
        return acc;
    }, [])

    compOpcoes.push(compFixa[0], compVariveis[0], compTemp[0]);

    if (selectedFixa === "temporaria" || selectedFixa === "recorrente" || selectedFixa === "variavel") {
      let despesas;
      if(selectedFixa === "temporaria"){
        despesas = categorias.reduce((acc, categoria) => {
          categoria.despesas
            .filter((despesa) => despesa.despesaTemporaria === true)
            .forEach((despesa) => {
              if (despesa.valor) {
                const dataDespesa = despesa.dataDespesa;
              
                const mesDespesa = moment(dataDespesa).format('M');
                const anoDespesa = moment(dataDespesa).format('YYYY');

                const formatDespesa = moment([anoDespesa, mesDespesa - 1]);
                const formatAtual = moment([ano, mes - 1]);

                const parcelasPagas = formatAtual.diff(formatDespesa, "month", true);
                
                if(!(((despesa.totalParcelas - despesa.parcela) - parcelasPagas) < 0)) {
                  acc.push(despesa);
                }
              }
            });
          return acc;
        }, []);
      } else if(selectedFixa === "recorrente") {
        despesas = categorias.reduce((acc, categoria) => {
          categoria.despesas
            .filter((despesa) => despesa.despesaFixa === true)
            .forEach((despesa) => {
              if (despesa.valor) {
                acc.push(despesa);
              }
            });
          return acc;
        }, []);
      } else {
        despesas = categorias.reduce((acc, categoria) => {
          categoria.despesas
            .filter((despesa) => (!despesa.despesaFixa && !despesa.despesaTemporaria))
            .forEach((despesa) => {
              if (despesa.valor) {
                acc.push(despesa);
              }
            });
          return acc;
        }, []);
      }
      
      compLegendas = despesas.reduce((acc, cur, i, a) => {
        if (acc.indexOf(cur.descricao) < 0) {
          acc.push(cur.descricao);
        }
        return acc;
      }, []);
      compDados = compLegendas.map((legenda) =>
        despesas.reduce((acc, despesa) => {
          if (despesa.descricao === legenda) {
            acc.push(despesa.valor);
          }
          return acc;
        }, [])
      );
      let pares = compLegendas
        .map((legenda, i) => {
          let numeroItems = compDados[i].length;
          return {
            legenda: numeroItems > 1 ? `${legenda} (${numeroItems})` : legenda,
            valor: compDados[i].reduce((acc, cur) => acc + cur, 0.0),
          };
        })
        .sort((a, b) => b.valor - a.valor);
      compLegendas = pares.map((item) => item.legenda);
      compDados = pares.map((item) => item.valor);
    } else {
      compLegendas = compOpcoes.map((opcao) => {
        if(opcao === "variavel") {
          return "Variável";
        } else if (opcao === "recorrente") {
          return "Recorrente";
        } else {
          return "Temporária";
        }
      });
      
      compDados = categorias.reduce(
        (acc, categoria) => {
          categoria.despesas.forEach((despesa) => {
            let opcaoIdx = -1;
            const dataDespesa = despesa.dataDespesa;
              
            const mesDespesa = moment(dataDespesa).format('M');
            const anoDespesa = moment(dataDespesa).format('YYYY');

            const formatDespesa = moment([anoDespesa, mesDespesa - 1]);
            const formatAtual = moment([ano, mes - 1]);

            if(!despesa.despesaFixa && !despesa.despesaTemporaria) {
              opcaoIdx = compOpcoes.indexOf("variavel");
            } else if (despesa.despesaFixa) {
              opcaoIdx = compOpcoes.indexOf("recorrente");
            } else {
              const parcelasPagas = formatAtual.diff(formatDespesa, "month", true);

              opcaoIdx = compOpcoes.indexOf("temporaria");
              
              // caso a despesa esteja paga, não adicione o valor
              if(((despesa.totalParcelas - despesa.parcela) - parcelasPagas) < 0) {
                acc[opcaoIdx] += 0;
              } else {
                // caso a despesa NÃO esteja paga, adicone o valor
                acc[opcaoIdx] += despesa.valor;
              }
            }
            // pega o valor total
            if (opcaoIdx >= 0 && opcaoIdx < compLegendas.length) {
              if(!despesa.despesaTemporaria){
                acc[opcaoIdx] += despesa.valor;
              }
            }
          });

          return acc;
        },
        [...Array(compOpcoes.length).keys()].map(() => 0.0)
      );
      
      const pares = compLegendas
        .map((legenda, idx) => ({
          legenda,
          opcao: compOpcoes[idx],
          valor: compDados[idx],
        }))
        
      compOpcoes = pares.map((par) => par.opcao);

      compLegendas = pares.map((par) => par.legenda);
      compDados = pares.map((par) => par.valor);
    }

    compTotal = compDados.reduce((acc, cur) => acc + cur, 0);
    compCores = palette("tol-dv", compDados.length)
      .map((hex) => "#" + hex);
    
    compCores = compCores.map((color) => {
      if(color === "#fbf8d9"){
        return "#2986cc";
      } else if(color === "#aa1c3b") {
        return "#f0902c"
      } else {
        return "#8fce00";
      }
    })

    compCores = compCores.map((color) => {
      if(color === "#8fce00") {
        return "#2986cc"
      } else if(color === "#2986cc"){
        return "#8fce00"
      } else {
        return color
      }
    })
    console.log(compCores)
    setaOpcoes(compOpcoes);
    setaLegendas(compLegendas);
    setaDados(compDados);
    setaTotal(compTotal);
    setaCores(compCores);
  }, [categorias, selectedFixa, titulo]);

  const spinner = (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );

  const hoverCb = (index) => {
    if (!isNaN(index) && index >= 0) {
      hoverId(index);
    } else {
      hoverId(-1);
    }
  };

  const clickCb = (index) => {
    if (selectedFixa === null && index >= 0 && index < legendas.length) {
      const novoTitulo = legendas[index];
      setaTitulo(novoTitulo);
      selectFixa(opcoes[index]);
      console.log(opcoes[index]);
    }
  };

  const backClickCb = (e) => {
    e.preventDefault();
    setaTitulo("");
    selectFixa(null);
  };

  return (
    <Row className="mb-5">
      <Col lg={6} xl={8} className="text-center">
        {isLoading && spinner}
        {!isLoading && (
          <Fragment>
            <GraficoRosca
              legendas={legendas}
              dados={dados}
              titulo={selectedFixa !== null ? titulo : "Todas as despesas"}
              total={total}
              cores={cores}
              hoverCb={hoverCb}
              clickCb={selectedFixa === null && clickCb}
            />
            {selectedFixa !== null && (
              <Button
                color="light"
                size="sm"
                onClick={backClickCb}
                className="mt-2 mb-4"
              >
                <i className="fas fa-arrow-left" />
                &nbsp;&nbsp;&nbsp;Todas as despesas
              </Button>
            )}
          </Fragment>
        )}
      </Col>
      <Col lg={6} xl={4} className="mt-4 mt-lg-0">
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <strong>Recorrentes, Temporárias e Variáveis</strong>
          </CardHeader>
          <CardBody>
            {isLoading && spinner}
            {!isLoading && (
              <Table size="sm" borderless responsive>
                <tbody>
                  {dados.map((valor, i) => (
                    <tr
                      key={`categoria_${i}`}
                      className={hoveredId === i ? "table-light" : ""}
                    >
                      <td style={{ width: 30 }} className="text-center">
                        <i
                          className="fa fa-circle"
                          style={{
                            color: cores[i],
                            fontSize: hoveredId === i ? "1.1rem" : "inherit",
                          }}
                        />
                      </td>
                      <td>{legendas[i]}</td>
                      <td className="text-right">{toBRLColor(valor)}</td>
                      <td className="text-right">
                        {total > 0 && toPercentColor(valor / total)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

DespesasPorFixasVariaveis.propTypes = {
  ano: PropTypes.number,
  mes: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(DespesasPorFixasVariaveis);
