import moment from "moment";
import "moment/locale/pt-br";

//Limite tamanho textos
export const maxWidthText = 100;
export const maxDescriptionWidthText = 4000;

export const TIPOS_RECEITAS = ["Principal", "Extra", "Passiva"];

export const CATEGORIAS_DESPESAS = [
  { nome: "Habitação", icone: "fas fa-home" },
  { nome: "Alimentação", icone: "fas fa-utensils" },
  { nome: "Saúde", icone: "fas fa-briefcase-medical" },
  { nome: "Transporte", icone: "fas fa-car" },
  { nome: "Vestuário", icone: "fas fa-tshirt" },
  { nome: "Cuidados pessoais", icone: "far fa-smile-beam" },
  { nome: "Lazer", icone: "fa fa-umbrella-beach" },
  { nome: "Educação", icone: "fas fa-graduation-cap" },
  { nome: "Profissional PJ", icone: "fas fa-briefcase" },
  { nome: "Outras", icone: "fas fa-credit-card" }
];

export const CATEGORIAS_DESPESAS_OPCOES = Object.keys(CATEGORIAS_DESPESAS).map(
  key => {
    return CATEGORIAS_DESPESAS[key].nome;
  }
);

export const TIPOS_DESPESAS = ["Essencial", "Livre", "Supérflua"];

export const MESES = Array.apply(null, { length: 12 }).map((v, i) =>
  moment()
    .locale("pt-BR")
    .month(i)
    .format("MMMM")
);

export const SEXO = {
  MASCULINO: 1,
  FEMININO: 2
};

export const ESTADOS_BRASILEIROS = [
  {
    ID: "1",
    Sigla: "AC",
    Nome: "Acre"
  },
  {
    ID: "2",
    Sigla: "AL",
    Nome: "Alagoas"
  },
  {
    ID: "3",
    Sigla: "AM",
    Nome: "Amazonas"
  },
  {
    ID: "4",
    Sigla: "AP",
    Nome: "Amapá"
  },
  {
    ID: "5",
    Sigla: "BA",
    Nome: "Bahia"
  },
  {
    ID: "6",
    Sigla: "CE",
    Nome: "Ceará"
  },
  {
    ID: "7",
    Sigla: "DF",
    Nome: "Distrito Federal"
  },
  {
    ID: "8",
    Sigla: "ES",
    Nome: "Espírito Santo"
  },
  {
    ID: "9",
    Sigla: "GO",
    Nome: "Goiás"
  },
  {
    ID: "10",
    Sigla: "MA",
    Nome: "Maranhão"
  },
  {
    ID: "11",
    Sigla: "MG",
    Nome: "Minas Gerais"
  },
  {
    ID: "12",
    Sigla: "MS",
    Nome: "Mato Grosso do Sul"
  },
  {
    ID: "13",
    Sigla: "MT",
    Nome: "Mato Grosso"
  },
  {
    ID: "14",
    Sigla: "PA",
    Nome: "Pará"
  },
  {
    ID: "15",
    Sigla: "PB",
    Nome: "Paraíba"
  },
  {
    ID: "16",
    Sigla: "PE",
    Nome: "Pernambuco"
  },
  {
    ID: "17",
    Sigla: "PI",
    Nome: "Piauí"
  },
  {
    ID: "18",
    Sigla: "PR",
    Nome: "Paraná"
  },
  {
    ID: "19",
    Sigla: "RJ",
    Nome: "Rio de Janeiro"
  },
  {
    ID: "20",
    Sigla: "RN",
    Nome: "Rio Grande do Norte"
  },
  {
    ID: "21",
    Sigla: "RO",
    Nome: "Rondônia"
  },
  {
    ID: "22",
    Sigla: "RR",
    Nome: "Roraima"
  },
  {
    ID: "23",
    Sigla: "RS",
    Nome: "Rio Grande do Sul"
  },
  {
    ID: "24",
    Sigla: "SC",
    Nome: "Santa Catarina"
  },
  {
    ID: "25",
    Sigla: "SE",
    Nome: "Sergipe"
  },
  {
    ID: "26",
    Sigla: "SP",
    Nome: "São Paulo"
  },
  {
    ID: "27",
    Sigla: "TO",
    Nome: "Tocantins"
  }
];

export const ESTADOS_BRASILEIROS_OPTS = ESTADOS_BRASILEIROS.map(estado => ({
  value: estado.Sigla,
  label: estado.Nome
}));

export const TIPOS_TELEFONE = ["Celular", "Fixo", "Fax"];

export const TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA = "Participação acionária";

export const TIPOS_PATRIMONIOS = [
  "Minha casa própria",
  "Outros Imóveis",
  "Veículos/Autos",
  "Outros bens",
  TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA
];

export const TIPOS_PROJETOS = [
  "Acumular para realizar",
  "Reduzir despesas",
  "Aumentar receitas"
];

export const TIPOS_PREVIDENCIAS = ["VGBL", "PGBL", "Fundo de Pensão"];

export const JUROS_PADRAO = "0,5";

export const TIPOS_PROTECOES = [
  "Vida / Invalidez",
  "Doenças Graves",
  "Diária de Incapacidade Temporária (DIT)",
  "RC Profissional",
  "Saúde",
  "Automóvel",
  "Residencial",
  "Empresarial",
  "Outros"
];

export const TIPOS_PROTECOES_COM_CAPITAL_SEGURADO = [
  "Vida / Invalidez",
  "Doenças Graves",
  "Diária de Incapacidade Temporária (DIT)",
  "RC Profissional"
];

export const TIPOS_PROTECOES_COM_MES_VENCIMENTO = [
  "Automóvel",
  "Residencial",
  "Empresarial",
  "Outros"
];

export const TIPOS_RISCOS = [
  "Alto", 
  "Médio", 
  "Baixo"
];

export const TIPOS_INVESTIMENTOS = [
  "Previdência Privada",
  "Fundo de Renda Fixa",
  "Poupança",
  "Títulos Públicos",
  "CDB",
  "LCI/LCA",
  "CRI/CRA",
  "Fundos imobiliários",
  "Debentures",
  "COE",
  "Fundo de ações",
  "Fundo multimercados",
  "Fundo cambial",
  "Investimentos no exterior",
  "Renda variável",
  "Derivativos",
  "Ações",
  "Criptomoedas",
  "Opções",
  "ETF",
  "Outros"
];

export const LIQUIDEZ_INVESTIMENTOS = [
  "Imediata",
  "Até 1 mês",
  "Até 3 meses",
  "Até 6 meses",
  "Acima de 6 meses",
];

export const TESTE_PERFIL_CAMPOS = [
  "caracteristicas",
  "pontos fortes",
  "pontos fracos"
]