const TextosIndice = [
    `<p>A porcentagem indica que seus patrimônios são financiados por dívidas, podendo causar uma falsa impressão de tranquilidade.</p>
     <p>Quanto mais alto for este índice, menor é o seu patrimônio líquido (todos os seus bens, descontadas as suas dívidas).</p>
     <p>O ideal é mantê-lo próximo de zero, pois as taxas de juros tem sido muito altas no Brasil.</p>
     <p>LEMBRE-SE que este índice aponta um percentual do seu patrimonio que NÃO É SEU.</p>   
    `,
    `
    <p>Indica como está dimensionado seu padrão de despesas, comparado com suas receitas. Mede a sua Capacidade de Poupança mensal.</p>
    <p>Quanto menor este índice, significa que está poupando mais, e então poderá comprar mais ativos e gerar mais renda passiva.</p>
    <p>Quando maior que 100%, você está gastando mais do que ganha e deve agir imediatamente.</p>
    <p>EQUILIBRE O SEU PADRÃO DE VIDA PARA AUMENTAR A SUA CAPACIDADE DE POUPANÇA.</p>
    <p>IDEAL QUE ESTE INDICE FIQUE SEMPRE INFERIOR A 80%.</p>
    `,
    `
    <p>Calcula como está sua reserva de segurança, caso aconteça um imprevisto, como a perda do emprego, crise, doença ou acidente. Indica por quantos meses você conseguiria sobreviver com seus Ativos de Curto Prazo, que são aqueles com liquidez imediata.</p>
    <p>Nunca deixe esse indicador ficar abaixo de 6.</p>
    <p>Quando este índice ficar acima de 6, você deve procurar novos investimentos para aumentar a sua rentabilidade, pois está desperdiçando dinheiro deixando de realizar investimentos mais inteligentes.</p>
    `,
    `
    <p>A porcentagem indica que seu patrimônio está investido em Imóveis, incluindo sua casa própria.</p>
    <p>Um alto índice de imobilização pode gerar falta de liquidez nos períodos de crises e recessões.</p>
    <p>Avalie cada um dos imóveis individualmente e veja quais estão rendendo e quais estão gerando apenas despesas.</p>
    <p>NUNCA deixe esse índice superar 70% dos seu patrimônio.</p>
    `,
    `
    <p>A porcentagem indica que seus patrimônios estão imobilizados na casa própria, e não estão rendendo R$ 1,00 centavo sequer.</p>
    <p>Será que você mora em uma casa compatível com o seu patrimônio?</p>
    `,
    `
    <p>A porcentagem indica que seus patrimônios foram destinados para a aquisição dos seus veículos.</p>
    <p>Será que faz sentido ter mais de 20% de todo seu patrimônio em carros que não geram dinheiro algum a você? Certamente não!</p>
    `,
    `
    <p>A porcentagem indica que seu patrimônio está gerando dinheiro. São realmente ATIVOS FINANCEIROS.</p>
    <p>O restante gera apenas despesas, e isso pode comprometer o seu processo de enriquecimento.</p>
    <p>É hora de avaliar decisões tomadas no passado quanto ao dimensionamento e escolha de seus bens atuais (casa, carro, etc.).</p>
    <p>Quanto mais aumentar este índice, mais seu dinheiro trabalhará para você!</p>
    `,
    `
    <p>Liquidez é a capacidade de transformar um bem em dinheiro vivo. Tem a ver com a disponibilidade dos recursos para utilização imediata.</p>
    <p>Indica o quanto do patrimônio do cliente estará disponível para utilização imediata.</p>
    <p>Se o indicador estiver em branco, sinaliza que você não possui nenhuma liquidez em seu patrimônio.</p>
    `,
    `
    <p>A porcentagem indica que suas Despesas conseguem ser pagas através de rendimentos de seus investimentos.</p>
    <p>Índice acima de 100% significa que atingiu a sua Liberdade Financeira, e não depende mais do trabalho para pagar as contas mensais.</p>
    <p>EXISTEM 2 FORMAS DE AUMENTAR ESTE ÍNDICE: COMPRANDO ATIVOS E AUMENTANDO A RENDA PASSIVA, OU REDUZINDO O SEU PADRÃO DE VIDA.</p>
    `,
]

export default TextosIndice