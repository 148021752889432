import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  CardBody,
  Button
} from "reactstrap";
import { GraficoRosca, LoadingSpinner } from "../../../components";
import palette from "google-palette";
import PropTypes from "prop-types";
import { toBRLColor, toPercentColor } from "../../../utils";

function GraficoDeducoes({ isLoading, deducoes, onClickBtnVoltar }) {
  const legendas = deducoes.map(deducao => deducao.descricao);
  const dados = deducoes.map(deducao => deducao.valor);
  const totalDeducoes = dados.reduce((acc, cur) => acc + cur, 0.0);
  const cores = palette("tol-rainbow", dados.length).map(hex => "#" + hex);
  const [itemSelecionado, setaItemSelecionado] = useState(null);

  const hoverCb = index => {
    let value;
    if (!isNaN(index) && index >= 0) {
      value = index;
    } else {
      value = null;
    }
    setaItemSelecionado(value);
  };

  return (
    <Row>
      <Col lg={6} xl={8} className="text-center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <GraficoRosca
              legendas={legendas}
              dados={dados}
              titulo={"Total deduções"}
              total={totalDeducoes}
              cores={cores}
              hoverCb={hoverCb}
            />
            {onClickBtnVoltar && (
              <Button
                color="light"
                size="sm"
                onClick={onClickBtnVoltar}
                className="mt-2 mb-4"
              >
                {" "}
                <i className="fas fa-arrow-left" />
                &nbsp;&nbsp;&nbsp;Todas as receitas
              </Button>
            )}
          </Fragment>
        )}
      </Col>
      <Col lg={6} xl={4} className="mt-4 mt-lg-0">
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <strong>Categorias</strong>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Table size="sm" borderless responsive>
                <tbody>
                  {dados.map((valor, i) => (
                    <tr
                      key={`categoria_${i}`}
                      className={itemSelecionado === i ? "table-light" : ""}
                    >
                      <td style={{ width: 30 }} className="text-center">
                        <i
                          className="fa fa-circle"
                          style={{
                            color: cores[i],
                            fontSize:
                              itemSelecionado === i ? "1.1rem" : "inherit"
                          }}
                        />
                      </td>
                      <td>{legendas[i]}</td>
                      <td className="text-right">{toBRLColor(valor)}</td>
                      <td className="text-right">
                        {totalDeducoes > 0 &&
                          toPercentColor(valor / totalDeducoes)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

GraficoDeducoes.propTypes = {
  isLoading: PropTypes.bool,
  deducoes: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string.isRequired,
      valor: PropTypes.number.isRequired
    })
  ).isRequired,
  onClickBtnVoltar: PropTypes.func
};

export default GraficoDeducoes;
