import React from "react";
import { Badge } from "reactstrap";

function ItemAtivo() {
  return (
    <Badge color="primary" pill className="p-2">
      Ativo
    </Badge>
  );
}

export default ItemAtivo;
