import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Container, Row } from "reactstrap";
import { getAxiosAuth, getClienteId, wasRequestCancelled } from "../../../utils";
import TestePerfilResults from "./TestePerfilResults";
import TestePerfilPage from "./TestePerfilPage";
import { TESTE_PERFIL_CAMPOS } from "../../../options";

function TestePerfil({ history }) {
  const clienteId = getClienteId();
  const [perfilId, setaPerfilId] = React.useState(null)
  const [loading, setLoading] = useState(true);
  // Estado para controlar a página atual
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProfile, setCurrentProfile] = useState(TESTE_PERFIL_CAMPOS[0]);
  const [totalPages, setTotalPages] = useState(0);

  const [data, setData] = useState([]);

  React.useEffect(() => {
    getPerguntas();
  }, [])

  useEffect(() => {
    dateItemTypeChange();
  }, [currentPage])

  function dateItemTypeChange() {
    switch (currentPage) {
      case 1:
        setCurrentProfile("poupador");
        break;
      case 2:
        setCurrentProfile("gastador");
        break;
      case 3:
        setCurrentProfile("descontrolado");
        break;
      case 4:
        setCurrentProfile("desligado");
        break;
      case 5:
        setCurrentProfile("financista");
        break;
      default:
        setCurrentProfile("");
    }
  }

  const axiosInstance = getAxiosAuth(() => {
    history.push("/login");
  });

  const getPerguntas = async () => {
    const uri = `/clientes/${clienteId}/perfil`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      history.push("/500");
      return;
    }

    if (response.data) {
      setaPerfilId(response.data.perfilId)

      const sortedData = response.data.perguntas.sort((a, b) => a.order - b.order);
      const finalized = sortedData.reduce(
        (acc, curr) => acc = acc && curr.valor !== null, true
      );
      const uniqueTypes = [...new Set(sortedData.map((item) => item.tipo))];
      setTotalPages(uniqueTypes.length);
      if (finalized) setCurrentPage(uniqueTypes.length + 1);

      setData(sortedData);
    } else {
      setData([]); // Inicializa como um array vazio se a resposta não for um array
    }
    setLoading(false); // Marca o carregamento como concluído
  }


  const postNewValuesPerguntas = async (dataSlice) => {
    const uri = `/clientes/${clienteId}/perfil`;
    let response;

    const requestBody = {
      perfilId: perfilId,
      perguntas: dataSlice.map(item => ({
        id: item.id,
        valor: item.valor,
        descricao: item.descricao,
        tipo: item.tipo
      }))
    };
    try {
      response = await axiosInstance.post(uri, requestBody);
      if (response.status === 200) {
        console.log('Valores enviados com sucesso!');
      } else {
        console.error('Falha ao enviar valores:', response.status);
      }
    } catch (error) {
      console.error('Erro durante a requisição:', error);
    }
  }

  // Calcular índices de itens para a página atual
  const currentItems = data.filter((item) => item.tipo === currentProfile);

  const isCurrentPageValid = () => {
    return currentItems.every((item) => item.valor !== null);
  };

  const handlePageChange = async (newPage) => {
    const isForward = newPage > currentPage;

    if (!isForward) {
      setCurrentPage(newPage);
      return;
    }

    if (isCurrentPageValid()) {
      await postNewValuesPerguntas(currentItems);
      setCurrentPage(newPage);
    }
  };

  const handleInputChange = (itemId, value) => {
    const intValue = parseInt(value) || 0;
    const clampedValue = Math.min(10, Math.max(0, intValue));
    const newItems = data.map((item) =>
      item.id === itemId ? { ...item, valor: clampedValue } : item
    );
    setData(newItems);
  };

  return (
    <Container>
      <Row style={{ minHeight: "60px" }}>
        <div style={{ minWidth: "33%" }}>
          <h2>
            <b>Perfil Financeiro {currentProfile ? "- " + currentProfile.charAt(0).toUpperCase() + currentProfile.slice(1) : "- Resultados"}</b>
          </h2>
        </div>
      </Row>
      <div style={{ minHeight: "120px" }}>
        <Row style={{ minWidth: "55%", textAlign: "justify" }}>
          <h4><b>Preencha de 0 a 10</b></h4>
        </Row>
      </div>
      {/* Nova seção com a paginacao */}
      <div style={{ margin: "-40px 0 20px -10px" }}>
        {loading ? (
          <div style={{ fontSize: "26px", fontWeight: "600" }}>Loading...</div>
        ) : (
          currentPage <= totalPages
            ? TESTE_PERFIL_CAMPOS.map((campo) => (
              <TestePerfilPage
                key={campo}
                items={currentItems}
                campo={campo}
                onInputChange={handleInputChange}
              />
            ))
            : <TestePerfilResults clienteId={clienteId} data={data} />
        )}
        {/* Controle de Paginação */}
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >Anterior</Button>
          <span style={{ margin: "0 10px" }}>
            {currentPage} de {totalPages + 1}
          </span>
          <Button
            disabled={currentPage === totalPages + 1}
            onClick={() => handlePageChange(currentPage + 1)}
          >Próxima</Button>
        </div>
      </div>
    </Container>
  );
}
export default withRouter(TestePerfil);