import React, { Fragment } from "react";
import { Badge, Button } from "reactstrap";
import moment from "moment";
import {
  toBRLColor,
  toPercentColor,
  getAxiosAuth,
  getTokenClientId,
  wasRequestCancelled,
} from "../../../utils";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

function LinhaFinanciamento(props) {
  const agora = moment();
  const momentFinanciamento = moment(props.dataFinanciamento);
  let dataFinanciamento = momentFinanciamento.format("DD/MM/YYYY");

  const momentDespesa = moment(props.dataDespesa);
  let dataDespesa = momentDespesa.format("DD/MM/YYYY");
  let valorJuros = props.juros ? props.juros / 100.0 : null;
  let interrompido = !!props.financiamentoCancelado;
  let exibirBotoes =
    agora.month() + 1 === props.mes && agora.year() === props.ano;

  const clienteId = getTokenClientId();

  const makePatchRequest = async (cancelado) => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/financiamentos`;
    const dados = {
      cancelado,
      clienteId,
      nome: props.nome,
      valor: props.valorParcela,
      totalParcelas: props.totalParcelas,
    };
    try {
      await axiosInstance.patch(uri, dados);
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }
  };

  const onClickInterromper = async (e) => {
    e.preventDefault();
    await makePatchRequest(true);
    if (props.onFinanciamentoInterrompido) {
      props.onFinanciamentoInterrompido(props.nome);
    }
  };

  const onClickRetomar = async (e) => {
    e.preventDefault();
    await makePatchRequest(false);
    if (props.onFinanciamentoRetomado) {
      props.onFinanciamentoRetomado(props.nome);
    }
  };

  const btnInterromper = (
    <Button size="sm" color="warning" onClick={onClickInterromper}>
      <i className="far fa-stop-circle" /> &nbsp; Interromper
    </Button>
  );

  const btnRetomar = (
    <Button size="sm" onClick={onClickRetomar}>
      <i className="far fa-play-circle"></i> &nbsp; Retomar
    </Button>
  );

  const btnToggleInterromper = exibirBotoes
    ? interrompido
      ? btnRetomar
      : btnInterromper
    : null;

  return (
    <tr className={interrompido ? "table-secondary" : null}>
      <td>{dataFinanciamento}</td>
      <td>
        {props.nome}
        <span className="d-block d-sm-none">
          {props.credor && (
            <Fragment>
              <small>Credor: {props.credor}</small>
            </Fragment>
          )}
        </span>
        <span className="d-block d-sm-none">
          <Badge color="danger" pill style={{ marginRight: 5 }}>
            <i className="far fa-clock" /> {props.parcela} de{" "}
            {props.totalParcelas}
          </Badge>
        </span>
        {interrompido && <em className="d-block">Interrompido</em>}
      </td>
      <td className="text-right">
        {toBRLColor(props.valorParcela)}
        <span className="d-block d-sm-none">
          <small>Restante: {toBRLColor(props.valorRestante)}</small>
        </span>
        <div className="d-block d-sm-none">
          {valorJuros && <small>Juros: {toPercentColor(valorJuros)}</small>}
        </div>
        <div className="d-block d-sm-none">{btnToggleInterromper}</div>
      </td>

      <td className="text-right d-none d-sm-table-cell">{props.parcela}</td>
      <td className="text-right d-none d-sm-table-cell">
        {props.totalParcelas}
      </td>
      <td className="d-none d-sm-table-cell">{dataDespesa}</td>
      <td className="d-none d-sm-table-cell">{props.credor}</td>
      <td className="text-right d-none d-sm-table-cell">
        {valorJuros && toPercentColor(valorJuros)}
      </td>
      <td className="text-right d-none d-sm-table-cell">
        {toBRLColor(props.valorRestante)}
      </td>
      <td className="text-right d-none d-md-table-cell">
        {btnToggleInterromper}
      </td>
    </tr>
  );
}

LinhaFinanciamento.propTypes = {
  id: PropTypes.number.isRequired,
  dataFinanciamento: PropTypes.string.isRequired,
  dataDespesa: PropTypes.string.isRequired,
  nome: PropTypes.string,
  credor: PropTypes.string,
  valorParcela: PropTypes.number,
  juros: PropTypes.number,
  parcela: PropTypes.number,
  totalParcelas: PropTypes.number,
  valorRestante: PropTypes.number,
  financiamentoCanceladoEm: PropTypes.string,
  financiamentoCancelado: PropTypes.bool,
  onFinanciamentoInterrompido: PropTypes.func,
  onFinanciamentoRetomado: PropTypes.func,
  mes: PropTypes.number.isRequired,
  ano: PropTypes.number.isRequired,
};

export default withRouter(LinhaFinanciamento);
