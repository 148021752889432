import React, { Fragment, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";
import { toBRL, getAxiosAuth, getClienteId } from "../../../utils";
import { withRouter } from "react-router-dom";
import moment from "moment";

function BtnModalRemoverReceita(props) {
  const [aberto, setaAberto] = useState(false);
  const clienteId = getClienteId();

  // --- eventos para o modal ---
  const toggle = (e) => {
    e && e.stopPropagation();
    setaAberto(!aberto);
  };

  const handleDeleteCascade = async(e) => {
    e.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas/${props.id}`;
    let response;

    const date = new Date(props.ano, props.mes - 1, props.dia);
    const dataFormat = moment(date).format('YYYY-MM-DD');    

    try {
      response = await axiosInstance.delete(uri, { params: {
        cascade: true,
        dataAtual: dataFormat,
        descricao: props.descricao,
        valor: props.valor,
        tipo: props.tipo,
      } });
    } catch (err) {
      if (err.response) {
        response = err.response;
      } else {
        console.error(err, response);
        props.history.push("/500");
        return;
      }
    }

    setaAberto(false);
    props.onDelete && props.onDelete(props.id);
  }

  // --- eventos para o formulário ---
  const onSubmitForm = async (e) => {
    e.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas/${props.id}`;
    let response;

    try {
      response = await axiosInstance.delete(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      props.history.push("/500");
      return;
    }

    setaAberto(false);
    props.onDelete && props.onDelete(props.id);
  };
  
  return (
    <Fragment>
      <Button size="sm" onClick={toggle}>
        <i className="fas fa-times" />
      </Button>
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>Remover Receita</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <p>
              Deseja confirmar a remoção de <strong>{props.descricao}</strong>{" "}
              de <strong>{toBRL(props.valor)}</strong>?
            </p>
          </Form>
        </ModalBody>
        <ModalFooter>
          {
            props.rendaFixa ?
            (<>
              <Button color="warning" onClick={onSubmitForm}>
              Remover esta receita
              </Button>
              <Button color="danger" onClick={handleDeleteCascade}>
              Remover todas as receitas futuras
              </Button></>
            ) :
            <Button color="warning" onClick={onSubmitForm}>
              Remover
            </Button>
          }
          
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalRemoverReceita.propTypes = {
  id: PropTypes.number.isRequired,
  descricao: PropTypes.string.isRequired,
  valor: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
};

export default withRouter(BtnModalRemoverReceita);
