import React, { Fragment, useState, useEffect } from "react";
import { Container, Table, Row, Col } from "reactstrap";
import moment from "moment";
import {
  getAxiosAuth,
  wasRequestCancelled,
  toBRLColor,
  getTokenClientId
} from "../../../utils";
import { DateCarousel } from "../../../components";
import { withRouter } from "react-router-dom";
import LinhaPatrimonio from "./LinhaPatrimonio";
import LinhaInvestimento from "./LinhaInvestimento";
import GraficoAtivosXPassivos from "./GraficoAtivosXPassivos";

function AtivosReaisXPassivos(props) {
  const clienteId = getTokenClientId();
  const m = moment();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [patrimonios, setPatrimonios] = useState([]);
  const [investimentos, setInvestimentos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const totalValor = patrimonios.reduce((acc, cur) => acc + cur.valor, 0);
  const totalValorInvestimento = investimentos.reduce((acc, cur) => acc + cur.valor, 0);
  const totalManutencao = patrimonios.reduce(
    (acc, cur) => acc + cur.custoMensal,
    0
  );
  const totalReceita = patrimonios.reduce(
    (acc, cur) => acc + cur.receitaMensal,
    0
  );

  const ordenador = (a, b) => b.valor - a.valor;

  const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "patrimonios" in response.data) {
      setPatrimonios(response.data.patrimonios.sort(ordenador));
    }
  };

  const getInvestimentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/investimentos`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "investimentos" in response.data) {
      setInvestimentos(response.data.investimentos.sort(ordenador));
    }
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  // primeiro acesso e quando alterar a data
  useEffect(() => {
    setIsLoading(true);
  }, [mes, ano]);

  useEffect(() => {
    if (isLoading) {
      getPatrimonios();
      getInvestimentos();
      setIsLoading(false);
    }
  }, [isLoading]);

  const tiposPatrimonios = Array.from(new Set(patrimonios.map(p => p.tipo)));

  const tipos = tiposPatrimonios
    .map(tipo => {
      const items = patrimonios
        .filter(p => p.tipo === tipo)
        .map(item => {
          if (!item.custoMensal) {
            item.custoMensal = 0;
          }

          if (!item.receitaMensal) {
            item.receitaMensal = 0;
          }

          return {
            ...item,
            isAtivo: item.receitaMensal >= item.custoMensal
          };
        });
      return {
        grupo: tipo,
        totalValor: items.reduce((acc, cur) => acc + cur.valor, 0),
        totalManutencao: items.reduce((acc, cur) => acc + cur.custoMensal, 0),
        totalReceita: items.reduce((acc, cur) => acc + cur.receitaMensal, 0),
        items
      };
    })
    .filter(tipo => tipo.items.length > 0)
  
  const valores = tipos.concat([{
      grupo: "Investimentos",
      totalValor: investimentos.reduce((acc, cur) => acc + cur.valor, 0),
      totalManutencao: 0,
      totalReceita: investimentos.reduce((acc, cur) => acc + cur.valor, 0),
      items: investimentos.map(investimento => ({...investimento, isAtivo: true}))
    }])

  return (
    <div className="animated fadeIn">
      <Container className="">
        <Row>
          <Col md={6} sm={12} className="text-center">
            <h4 className="mt-1 mb-2">Ativos Reais X Passivos</h4>
          </Col>
          <Col md={6} sm={12} className="text-center mt-2 mt-md-0">
            <DateCarousel mes={mes} ano={ano} onChange={onChangeDate} lockMomentMonth={true}/>
          </Col>
        </Row>

        <Table size="sm" hover>
          <thead className="table-dark">
            <tr>
              <th>Patrimônio</th>
              <th className="d-none d-sm-table-cell text-right">Valor</th>
              <th className="d-none d-sm-table-cell text-right">
                Custo de Manutenção
              </th>
              <th className="d-none d-sm-table-cell text-right">Receita</th>
              <th className="d-none d-sm-table-cell text-center">
                Classificação
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr className="table-warning">
                <td colSpan={5}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!isLoading && (
              <Fragment>
                {tipos.map(tipo => (
                  <LinhaPatrimonio key={`patrimonio_${tipo.grupo}`} {...tipo} />
                ))}
              </Fragment>
            )}
          </tbody>
          <tfoot className="table-success">
            <tr className="">
              <th className="">TOTAL</th>
              <th className="d-none d-sm-table-cell text-right">
                {toBRLColor(totalValor)}
              </th>
              <th className="d-none d-sm-table-cell text-right">
                {toBRLColor(totalManutencao * -1)}
              </th>
              <th className="d-none d-sm-table-cell text-right">
                {toBRLColor(totalReceita)}
              </th>
              <th />
            </tr>
            <tr className="d-table-row d-sm-none">
              <th className="" colSpan={4}>
                Patrimônios
                <span className="text-right d-block float-right">
                  {toBRLColor(totalValor)}
                </span>
              </th>
            </tr>
            <tr className="d-table-row d-sm-none">
              <th className="" colSpan={4}>
                Custo de Manutenção
                <span className="text-right d-block float-right">
                  {toBRLColor(totalManutencao * -1)}
                </span>
              </th>
            </tr>
            <tr className="d-table-row d-sm-none">
              <th className="" colSpan={4}>
                Receita
                <span className="text-right d-block float-right">
                  {toBRLColor(totalReceita)}
                </span>
              </th>
            </tr>
          </tfoot>
        </Table>
        <Table size="sm" hover>
          <thead className="table-dark">
            <tr>
              <th>Investimento</th>
              <th className="d-none d-sm-table-cell text-right">Valor</th>
              <th className="d-none d-sm-table-cell text-right">
                Tipo
              </th>
              <th className="d-none d-sm-table-cell text-center">Descrição</th>
              <th className="d-none d-sm-table-cell text-center">
                Risco
              </th>
              <th className="d-none d-sm-table-cell text-center">
                Liquidez
              </th>
              <th className="d-none d-sm-table-cell text-center">
                Classificação
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr className="table-warning">
                <td colSpan={5}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!isLoading && (
              <Fragment>
                {investimentos.map(investimento => (
                  <LinhaInvestimento key={`investimento_${investimento.id}`} {...investimento} />
                ))}
              </Fragment>
            )}
          </tbody>
          <tfoot className="table-success">
            <tr className="">
              <th className="">TOTAL</th>
              <th className="d-none d-sm-table-cell text-right">
                {toBRLColor(totalValorInvestimento)}
              </th>
              <th />
              <th />
              <th />
              <th />
              <th />
            </tr>
          </tfoot>
        </Table>

        <GraficoAtivosXPassivos patrimonios={valores} />
      </Container>
    </div>
  );
}

export default withRouter(AtivosReaisXPassivos);
