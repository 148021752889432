import React from "react"
import CreatableSelect from "react-select/creatable";
import { Button, Col, Input, Row } from "reactstrap";
import { maxWidthText, TIPOS_RECEITAS } from "../../../options";
import { BtnModalFormPatrimonio } from "../../../components";
import moment from "moment";

function LinhaReceita(props) {
  const {
    receita, index, handleEdit, handleDelete, tagsOpcoes, itensGlobal, patrimonios, onUpdatePatrimonios, incorrectDate, setIncorrectDate
  } = props

  const date = moment(receita.data, 'DD/MM/YYYY');
  const mes = date.month() + 1;
  const ano = date.year();

  const patrimoniosOptions = patrimonios[ano]
    ? patrimonios[ano][mes]
      ? patrimonios[ano][mes]
      : []
    : [];

  const disabled = receita.duplication && !receita.ignoreDuplication;

  const [selectedTags, setSelectedTags] = React.useState(itensGlobal);
  const [tagsLocal, setTagsLocal] = React.useState([]);


  React.useEffect(() => {
    setTagsLocal(receita.tags);
  }, [])

  React.useEffect(() => {
    if (!Array.isArray(itensGlobal)) {
      setSelectedTags(tagsLocal)
      return;
    } else if (!Array.isArray(tagsLocal)) {
      setSelectedTags(itensGlobal)
      return;
    }
    else if (!Array.isArray(tagsLocal) && !Array.isArray(itensGlobal)) {
      setSelectedTags([])
      return;
    }


    const uniqueItensGlobal = itensGlobal.filter(item =>
      !tagsLocal.some(tag => tag.value === item.value)
    );


    const combinedTags = uniqueItensGlobal.concat(tagsLocal);
    setSelectedTags(combinedTags)
    handleEdit(index, 'tags', combinedTags)

  }, [itensGlobal, tagsLocal]);

  function validationDate(receitaDate) {
    const partesData = receitaDate.trim().split('/').map(Number);
    if (partesData.length === 3) {
      let [dia, mes, ano] = partesData;
      if (!isNaN(dia) && !isNaN(mes) && !isNaN(ano)) {
        if (dia > 0 && dia <= 31 && mes > 0 && mes <= 12 && ano > 0) {
          const dataFormatada = `${dia}/${mes}/${ano}`;
          handleEdit(index, 'data', dataFormatada);
          setIncorrectDate(false); 
          return true; 
        }
      }
    }
    setIncorrectDate(true); 
    return false; 
  }


  return (
    <>
      {incorrectDate && (
        <div style={{ color: 'red', marginTop: '5px', marginBottom: '-5px' }}>
          Formato ou valor da data incorreto (Formato: dd/MM/yyyy)
        </div>
      )}
      <tr style={{ backgroundColor: disabled ? 'lightgray' : 'transparent' }}>
        <td
          style={{ maxWidth: '85px', overflowWrap: 'break-word' }}
          contentEditable={!disabled}
          suppressContentEditableWarning
          onBlur={(e) =>{
            const receitaDate = e.target.textContent;
            const validate = validationDate(receitaDate);
            if (validate) {
              handleEdit(index, 'data', receitaDate);
            }
          }
        }
        >
          {receita.data}
        </td>
        <td
          style={{ maxWidth: '120px', overflowWrap: 'break-word'}}
          contentEditable={!disabled}
          suppressContentEditableWarning
          
          onBlur={(e) => {
            const editedText = e.target.textContent;
            if (editedText.length > maxWidthText) {
              const truncatedText = editedText.slice(0, maxWidthText);
              e.target.textContent = truncatedText;
            }
            handleEdit(index, 'descricao', e.target.textContent);
          }}
          onKeyPress={(e) => {
            const editedText = e.target.textContent + e.key;
            if (editedText.length > maxWidthText) {
              e.preventDefault(); // Impede a entrada de mais caracteres
            }
          }}
        >
          {receita.descricao}
        </td>

        <td>{receita.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>

        <td>
          <Input
            type="select"
            value={receita.tipo}
            onChange={(e) => handleEdit(index, 'tipo', e.target.value)}
            disabled={disabled}
          >
            {TIPOS_RECEITAS.map((tipoReceita) => (
              <option
                value={tipoReceita}
                key={`tipoReceita_opt_${tipoReceita}`}
              >
                {tipoReceita}
              </option>
            ))}
          </Input>
        </td>

        <td>
          <CreatableSelect
            isMulti
            isDisabled={disabled}
            options={tagsOpcoes}
            onChange={(newValue) => {
              if (!Array.isArray(itensGlobal)) {
                setTagsLocal(newValue)
                setSelectedTags(newValue)
                return;
              }
              let localTagsValue = [];
              if (Array.isArray(newValue)) {
                localTagsValue = newValue.filter(
                  (tag) => !itensGlobal.some((globalTag) => globalTag.value === tag.value)
                );
              }
              setTagsLocal(localTagsValue)
            }
            }
            value={selectedTags}
            placeholder="Selecione..."
            noOptionsMessage={() => "Crie uma tag!"}
            formatCreateLabel={(t) => (
              <span>
                Criar nova tag <strong>{t}</strong>...
              </span>
            )}
          />
        </td>

        <td>
          <Row>
            <Col sm={10}>
              <Input
                type="select"
                value={receita.patrimonio}
                onChange={(e) => handleEdit(index, 'patrimonio', e.target.value)}
                disabled={disabled}
              >
                <option value="">Selecione</option>
                {patrimoniosOptions.map((p) => (
                  <option value={p.id} key={`patrimonio_${p.id}`}>
                    {p.descricao}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm={2} className="d-flex justify-content-end">
              <BtnModalFormPatrimonio
                mes={mes}
                ano={ano}
                onAdd={() => onUpdatePatrimonios(mes, ano)}
                disabled={disabled}
              />
            </Col>
          </Row>
        </td>

        <td>
          <Button
            size="sm"
            color="light"
            onClick={() => handleDelete(index)}
            disabled={disabled}
          >
            <i className="fas fa-times" />
          </Button>
        </td>
      </tr>
      {disabled && (
        <tr style={{ backgroundColor: 'lightgray', margin: "none" }}>
          <td
            style={{ border: "none" }}
            colSpan="8"
            className="text-center"
            contentEditable={!disabled}
            suppressContentEditableWarning
          >
            <span style={{ color: 'red' }}>
              Já importada anteriormente <t /> 
              <button
                style={{ color: 'blue', cursor: 'pointer', background: 'none', border: 'none', padding: 0, textDecoration: 'underline' }}
                onClick={() => handleEdit(
                  index,
                  'ignoreDuplication',
                  receita.ignoreDuplication ? false : true
                )}
              >
                Ignorar e importar novamente
              </button>
            </span>
          </td>
        </tr>
      )}
    </>
  )
}

export default LinhaReceita;