import React from "react";
import { Badge } from "reactstrap";
import PropTypes from "prop-types";

function BadgeSituacao({ situacao }) {
  if (situacao === "Em análise") {
    return (
      <Badge pill color="light">
        Em análise <i className="far fa-clock" />
      </Badge>
    );
  } else if (situacao === "Aguardando Cliente") {
    return (
      <Badge pill color="warning">
        Aguardando Cliente <i className="far fa-comment-dots" />
      </Badge>
    );
  } else if (situacao === "Finalizado") {
    return (
      <Badge pill color="primary">
        Finalizado <i className="far fa-check-circle" />
      </Badge>
    );
  } else if (situacao === "Cancelado") {
    return (
      <Badge pill color="dark">
        Cancelado <i className="far fa-trash-alt" />
      </Badge>
    );
  } else {
    return null;
  }
}

BadgeSituacao.propTypes = {
  situacao: PropTypes.oneOf([
    "Em análise",
    "Aguardando Cliente",
    "Finalizado",
    "Cancelado",
  ]),
};

export default BadgeSituacao;
