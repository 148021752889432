import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
} from "reactstrap";
import validator from "validator";
import { fromBRL, fromPercent, getAxiosAuth, getTimestamp, getTokenClientId, isPositiveBRL, pad } from "../../utils";
import { withRouter } from "react-router-dom";
import { TIPOS_PATRIMONIOS, TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA } from "../../options";
import { FormErrMsg, Monetary, Percent } from "..";

function BtnModalFormPatrimonio(props) {
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);
  const [descricao, setaDescricao] = useState("");
  const categorias = TIPOS_PATRIMONIOS;
  const [categoria, setaCategoria] = useState("");
  const [valor, setaValor] = useState("");
  const [valorErro, setaValorErro] = useState(null);
  const [porcentagemAcionaria, setaPorcentagemAcionaria] = useState(null);
  const [porcentagemAcionariaErro, setaPorcentagemAcionariaErro] = useState(null);
  const [descricaoErro, setaDescricaoErro] = useState(null);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState(null);
  const [enviarFormulario, setaEnviarFormulario] = useState(null);
  const descricaoEl = useRef(null);

  const verificaPatrimonioExistente = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios?filtroMes=${props.mes}&filtroAno=${props.ano}`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      return;
    }

    if (response && response.data && "patrimonios" in response.data) {
      var some = response.data.patrimonios.some((patrimonio) => {
        return patrimonio.descricao === descricao
      })

      return some;
    }

    return false;
  }

  // --- eventos para o campo Descrição ---
  const validaDescricao = async () => {
    if (validator.isEmpty(descricao, { ignore_whitespace: true })) {
      setaDescricaoErro("Descrição obrigatória.");
      return false;
    }

    if (!validator.isLength(descricao, { min: 3 })) {
      setaDescricaoErro("Descrição muito curta. Mínimo 3 caracteres.");
      return false;
    }

    if (!validator.isLength(descricao, { max: 255 })) {
      setaDescricaoErro("Descrição muito longa. Máximo 255 caracteres.");
      return false;
    }

    if (await verificaPatrimonioExistente()) {
      setaDescricaoErro("Patrimônio já existente");
      return false;
    }

    setaDescricaoErro(false);
    return true;
  };

  const onChangeDescricao = (e) => {
    setaDescricao(e.target.value);
  };

  const onBlurDescricao = () => {
    validaDescricao();
  };

  useEffect(() => {
    if (descricaoErro !== null) {
      validaDescricao();
    }
  }, [descricao, validaDescricao]);

  // --- eventos para o campo Categoria ---
  const onChangeCategoria = (e) => {
    setaCategoria(e.target.value);
  };

  // --- eventos para o campo Valor ---
  const validaValor = () => {
    if (validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaValorErro("Valor obrigatório.");
      return false;
    }

    if (!isPositiveBRL(valor)) {
      setaValorErro("Valor mometário inválido.");
      return false;
    }

    setaValorErro(false);
    return true;
  };

  const onChangeValor = (e) => {
    setaValor(e.target.value);
  };

  const onBlurValor = () => {
    validaValor();
  };

  useEffect(() => {
    if (valorErro !== null) {
      validaValor();
    }
  }, [valor]);

  const validaPorcentagemAcionaria = () => {
    if (categoria !== TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA) {
      return true;
    }

    let valido = true;

    if (
      validator.isEmpty(porcentagemAcionaria, {
        ignore_whitespace: true,
      })
    ) {
      setaPorcentagemAcionariaErro("Campo obrigatório");
      valido = false;
    }

    if (
      valido &&
      !validator.isDecimal(porcentagemAcionaria, { locale: "pt-BR" })
    ) {
      setaPorcentagemAcionariaErro("Porcentagem inválida.");
      valido = false;
    }

    setaPorcentagemAcionariaErro(false);
    return valido;
  };

  const onChangePorcentagemAcionaria = (e) => {
    setaPorcentagemAcionaria(e.target.value);
  };

  const onBlurPorcentagemAcionaria = () => {
    validaPorcentagemAcionaria();
  };

  const limparFormulario = () => {
    // limpando mensagens de erro
    setaDescricaoErro(null);
    setaValorErro(null);
    setaFormularioValido(null);
    setaFormularioErro("");
    setaEnviarFormulario(null);

    // limpando campos
    setaDescricao("");
    setaCategoria([]);
    setaValor("")
  }

  const toggle = (e) => {
    e && e.stopPropagation();
    setaAberto(!aberto);

    if (!aberto) {
      // limpando estados para props de entrada
      limparFormulario();
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    // validando campos
    const descricaoValida = await validaDescricao();
    const valorValido = validaValor();
    let deducoesValidas = true;

    if (descricaoValida && valorValido && deducoesValidas) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos acima.");
      setaFormularioValido(false);
    }
  }

  useEffect(() => {
    const putPatrimonio = async (axiosInstance, dados) => {
      const uri = `/clientes/${clienteId}/patrimonios`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      // fecha modal
      setaAberto(false);

      props.onAdd && props.onAdd(descricao);
    };

    const getPatrimonios = async (axiosInstance) => {
      const uri = `/clientes/${clienteId}/patrimonios`;
      let response;
      let dados = {
        filtroMes: props.mes,
        filtroAno: props.ano,
      };

      try {
        response = await axiosInstance.get(uri, { params: dados });
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        return;
      }

      if (response && response.data && "patrimonios" in response.data) {
        return response.data.patrimonios
      }

      return null;
    };

    const salvaDados = async () => {
      let anoPad = pad(props.ano, 4);
      let mesPad = pad(props.mes, 2);
      let diaPad = pad(1, 2);
      const dataPatrimonio = `${anoPad}-${mesPad}-${diaPad}`;
      // const rendaFixa = `${rendaFixa}`
      const valorLimpo = fromBRL(valor);

      const isParticipacaoAcionaria = categoria === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA
      const porcentagemAcionariaLimpa = isParticipacaoAcionaria
        ? fromPercent(porcentagemAcionaria)
        : null;

      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      var patrimonios = await getPatrimonios(axiosInstance);
      patrimonios = patrimonios.map(patrimonio => ({ ...patrimonio, dataPatrimonio }));
      // preparando dados para enviar
      const dados = {
        dataPatrimonio,
        patrimonios: [
          ...patrimonios,
          {
            id: null,
            dataPatrimonio,
            tipo: categoria,
            descricao,
            valor: valorLimpo,
            valorTotalEmpresa: isParticipacaoAcionaria ? valorLimpo : null,
            porcentagemAcionaria: porcentagemAcionariaLimpa,
          }
        ]
      };

      await putPatrimonio(axiosInstance, dados);
    };

    if (formularioValido) {
      salvaDados();
    }
  }, [enviarFormulario]);

  const trigger =
    <Button
      color="primary"
      className="float-right"
      onClick={toggle}
      disabled={props.disabled}
    >
      <i className="fas fa-plus" />
    </Button>

  return (
    <Fragment>
      {trigger}
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>
          Adicionar Patrimônio
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <FormGroup>
              <Label>Categoria</Label>
              <Input
                type="select"
                value={categoria}
                onChange={onChangeCategoria}
              >
                <option value="">Selecione</option>
                {categorias.map((p) => (
                  <option value={p} key={`categoria_${p}`}>
                    {p}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label>Descrição</Label>
              <Input
                type="text"
                value={descricao}
                onChange={onChangeDescricao}
                onBlur={onBlurDescricao}
                valid={descricaoErro === false}
                invalid={!!descricaoErro}
                maxLength={255}
                innerRef={descricaoEl}
              />
              <FormErrMsg>{descricaoErro}</FormErrMsg>
            </FormGroup>

            <Row>
              <Col md={"auto"}>
                <FormGroup>
                  <Label
                    className="required"
                  >
                    {categoria === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA
                      ? "Valor total da empresa"
                      : "Valor"}
                  </Label>
                  <Monetary
                    value={valor}
                    onChange={onChangeValor}
                    onBlur={onBlurValor}
                    valid={valorErro === false}
                    invalid={!!valorErro}
                  />
                  <FormErrMsg>{valorErro}</FormErrMsg>
                </FormGroup>
              </Col>
              {categoria === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA && (
                <Col md={"auto"}>
                  <FormGroup>
                    <Label className="required">
                      Porcentagem acionária
                    </Label>
                    <Percent
                      value={porcentagemAcionaria}
                      onChange={onChangePorcentagemAcionaria}
                      onBlur={onBlurPorcentagemAcionaria}
                      valid={
                        porcentagemAcionariaErro === false
                      }
                      invalid={!!porcentagemAcionariaErro}
                    />
                    <FormErrMsg>
                      {porcentagemAcionariaErro}
                    </FormErrMsg>
                  </FormGroup>
                </Col>
              )}
            </Row>

            {formularioValido === false && (
              <Alert color="danger">
                Não foi possível salvar patrimonio. {formularioErro}
              </Alert>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmitForm}>
            Salvar
          </Button>
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default withRouter(BtnModalFormPatrimonio);