import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import notasIcone from "../../assets/img/notasIcone.png"
import { withRouter } from "react-router-dom";
import { NotasModal } from "../../components/NotasModal";

function DropdownNotas(props) {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () =>{
    setIsOpen(true);
  }

  return (
    <Dropdown nav className="d-xs-down-none" isOpen={isOpen}  >
      <DropdownToggle nav  onClick={openModal} style={{marginTop:"14px"}}>
          <img alt="Icone de notas" src={notasIcone} style={{width:"20px", heigth:"20px",marginBottom:"15px"}}/>
      </DropdownToggle>
      <NotasModal isOpen={isOpen} closeModal={closeModal} />
    </Dropdown>
  );
}

export default withRouter(DropdownNotas);
