import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import palette from "google-palette";
import moment from "moment";
import "moment/locale/pt-br";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { toBRLNegative, toPercent } from "../../utils";

function GraficoTopDespesasBarras({ topDespesas }) {
  const categorias = Array.from(
    topDespesas
      .reduce((acc, cur) => {
        for (let topDespesa of cur) {
          const { categoria } = topDespesa;
          if (categoria !== "Outras") {
            acc.add(categoria);
          }
        }
        return acc;
      }, new Set())
      .add("Outras") // A categoria "Outras" deve ser a última do gráfico
  );
  const cores = palette("tol-rainbow", categorias.length).map(hex => "#" + hex);
  
  const datasets = categorias.map((categoria, index) => {
    return {
      label: categoria,
      barPercentage: 0.5,
      data: topDespesas.map(topDespesasMes =>
        topDespesasMes
          .filter(topDespesa => topDespesa.categoria === categoria)
          .reduce((acc, cur) => acc + cur.totalDespesas, 0.0)
      ),
      backgroundColor: cores[index]
    };
  });
  
  const graficoDados = {
    labels: Array.from(Array(topDespesas.length).keys()).map(indiceMes =>
      moment()
        .subtract(topDespesas.length - 1 - indiceMes, "month")
        .format("MMMM")
    ),
    datasets
  };
  
  const graficoOpcoes = {
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const { yLabel, index } = tooltipItem;

          const totalMes = data.datasets.reduce(
            (acc, cur) => acc + cur.data[index],
            0.0
          );
          
          let label = data.datasets[tooltipItem.datasetIndex].label || "";
          
          if (label) {
            label += `: `;
            label += toBRLNegative(yLabel);
          }
          
          let percentual;
          if (totalMes > 0.0) {
            percentual = toPercent(yLabel / totalMes);
          }
          if (percentual) {
            label += " (" + percentual + ")";
          }

          return label;
        }
      }
    },
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            callback: function(value) {
              return toBRLNegative(value);
            }
          }
        }
      ]
    }
  };
 
  return (
    <Card>
      <CardBody>
        <CardTitle className="text-center">Despesas</CardTitle>
        <div className="chart-wrapper mt-3 mb-5" style={{ minHeight: 600 }}>
          <Bar data={graficoDados} options={graficoOpcoes} />
        </div>
      </CardBody>
    </Card>
  );
}

GraficoTopDespesasBarras.propTypes = {
  topDespesas: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        categoria: PropTypes.string.isRequired,
        mes: PropTypes.number.isRequired,
        ano: PropTypes.number.isRequired,
        totalDespesas: PropTypes.number.isRequired
      })
    )
  ).isRequired
};

export default GraficoTopDespesasBarras;
