import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Nav,
  NavItem,
  Form,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Alert
} from "reactstrap";
import { MESES } from "../../options";
import moment from "moment";
import "moment/locale/pt-br";
import PropTypes from "prop-types";
import { isDateValid, pad } from "../../utils";

function DateSpanSelector(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [triggerLabel, setTriggerLabel] = useState("Selecione");
  const [mesInicial, setaMesInicial] = useState(props.mesInicial);
  const [anoInicial, setaAnoInicial] = useState(props.anoInicial);
  const [mesFinal, setaMesFinal] = useState(props.mesFinal);
  const [anoFinal, setaAnoFinal] = useState(props.anoFinal);
  const [mensagemErro, setaMensagemErro] = useState();

  const toggleIsOpen = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const optionsMonths = (
    <Fragment>
      <option>Selecione</option>
      {MESES.map((mes, i) => (
        <option value={i + 1} key={`mes_opt_${i}`}>
          {mes}
        </option>
      ))}
    </Fragment>
  );

  const onChangeMesInicial = event => {
    setaMesInicial(event.target.value);
  };

  const onChangeAnoInicial = event => {
    setaAnoInicial(event.target.value);
  };

  const onChangeMesFinal = event => {
    setaMesFinal(event.target.value);
  };

  const onChangeAnoFinal = event => {
    setaAnoFinal(event.target.value);
  };

  const onSubmitForm = event => {
    event.preventDefault();

    let dataInicial = `01/${pad(mesInicial, 2)}/${pad(anoInicial, 4)}`;
    let dataFinal = `01/${pad(mesFinal, 2)}/${pad(anoFinal, 4)}`;

    if (!isDateValid(dataInicial)) {
      setaMensagemErro("Data inicial inválida.");
      return;
    } else if (!isDateValid(dataFinal)) {
      setaMensagemErro("Data final inválida.");
      return;
    } else {
      let momentInicial = moment(dataInicial, "DD/MM/YYYY", true);
      let momentFinal = moment(dataFinal, "DD/MM/YYYY", true);
      if (momentInicial > momentFinal) {
        setaMensagemErro("Intervalo inválido.");
        return;
      }
    }

    if (props.onChange) {
      props.onChange(
        Number(mesInicial),
        Number(anoInicial),
        Number(mesFinal),
        Number(anoFinal)
      );
    }

    setaMensagemErro(null);
    setIsOpen(false);
  };

  const onClickButtonEsteMes = event => {
    event.preventDefault();

    const momentAgora = moment();

    const novoMesInicial = Number(momentAgora.format("M"));
    const novoAnoInicial = Number(momentAgora.format("Y"));
    const novoMesFinal = novoMesInicial;
    const novoAnoFinal = novoAnoInicial;

    if (props.onChange) {
      props.onChange(
        novoMesInicial,
        novoAnoInicial,
        novoMesFinal,
        novoAnoFinal
      );
    }

    setaMensagemErro(null);
    setaMesInicial(novoMesInicial);
    setaAnoInicial(novoAnoInicial);
    setaMesFinal(novoMesFinal);
    setaAnoFinal(novoAnoFinal);
    setIsOpen(false);
  };

  const onClickButtonUltimos3Meses = event => {
    event.preventDefault();

    const momentAgora = moment();
    const moment3Meses = moment().subtract(2, "month");

    const novoMesInicial = Number(moment3Meses.format("M"));
    const novoAnoInicial = Number(moment3Meses.format("Y"));
    const novoMesFinal = Number(momentAgora.format("M"));
    const novoAnoFinal = Number(momentAgora.format("Y"));

    if (props.onChange) {
      props.onChange(
        novoMesInicial,
        novoAnoInicial,
        novoMesFinal,
        novoAnoFinal
      );
    }

    setaMensagemErro(null);
    setaMesInicial(novoMesInicial);
    setaAnoInicial(novoAnoInicial);
    setaMesFinal(novoMesFinal);
    setaAnoFinal(novoAnoFinal);
    setIsOpen(false);
  };

  const onClickButtonUltimos6Meses = event => {
    event.preventDefault();

    const momentAgora = moment();
    const moment6Meses = moment().subtract(5, "month");

    const novoMesInicial = Number(moment6Meses.format("M"));
    const novoAnoInicial = Number(moment6Meses.format("Y"));
    const novoMesFinal = Number(momentAgora.format("M"));
    const novoAnoFinal = Number(momentAgora.format("Y"));

    if (props.onChange) {
      props.onChange(
        novoMesInicial,
        novoAnoInicial,
        novoMesFinal,
        novoAnoFinal
      );
    }

    setaMensagemErro(null);
    setaMesInicial(novoMesInicial);
    setaAnoInicial(novoAnoInicial);
    setaMesFinal(novoMesFinal);
    setaAnoFinal(novoAnoFinal);
    setIsOpen(false);
  };

  const onClickButtonUltimos12Meses = event => {
    event.preventDefault();

    const momentAgora = moment();
    const moment12Meses = moment().subtract(11, "month");

    const novoMesInicial = Number(moment12Meses.format("M"));
    const novoAnoInicial = Number(moment12Meses.format("Y"));
    const novoMesFinal = Number(momentAgora.format("M"));
    const novoAnoFinal = Number(momentAgora.format("Y"));

    if (props.onChange) {
      props.onChange(
        novoMesInicial,
        novoAnoInicial,
        novoMesFinal,
        novoAnoFinal
      );
    }

    setaMensagemErro(null);
    setaMesInicial(novoMesInicial);
    setaAnoInicial(novoAnoInicial);
    setaMesFinal(novoMesFinal);
    setaAnoFinal(novoAnoFinal);
    setIsOpen(false);
  };

  const onClickButtonTodasAsDatas = event => {
    event.preventDefault();

    const novoMesInicial = null;
    const novoAnoInicial = null;
    const novoMesFinal = null;
    const novoAnoFinal = null;

    if (props.onChange) {
      props.onChange(
        novoMesInicial,
        novoAnoInicial,
        novoMesFinal,
        novoAnoFinal
      );
    }

    setaMensagemErro(null);
    setaMesInicial("");
    setaAnoInicial("");
    setaMesFinal("");
    setaAnoFinal("");
    setIsOpen(false);
  };

  useEffect(() => {
    let novoLabel;

    if (props.mesInicial && props.mesFinal) {
      const mesInicialTexto = moment()
        .month(props.mesInicial - 1)
        .format("MMMM");
      const mesFinalTexto = moment()
        .month(props.mesFinal - 1)
        .format("MMMM");

      novoLabel = `${mesInicialTexto} ${props.anoInicial} - ${mesFinalTexto} ${props.anoFinal}`;
    } else {
      novoLabel = "Todas as datas";
    }

    setTriggerLabel(novoLabel);
  }, [props.mesInicial, props.anoInicial, props.mesFinal, props.anoFinal]);

  return (
    <Fragment>
      <Button
        size="md"
        outline
        color="primary"
        className="mt-1"
        onClick={toggleIsOpen}
      >
        {triggerLabel}
      </Button>
      <Modal isOpen={isOpen} toggle={toggleIsOpen} fade>
        <ModalHeader>Selecione um intervalo</ModalHeader>
        <ModalBody>
          <Nav fill>
            <NavItem>
              <Button
                outline
                color="primary"
                size="sm"
                className="text-nowrap mt-2"
                onClick={onClickButtonEsteMes}
              >
                Este mês
              </Button>
            </NavItem>
            <NavItem>
              <Button
                outline
                color="primary"
                size="sm"
                className="text-nowrap mt-2"
                onClick={onClickButtonUltimos3Meses}
              >
                3 meses
              </Button>
            </NavItem>
            <NavItem>
              <Button
                outline
                color="primary"
                size="sm"
                className="text-nowrap mt-2"
                onClick={onClickButtonUltimos6Meses}
              >
                6 meses
              </Button>
            </NavItem>
            <NavItem>
              <Button
                outline
                color="primary"
                size="sm"
                className="text-nowrap mt-2"
                onClick={onClickButtonUltimos12Meses}
              >
                12 meses
              </Button>
            </NavItem>
            <NavItem>
              <Button
                outline
                color="primary"
                size="sm"
                className="text-nowrap mt-2"
                onClick={onClickButtonTodasAsDatas}
              >
                Todas as datas
              </Button>
            </NavItem>
          </Nav>
          <hr />
          <Form onSubmit={onSubmitForm}>
            {mensagemErro && <Alert color="danger">{mensagemErro}</Alert>}
            <Row form className="mb-2">
              <Col xs={2} className="text-center">
                <Label>De:</Label>
              </Col>
              <Col xs={6}>
                <Input
                  type="select"
                  value={mesInicial}
                  onChange={onChangeMesInicial}
                >
                  {optionsMonths}
                </Input>
              </Col>
              <Col xs={4}>
                <Input
                  type="text"
                  value={anoInicial}
                  onChange={onChangeAnoInicial}
                />
              </Col>
            </Row>
            <Row form className="mb-2">
              <Col xs={2} className="text-center">
                <Label>Até:</Label>
              </Col>
              <Col xs={6}>
                <Input
                  type="select"
                  value={mesFinal}
                  onChange={onChangeMesFinal}
                >
                  {optionsMonths}
                </Input>
              </Col>
              <Col xs={4}>
                <Input
                  type="text"
                  value={anoFinal}
                  onChange={onChangeAnoFinal}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmitForm}>
            Selecionar intervalo
          </Button>
          <Button color="link" onClick={toggleIsOpen}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

DateSpanSelector.propTypes = {
  mesInicial: PropTypes.number,
  anoInicial: PropTypes.number,
  mesFinal: PropTypes.number,
  anoFinal: PropTypes.number,
  onChange: PropTypes.func
};

export default DateSpanSelector;
