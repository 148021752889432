import React, { Component } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Progress,
} from "reactstrap";
import { closeClientSection, getAxiosAuth, getClienteId, isAdmin, logout } from "../../utils";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const propTypes = {
  progress: PropTypes.bool,
  accnt: PropTypes.bool,
};

const defaultProps = {
  progress: false,
  accnt: false,
};

class DefaultHeaderDropdown extends Component {
  state = {
    dropdownOpen: false,
    avatar: "/assets/img/user-circle.svg",
    nome: ""
  };

  componentDidMount() {
    if (this.props.accnt) {
      const clienteId = getClienteId();
      const uri = `/clientes/${clienteId}`;
      const axiosInstance = getAxiosAuth();

      axiosInstance.get(uri)
        .then(response => {
          let avatar = this.state.avatar;
          if (response.data.avatar) {
            avatar = `data:image/jpeg;base64,${response.data.avatar}`;
          }

          const nome = response.data.nomeCompleto;
          this.setState({ avatar, nome })
        })
        .catch(err => {
          if (!err.response) {
            this.props.history.push("/500");
            return;
          }
        });
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  onClickLogout = (e) => {
    e.preventDefault();
    logout();

    if (isAdmin()) closeClientSection(true);
    else if (this.props.history) this.props.history.push("/");
  };

  dropAccnt = () => (
    <Dropdown
      nav
      className="d-xs-down-none"
      isOpen={this.state.dropdownOpen}
      toggle={this.toggle}
    >
      <DropdownToggle nav>
        <img
          src={this.state.avatar}
          className="img-avatar"
          style={{ height: 40, width: 40, objectFit: 'cover' }}
          alt={this.state.nome}
        />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg">
        <DropdownItem header tag="div" className="text-center">
          <strong>Configurações</strong>
        </DropdownItem>
        <DropdownItem
          onClick={() => this.props.history.push("/configuracoes/acesso")}
        >
          <i className="fa fa-user" /> Dados de Acesso
        </DropdownItem>
        <DropdownItem
          onClick={() =>
            this.props.history.push("/planejamento-financeiro/relatorio-mensal")
          }
        >
          <i className="far fa-file-pdf" /> Relatório Mensal
        </DropdownItem>
        <DropdownItem
          onClick={() => this.props.history.push("/configuracoes/tags")}
        >
          <i className="fas fa-tags" /> Gerenciar Tags
        </DropdownItem>
        <DropdownItem onClick={this.onClickLogout}>
          <i className="fas fa-lock" /> Sair
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

  dropProgress = () => (
    <Dropdown
      nav
      className="d-md-down-none"
      isOpen={this.state.dropdownOpen}
      toggle={this.toggle}
    >
      <DropdownToggle nav>
        <i className="fas fa-tasks" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg">
        <DropdownItem header tag="div" className="text-center">
          <strong>Preenchimento dos dados</strong>
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Dados pessoais{" "}
            <span className="float-right">
              <strong>60%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="info" value={60} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Organize{" "}
            <span className="float-right">
              <strong>85%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="success" value={85} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Equilibre{" "}
            <span className="float-right">
              <strong>30%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="warning" value={30} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Planeje{" "}
            <span className="float-right">
              <strong>95%</strong>
            </span>
          </div>
          <Progress className="progress-xs" value={95} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Proteja{" "}
            <span className="float-right">
              <strong>5%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="danger" value={5} />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

  render() {
    const { progress, accnt } = this.props;
    return progress ? this.dropProgress() : accnt ? this.dropAccnt() : null;
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default withRouter(DefaultHeaderDropdown);
