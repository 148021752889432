import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert,
  FormFeedback,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { getAxios } from "../../../utils";
import validator from "validator";
import { withRouter, Link } from "react-router-dom";

function EsqueciMinhaSenha(props) {
  const [email, setaEmail] = useState("");
  const [emailErro, setaEmailErro] = useState(null);
  const [enviar, setaEnviar] = useState(null);
  const [enviado, setaEnviado] = useState(false);

  // --- eventos para o formulário ---
  const onSubmitForm = (e) => {
    e.preventDefault();

    const emailValido = validaEmail();

    if (emailValido) {
      setaEnviar(true);
    }
  };

  useEffect(() => {
    const postDados = async () => {
      const dados = {
        email,
      };

      const axiosInstance = getAxios();
      const uri = "/recuperar-senha";
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      if (response.status === 500) {
        props.history.push("/500");
        return;
      } else {
        setaEnviado(true);
      }
    };

    if (emailErro === false) {
      postDados();
    }
  }, [enviar]);

  // --- eventos para campo Email ---
  const validaEmail = () => {
    if (validator.isEmpty(email, { ignore_whitespace: true })) {
      setaEmailErro("Email obrigatório.");
      return false;
    }

    if (!validator.isEmail(email)) {
      setaEmailErro("Email inválido.");
      return false;
    }

    setaEmailErro(false);
    return true;
  };

  const onChangeEmail = (e) => {
    setaEmail(e.target.value);
  };

  useEffect(() => {
    if (emailErro !== null) {
      validaEmail();
    }
  }, [email]);

  const onBlurEmail = () => {
    validaEmail();
  };

  return (
    <div className="app flex-row align-items-center login-bg">
      <Container>
        <Row className="justify-content-center animated fadeIn">
          <Col md={6}>
            <Card className="mx-4">
              <CardBody className="p-4">
                <Form onSubmit={onSubmitForm}>
                  <h1>Esqueci minha senha</h1>
                  <p className="text-muted">Redefina a sua senha</p>

                  {!enviado && (
                    <Fragment>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          autoComplete="off"
                          value={email}
                          onChange={onChangeEmail}
                          onBlur={onBlurEmail}
                          valid={emailErro === false}
                          invalid={!!emailErro}
                        />
                        {emailErro && <FormFeedback>{emailErro}</FormFeedback>}
                      </InputGroup>
                      <Row>
                        <Col xs={6}>
                          <Button color="primary" className="mr-2">
                            Continuar
                          </Button>
                        </Col>
                        <Col xs={6} className="text-right">
                          <Link to="/">Cancelar</Link>
                        </Col>
                      </Row>
                    </Fragment>
                  )}

                  {enviado && (
                    <Fragment>
                      <Alert color="success">
                        <i className="far fa-check-circle"></i>
                        &nbsp; Enviamos um email para <strong>
                          {email}
                        </strong>{" "}
                        com um link para redefinir sua senha.
                        <br />
                        <br />
                        Acesse o seu email para continuar.
                      </Alert>
                      <Row>
                        <Col xs={12} className="text-center">
                          <Link to="/">Voltar para o login</Link>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(EsqueciMinhaSenha);
