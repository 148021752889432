import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.scss";
import { logout } from "./utils";

// Containers
import { DefaultLayout } from "./containers";
// Páginas
import {
  Login,
  Registrar,
  Termos,
  Page404,
  Page500,
  EsqueciMinhaSenha,
  RedefinirSenha
} from "./views/Pages";

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/404" name="Página 404" component={Page404} />
          <Route exact path="/500" name="Página 500" component={Page500} />
          <Route path="/login" name="Login" component={Login} />
          <Route path="/registrar" name="Registrar" component={Registrar} />
          <Route
            path="/termos-de-uso-e-politica-de-privacidade"
            name="Termos de Uso e Política de Privacidade"
            component={Termos}
          />
          <Route
            path="/esqueci-minha-senha"
            name="Esqueci minha senha"
            component={EsqueciMinhaSenha}
          />
          <Route
            path="/redefinir-senha/:token"
            name="Redefinir senha"
            component={RedefinirSenha}
          />
          <Route
            path="/sair"
            name="Sair"
            render={() => {
              logout();
              return <Redirect to="/" />;
            }}
          />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
