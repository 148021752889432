import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { toBRL, toPercent } from "../../utils";

function GraficoRosca(props) {
  const { legendas, dados, cores, titulo, total, hoverCb, clickCb } = props;
  const [plugins, setaPlugins] = useState();
  const [redraw, setaRedraw] = useState(false);

  const doughnut = {
    labels: legendas,
    datasets: [
      {
        data: dados,
        backgroundColor: cores,
        borderWidth: 0,
        hoverBorderWidth: 5,
        hoverBorderColor: cores,
        hoverBackgroundColor: cores,
      },
    ],
  };

  const opts = {
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#F0F0F0",
      bodyFontColor: "#000000",
      titleFontColor: "#000000",
      displayColors: false,
      callbacks: {
        title: function (attrs, data) {
          if (attrs && attrs.length >= 1) {
            let index = attrs[0].index;

            const label = data.labels[index];
            return label;
          }
        },
        label: function (tooltipItem, data) {
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const percent = value / total;
          return [toBRL(value), toPercent(percent)];
        },
      },
    },
    onHover: function (e, attrs) {
      let index;
      if (attrs && attrs.length >= 1) {
        index = attrs[0]._index;
        if (clickCb) {
          e.target.style.cursor = "pointer";
        } else {
          e.target.style.cursor = "default";
        }
      } else {
        e.target.style.cursor = "default";
      }

      hoverCb && hoverCb(index);
    },
    onClick: function (e, attrs) {
      let index;
      if (attrs && attrs.length >= 1) {
        index = attrs[0]._index;
      }

      clickCb && clickCb(index);
    },
  };

  useEffect(() => {
    setaPlugins([
      {
        afterDraw: (chart) => {
          const { width, height, ctx } = chart.chart;
          const fontSize = (height / 350).toFixed(2);
          const fontSizePt = fontSize * 12;
          ctx.font = fontSize + "em Lato, sans-serif";
          ctx.textBaseline = "middle";

          let text, x, y, mid;

          text = titulo;
          x = Math.round((width - ctx.measureText(text).width) / 2);
          y = height / 2 - fontSizePt;
          ctx.fillStyle = "#000000";
          ctx.fillText(text, x, y);

          mid = x + ctx.measureText(text).width / 2;
          text = toBRL(total);
          y = height / 2 + fontSizePt;
          x = Math.round(mid - ctx.measureText(text).width / 2);
          ctx.font = "bold " + ctx.font;
          ctx.fillText(text, x, y);
        },
        afterInit: () => {
          setaRedraw(false);
        },
      },
    ]);
    setaRedraw(true);
  }, [titulo, total]);

  return (
    <div className="animated fadeIn" style={{ minHeight: 350 }}>
      <Doughnut
        data={doughnut}
        options={opts}
        plugins={plugins}
        redraw={redraw}
      />
    </div>
  );
}

export default GraficoRosca;
