import React, { Fragment, useState, useEffect } from "react";
import { Col, FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { FormErrMsg } from "../../../components";

function HistoricoSaudeCampos(props) {
  const [usaMedicamentos, setaUsaMedicamentos] = useState();
  const [fezCirurgia, setaFezCirurgia] = useState();
  const [fazTratamentoDepressao, setaFazTratamentoDepressao] = useState();
  const [temDiabetes, setaTemDiabetes] = useState();
  const [familiaTeveCancer, setaFamiliaTeveCancer] = useState();
  const [praticaEsportesRadicais, setaPraticaEsportesRadicais] = useState();
  const [usaOculos, setaUsaOculos] = useState();

  const { conjuge } = props;

  useEffect(() => {
    setaUsaMedicamentos(props.usaMedicamentos);
    setaFezCirurgia(props.fezCirurgia);
    setaFazTratamentoDepressao(props.fazTratamentoDepressao);
    setaTemDiabetes(props.temDiabetes);
    setaFamiliaTeveCancer(props.familiaTeveCancer);
    setaPraticaEsportesRadicais(props.praticaEsportesRadicais);
    setaUsaOculos(props.usaOculos);
  }, [
    props.usaMedicamentos,
    props.fezCirurgia,
    props.fazTratamentoDepressao,
    props.temDiabetes,
    props.familiaTeveCancer,
    props.praticaEsportesRadicais,
    props.usaOculos
  ]);

  return (
    <Fragment>
      <FormGroup>
        <Label>Faz uso de medicamentos?</Label>
        <FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`uso_medicamentos` + (conjuge ? `_conjuge` : ``)}
              value={true}
              checked={props.usaMedicamentos === true}
              onChange={e => {
                setaUsaMedicamentos(e.target.checked);
                props.onChangeUsaMedicamentos &&
                  props.onChangeUsaMedicamentos(e);
              }}
            />
            <Label check>Sim</Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`uso_medicamentos` + (conjuge ? `_conjuge` : ``)}
              value={false}
              checked={props.usaMedicamentos === false}
              onChange={e => {
                setaUsaMedicamentos(!e.target.checked);
                props.onChangeUsaMedicamentos &&
                  props.onChangeUsaMedicamentos(e);
              }}
            />
            <Label check>Não</Label>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      {usaMedicamentos && (
        <FormGroup>
          <Label className="required">Quais?</Label>
          <Input
            type="text"
            placeholder="Nome dos medicamentos"
            value={props.descrevaMedicamentos}
            valid={props.descrevaMedicamentosErro === false}
            invalid={!!props.descrevaMedicamentosErro}
            onChange={props.onChangeDescrevaMedicamentos}
            onBlur={props.onBlurDescrevaMedicamentos}
          />
          <FormErrMsg>{props.descrevaMedicamentosErro}</FormErrMsg>
        </FormGroup>
      )}
      <FormGroup>
        <Label>Foi diagnosticado e/ou submetido a tratamento/cirurgia?</Label>
        <FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`cirurgia` + (conjuge ? `_conjuge` : ``)}
              value={true}
              checked={props.fezCirurgia === true}
              onChange={e => {
                setaFezCirurgia(e.target.checked);
                props.onChangeFezCirurgia && props.onChangeFezCirurgia(e);
              }}
            />
            <Label check>Sim</Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`cirurgia` + (conjuge ? `_conjuge` : ``)}
              value={false}
              checked={props.fezCirurgia === false}
              onChange={e => {
                setaFezCirurgia(!e.target.checked);
                props.onChangeFezCirurgia && props.onChangeFezCirurgia(e);
              }}
            />
            <Label check>Não</Label>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      {fezCirurgia && (
        <FormGroup>
          <Label className="required">Quais?</Label>
          <Input
            type="text"
            placeholder="Descreva o tratamentos e/ou as cirurgias"
            value={props.descrevaCirurgia}
            valid={props.descrevaCirurgiaErro === false}
            invalid={!!props.descrevaCirurgiaErro}
            onChange={props.onChangeDescrevaCirurgia}
            onBlur={props.onBlurDescrevaCirurgia}
          />
          <FormErrMsg>{props.descrevaCirurgiaErro}</FormErrMsg>
        </FormGroup>
      )}
      <FormGroup>
        <Label>Faz tratamento de ansiedade, bipolaridade e/ou depressão?</Label>
        <FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`depressao` + (conjuge ? `_conjuge` : ``)}
              value={true}
              checked={props.temDepressao === true}
              onChange={e => {
                setaFazTratamentoDepressao(e.target.checked);
                props.onChangeTemDepressao && props.onChangeTemDepressao(e);
              }}
            />
            <Label check>Sim</Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`depressao` + (conjuge ? `_conjuge` : ``)}
              value={false}
              checked={props.temDepressao === false}
              onChange={e => {
                setaFazTratamentoDepressao(!e.target.checked);
                props.onChangeTemDepressao && props.onChangeTemDepressao(e);
              }}
            />
            <Label check>Não</Label>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      {fazTratamentoDepressao && (
        <FormGroup>
          <Label className="required">O quê?</Label>
          <Input
            type="text"
            placeholder="Descreva os tratamentos que realiza"
            value={props.descrevaDepressao}
            valid={props.descrevaDepressaoErro === false}
            invalid={!!props.descrevaDepressaoErro}
            onChange={props.onChangeDescrevaDepressao}
            onBlur={props.onBlurDescrevaDepressao}
          />
          <FormErrMsg>{props.descrevaDepressaoErro}</FormErrMsg>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          Tem diabetes, pressão alta, colesterol alto e/ou hepatite?
        </Label>
        <FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`diabetes` + (conjuge ? `_conjuge` : ``)}
              value={true}
              checked={props.temDiabetes === true}
              onChange={e => {
                setaTemDiabetes(e.target.checked);
                props.onChangeTemDiabetes && props.onChangeTemDiabetes(e);
              }}
            />
            <Label check>Sim</Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`diabetes` + (conjuge ? `_conjuge` : ``)}
              value={false}
              checked={props.temDiabetes === false}
              onChange={e => {
                setaTemDiabetes(!e.target.checked);
                props.onChangeTemDiabetes && props.onChangeTemDiabetes(e);
              }}
            />
            <Label check>Não</Label>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      {temDiabetes && (
        <FormGroup>
          <Label className="required">O quê?</Label>
          <Input
            type="text"
            placeholder="Descreva"
            value={props.descrevaDiabetes}
            valid={props.descrevaDiabetesErro === false}
            invalid={!!props.descrevaDiabetesErro}
            onChange={props.onChangeDescrevaDiabetes}
            onBlur={props.onBlurDescrevaDiabetes}
          />
          <FormErrMsg>{props.descrevaDiabetesErro}</FormErrMsg>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          Alguém da família tem/teve câncer, infarto, AVC, marcapasso,
          Alzheimer?
        </Label>
        <FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`cancer` + (conjuge ? `_conjuge` : ``)}
              value={true}
              checked={props.temFamiliarCancer === true}
              onChange={e => {
                setaFamiliaTeveCancer(e.target.checked);
                props.onChangeTemFamiliarCancer &&
                  props.onChangeTemFamiliarCancer(e);
              }}
            />
            <Label check>Sim</Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`cancer` + (conjuge ? `_conjuge` : ``)}
              value={false}
              checked={props.temFamiliarCancer === false}
              onChange={e => {
                setaFamiliaTeveCancer(!e.target.checked);
                props.onChangeTemFamiliarCancer &&
                  props.onChangeTemFamiliarCancer(e);
              }}
            />
            <Label check>Não</Label>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      {familiaTeveCancer && (
        <FormGroup>
          <Label className="required">Quem?</Label>
          <Input
            type="text"
            placeholder="Descreva"
            value={props.descrevaFamiliarCancer}
            valid={props.descrevaFamiliarCancerErro === false}
            invalid={!!props.descrevaFamiliarCancerErro}
            onChange={props.onChangeDescrevaFamiliarCancer}
            onBlur={props.onBlurDescrevaFamiliarCancer}
          />
          <FormErrMsg>{props.descrevaFamiliarCancerErro}</FormErrMsg>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          Pratica atividades radicais como: mergulho, paraquedismo, aviação,
          motociclismo, automobilismo, outros?
        </Label>
        <FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`esporte` + (conjuge ? `_conjuge` : ``)}
              value={true}
              checked={props.praticaEsportesRadicais === true}
              onChange={e => {
                setaPraticaEsportesRadicais(e.target.checked);
                props.onChangePraticaEsportesRadicais &&
                  props.onChangePraticaEsportesRadicais(e);
              }}
            />
            <Label check>Sim</Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`esporte` + (conjuge ? `_conjuge` : ``)}
              value={false}
              checked={props.praticaEsportesRadicais === false}
              onChange={e => {
                setaPraticaEsportesRadicais(!e.target.checked);
                props.onChangePraticaEsportesRadicais &&
                  props.onChangePraticaEsportesRadicais(e);
              }}
            />
            <Label check>Não</Label>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      {praticaEsportesRadicais && (
        <FormGroup>
          <Label className="required">Quais?</Label>
          <Input
            type="text"
            placeholder="Descreva"
            value={props.descrevaEsportesRadicais}
            valid={props.descrevaEsportesRadicaisErro === false}
            invalid={!!props.descrevaEsportesRadicaisErro}
            onChange={props.onChangeDescrevaEsportesRadicais}
            onBlur={props.onBlurDescrevaEsportesRadicais}
          />
          <FormErrMsg>{props.descrevaEsportesRadicaisErro}</FormErrMsg>
        </FormGroup>
      )}
      <FormGroup>
        <Label>Faz uso de óculos e/ou lentes de contato?</Label>
        <FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`oculos` + (conjuge ? `_conjuge` : ``)}
              value={true}
              checked={props.usaOculos === true}
              onChange={e => {
                setaUsaOculos(e.target.checked);
                props.onChangeUsaOculos && props.onChangeUsaOculos(e);
              }}
            />
            <Label check>Sim</Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              name={`oculos` + (conjuge ? `_conjuge` : ``)}
              value={false}
              checked={props.usaOculos === false}
              onChange={e => {
                setaUsaOculos(!e.target.checked);
                props.onChangeUsaOculos && props.onChangeUsaOculos(e);
              }}
            />
            <Label check>Não</Label>
          </FormGroup>
        </FormGroup>
        {usaOculos && (
          <FormGroup>
            <Label className="required">Qual grau?</Label>
            <Input
              type="text"
              placeholder="Descreva"
              value={props.descrevaOculos}
              valid={props.descrevaOculosErro === false}
              invalid={!!props.descrevaOculosErro}
              onChange={props.onChangeDescrevaOculos}
              onBlur={props.onBlurDescrevaOculos}
            />
            <FormErrMsg>{props.descrevaOculosErro}</FormErrMsg>
          </FormGroup>
        )}
        <FormGroup row className="my-0">
          <Col md={`auto`}>
            <FormGroup style={{ maxWidth: 120 }}>
              <Label htmlFor="peso">Peso</Label>
              <Input
                type="text"
                id="peso"
                className="text-right"
                value={props.peso}
                valid={props.pesoErro === false}
                invalid={!!props.pesoErro}
                onChange={props.onChangePeso}
                onBlur={props.onBlurPeso}
              />
              <FormErrMsg>{props.pesoErro}</FormErrMsg>
            </FormGroup>
          </Col>
          <Col md={`auto`}>
            <FormGroup style={{ maxWidth: 120 }}>
              <Label htmlFor="altura">Altura</Label>
              <Input
                type="text"
                id="altura"
                className="text-right"
                value={props.altura}
                valid={props.alturaErro === false}
                invalid={!!props.alturaErro}
                onChange={props.onChangeAltura}
                onBlur={props.onBlurAltura}
              />
              <FormErrMsg>{props.alturaErro}</FormErrMsg>
            </FormGroup>
          </Col>
          <Col md={`auto`} className="d-flex align-items-center">
            <FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="fumante"
                  value={true}
                  checked={props.fumante === true}
                  onChange={props.onChangeFumante}
                />
                <Label check htmlFor="fumante">
                  Fumante
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>
      </FormGroup>
    </Fragment>
  );
}

HistoricoSaudeCampos.propTypes = {
  conjuge: PropTypes.bool,

  usaMedicamentos: PropTypes.bool,
  onChangeUsaMedicamentos: PropTypes.func,
  descrevaMedicamentos: PropTypes.string,
  descrevaMedicamentosErro: PropTypes.any,
  onChangeDescrevaMedicamentos: PropTypes.func,
  onBlurDescrevaMedicamentos: PropTypes.func,

  fezCirurgia: PropTypes.bool,
  onChangeFezCirurgia: PropTypes.func,
  descrevaCirurgia: PropTypes.string,
  descrevaCirurgiaErro: PropTypes.any,
  onChangeDescrevaCirurgia: PropTypes.func,
  onBlurDescrevaCirurgia: PropTypes.func,

  temDepressao: PropTypes.bool,
  onChangeTemDepressao: PropTypes.func,
  descrevaDepressao: PropTypes.string,
  descrevaDepressaoErro: PropTypes.any,
  onChangeDescrevaDepressao: PropTypes.func,
  onBlurDescrevaDepressao: PropTypes.func,

  temDiabetes: PropTypes.bool,
  onChangeTemDiabetes: PropTypes.func,
  descrevaDiabetes: PropTypes.string,
  descrevaDiabetesErro: PropTypes.any,
  onChangeDescrevaDiabetes: PropTypes.func,
  onBlurDescrevaDiabetes: PropTypes.func,

  temFamiliarCancer: PropTypes.bool,
  onChangeTemFamiliarCancer: PropTypes.func,
  descrevaFamiliarCancer: PropTypes.string,
  descrevaFamiliarCancerErro: PropTypes.any,
  onChangeDescrevaFamiliarCancer: PropTypes.func,
  onBlurDescrevaFamiliarCancer: PropTypes.func,

  praticaEsportesRadicais: PropTypes.bool,
  onChangePraticaEsportesRadicais: PropTypes.func,
  descrevaEsportesRadicais: PropTypes.string,
  descrevaEsportesRadicaisErro: PropTypes.any,
  onChangeDescrevaEsportesRadicais: PropTypes.func,
  onBlurDescrevaEsportesRadicais: PropTypes.func,

  usaOculos: PropTypes.bool,
  onChangeUsaOculos: PropTypes.func,
  descrevaOculos: PropTypes.string,
  descrevaOculosErro: PropTypes.any,
  onChangeDescrevaOculos: PropTypes.func,
  onBlurDescrevaOculos: PropTypes.func,

  peso: PropTypes.string,
  pesoErro: PropTypes.any,
  onChangePeso: PropTypes.func,
  onBlurPeso: PropTypes.func,

  altura: PropTypes.string,
  alturaErro: PropTypes.any,
  onChangeAltura: PropTypes.func,
  onBlurAltura: PropTypes.func,

  fumante: PropTypes.bool,
  onChangeFumante: PropTypes.func
};

export default HistoricoSaudeCampos;
