import React from "react";
import moment from "moment";
import axios, { CancelToken } from "axios";
import decode from "jwt-decode";
import isCurrency from "validator/lib/isCurrency";
import jwtDecode from "jwt-decode";


export function getTimestamp() {
  return Math.floor(Date.now() / 1000);
}

export function toPercent(value, opts = {}) {
  if (isNaN(value)) {
    value = 0;
  }

  return Number(value).toLocaleString("pt-BR", {
    style: "percent",
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    ...opts,
  });
}

export function toDecimal(value) {
  return Number(value).toLocaleString("pt-BR");
}

export function toPercentColor(value) {
  if (value < 0) {
    return <span className="text-danger">{toPercent(value)}</span>;
  } else {
    return toPercent(value);
  }
}

export function toBRL(value) {
  if (value !== 0 && !value) {
    value = 0;
  }

  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function isBRL(value, opts = {}) {
  return isCurrency(value, {
    thousands_separator: ".",
    decimal_separator: ",",
    ...opts,
  });
}

export function isPositiveBRL(value) {
  return isBRL(value, { allow_negatives: false });
}

export function fromBRL(value) {
  return parseFloat(value.replace(new RegExp(/\./, "g"), "").replace(",", "."));
}

export function fromPercent(value) {
  if (value) {
    return parseFloat(value.replace(",", "."));
  }
}

export function fromDecimal(value) {
  return fromPercent(value);
}

export function toBRLNegative(value) {
  if (value < 0) {
    return `(` + toBRL(value * -1) + `)`;
  } else {
    return toBRL(value);
  }
}

export function toBRLColor(value) {
  if (value <= -1) {
    return <span className="text-danger">{toBRLNegative(value)}</span>;
  } else if (value === 0) {
    // trata -0 e +0
    return toBRL(0);
  } else {
    return toBRL(value);
  }
}

export function toBRLValueOnly(value) {
  value = toBRL(value);

  return value.replace(new RegExp(/R\$\s/, "g"), "");
}

export function toISO8601(value) {
  const m = moment(value, "DD/MM/YYYY", true);
  if (m.isValid()) {
    return m.format("YYYY-MM-DD");
  }
}

export function fromISO8601(value) {
  const m = moment(value, "YYYY-MM-DD", true);
  if (m.isValid()) {
    return m.format("DD/MM/YYYY");
  }
}

export function setAccessToken(token) {
  if (token) {
    delete_cookie("cliente_accessToken");
    localStorage.setItem("cliente_accessToken", token);
  }
}

export function getAccessToken() {
  // Primeiro, tenta obter o valor do cookie
  let accessToken = getCookie("cliente_accessToken");

  // Se não estiver no cookie, tenta obter do localStorage
  if (!accessToken || isTokenExpired(accessToken)) {
    accessToken = localStorage.getItem("cliente_accessToken");
  }

  return accessToken;
}

export function setRefreshToken(token) {
  if (token) {
    delete_cookie("cliente_refreshToken");
    localStorage.setItem("cliente_refreshToken", token);
  }
}

export function getRefreshToken() {
  // Primeiro, tenta obter o valor do cookie
  let refreshToken = getCookie("cliente_refreshToken");

  // Se não estiver no cookie, tenta obter do localStorage
  if (!refreshToken || isTokenExpired(refreshToken)) {
    refreshToken = localStorage.getItem("cliente_refreshToken");
  }

  return refreshToken;
}

export function setClienteId(clienteId) {
  if (clienteId) {
    delete_cookie("cliente_id");
    localStorage.setItem("cliente_id", clienteId);
  }
}

export function getCookie(name) {
  // Cria uma expressão regular para buscar o cookie desejado
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  // Retorna null se o cookie não for encontrado
  return null;
}

export function isAdmin() {
  try {
    const token = getAccessToken();
    const decoded = jwtDecode(token);
    return decoded.adm;
  } catch (error) {
    return false;
  }
}

export function getClienteId() {
  // Primeiro, tenta obter o valor do cookie
  let clienteId = getCookie("cliente_id");
  // Se não estiver no cookie, tenta obter do localStorage
  if (!clienteId) {
    clienteId = localStorage.getItem("cliente_id");
  }

  return clienteId;
}

function delete_cookie(name) {
  document.cookie = name + "=" +
    ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
}

export function logout() {
  localStorage.removeItem("cliente_accessToken");
  localStorage.removeItem("cliente_refreshToken");
  localStorage.removeItem("cliente_id");
}

export function closeClientSection(refresh = false) {
  let url = ADM_FRONTEND_URL + "/close_client_section";
  if (refresh) url += "?return=true";
  window.location.href = url;
}

export function isLoggedIn() {
  const token = getAccessToken();
  return !!token && !isTokenExpired(token);
}

export function hasValidToken() {
  const refreshToken = getRefreshToken();
  return isLoggedIn() || (!!refreshToken && !isTokenExpired(refreshToken));
}

export function isTokenExpired(token) {
  if (!token) {
    return true;
  }

  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true; // token expired
    } else {
      return false;
    }
  } catch (err) {
    console.error("[x] Não pude decodificar o token:", err.message);
    return false;
  }
}

export const SERVICE_URL =
  process.env.REACT_APP_SERVICE_URL ||
  (process.env.NODE_ENV === "production"
    ? "/api/"
    : "http://localhost:3002/api/");

export const ADM_FRONTEND_URL = "https://adm.sfl.money/";

export function getAxios(headers = {}, timeout = 8000) {
  return axios.create({
    baseURL: SERVICE_URL,
    timeout: timeout,
    headers,
  });
}

export function getAxiosAuth(onTokensExpired, customHeaders = {}) {
  const token = getAccessToken();

  const headers = {
    "x-access-token": token,
    ...customHeaders,
  };

  const instance = getAxios(headers);

  instance.interceptors.request.use(async function (config) {
    if (isLoggedIn()) {
      return config;
    }

    // se o access token não expirou:

    const token = getRefreshToken();

    if (isTokenExpired(token)) {
      // se até o refresh token expirou, então desloga
      // e cancela a requisição
      logout();

      onTokensExpired && onTokensExpired();
      return {
        ...config,
        cancelToken: new CancelToken((cancel) => cancel()),
      };
    }

    // caso contrário, tenta obter novo access token usando o refresh token
    const headers = {
      "x-refresh-token": token,
    };
    const refresher = getAxios(headers);
    let response;

    try {
      response = await refresher.put("/sessao");
    } catch (err) {
      logout();
      console.error(err.message);
      onTokensExpired && onTokensExpired();
      return {
        ...config,
        cancelToken: new CancelToken((cancel) => cancel()),
      };
    }

    setAccessToken(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);

    config.headers["x-access-token"] = response.data.accessToken;

    return config;
  });

  return instance;
}

export function wasRequestCancelled(err) {
  return axios.isCancel(err);
}

export function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function isDateValid(date) {
  return moment(date, "DD/MM/YYYY", true).isValid();
}

export function getTokenClientId() {
  return getClienteId();
}

export function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  return (
    (-fv - pv * Math.pow(1 + ir, np)) /
    (1 + ir * type) /
    ((Math.pow(1 + ir, np) - 1) / ir)
  );
}

export function PGTO(Taxa, NPer, VP, VF, Tipo) {
  return PMT(Taxa, NPer, VP, VF, Tipo);
}

export function _NPER(rate, payment, present, future, type) {
  // Initialize type
  type = typeof type === "undefined" ? 0 : type;

  // Initialize future value
  future = typeof future === "undefined" ? 0 : future;

  // Evaluate rate and periods (TODO: replace with secure expression evaluator)
  // rate = eval(rate);

  // Return number of periods
  var num = payment * (1 + rate * type) - future * rate;
  var den = present * rate + payment * (1 + rate * type);
  return Math.log(num / den) / Math.log(1 + rate);
}

export function NPER(Taxa, Pmt, VP, VF, Tipo) {
  return _NPER(Taxa, Pmt, VP, VF, Tipo);
}

export function PV(Rate, NPer, Pmt) {
  return (Pmt / Rate) * (1 - Math.pow(1 + Rate, -NPer));
}

export function VA(Taxa, NPer, Pagto) {
  return PV(Taxa, NPer, Pagto);
}

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (String(resultado) !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (String(resultado) !== digitos.charAt(1)) return false;

  return true;
}
