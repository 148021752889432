import React  from "react";
import "moment/locale/pt-br";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Bar } from "react-chartjs-2";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { toBRL } from "../../utils";
import { withRouter } from "react-router-dom";

const GraficoDespesasEssenciais = (props) => {
    const graficoDados = {
        labels: props.meses,
        datasets: [{
            label: 'Esperado',
            data: props.meses.map((mes) => 50),
            backgroundColor: "#5D9C59",
            borderColor: props.cores[0],
        },{
            label: 'Realizado',
            data:  Object.keys(props.investimentosMensais).map((data) => {
        
                const metaMes = (props.investimentosMensais[data]['totalDespesaEssencial'] * 100)/props.investimentosMensais[data]['totalReceitas']
                
                return metaMes
            }),
            backgroundColor: "#9DC08B",
            borderColor: "#0000FF",
        }],
    };

    const graficoOpcoes = {
        plugins: {
            legend: {
                display: true,
                labels: {
                    color:  'rgb(255, 99, 132)',
                },
            },
        },
        tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const { yLabel, index } = tooltipItem;

                const indices = Object.keys(props.investimentosMensais)

                let label = data.datasets[tooltipItem.datasetIndex].label || "";
      
                if(label) {
                    if(yLabel === 50){
                        label = toBRL(props.investimentosMensais[indices[index]]['totalReceitas']*0.50) + ` (${Math.floor(yLabel)}%)`;
                    }
                    else {
                        label = toBRL(props.investimentosMensais[indices[index]]['totalDespesaEssencial']) + ` (${Math.floor(yLabel)}%)`;
                    }
                }
      
                return label;
              }
            }
          },
        scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false
                }
              }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: (value) => value + "%",
                    }
                }
            ]
          },
        maintainAspectRatio: false,
    }
    

    return (
        <Card>
            <CardBody>
                <CardTitle className="text-center">Despesas Essenciais</CardTitle>

                <div className="chart-wrapper" style={{ height: 300, marginTop: 40 }}>
                    {Object.keys(props.investimentosMensais).length !== 0 && <Bar 
                        data={graficoDados}
                        options={graficoOpcoes}
                        plugins={[ChartAnnotation]}
                    />}
                </div>
            </CardBody>
        </Card>
    )
}

export default withRouter(GraficoDespesasEssenciais);