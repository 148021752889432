import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  Label,
  Input,
  Row,
  CardFooter,
  Button,
  Alert,
} from "reactstrap";
import { TIPOS_TELEFONE } from "../../options";
import {
  FieldTelefoneCelular,
  FieldTelefoneFixo,
  FormErrMsg,
} from "../../components";
import { getAxiosAuth, getTokenClientId } from "../../utils";
import validator from "validator";

function NovoChamado(props) {
  const [carregandoDados, setaCarregandoDados] = useState(true);
  const [erroObterDados, setaErroObterDados] = useState(false);
  const [submetendoDados, setaSubmetendoDados] = useState(false);
  const [erroSubmeterDados, setaErroSubmeterDados] = useState(false);
  const [nome, setaNome] = useState("");
  const [email, setaEmail] = useState("");
  const [telefoneTipo, setaTelefoneTipo] = useState(TIPOS_TELEFONE[0]);
  const [usarTelefoneFixo, setaUsarTelefoneFixo] = useState(false);
  const [telefone, setaTelefone] = useState("");
  const [titulo, setaTitulo] = useState("");
  const [descricao, setaDescricao] = useState("");
  const [mensagemErroNome, setaMensagemErroNome] = useState(null);
  const [mensagemErroEmail, setaMensagemErroEmail] = useState(null);
  const [mensagemErroTitulo, setaMensagemErroTitulo] = useState(null);
  const [mensagemErroDescricao, setaMensagemErroDescricao] = useState(null);
  const [mensagemErroFormulario, setaMensagemErroFormulario] = useState(null);

  const nomeInputHandler = (event) => {
    setaNome(event.target.value);
    setaMensagemErroNome(null);
  };

  const nomeBlurHandler = () => {
    validarNome(nome);
  };

  const emailInputHandler = (event) => {
    setaEmail(event.target.value);
    setaMensagemErroEmail(null);
  };

  const emailBlurHandler = () => {
    validarEmail(email);
  };

  const telefoneTipoInputHandler = (event) => {
    setaTelefoneTipo(event.target.value);
  };

  const telefoneInputHandler = (event) => {
    setaTelefone(event.target.value);
  };

  const tituloInputHandler = (event) => {
    setaTitulo(event.target.value);
    setaMensagemErroTitulo(null);
  };

  const tituloBlurHandler = () => {
    validarTitulo(titulo);
  };

  const descricaoInputHandler = (event) => {
    setaDescricao(event.target.value);
    setaMensagemErroDescricao(null);
  };

  const descricaoBlurHandler = () => {
    validarDescricao(descricao);
  };

  const botaoCancelarInputHandler = (event) => {
    event.preventDefault();
    props.history.push("/atendimento");
  };

  const obterSetarDadosCadastroCliente = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}`;

    const response = await axiosInstance.get(uri);

    if (response && response.status === 200) {
      setaNome(response.data.nomeCompleto);
      setaEmail(response.data.email);
      if (response.data.telefones.length > 0) {
        const telefone = response.data.telefones[0];
        setaTelefoneTipo(telefone.tipo);
        setaTelefone(telefone.numero);
      }
    }
  };

  const obterSetarDados = async () => {
    setaErroObterDados(false);
    setaCarregandoDados(true);

    try {
      await obterSetarDadosCadastroCliente();
    } catch (error) {
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }
  };

  useEffect(() => {
    obterSetarDados();
  }, []);

  useEffect(() => {
    setaUsarTelefoneFixo(telefoneTipo !== "Celular");
  }, [telefoneTipo]);

  const validarNome = (nome) => {
    if (validator.isEmpty(nome, { ignore_whitespace: true })) {
      setaMensagemErroNome("Obrigatório");
      return false;
    } else {
      setaMensagemErroNome(false);
    }
    return true;
  };

  const validarEmail = (email) => {
    if (!email || validator.isEmpty(email, { ignore_whitespace: true })) {
      setaMensagemErroEmail("Obrigatório");
      return false;
    } else if (!validator.isEmail(email)) {
      setaMensagemErroEmail("Email inválido");
      return false;
    } else {
      setaMensagemErroEmail(false);
    }
    return true;
  };

  const validarTitulo = (titulo) => {
    if (!titulo || validator.isEmpty(titulo, { ignore_whitespace: true })) {
      setaMensagemErroTitulo("Obrigatório");
      return false;
    } else {
      setaMensagemErroTitulo(false);
    }
    return true;
  };

  const validarDescricao = (descricao) => {
    if (
      !descricao ||
      validator.isEmpty(descricao, { ignore_whitespace: true })
    ) {
      setaMensagemErroDescricao("Obrigatório");
      return false;
    } else {
      setaMensagemErroDescricao(false);
    }
    return true;
  };

  const submeterDados = async () => {
    setaErroSubmeterDados(false);

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/atendimento`;
    let dados = {
      nome,
      email,
      telefoneTipo,
      telefoneNumero: telefone,
      titulo,
      descricao,
    };

    setaSubmetendoDados(true);

    let response;
    try {
      response = await axiosInstance.post(uri, dados);
    } catch (error) {
      setaErroSubmeterDados(error.response ? error.response.status : true);
    } finally {
      setaSubmetendoDados(false);
    }

    if (response && response.status === 200) {
      console.log(response.data);
      const { id } = response.data;
      props.history.push(`/atendimento/andamento/${id}`, {
        criouSucesso: true,
      });
    }
  };

  const submitInputHandler = (event) => {
    event.preventDefault();

    let valido = true;

    const nomeValido = validarNome(nome);
    const emailValido = validarEmail(email);
    const tituloValido = validarTitulo(titulo);
    const descricaoValida = validarDescricao(descricao);

    if (!nomeValido || !emailValido || !tituloValido || !descricaoValida) {
      valido = false;
    }

    if (valido) {
      submeterDados();
      setaMensagemErroFormulario(null);
    } else {
      setaMensagemErroFormulario("Verifique os campos.");
    }
  };

  return (
    <div className="animated fadeIn">
      {carregandoDados && (
        <Container>
          <div className="shimmer line br animate"></div>
          <div className="shimmer line br animate"></div>
          <div className="shimmer line br animate"></div>
          <div className="shimmer textarea br animate"></div>
        </Container>
      )}
      {!carregandoDados && !erroObterDados && (
        <Container>
          <Form onSubmit={submitInputHandler}>
            <Card>
              <CardHeader>
                <i className="fas fa-edit" />
                <strong>Novo Chamado</strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="required">Seu nome</Label>
                      <Input
                        type="text"
                        placeholder="Nome completo"
                        value={nome}
                        onChange={nomeInputHandler}
                        onBlur={nomeBlurHandler}
                        valid={mensagemErroNome === false}
                        invalid={!!mensagemErroNome}
                      />
                      <FormErrMsg>{mensagemErroNome}</FormErrMsg>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="required">Email para contato</Label>
                      <Input
                        type="email"
                        placeholder="Email para contato"
                        value={email}
                        onChange={emailInputHandler}
                        onBlur={emailBlurHandler}
                        valid={mensagemErroEmail === false}
                        invalid={!!mensagemErroEmail}
                      />
                      <FormErrMsg>{mensagemErroEmail}</FormErrMsg>
                    </FormGroup>
                  </Col>
                  <Col xs={`auto`}>
                    <FormGroup>
                      <Label>Telefone</Label>
                      <FormGroup className="form-inline">
                        <Input
                          type="select"
                          onChange={telefoneTipoInputHandler}
                          value={telefoneTipo}
                        >
                          {TIPOS_TELEFONE.map((tipo) => (
                            <option key={`telefone_${tipo}`}>{tipo}</option>
                          ))}
                        </Input>
                        {usarTelefoneFixo ? (
                          <FieldTelefoneFixo
                            className="form-control"
                            placeholder="(99) 9999-9999"
                            value={telefone}
                            onChange={telefoneInputHandler}
                          />
                        ) : (
                          <FieldTelefoneCelular
                            className="form-control"
                            placeholder="(99) 99999-9999"
                            value={telefone}
                            onChange={telefoneInputHandler}
                          />
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label className="required">
                    Sobre o que é a sua dúvida?
                  </Label>
                  <Input
                    type="text"
                    placeholder="Título do chamado"
                    value={titulo}
                    onChange={tituloInputHandler}
                    onBlur={tituloBlurHandler}
                    valid={mensagemErroTitulo === false}
                    invalid={!!mensagemErroTitulo}
                  />
                  <FormErrMsg>{mensagemErroTitulo}</FormErrMsg>
                </FormGroup>
                <FormGroup>
                  <Label className="required">Descrição</Label>
                  <Input
                    type="textarea"
                    rows={10}
                    value={descricao}
                    onChange={descricaoInputHandler}
                    onBlur={descricaoBlurHandler}
                    valid={mensagemErroDescricao === false}
                    invalid={!!mensagemErroDescricao}
                  />
                  <FormErrMsg>{mensagemErroDescricao}</FormErrMsg>
                </FormGroup>
                {mensagemErroFormulario && (
                  <Alert color="danger" style={{ display: "inline-block" }}>
                    Não foi possível enviar. {mensagemErroFormulario}
                  </Alert>
                )}
                {erroSubmeterDados && (
                  <Alert color="danger" style={{ display: "inline-block" }}>
                    Não foi possível enviar o chamado.
                    {erroSubmeterDados === true
                      ? " Problema com a conexão."
                      : `Problema interno no servidor (código ${erroSubmeterDados}).`}
                  </Alert>
                )}
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  color="primary"
                  disabled={submetendoDados}
                >
                  {submetendoDados && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Enviar chamado
                </Button>
                <Button color="link" onClick={botaoCancelarInputHandler}>
                  Cancelar
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </Container>
      )}

      {erroObterDados && (
        <>
          <Alert color="danger" style={{ display: "inline-block" }}>
            Não foi possível obter seus dados.
            {erroObterDados === true
              ? " Problema com a conexão."
              : ` Problema interno no servidor (código ${erroObterDados}).`}
          </Alert>
          <p>
            <Button onClick={obterSetarDados}>Tentar novamente</Button>
          </p>
        </>
      )}
    </div>
  );
}

export default withRouter(NovoChamado);
