import React, { useEffect, useState } from "react";
import { getAxios, toBRLColor } from "../../utils";
import { Line } from "react-chartjs-2";
// import { Card, CardBody } from "reactstrap";
import moment from "moment";
import "moment/locale/pt-br";

function IndiceCotacaoDolar(props) {
  const [valorAtual, setaValorAtual] = useState(0.0);
  const [legendas, setaLegendas] = useState([]);
  const [valores, setaValores] = useState([]);

  /**
   * Primeiro acesso: obtem cotação do dólar
   */
  useEffect(() => {
    const getCotacaoDolar = async () => {
      const axiosInstance = getAxios();

      const dataInicial = moment()
        .subtract(1, "day")
        .format("MM-DD-YYYY");
      const dataFinal = moment().format("MM-DD-YYYY");

      const url = `https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarPeriodo(dataInicial=@dataInicial,dataFinalCotacao=@dataFinalCotacao)?@dataInicial='${dataInicial}'&@dataFinalCotacao='${dataFinal}'&$top=10&$format=json`;
      let response;

      try {
        response = await axiosInstance.get(url);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);
          return;
        }
      }

      if (response.status === 200) {
        // console.log(response.data);

        if (
          "value" in response.data &&
          Array.isArray(response.data.value) &&
          response.data.value.length > 0
        ) {
          let ultimo = response.data.value[response.data.value.length - 1];

          if ("cotacaoVenda" in ultimo) {
            setaValorAtual(ultimo.cotacaoVenda);
          }

          let novasLegendas = [];
          let novosValores = [];

          response.data.value.forEach(entrada => {
            novasLegendas.push(moment(entrada.dataHoraCotacao).format("dddd"));

            novosValores.push(entrada.cotacaoVenda);
          });

          setaLegendas(novasLegendas);
          // console.log(novasLegendas);
          setaValores(novosValores);
        }
      }
    };

    getCotacaoDolar();
  }, []);

  const chart = {
    labels: legendas,
    datasets: [
      {
        backgroundColor: "transparent",
        borderColor: "#84C027",
        data: valores
      }
    ]
  };

  const chartOptions = {
    tooltips: {
      enabled: false
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          display: false
        }
      ],
      yAxes: [
        {
          display: false
        }
      ]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    },
    legend: {
      display: false
    }
  };

  const openLink = () => window.open('https://br.investing.com/currencies/usd-brl-chart', '_blank','noreferrer noopener');

  return (
    <div
      className="callout callout-success link-cotacao" 
      onClick={openLink}
      style={{cursor: "pointer"}}
    >
      <small className="text-muted">Dólar comercial</small>
      <br />
      <strong className="h4">{toBRLColor(valorAtual)}</strong>
      <div className="chart-wrapper">
        <Line data={chart} options={chartOptions} width={100} height={30} />
      </div>
    </div>
  );
}

export default IndiceCotacaoDolar;
