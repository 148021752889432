import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button
} from "reactstrap";
import { DateCarousel } from "../../../components";
import moment from "moment";
import {
  getAxiosAuth,
  wasRequestCancelled,
  toBRLColor,
  toPercentColor,
  getTokenClientId
} from "../../../utils";
import { withRouter } from "react-router-dom";
import palette from "google-palette";
import { GraficoRosca } from "../../../components";

function Distribuicao(props) {
  const MOSTRAR_TODOS = 0;
  const MOSTRAR_PATRIMONIOS = 1;
  const MOSTRAR_INVESTIMENTOS = 2;
  const clienteId = getTokenClientId();
  const m = moment();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [isLoading, setIsLoading] = useState(true);
  const [mostrar, setaMostrar] = useState(MOSTRAR_TODOS);
  const [idSelecionado, selecionarId] = useState(null);
  const [idHovered, setaHoverId] = useState(null);
  const [patrimonios, setaPatrimonios] = useState([]);
  const [investimentos, setaInvestimentos] = useState([]);

  const ordenador = (a, b) => b.valor - a.valor;

  const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "patrimonios" in response.data) {
      setaPatrimonios(response.data.patrimonios.sort(ordenador));
    }
  };

  const getInvestimentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/investimentos`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "investimentos" in response.data) {
      setaInvestimentos(response.data.investimentos.sort(ordenador));
    }
  };

  const getDados = async () => {
    await getPatrimonios();

    await getInvestimentos();

    setIsLoading(false);
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  // primeiro acesso e quando alterar a data
  useEffect(() => {
    setIsLoading(true);
  }, [mes, ano]);

  useEffect(() => {
    if (isLoading) {
      getDados();
    }
  }, [isLoading]);

  let titulo;
  let legendas, dados, total, cores, paletaCores;

  const totalPatrimonios = patrimonios.reduce((acc, cur) => acc + cur.valor, 0);

  const totalInvestimentos = investimentos.reduce(
    (acc, cur) => acc + cur.valor,
    0
  );

  if (mostrar === MOSTRAR_PATRIMONIOS) {
    titulo = "Patrimônios";
    total = totalPatrimonios;
    legendas = Array.from(new Set(patrimonios.map(p => p.tipo)));

    dados = legendas.map(legenda =>
      patrimonios
        .filter(p => p.tipo === legenda)
        .reduce((acc, cur) => acc + cur.valor, 0)
    );

    if (idSelecionado !== null) {
      titulo = legendas[idSelecionado] ? legendas[idSelecionado] : "";
      let ps = patrimonios.filter(p => p.tipo === titulo);
      legendas = ps.map(p => p.descricao);
      total = ps.reduce((acc, cur) => acc + cur.valor, 0);
      dados = ps.map(p => p.valor);
    }
    paletaCores = "tol-rainbow";
  } else if (mostrar === MOSTRAR_INVESTIMENTOS) {
    titulo = "Investimentos";
    total = totalInvestimentos;
    legendas = investimentos
      .map(i => i.tipo)
      .filter((v, i, a) => a.indexOf(v) === i);
    dados = legendas.map(legenda =>
      investimentos
        .filter(p => p.tipo === legenda)
        .reduce((acc, cur) => acc + cur.valor, 0)
    );

    if (idSelecionado !== null) {
      titulo = legendas[idSelecionado] ? legendas[idSelecionado] : "";
      let is = investimentos.filter(p => p.tipo === titulo);
      legendas = is.map(i => i.descricao);
      total = is.reduce((acc, cur) => acc + cur.valor, 0);
      dados = is.map(i => i.valor);
    }

    paletaCores = "tol-rainbow";
  } else {
    titulo = "Total";
    total = totalPatrimonios + totalInvestimentos;
    legendas = ["Patrimônios", "Investimentos"];
    dados = [totalPatrimonios, totalInvestimentos];
    paletaCores = "cb-Accent";
  }

  cores = palette(paletaCores, dados.length).map(hex => "#" + hex);

  const hoverCb = index => {
    if (index !== null && index >= 0) {
      setaHoverId(index);
    } else {
      setaHoverId(null);
    }
  };

  const clickCb = index => {
    if (mostrar === MOSTRAR_PATRIMONIOS) {
      if (idSelecionado === null) {
        selecionarId(index);
      }
    } else if (mostrar === MOSTRAR_INVESTIMENTOS) {
      if (idSelecionado === null) {
        selecionarId(index);
      }
    } else {
      if (index === 0) {
        setaMostrar(MOSTRAR_PATRIMONIOS);
      } else if (index === 1) {
        setaMostrar(MOSTRAR_INVESTIMENTOS);
      }
    }
  };

  const backClickCb = e => {
    e.preventDefault();

    if (mostrar === MOSTRAR_PATRIMONIOS) {
      if (idSelecionado === null) {
        setaMostrar(MOSTRAR_TODOS);
      } else {
        selecionarId(null);
      }
    } else if (mostrar === MOSTRAR_INVESTIMENTOS) {
      if (idSelecionado === null) {
        setaMostrar(MOSTRAR_TODOS);
      } else {
        selecionarId(null);
      }
    }
  };

  const spinner = (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );

  return (
    <div className="animated fadeIn">
      <Container className="">
        <Row>
          <Col lg={6} xl={8} className="text-center">
            <DateCarousel travaMesAtual ano={ano} mes={mes} onChange={onChangeDate} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={8} className="text-center">
            {isLoading && spinner}
            {!isLoading && (
              <Fragment>
                <GraficoRosca
                  legendas={legendas}
                  dados={dados}
                  titulo={titulo}
                  total={total}
                  cores={cores}
                  hoverCb={hoverCb}
                  clickCb={idSelecionado === null ? clickCb : null}
                />
                {mostrar !== MOSTRAR_TODOS && (
                  <Button
                    color="light"
                    size="sm"
                    onClick={backClickCb}
                    className="mt-2 mb-4"
                  >
                    <i className="fas fa-arrow-left" />
                    &nbsp;&nbsp;&nbsp;Voltar
                  </Button>
                )}
              </Fragment>
            )}
          </Col>
          <Col lg={6} xl={4} className="mt-4 mt-lg-0">
            <Card style={{ width: "100%" }}>
              <CardHeader>
                <strong>Categorias</strong>
              </CardHeader>
              <CardBody>
                {isLoading && spinner}
                {!isLoading && (
                  <Table size="sm" borderless responsive>
                    <tbody>
                      {dados.map((valor, i) => (
                        <tr
                          key={`categoria_${i}`}
                          className={idHovered === i ? "table-light" : ""}
                        >
                          <td style={{ width: 30 }} className="text-center">
                            <i
                              className="fa fa-circle"
                              style={{
                                color: cores[i],
                                fontSize: idHovered === i ? "1.1rem" : "inherit"
                              }}
                            />
                          </td>
                          <td className="text-left">{legendas[i]}</td>
                          <td className="text-right">{toBRLColor(valor)}</td>
                          <td className="text-right">
                            {toPercentColor(valor / total)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Distribuicao);
