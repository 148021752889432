import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Button,
  ButtonGroup,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
} from "reactstrap";
import { FormErrMsg, Monetary, FieldCNPJ } from "../../../components";
import {
  getAxiosAuth,
  getTokenClientId,
  isPositiveBRL,
  validarCNPJ,
  fromBRL,
  toBRLValueOnly,
  fromPercent,
  PGTO,
  toDecimal,
} from "../../../utils";
import { TIPOS_PREVIDENCIAS, JUROS_PADRAO } from "../../../options";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import moment from "moment";

function Aposentadoria(props) {
  const clienteId = getTokenClientId();
  const [rendaMensalPlanejada, setaRendaMensalPlanejada] = useState("");
  const [rendaMensalPlanejadaErro, setaRendaMensalPlanejadaErro] = useState(
    null
  );
  const [idadeAposentadoria, setaIdadeAposentadoria] = useState("");
  const [idadeAposentadoriaErro, setaIdadeAposentadoriaErro] = useState(null);
  const [juros, setaJuros] = useState(JUROS_PADRAO);
  const [jurosErro, setaJurosErro] = useState(null);
  const [precisoGuardar, setaPrecisoGuardar] = useState("");
  const [precisoGuardarErro, setaPrecisoGuardarErro] = useState(null);
  const [precisoAcumular, setaPrecisoAcumular] = useState("");
  const [precisoAcumularErro, setaPrecisoAcumularErro] = useState(null);
  const [recolheInss, setaRecolheInss] = useState();
  const [recolheInssErro, setaRecolheInssErro] = useState(null);
  const [InssNumeroSalarios, setaInssNumeroSalarios] = useState(1);
  const [InssNumeroSalariosErro, setaInssNumeroSalariosErro] = useState(null);
  const [InssRecolheAnos, setaInssRecolheAnos] = useState("");
  const [InssRecolheAnosErro, setaInssRecolheAnosErro] = useState(null);
  const [temPrevidenciaPrivada, setaTemPrevidenciaPrivada] = useState();
  const [temPrevidenciaPrivadaErro, setaTemPrevidenciaPrivadaErro] = useState(
    null
  );
  const [previdencias, setaPrevidencias] = useState([]);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [salvar, setaSalvar] = useState();
  const [obter, setaObter] = useState();
  const [idadeCliente, setaIdadeCliente] = useState(0);

  const NOVA_PREVIDENCIA = {
    id: null,
    tipo: TIPOS_PREVIDENCIAS[0],
    tipoErro: null,
    seguradora: "",
    seguradoraErro: null,
    reservaAcumulada: "",
    reservaAcumuladaErro: null,
    tabelaIr: null,
    tabelaIrErro: null,
    cnpjFundo: "",
    cnpjFundoErro: null,
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    let valido = true;

    const rendaMensalPlanejadaValida = validaRendaMensalPlanejada();
    const idadeAposentadoriaValida = validaIdadeAposentadoria();
    const jurosValido = validaJuros();
    const precisoGuardarValido = validaQuantoPrecisoGuardar();
    const precisoAcumularValido = validaQuantoPrecisoAcumular();
    const recolheInssValido = validaRecolheInss();
    const InssNumeroSalariosValido = validaInssNumeroSalarios();
    const InssRecolheAnosValido = validaInssRecolheAnos();
    const temPrevidenciaPrivadaValido = validaTemPrevidenciaPrivada();

    if (
      !rendaMensalPlanejadaValida ||
      !idadeAposentadoriaValida ||
      !jurosValido ||
      !precisoGuardarValido ||
      !precisoAcumularValido ||
      !recolheInssValido ||
      !InssNumeroSalariosValido ||
      !InssRecolheAnosValido ||
      !temPrevidenciaPrivadaValido
    ) {
      valido = false;
    }

    if (temPrevidenciaPrivada) {
      previdencias.forEach((previdencia, idx) => {
        const tipoValido = validaPrevidenciaTipo(idx);
        const seguradoraValida = validaPrevidenciaSeguradora(idx);
        const tabelaIrValida = validaPrevidenciaTabelaIR(idx);
        const cnpjFundoValido = validaPrevidenciaCNPJFundo(idx);

        if (
          (valido && !tipoValido) ||
          !seguradoraValida ||
          !tabelaIrValida ||
          !cnpjFundoValido
        ) {
          valido = false;
        }
      });
    }

    if (valido) {
      setaFormularioValido(true);
      setaSalvar(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  useEffect(() => {
    setaPrevidencias([{ ...NOVA_PREVIDENCIA }]);
  }, []);

  const onClickBtnAdicionarPrevidencia = (e) => {
    e.preventDefault();

    let novasPrevidencias = [...previdencias, { ...NOVA_PREVIDENCIA }];

    setaPrevidencias(novasPrevidencias);
  };

  const onClickBtnRemoverPrevidencia = (idx, e) => {
    e.preventDefault();

    let novasPrevidencias = [...previdencias];

    if (previdencias.length === 1) {
      novasPrevidencias = [{ ...NOVA_PREVIDENCIA }];
    } else {
      novasPrevidencias.splice(idx, 1);
    }

    setaPrevidencias(novasPrevidencias);
  };

  const onChangeRendaMensalPlanejada = (e) => {
    setaRendaMensalPlanejada(e.target.value);
  };

  const onChangeIdadeAposentadoria = (e) => {
    setaIdadeAposentadoria(e.target.value);
  };

  const onChangeJuros = (e) => {
    setaJuros(e.target.value);
  };

  const onChangeRecolheInss = (valor) => {
    setaRecolheInss(valor);
  };

  const onChangeInssNumeroSalarios = (e) => {
    setaInssNumeroSalarios(e.target.value);
  };

  const onChangeInssRecolheAnos = (e) => {
    setaInssRecolheAnos(e.target.value);
  };

  const onChangeTemPrevidenciaPrivada = (valor) => {
    setaTemPrevidenciaPrivada(valor);
  };

  const onChangePrevidenciaCampo = (idx, campo, valor) => {
    if (!previdencias[idx]) return;

    let novasPrevidencias = [...previdencias];

    novasPrevidencias[idx][campo] = valor;

    setaPrevidencias(novasPrevidencias);
  };

  const onChangePrevidenciaTipo = (idx, e) => {
    onChangePrevidenciaCampo(idx, "tipo", e.target.value);
  };

  const onChangePrevidenciaSeguradora = (idx, e) => {
    onChangePrevidenciaCampo(idx, "seguradora", e.target.value);
  };

  const onChangePrevidenciaTabelaIR = (idx, e) => {
    onChangePrevidenciaCampo(idx, "tabelaIr", parseInt(e.target.value));
  };

  const onChangePrevidenciaCNPJFundo = (idx, e) => {
    onChangePrevidenciaCampo(idx, "cnpjFundo", e.target.value);
  };

  const validaRendaMensalPlanejada = () => {
    if (validator.isEmpty(rendaMensalPlanejada, { ignore_whitespace: true })) {
      setaRendaMensalPlanejadaErro("Campo obrigatório");
      return false;
    }

    if (!isPositiveBRL(rendaMensalPlanejada)) {
      setaRendaMensalPlanejadaErro("Valor monetário inválido");
      return false;
    }

    setaRendaMensalPlanejadaErro(false);
    return true;
  };

  const validaIdadeAposentadoria = () => {
    if (validator.isEmpty(idadeAposentadoria, { ignore_whitespace: true })) {
      setaIdadeAposentadoriaErro("Campo obrigatório");
      return false;
    }

    if (!validator.isInt(idadeAposentadoria) || idadeAposentadoria < 0) {
      setaIdadeAposentadoriaErro("Valor inválido");
      return false;
    }

    if (idadeAposentadoria > 100) {
      setaIdadeAposentadoriaErro("Idade máxima: 100.");
      return false;
    }

    setaIdadeAposentadoriaErro(false);
    return true;
  };

  const validaJuros = () => {
    if (validator.isEmpty(juros, { ignore_whitespace: true })) {
      setaJurosErro("Campo obrigatório");
      return false;
    }

    if (!validator.isDecimal(juros, { locale: "pt-BR" })) {
      setaJurosErro("Porcentagem inválida");
      return false;
    }

    setaJurosErro(false);
    return true;
  };

  const validaQuantoPrecisoGuardar = () => {
    if (validator.isEmpty(precisoGuardar, { ignore_whitespace: true })) {
      setaPrecisoGuardarErro("Campo obrigatório");
      return false;
    }

    if (!isPositiveBRL(precisoGuardar)) {
      setaPrecisoGuardarErro("Valor monetário inválido");
      return false;
    }

    setaPrecisoGuardarErro(false);
    return true;
  };

  const validaQuantoPrecisoAcumular = () => {
    if (validator.isEmpty(precisoAcumular, { ignore_whitespace: true })) {
      setaPrecisoAcumularErro("Campo obrigatório");
      return false;
    }

    if (!isPositiveBRL(precisoAcumular)) {
      setaPrecisoAcumularErro("Valor monetário inválido");
      return false;
    }

    setaPrecisoAcumularErro(false);
    return true;
  };

  const validaRecolheInss = () => {
    if (!recolheInss && recolheInss !== false) {
      setaRecolheInssErro("Campo obrigatório");
      return false;
    }

    setaRecolheInssErro(false);
    return true;
  };

  const validaInssNumeroSalarios = () => {
    if (!recolheInss) {
      setaInssNumeroSalariosErro(null);
      return true;
    }

    if (!InssNumeroSalarios) {
      setaInssNumeroSalariosErro("Campo obrigatório");
      return false;
    }

    setaInssNumeroSalariosErro(false);
    return true;
  };

  const validaInssRecolheAnos = () => {
    if (!recolheInss) {
      setaInssRecolheAnosErro(null);
      return true;
    }

    if (validator.isEmpty(InssRecolheAnos, { ignore_whitespace: true })) {
      setaInssRecolheAnosErro("Campo obrigatório");
      return false;
    }

    if (!validator.isInt(InssRecolheAnos) || InssRecolheAnos < 0) {
      setaInssRecolheAnosErro("Valor inválido");
      return false;
    }

    setaInssRecolheAnosErro(false);
    return true;
  };

  const validaTemPrevidenciaPrivada = () => {
    if (!temPrevidenciaPrivada && temPrevidenciaPrivada !== false) {
      setaTemPrevidenciaPrivadaErro("Campo obrigatório");
      return false;
    }

    setaTemPrevidenciaPrivadaErro(false);
    return true;
  };

  const validaPrevidenciaTipo = (idx) => {
    if (!previdencias[idx]) return true;

    let novasPrevidencias = [...previdencias];

    const previdencia = novasPrevidencias[idx];

    let valido = true;

    if (!previdencia.tipo) {
      previdencia.tipoErro = "Campo obrigatório";
      valido = false;
    }

    if (valido) {
      previdencia.tipoErro = false;
    }

    setaPrevidencias(novasPrevidencias);

    return valido;
  };

  const validaPrevidenciaSeguradora = (idx) => {
    if (!previdencias[idx]) return true;

    let novasPrevidencias = [...previdencias];

    const previdencia = novasPrevidencias[idx];

    let valido = true;

    if (
      validator.isEmpty(previdencia.seguradora, { ignore_whitespace: true })
    ) {
      previdencia.seguradoraErro = "Campo obrigatório";
      valido = false;
    }

    if (valido) {
      previdencia.seguradoraErro = false;
    }

    setaPrevidencias(novasPrevidencias);

    return valido;
  };

  const validaPrevidenciaTabelaIR = (idx) => {
    if (!previdencias[idx]) return true;

    let novasPrevidencias = [...previdencias];

    const previdencia = novasPrevidencias[idx];

    let valido = true;

    if (!previdencia.tabelaIr) {
      previdencia.tabelaIrErro = "Campo obrigatório";
      valido = false;
    }

    if (valido) {
      previdencia.tabelaIrErro = false;
    }

    setaPrevidencias(novasPrevidencias);

    return valido;
  };

  const validaPrevidenciaCNPJFundo = (idx) => {
    if (!previdencias[idx]) return true;

    let novasPrevidencias = [...previdencias];

    const previdencia = novasPrevidencias[idx];

    let valido = true;

    if (
      !validator.isEmpty(previdencia.cnpjFundo, { ignore_whitespace: true }) &&
      !validarCNPJ(previdencia.cnpjFundo)
    ) {
      previdencia.cnpjFundoErro = "CNPJ inválido";
      valido = false;
    }

    if (valido) {
      previdencia.cnpjFundoErro = false;
    }

    setaPrevidencias(novasPrevidencias);

    return valido;
  };

  const onBlurRendaMensalPlanejada = () => {
    validaRendaMensalPlanejada();
  };

  const onBlurIdadeAposentadoria = () => {
    validaIdadeAposentadoria();
  };

  const onBlurJuros = () => {
    validaJuros();
  };

  const onBlurInssRecolheAnos = () => {
    validaInssRecolheAnos();
  };

  const onBlurPrevidenciaSeguradora = (idx) => {
    validaPrevidenciaSeguradora(idx);
  };

  const onBlurPrevidenciaCNPJFundo = (idx) => {
    validaPrevidenciaCNPJFundo(idx);
  };

  const onClickBtnCalcular = (e) => {
    e.preventDefault();

    const rendaMensalPlanejadaValida = validaRendaMensalPlanejada();
    const idadeAposentadoriaValida = validaIdadeAposentadoria();
    const jurosValido = validaJuros();

    if (
      !rendaMensalPlanejadaValida ||
      !idadeAposentadoriaValida ||
      !jurosValido
    ) {
      return;
    }

    let patrimonioIdeal = fromBRL(rendaMensalPlanejada) * 120;
    setaPrecisoAcumular(toBRLValueOnly(patrimonioIdeal));

    let NPer =
      Math.max(parseInt(idadeAposentadoria) - parseInt(idadeCliente), 0) * 12;

    let Taxa = fromPercent(juros) / 100.0;
    let VP = 0;
    let VF = patrimonioIdeal;
    let contribuicaoMensal = -PGTO(Taxa, NPer, -VP, VF);

    setaPrecisoGuardar(toBRLValueOnly(contribuicaoMensal));
  };

  // primeiro acesso: obtem dados de aposentadoria
  useEffect(() => {
    const getCliente = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);
          props.history.push("/500");
          return;
        }

        if (response.status !== 200 || !response.data.dataNascimento) {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      const dados = response.data;
      const dataNascimento = moment(dados.dataNascimento, "YYYY-MM-DD", true);
      const idade = moment().diff(dataNascimento, "years");
      if (idade > 0) {
        setaIdadeCliente(idade);
      }
    };

    const getAposentadoria = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/aposentadoria`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);
          props.history.push("/500");
          return;
        }

        if (response.status !== 200 && response.status !== 404) {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      if (response.status === 404) {
        return;
      }

      const dados = response.data;

      setaRendaMensalPlanejada(toBRLValueOnly(dados.rendaMensalPlanejada));
      setaIdadeAposentadoria(
        dados.idadeAposentadoria ? String(dados.idadeAposentadoria) : ""
      );
      setaJuros(toDecimal(dados.juros));
      setaPrecisoGuardar(toBRLValueOnly(dados.precisoGuardar));
      setaPrecisoAcumular(toBRLValueOnly(dados.precisoAcumular));
      setaRecolheInss(dados.recolheInss);
      setaInssNumeroSalarios(
        dados.InssNumeroSalarios ? String(dados.InssNumeroSalarios) : ""
      );
      setaInssRecolheAnos(
        dados.InssRecolheAnos ? String(dados.InssRecolheAnos) : ""
      );
      setaTemPrevidenciaPrivada(dados.temPrevidenciaPrivada);

      if (
        dados.temPrevidenciaPrivada &&
        Array.isArray(dados.previdencias) &&
        dados.previdencias.length > 0
      ) {
        const novasPrevidencias = [];

        dados.previdencias.forEach((dados) => {
          const previdencia = {
            ...NOVA_PREVIDENCIA,
            tipo: dados.tipo,
            seguradora: dados.seguradoraNome,
            tabelaIr: dados.tabelaIr,
            cnpjFundo: dados.cnpjFundo,
          };

          novasPrevidencias.push(previdencia);
        });

        setaPrevidencias(novasPrevidencias);
      }
    };

    getAposentadoria();
    getCliente();
  }, [obter]);

  // salvar
  useEffect(() => {
    const putAposentadoria = async () => {
      let dados = {
        rendaMensalPlanejada: fromBRL(rendaMensalPlanejada),
        idadeAposentadoria: parseInt(idadeAposentadoria),
        juros: fromPercent(juros),
        precisoGuardar: fromBRL(precisoGuardar),
        precisoAcumular: fromBRL(precisoAcumular),
        recolheInss,
        temPrevidenciaPrivada,
      };

      if (recolheInss) {
        dados = {
          ...dados,
          InssNumeroSalarios: parseInt(InssNumeroSalarios),
          InssRecolheAnos: parseInt(InssRecolheAnos),
        };
      }

      if (temPrevidenciaPrivada) {
        dados = {
          ...dados,
          previdencias: previdencias.map((previdencia) => ({
            tipo: previdencia.tipo,
            seguradoraNome: previdencia.seguradora,
            tabelaIr: previdencia.tabelaIr,
            cnpjFundo: previdencia.cnpjFundo,
          })),
        };
      }

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/aposentadoria`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err.response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      if (response.status === 200) {
        toast("Aposentadoria salva com sucesso!");
        setaObter(new Date());
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        props.history.push("/500");
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      putAposentadoria();
    }
  }, [salvar]);

  const footerSalvar = (
    <CardFooter>
      <Button type="submit" color="primary">
        Salvar
      </Button>
    </CardFooter>
  );

  const blocoFormMsgErro = formularioValido === false && (
    <Alert color="danger" style={{ display: "inline-block" }}>
      Não foi possível salvar. {formularioErro}
    </Alert>
  );

  return (
    <div className="animated fadeIn" style={{ marginBottom: 40 }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <Container>
          <Card>
            <CardHeader>
              <i className="fas fa-coins" />
              <strong>Aposentadoria</strong>
            </CardHeader>
            <CardBody>
              <FormGroup row>
                <Col md={6} xl={4}>
                  <Label className="required">Renda mensal desejada</Label>
                </Col>
                <Col lg={4}>
                  <Monetary
                    value={rendaMensalPlanejada}
                    onChange={onChangeRendaMensalPlanejada}
                    onBlur={onBlurRendaMensalPlanejada}
                    valid={rendaMensalPlanejadaErro === false}
                    invalid={!!rendaMensalPlanejadaErro}
                  />
                  <FormErrMsg>{rendaMensalPlanejadaErro}</FormErrMsg>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={6} xl={4}>
                  <Label className="required">
                    Quero me aposentar com que idade?
                  </Label>
                </Col>
                <Col lg={4}>
                  <InputGroup>
                    <Input
                      type="text"
                      className="text-right"
                      style={{ maxWidth: 80 }}
                      value={idadeAposentadoria}
                      onChange={onChangeIdadeAposentadoria}
                      onBlur={onBlurIdadeAposentadoria}
                      valid={idadeAposentadoriaErro === false}
                      invalid={!!idadeAposentadoriaErro}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>anos</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormErrMsg>{idadeAposentadoriaErro}</FormErrMsg>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={6} xl={4}>
                  <Label className="required">
                    Taxa de juros líquida estimada:
                  </Label>
                </Col>
                <Col lg={4}>
                  <InputGroup>
                    <Input
                      type="text"
                      className="text-right"
                      style={{ maxWidth: 80 }}
                      value={juros}
                      onChange={onChangeJuros}
                      onBlur={onBlurJuros}
                      valid={jurosErro === false}
                      invalid={!!jurosErro}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>%</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormErrMsg>{jurosErro}</FormErrMsg>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={6} xl={4}>
                  <Label className="required">Quanto preciso guardar?</Label>
                </Col>
                <Col lg={4}>
                  <div style={{ float: "left" }}>
                    <Monetary
                      readOnly
                      value={precisoGuardar}
                      valid={precisoGuardarErro === false}
                      invalid={!!precisoGuardarErro}
                    />
                    <FormErrMsg>{precisoGuardarErro}</FormErrMsg>
                  </div>
                  <Button
                    size="sm"
                    color="warning"
                    className="ml-0 ml-sm-2 ml-lg-2 mt-2 mt-sm-0"
                    style={{ float: "left" }}
                    onClick={onClickBtnCalcular}
                  >
                    <i className="fas fa-calculator" />
                    &nbsp; Calcular
                  </Button>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={6} xl={4}>
                  <Label className="required">
                    Quanto tenho que acumular para viver de renda por 15 anos?
                  </Label>
                </Col>
                <Col lg={4}>
                  <Monetary
                    readOnly
                    value={precisoAcumular}
                    valid={precisoAcumularErro === false}
                    invalid={!!precisoAcumularErro}
                  />
                  <FormErrMsg>{precisoAcumularErro}</FormErrMsg>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={6} xl={4}>
                  <Label className="required">Recolhe INSS?</Label>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <FormGroup check inline>
                      <Input
                        type="radio"
                        name={`recolhe_inss`}
                        value={true}
                        checked={recolheInss === true}
                        onChange={onChangeRecolheInss.bind(this, true)}
                      />
                      <Label check>Sim</Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input
                        type="radio"
                        name={`recolhe_inss`}
                        value={false}
                        checked={recolheInss === false}
                        onChange={onChangeRecolheInss.bind(this, false)}
                      />
                      <Label check>Não</Label>
                    </FormGroup>
                  </FormGroup>
                  <FormErrMsg>{recolheInssErro}</FormErrMsg>
                </Col>
              </FormGroup>
              {recolheInss && (
                <Fragment>
                  <FormGroup row>
                    <Col md={6} xl={4}>
                      <Label className="required">
                        Sobre quantos salários?
                      </Label>
                    </Col>
                    <Col lg={3}>
                      <Input
                        type="select"
                        value={InssNumeroSalarios}
                        onChange={onChangeInssNumeroSalarios}
                        valid={InssNumeroSalariosErro === false}
                        invalid={!!InssNumeroSalariosErro}
                      >
                        <option value={1}>1 salário</option>
                        <option value={2}>2 salários</option>
                        <option value={3}>3 salários</option>
                        <option value={4}>4 salários</option>
                        <option value={5}>5 salários</option>
                        <option value={6}>6 salários</option>
                      </Input>
                      <FormErrMsg>{InssNumeroSalariosErro}</FormErrMsg>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={6} xl={4}>
                      <Label className="required">A quanto tempo?</Label>
                    </Col>
                    <Col lg={4}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="text-right"
                          style={{ maxWidth: 80 }}
                          value={InssRecolheAnos}
                          onChange={onChangeInssRecolheAnos}
                          onBlur={onBlurInssRecolheAnos}
                          valid={InssRecolheAnosErro === false}
                          invalid={!!InssRecolheAnosErro}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>anos</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormErrMsg>{InssRecolheAnosErro}</FormErrMsg>
                    </Col>
                  </FormGroup>
                </Fragment>
              )}
              <FormGroup row>
                <Col md={6} xl={4}>
                  <Label className="required">Tem Previdência Privada?</Label>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <FormGroup check inline>
                      <Input
                        type="radio"
                        name={`tem_previdencia_privada`}
                        value={true}
                        checked={temPrevidenciaPrivada === true}
                        onChange={onChangeTemPrevidenciaPrivada.bind(
                          this,
                          true
                        )}
                      />
                      <Label check>Sim</Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input
                        type="radio"
                        name={`tem_previdencia_privada`}
                        value={false}
                        checked={temPrevidenciaPrivada === false}
                        onChange={onChangeTemPrevidenciaPrivada.bind(
                          this,
                          false
                        )}
                      />
                      <Label check>Não</Label>
                    </FormGroup>
                  </FormGroup>
                  <FormErrMsg>{temPrevidenciaPrivadaErro}</FormErrMsg>
                </Col>
              </FormGroup>
              {!temPrevidenciaPrivada && blocoFormMsgErro}
            </CardBody>
            {!temPrevidenciaPrivada && footerSalvar}
          </Card>

          {temPrevidenciaPrivada &&
            previdencias.map((previdencia, idx) => (
              <Card key={`previdencia_bloco_${idx}`}>
                <CardHeader>
                  <i className="fas fa-book-reader" />
                  <strong>Previdência Privada</strong>

                  <div className="card-header-actions">
                    <ButtonGroup>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={onClickBtnAdicionarPrevidencia}
                      >
                        <i className="fas fa-plus" />
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={onClickBtnRemoverPrevidencia.bind(this, idx)}
                      >
                        <i className="fas fa-minus" />
                      </Button>
                    </ButtonGroup>
                  </div>
                </CardHeader>
                <CardBody>
                  <FormGroup row>
                    <Col md={6} xl={4}>
                      <Label className="required">
                        Tipo de Previdência Privada
                      </Label>
                    </Col>
                    <Col lg={3}>
                      <Input
                        type="select"
                        value={previdencia.tipo}
                        onChange={onChangePrevidenciaTipo.bind(this, idx)}
                        valid={previdencia.tipoErro === false}
                        invalid={!!previdencia.tipoErro}
                      >
                        {TIPOS_PREVIDENCIAS.map((tipo) => (
                          <option key={`tipo_${tipo}_${idx}`}>{tipo}</option>
                        ))}
                      </Input>
                      <FormErrMsg>{previdencia.tipoErro}</FormErrMsg>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={6} xl={4}>
                      <Label className="required">Qual seguradora?</Label>
                    </Col>
                    <Col lg={4}>
                      <Input
                        type="text"
                        value={previdencia.seguradora}
                        onChange={onChangePrevidenciaSeguradora.bind(this, idx)}
                        onBlur={onBlurPrevidenciaSeguradora.bind(this, idx)}
                        valid={previdencia.seguradoraErro === false}
                        invalid={!!previdencia.seguradoraErro}
                      />
                      <FormErrMsg>{previdencia.seguradoraErro}</FormErrMsg>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={6} xl={4}>
                      <Label className="required">Tabela de IR</Label>
                    </Col>
                    <Col lg={4}>
                      <FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name={`previdencia_privada_IR_${idx}`}
                            value={1}
                            checked={previdencia.tabelaIr === 1}
                            onChange={onChangePrevidenciaTabelaIR.bind(
                              this,
                              idx
                            )}
                          />
                          <Label check>Progressiva</Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name={`previdencia_privada_IR_${idx}`}
                            value={2}
                            checked={previdencia.tabelaIr === 2}
                            onChange={onChangePrevidenciaTabelaIR.bind(
                              this,
                              idx
                            )}
                          />
                          <Label check>Regressiva</Label>
                        </FormGroup>
                      </FormGroup>
                      <FormErrMsg>{previdencia.tabelaIrErro}</FormErrMsg>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={6} xl={4}>
                      <Label>CNPJ do fundo:</Label>
                    </Col>
                    <Col lg={4}>
                      <FieldCNPJ
                        style={{ maxWidth: 200 }}
                        value={previdencia.cnpjFundo}
                        onChange={onChangePrevidenciaCNPJFundo.bind(this, idx)}
                        onBlur={onBlurPrevidenciaCNPJFundo.bind(this, idx)}
                        valid={
                          !validator.isEmpty(previdencia.cnpjFundo) &&
                          previdencia.cnpjFundoErro === false
                        }
                        invalid={!!previdencia.cnpjFundoErro}
                      />
                      <FormErrMsg>{previdencia.cnpjFundoErro}</FormErrMsg>
                    </Col>
                  </FormGroup>
                  {idx === previdencias.length - 1 && blocoFormMsgErro}
                </CardBody>
                {idx === previdencias.length - 1 && footerSalvar}
              </Card>
            ))}
        </Container>
      </Form>
    </div>
  );
}

export default withRouter(Aposentadoria);
