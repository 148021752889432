import React, { useState } from "react";
import { Row, Col, Card, CardHeader, Table, CardBody } from "reactstrap";
import { GraficoRosca, LoadingSpinner } from "../../../components";
import palette from "google-palette";
import PropTypes from "prop-types";
import { toBRLColor, toPercentColor } from "../../../utils";

function GraficoTotalReceitasTotalDeducoes({
  isLoading,
  totalBruto,
  totalLiquido,
  totalDeducoes,
  onClickReceitasLiq,
  onClickDeducoes
}) {
  const dados = [totalLiquido, totalDeducoes];
  const cores = palette("tol-dv", dados.length).map(hex => "#" + hex);
  const legendas = ["Receita líquida", "Deduções"];
  const [itemSelecionado, setaItemSelecionado] = useState(null);

  const hoverCb = index => {
    let value;
    if (!isNaN(index) && index >= 0) {
      value = index;
    } else {
      value = null;
    }
    setaItemSelecionado(value);
  };

  const clickCb = index => {
    if (index === 0 && onClickReceitasLiq) {
      onClickReceitasLiq();
    } else if (index === 1 && onClickDeducoes) {
      onClickDeducoes();
    }
  };

  return (
    <Row>
      <Col lg={6} xl={8} className="text-center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <GraficoRosca
            legendas={legendas}
            dados={dados}
            titulo={"Total bruto"}
            total={totalBruto}
            cores={cores}
            hoverCb={hoverCb}
            clickCb={onClickReceitasLiq || onClickDeducoes ? clickCb : null}
          />
        )}
      </Col>
      <Col lg={6} xl={4} className="mt-4 mt-lg-0">
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <strong>Categorias</strong>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Table size="sm" borderless responsive>
                <tbody>
                  {dados.map((valor, i) => (
                    <tr
                      key={`categoria_${i}`}
                      className={itemSelecionado === i ? "table-light" : ""}
                    >
                      <td style={{ width: 30 }} className="text-center">
                        <i
                          className="fa fa-circle"
                          style={{
                            color: cores[i],
                            fontSize:
                              itemSelecionado === i ? "1.1rem" : "inherit"
                          }}
                        />
                      </td>
                      <td>{legendas[i]}</td>
                      <td className="text-right">{toBRLColor(valor)}</td>
                      <td className="text-right">
                        {totalBruto > 0 && toPercentColor(valor / totalBruto)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

GraficoTotalReceitasTotalDeducoes.propTypes = {
  isLoading: PropTypes.bool,
  totalBruto: PropTypes.number,
  totalLiquido: PropTypes.number,
  totalDeducoes: PropTypes.number,
  onClickReceitasLiq: PropTypes.func,
  onClickDeducoes: PropTypes.func
};

export default GraficoTotalReceitasTotalDeducoes;
