import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { DateCarousel, TagsFilterSelector } from "../../../components";
import moment from "moment";
import { withRouter } from "react-router-dom";
import DespesasPorCategorias from "./DespesasPorCategorias";
import DespesasPorTipos from "./DespesasPorTipos";
import DespesasPorFixasVariaveis from "./DespesasPorFixasVariaveis";
import { Badge } from "reactstrap";


function Despesas(props) {
  const m = moment();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [tags, setaTags] = useState([]);

  const onChangeTags = newValues => {
    setaTags(newValues);
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  return (
    <div className="animated fadeIn">
      <Container>
        <Row>
          <Col md={4} className="text-center">
            <TagsFilterSelector tagShow={true} onChange={onChangeTags} tags={tags} />
            <div>
            {tags.map((tag)=>{
              return <Badge style={{fontSize:"14px"}} color="secondary" pill key={tag.id}>{tag.label}</Badge>
            })}
            </div>
          </Col>
          <Col md={4} className="text-center">
            <DateCarousel
              ano={ano}
              mes={mes}
              onChange={onChangeDate}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={8} className="text-center mt-5 mb-3">
            <h4>Despesas por Categorias</h4>
          </Col>
        </Row>
        <DespesasPorCategorias ano={ano} mes={mes} tags={tags} />
        <Row>
          <Col lg={6} xl={8} className="text-center mt-5 mb-3">
            <h4>Despesas por Tipos</h4>
          </Col>
        </Row>
        <DespesasPorTipos ano={ano} mes={mes} tags={tags} />
        <Row>
          <Col lg={6} xl={8} className="text-center mt-5 mb-3">
            <h4>Recorrentes, Temporárias e Variáveis</h4>
          </Col>
        </Row>
        <DespesasPorFixasVariaveis ano={ano} mes={mes} tags={tags} />
      </Container>
    </div>
  );
}

export default withRouter(Despesas);
