import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Jumbotron,
  Button,
  Table,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { getAxiosAuth, getTokenClientId } from "../../utils";
import BadgeSituacao from "./BadgeSituacao";

function Atendimento(props) {
  const [carregandoDados, setaCarregandoDados] = useState(true);
  const [erroObterDados, setaErroObterDados] = useState(false);
  const [chamados, setaChamados] = useState([]);

  const novoChamadoInputHandler = (event) => {
    event.stopPropagation();
    const { history } = props;
    history.push("/atendimento/novo-chamado");
  };

  const obterSetarChamados = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/atendimento`;

    const response = await axiosInstance.get(uri);

    if (
      response &&
      response.status === 200 &&
      Array.isArray(response.data.chamados)
    ) {
      setaChamados(response.data.chamados);
    }
  };

  const obterSetarDados = async () => {
    setaErroObterDados(false);
    setaCarregandoDados(true);

    try {
      await obterSetarChamados();
    } catch (error) {
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }
  };

  useEffect(() => {
    obterSetarDados();
  }, []);

  return (
    <div className="animated fadeIn">
      <Container>
        {carregandoDados && (
          <>
            <div className="shimmer line br animate"></div>
            <div className="shimmer line br animate"></div>
            <div className="shimmer line br animate"></div>
            <div className="shimmer line br animate"></div>
            <div className="shimmer line br animate"></div>
          </>
        )}
        {!carregandoDados && !erroObterDados && chamados.length === 0 && (
          <Jumbotron>
            <h1 className="display-4">Atendimento</h1>
            <p className="lead">
              Precisa de ajuda? Abra um chamado e fale com a gente!
            </p>
            <hr className="my-2" />
            <p className="lead">
              <Button
                size="lg"
                color="primary"
                onClick={novoChamadoInputHandler}
              >
                Abrir um Chamado
              </Button>
            </p>
          </Jumbotron>
        )}
        {!carregandoDados && !erroObterDados && chamados.length > 0 && (
          <>
            <Row className="mb-3">
              <Col>
                <h4 className="mb-3">Atendimento</h4>
              </Col>
              <Col className="text-right">
                <Button
                  color="primary"
                  onClick={() =>
                    props.history.push("/atendimento/novo-chamado")
                  }
                >
                  <i className="fas fa-edit" />
                  &nbsp;&nbsp;&nbsp; Abrir novo Chamado
                </Button>
              </Col>
            </Row>
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>Chamado#</th>
                  <th>Título</th>
                  <th style={{ width: "15%" }} className="text-nowrap">
                    Situação
                  </th>
                  <th style={{ width: "25%" }}>Última atualização</th>
                </tr>
              </thead>
              <tbody>
                {chamados.map((chamado) => (
                  <tr key={`chamado_${chamado.id}`}>
                    <td>
                      <Link
                        to={`/atendimento/andamento/${chamado.id}`}
                        title="Ver Chamado"
                      >
                        {chamado.naoLido ? (
                          <strong>{chamado.codigo}</strong>
                        ) : (
                          chamado.codigo
                        )}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/atendimento/andamento/${chamado.id}`}
                        title="Ver Chamado"
                      >
                        {chamado.naoLido ? (
                          <strong>{chamado.titulo}</strong>
                        ) : (
                          chamado.titulo
                        )}
                      </Link>
                    </td>
                    <td>
                      <BadgeSituacao situacao={chamado.situacao} />
                    </td>
                    <td>
                      {chamado.naoLido ? (
                        <strong>{chamado.atualizadoEm}</strong>
                      ) : (
                        chamado.atualizadoEm
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {erroObterDados && (
          <>
            <Alert color="danger" style={{ display: "inline-block" }}>
              Não foi possível obter chamados.
              {erroObterDados === true
                ? " Problema com a conexão."
                : ` Problema interno no servidor (código ${erroObterDados}).`}
            </Alert>
            <p>
              <Button onClick={() => obterSetarDados()} color="primary">
                Tentar novamente
              </Button>
            </p>
          </>
        )}
      </Container>
    </div>
  );
}

export default withRouter(Atendimento);
