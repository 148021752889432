import React from "react";
import { Badge } from "reactstrap";
import moment from "moment";
import { toBRL } from "../../../utils";
import PropTypes from "prop-types";
import BtnModalFormReceita from "./BtnModalFormReceita";
import BtnModalRemoverReceita from "./BtnModalRemoverReceita";
import patrimonyLinkedPath from '../../../assets/img/patrimonyLinked.png'

function LinhaReceita(props) {

  const m = moment(props.dataReceita);
  let diaReceita = m.format("DD");
  let mesReceita = m.format("MM");
  let anoReceita = m.format("YYYY");

  const totalDeducoes =
    props.deducoes && props.deducoes.length > 0
      ? props.deducoes.reduce((acc, cur) => {
          acc += Number(cur.valor);

          return acc;
        }, 0.0)
      : 0.0;

  const totalLiquido = props.valor - totalDeducoes;

  const btns = (
    <div style={{ marginTop: 5 }}>
      <BtnModalFormReceita
        id={props.id}
        dia={Number(diaReceita)}
        mes={Number(mesReceita)}
        ano={Number(anoReceita)}
        descricao={props.descricao}
        tipo={props.tipo}
        valor={props.valor}
        rendaFixa={props.rendaFixa}
        patrimonioId={props.patrimonioId}
        patrimonioUuid={props.patrimonioUuid}
        deducoes={props.deducoes}
        tags={props.tags}
        onEdit={props.onEdit}
      />
      <BtnModalRemoverReceita
        dia={Number(diaReceita)}
        mes={Number(mesReceita)}
        ano={Number(anoReceita)}
        id={props.id}
        tipo={props.tipo}
        descricao={props.descricao}
        valor={props.valor}
        rendaFixa={props.rendaFixa}
        onDelete={props.onDelete}
      />
    </div>
  );

  const tagsPills =
    props.tags &&
    props.tags.map(tag => (
      <Badge pill className="ml-1" key={`tag_` + props.id + `_` + tag.id}>
        {tag.nome}
      </Badge>
    ));

  return (
    <tbody>
      <tr className="table-success">
        <td>
          {diaReceita}/{mesReceita}
        </td>
        <td style={{ 
                  marginRight: 5
                }}>
          <p className="limit-text-line">{props.descricao}{" "}</p>
          
          {tagsPills}
          {props.importacaoId ? (
                  <i title="Gerada por Upload" className="fas fa-upload" style={{marginLeft:8}}></i>
                ): null}
          <span className="d-block d-sm-none">
            <Badge color="warning" pill>
              {props.tipo}
            </Badge>{" "}
            {props.rendaFixa && (
              <Badge color="warning" pill>
                <i className="fas fa-thumbtack" /> Renda Recorrente
              </Badge>
            )}
            {tagsPills}
          </span>
        </td>
        <td className="text-left d-none d-sm-table-cell">
        {props.patrimonioUuid ? (
              <Badge pill color='info'  title="Patrimônio vinculado a esta receita">
                <span>
                  <img alt="Patrimônio vinculado" src={patrimonyLinkedPath}  style={{
                    width: '18px',
                    height: '15px',
                    position: 'relative',
                    top:'-2px'
                  }}
                /> 
                Patrimonio
              </span>
            </Badge>
            ):null}
        </td>
        <td className="text-left d-none d-sm-table-cell">
          {props.rendaFixa && (
            <Badge color="warning" pill>
              <i className="fas fa-thumbtack" /> Renda Recorrente
            </Badge>
          )}
        </td>
        <td className="text-left d-none d-sm-table-cell">
          <Badge color="light" pill>
            {props.tipo}
          </Badge>
        </td>
        <td className="text-right text-nowrap">
          <strong>{toBRL(props.valor)}</strong>
          <div className="d-block d-sm-none">{btns}</div>
        </td>
        <td className="d-none d-sm-table-cell text-right">{btns}</td>
      </tr>
      {props.deducoes &&
        props.deducoes.map((deducao, j) => (
          <tr key={diaReceita + "_" + j}>
            <td />
            <td className="text-left text-small text-muted">
              Dedução: <em>{deducao.descricao}</em>
            </td>
            <td className="text-right d-none d-sm-table-cell" />
            <td className="text-right d-none d-sm-table-cell" />
            <td className="text-right text-nowrap text-danger">
              <em>({toBRL(deducao.valor)})</em>
            </td>
            <td className="d-none d-sm-table-cell" />
          </tr>
        ))}
      {props.deducoes && props.deducoes.length > 0 && (
        <tr>
          <td />
          <td>
            <strong>Total Líquido</strong>
          </td>
          <td className="text-right d-none d-sm-table-cell" />
          <td className="text-right d-none d-sm-table-cell" />
          <td className="text-right text-nowrap">
            <strong>{toBRL(totalLiquido)}</strong>
          </td>
          <td className="d-none d-sm-table-cell" />
        </tr>
      )}
    </tbody>
  );
}

LinhaReceita.propTypes = {
  id: PropTypes.number.isRequired,
  dataReceita: PropTypes.string.isRequired,
  descricao: PropTypes.string,
  tipo: PropTypes.string,
  valor: PropTypes.number,
  rendaFixa: PropTypes.bool,
  patrimonioId: PropTypes.number,
  importacaoId: PropTypes.number,
  deducoes: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default LinhaReceita;
