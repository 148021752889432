import React from "react";
import { Input, Table } from "reactstrap";

function TestePerfilPage({ items, campo, onInputChange }) {
  const filteredItems = items.filter((item) => item.campo === campo);
  const rowspan = items.length;

  return (
    <Table borderless>
      <tbody>
        {filteredItems.map((item, index) => (
          <tr key={item.id}>
            <td className="table-items">
              <Input
                className="block-style"
                type="number"
                min="0"
                max="10"
                value={item.valor !== null ? item.valor : ''}
                onChange={(e) => onInputChange(item.id, e.target.value)}
              />
            </td>
            {index === 0 && (
              <td
                className="vertical-text table-items"
                style={{
                  color: `${item.colorText}`,
                  backgroundColor: `${item.colorBg}`
                }}
                rowspan={rowspan}
              >
                <span>{campo.toUpperCase()}</span>
              </td>
            )}
            <td>{item.descricao}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default TestePerfilPage;