import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
} from "reactstrap";
import PropTypes from "prop-types";
import ImagemPadrao from "../../../assets/img/sonho-independencia-financeira.jpg";
import {
  toBRL,
  toBRLNegative,
  toBRLColor,
  toPercent,
  fromISO8601,
} from "../../../utils";
import BtnModalRemoverProjeto from "./BtnModalRemoverProjeto";
import { Doughnut, Chart } from "react-chartjs-2";

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;

function ProjetoItem(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(ImagemPadrao);
  useEffect(() => {
    if (props.upload) {
      setImageSrc(`data:image/jpeg;base64,${props.upload.content}`);
    } else {
      setImageSrc(ImagemPadrao);
    }
  }, [props.upload]);
  
  // desenhando percentual no centro do gráfico
  useEffect(() => {
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function () {
        const chart = this.chart;
        const width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        originalDoughnutDraw.apply(this, arguments);

        const fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";

        var text = toPercent(percentualConquistado, {
            maximumFractionDigits: 0,
          }),
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2 + 15;

        ctx.fillText(text, textX, textY);
      },
    });

    return () =>
      Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
        draw: originalDoughnutDraw,
      });
  }, [isModalOpen]);

  const toggle = (e) => {
    e.preventDefault();
    setIsModalOpen(!isModalOpen);
  };

  const onClickBtnEditar = (e) => {
    e.preventDefault();

    const { history } = props;
    history.push(`/planeje/projetos-de-vida/especifique/${props.id}`);
  };

  const percentualConquistado = props.percentualConquistado < 1
    ? props.percentualConquistado
    : 1;
  const progressoValor = props.saldoAcumulado;
  const restante = percentualConquistado < 1 
    ? props.custoProjeto - props.saldoAcumulado 
    : 0;

  const totalPrazoEmMeses = props.prazoAnos * 12 + props.prazoMeses;
  const currentDate = new Date();
  const endDate = new Date(props.inicioProjeto);
  endDate.setMonth(endDate.getMonth() + totalPrazoEmMeses);

  const tempoRestante = endDate.getMonth() - currentDate.getMonth() + (12 * (endDate.getFullYear() - currentDate.getFullYear()));

  const doughnut = {
    labels: ["Conquistado", "Objetivo"],
    datasets: [
      {
        data: [progressoValor, restante],
        backgroundColor: ["#84C027", "#CDCDCD"],
      },
    ],
  };

  const doughnutOpts = {
    // rotation: -0.5 * Math.PI,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const label =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return toBRLNegative(label);
        },
      },
    },
  };

  let progressoCor;

  if (percentualConquistado < 0.05) {
    progressoCor = "red";
  } else if (
    percentualConquistado >= 0.05 &&
    percentualConquistado < 0.15
  ) {
    progressoCor = "yellow";
  } else if (
    percentualConquistado >= 0.15 &&
    percentualConquistado < 0.25
  ) {
    progressoCor = "green";
  } else if (percentualConquistado >= 0.25) {
    progressoCor = "blue";
  }

  return (
    <Col md={3}>
      <Card style={{ Height: 240, widht: 280 }} className="projeto-sonho-item">
        <CardHeader>
          <strong>{props.nome}</strong>
        </CardHeader>
        <CardBody className="text-center">
          <Button
            title={`Ver mais sobre ${props.nome}`}
            color="link"
            onClick={toggle}
          >
            <img
              src={imageSrc}
              className="img-fluid img-thumbnail"
              alt={props.nome}
              style={{ width: 180, height: 180 }}
            />
          </Button>
        </CardBody>
        <CardFooter>
          <div className="text-center">
            <a
              title={`Ver mais sobre ${props.nome}`}
              color="link"
              href="/planeje/projetos-de-vida"
              onClick={toggle}
            >
              <Progress
                striped
                value={percentualConquistado * 100}
                color={progressoCor}
              >
                {toPercent(percentualConquistado, {
                  maximumFractionDigits: 0,
                })}
              </Progress>
            </a>
          </div>
        </CardFooter>
      </Card>
      <Modal isOpen={isModalOpen} toggle={toggle} fade>
        <ModalHeader>{props.nome}</ModalHeader>
        <ModalBody>
          {props.descricao && <p className="text-justify">{props.descricao}</p>}

          <Fragment>
            <p>
              <strong>Quanto custa?</strong>
              <br />
              {toBRL(props.custoProjeto)}
            </p>

            <p>
              <strong>Quando vou guardar/mês?</strong>
              <br />
              {toBRL(props.valorPoupadoMes)}
            </p>

            <p>
              <strong>Com juros de?</strong>
              <br />
              {toPercent(props.percentual / 100)}
            </p>
          </Fragment>

          <p>
            <strong>Em quanto tempo?</strong>
            <br />
            {props.prazoAnos > 0 && (
              <Fragment>
                {props.prazoAnos} {props.prazoAnos > 1 ? "anos" : "ano"}
              </Fragment>
            )}

            {props.prazoMeses > 0 && (
              <Fragment>
                {props.prazoAnos > 0 && " e "}
                {props.prazoMeses} {props.prazoMeses > 1 ? "meses" : "mês"}
              </Fragment>
            )}     
          </p>

          <hr />

          <h4 className="text-center mb-4">Progresso</h4>

          <Row form>
            <Col xs={6}>
              <p>
                <strong>Iniciado em:</strong>
                <br />
                <span className="lead">{fromISO8601(props.inicioProjeto)}</span>
              </p>
            </Col>
            <Col xs={6} className="text-right">
              <p>
                <span className="lead">{
                  percentualConquistado >= 1
                    ? "Concluído"
                    : tempoRestante > 0
                      ? `${tempoRestante > 1 ? "Faltam" : "Falta"} ${tempoRestante} ${tempoRestante > 1 ? "meses" : "mês"}`
                      : `Acima do prazo em ${Math.abs(tempoRestante)} ${Math.abs(tempoRestante) > 1 ? "meses" : "mês"}`
                }</span>
              </p>
            </Col>
          </Row>

          <Doughnut data={doughnut} options={doughnutOpts} />

          <Row form>
            <Col xs={6}>
              <p>
                <strong>Já conquistei:</strong>
                <br />
                <span className="lead">{toBRLColor(progressoValor)}</span>
              </p>
            </Col>
            <Col xs={6} className="text-right">
              <p>
                <strong>Ainda falta:</strong>
                <br />
                <span className="lead">{toBRLColor(restante)}</span>
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="">
          <Button
            size="sm"
            color="light"
            title="Editar projeto"
            onClick={onClickBtnEditar}
          >
            <i className="fas fa-pen" /> Editar
          </Button>
          <BtnModalRemoverProjeto
            onDelete={() => {
              setIsModalOpen(false);
              props.onDelete && props.onDelete();
            }}
            id={props.id}
            nome={props.nome}
          />
          <Button size="sm" color="link" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
}

ProjetoItem.propTypes = {
  id: PropTypes.number.isRequired,
  nome: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

export default withRouter(ProjetoItem);
