import React, { useRef, useState } from "react";
import { Button, Input, Table } from "reactstrap";
import CreatableSelect from "react-select/creatable";

const fileTypeOptions = [
  {
    key: 'ofx',
    label: 'OFX',
    accept: '.ofx',
  },
  {
    key: 'pdf',
    label: 'PDF',
    accept: '.pdf',
    bankTypeOptions: [
      { name: "Bradesco Conta-Corrente" },
      { name: "Nubank Conta-Corrente" },
      { name: "Sicoob Conta-Corrente" },
      { name: "Inter Conta-Corrente" },
      { name: "Itau Cartao de Credito" },
      { name: "Itau Conta-Corrente" },
      { name: "Banco do Brasil Conta-Corrente" },
      { name: "Sicoob Cartao de Credito" },
      { name: "Banco do Brasil Cartao de Credito" },
      { name: "Santander Conta-Corrente" },
      { name: "Nubank Cartao de Credito" },
      { name: "Caixa Conta-Corrente" },
      { name: "Bradesco Cartao de Credito" },
      { name: "Santander Cartao de Credito" },
      { name: "Santander Cartao de Credito" },
      { name: "BTG Conta-Corrente" },
      { name: "BTG Cartao de Credito" }
      // Adicione mais bancos conforme necessário
    ]
  },
]

function FileMenu({ handleUpload, tagsOpcoes, tags, handleChangeTags, handleCreditoVerify }) {
  const [fileType, setFileType] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [password, setPassword] = useState(null);

  const fileRef = useRef();

  const handleFileTypeChange = (event) => {
    const key = event.target.value;
    const fileTypeAux = fileTypeOptions.find(type => type.key === key)
    setFileType(fileTypeAux);
  };

  const handleBankTypeChange = (event) => {
    const selectedBankName = event.target.value;
    const bankTypeOptions = fileTypeOptions.find(type => type.key === "pdf").bankTypeOptions;
    const bankTypeAux = bankTypeOptions.find(t => t.name === selectedBankName);
    setSelectedBank(bankTypeAux);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFileChange = (event) => setSelectedFile(event.target.files[0]);

  const handleSubmit = async () => {
    let bankName = selectedBank != null ? selectedBank.name : null;
    if (fileType.key == 'pdf') handleCreditoVerify(selectedBank);
    await handleUpload(fileType.key, selectedFile, bankName, password);
    if (fileRef.current) {
      fileRef.current.value = null;
    }
  }

  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th style={{ width: 200 }}>Tipo de arquivo</th>

          {fileType && fileType.key === "pdf" &&
            <th style={{ width: 200 }}>BANCO</th>
          }

          <th>Selecionar arquivo</th>
          {fileType && fileType.key === "pdf" &&
            <th style={{ width: 300 }}>Senha do PDF <br /> (Se necessário)</th>
          }
          <th style={{ width: 300 }}>Tags</th>
          <th>UPLOAD</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Input
              type="select"
              onChange={handleFileTypeChange}
            >
              <option value="">Selecione...</option>
              {fileTypeOptions.map(fileType =>
                <option key={fileType.key} value={fileType.key}>
                  {fileType.label}
                </option>
              )}
            </Input>
          </td>

          {fileType && fileType.key === "pdf" &&
            <td>
              <Input
                type="select"
                onChange={handleBankTypeChange}
              >
                <option value="">Selecione...</option>
                {fileType.bankTypeOptions
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(bankType => (
                    <option key={bankType.name} value={bankType.name}>
                      {bankType.name}
                    </option>
                  ))}
              </Input>
            </td>
          }

          <td>
            <input
              id="file"
              type="file"
              onChange={handleFileChange}
              accept={fileType ? fileType.accept : undefined}
              disabled={!fileType}
              ref={fileRef}
            />
          </td>

          {fileType && fileType.key === "pdf" &&
            <td>
              <Input value={password} onChange={handlePasswordChange} />
            </td>
          }

          <td>
            <CreatableSelect
              isMulti
              options={tagsOpcoes}
              onChange={handleChangeTags}
              value={tags}
              placeholder="Selecione..."
              noOptionsMessage={() => "Crie uma tag!"}
              formatCreateLabel={(t) => (
                <span>
                  Criar nova tag <strong>{t}</strong>...
                </span>
              )}
            />
          </td>

          <td>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={!selectedFile}
            >
              UPLOAD
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default FileMenu;