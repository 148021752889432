import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { closeClientSection, hasValidToken, isAdmin, logout } from "../../utils";

import {
  // AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
} from "@coreui/react";
import { MyAppSidebarNav } from "../../components";
// sidebar nav config
import navigation, { mobileNav } from "../../_nav";
// routes config
import routes from "../../routes";

// import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { NotasModal } from "../../components/NotasModal";

class DefaultLayout extends Component {
  state = {
    navigation,
  };

  resize = () => {
    const media = window.matchMedia("(max-width: 991px)");

    let newNavigation = { ...navigation };

    if (media.matches) {
      newNavigation.items = [...navigation.items, ...mobileNav.items];
    }

    this.setState({ navigation: newNavigation });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  onGoBackToAdm () {
    logout();
    closeClientSection();
  }

  render() {
    if (!hasValidToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <MyAppSidebarNav
              navConfig={this.state.navigation}
              {...this.props}
            />
            {/* <AppSidebarNav navConfig={this.state.navigation} {...this.props} /> */}
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <NotasModal style={{ borderRadius: '15px' }}/>
          <main className="main d-flex flex-column">
            <div>
              <AppBreadcrumb appRoutes={routes} />
              {isAdmin() 
                ? <button
                    className="button-adm-back"
                    onClick={this.onGoBackToAdm}
                  >
                    Voltar ao ADM
                  </button> 
                : null
              }
            </div>
            <Container fluid className="flex-fill">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" exact />
                <Route>
                  <Redirect to="/404" />
                </Route>
              </Switch>
            </Container>
          </main>
          {/* <AppAside fixed>
            <DefaultAside />
          </AppAside> */}
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default withRouter(DefaultLayout);
