import React from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

function Monetary(props) {
  const {
    value,
    onChange,
    onBlur,
    valid,
    invalid,
    readOnly,
    ...otherProps
  } = props;
  return (
    <InputGroup className="monetary" {...otherProps}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>R$</InputGroupText>
      </InputGroupAddon>
      <Input
        type="text"
        className="text-right"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        valid={valid}
        invalid={invalid}
        readOnly={readOnly}
      />
    </InputGroup>
  );
}

export default Monetary;
