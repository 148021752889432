import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import { toBRL } from "../../utils";
import { Bar } from "react-chartjs-2";

const COR_SUPERIOR = "#FF0029";
const COR_INFERIOR = "#7FC97F";
const COR_META = "#1474C2";

function GraficoReduzirDespesasDetalhado(props) {
  if (
    !props.datas || 
    !props.despesasTotaisPorCategoria || 
    (props.despesasTotaisPorCategoria.map((despesa) => despesa.valor)).length !== (props.valoresMetaPorCategoria
    .filter(
      (meta) =>
        props.despesasTotaisPorCategoria
          .map((despesa) => despesa.categoria)
          .indexOf(meta.categoria) >= 0
      )
      .map((meta) => meta.valor
    )).length
  ) {
    return null;
  }

  const graficoDados = {
    labels: props.despesasTotaisPorCategoria.map(
      (despesa) => despesa.categoria
    ),
    datasets: [
      {
        label: "Previsto",
        data: props.valoresMetaPorCategoria
        .filter(
          (meta) =>
            props.despesasTotaisPorCategoria
              .map((despesa) => despesa.categoria)
              .indexOf(meta.categoria) >= 0
          )
          .map((meta) => meta.valor
        ),
        backgroundColor: COR_META,
      },
      {
        label: "Realizado",
        data: props.despesasTotaisPorCategoria.map((despesa) => despesa.valor),
        backgroundColor: props.despesasTotaisPorCategoria
        .map((despesa) => {
          const meta = props.valoresMetaPorCategoria
          .find((meta)=>meta.categoria === despesa.categoria)

          return despesa.valor > meta.valor
            ? COR_SUPERIOR
            : COR_INFERIOR
          }
        ),
      },
    ],
  };

  const graficoOpcoes = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const { datasetIndex } = tooltipItem;
          let label = data.datasets[datasetIndex].label;
          
          if(data.datasets[datasetIndex].label === "Realizado" && data.datasets[datasetIndex].backgroundColor[tooltipItem.index] === "#FF0029") {
            return `Superado: ${toBRL(tooltipItem.yLabel)}`;
          }

          return `${label}: ${toBRL(tooltipItem.yLabel)}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: (value) => toBRL(value),
          },
        },
      ],
    },
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="text-center">Despesas Detalhadas</CardTitle>
        <div className="legenda">
          <div></div>
          <label>Previsto</label>
          <div></div>
          <label>Realizado</label>
          <div></div>
          <label>Superado</label>
        </div>
        <div className="chart-wrapper" style={{ height: 300, marginTop: 40 }}>   
          <Bar data={graficoDados} options={graficoOpcoes} />
        </div>
      </CardBody>
    </Card>
  );
}

GraficoReduzirDespesasDetalhado.propTypes = {
  datas: PropTypes.arrayOf(
    PropTypes.shape({
      ano: PropTypes.number.isRequired,
      mes: PropTypes.number.isRequired,
    })
  ),
  despesasTotaisPorCategoria: PropTypes.arrayOf(
    PropTypes.shape({
      categoria: PropTypes.string.isRequired,
      valor: PropTypes.number.isRequired,
    })
  ),
  valoresMetaPorCategoria: PropTypes.arrayOf(
    PropTypes.shape({
      categoria: PropTypes.string.isRequired,
      valor: PropTypes.number.isRequired,
    })
  ),
};

export default GraficoReduzirDespesasDetalhado;
