import React, { Fragment, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import { GraficoRosca } from "../../../components";
import { toBRLColor, toPercentColor } from "../../../utils";


function GraficoDeInvestimentos(props) {
  const [idHovered, setaHoverId] = useState(null);

  const hoverCb = index => {
    if (index !== null && index >= 0) {
      setaHoverId(index);
    } else {
      setaHoverId(null);
    }
  };

  const spinner = (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );

  return (
    <Row>
      <Col lg={6} xl={8} className="text-center">
        {props.isLoading && spinner}
        {!props.isLoading && (
        <Fragment>
          <GraficoRosca
            legendas={props.legendas}
            dados={props.dados}
            titulo={props.titulo}
            total={props.total}
            cores={props.cores}
            hoverCb={hoverCb}
          />
        </Fragment>
        )}
      </Col>
      <Col lg={6} xl={4} className="mt-4 mt-lg-0">
        <Card style={{ width: "100%" }}>
        <CardHeader>
          <strong>{props.titulo}</strong>
        </CardHeader>
        <CardBody>
          {props.isLoading && spinner}
          {!props.isLoading && (
            <Table size="sm" borderless responsive>
              <tbody>
                {props.dados.map((valor, i) => (
                  <tr
                    key={`categoria_${i}`}
                    className={idHovered === i ? "table-light" : ""}
                  >
                    <td style={{ width: 30 }} className="text-center">
                      <i
                        className="fa fa-circle"
                        style={{
                          color: props.cores[i],
                          fontSize: idHovered === i ? "1.1rem" : "inherit"
                        }}
                      />
                    </td>
                    <td className="text-left">{props.legendas[i]}</td>
                    <td className="text-right">{toBRLColor(valor)}</td>
                    <td className="text-right">
                      {toPercentColor(valor / props.total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default GraficoDeInvestimentos;