import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import {
  getAxiosAuth,
  wasRequestCancelled,
  toBRLNegative,
  getTokenClientId
} from "../../../utils";
import { DateCarousel } from "../../../components";
import { withRouter } from "react-router-dom";
import { HorizontalBar } from "react-chartjs-2";
import palette from "google-palette";

function BalancoPatrimonial(props) {
  const clienteId = getTokenClientId();
  const m = moment();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [patrimonios, setaPatrimonios] = useState([]);
  const [dividas, setaDividas] = useState([]);
  const [financiamentos, setaFinanciamentos] = useState([]);
  const [investimentos, setaInvestimentos] = useState([]);
  const totalInvestimentos = investimentos
    .map(i => i.valor)
    .reduce((acc, cur) => acc + cur, 0);

  const totalAtivos =
    patrimonios
      .filter(p => p.receitaMensal >= p.custoMensal)
      .map(p => p.valor)
      .reduce((acc, cur) => acc + cur, 0) + totalInvestimentos;
  const totalPassivos = patrimonios
    .filter(p => p.receitaMensal < p.custoMensal)
    .map(p => p.valor)
    .reduce((acc, cur) => acc + cur, 0);
  const totalDividas = dividas
    .map(d => d.valorTotalHoje)
    .reduce((acc, cur) => acc + cur, 0);
  const totalFinanciamentos = financiamentos.reduce((acc, cur) => {
    if (cur.financiamentoCancelado) {
      return acc;
    } else {
      return (
        acc + Math.max(cur.totalParcelas - cur.parcela, 0) * cur.valorParcela
      );
    }
  }, 0);

  const totalPatrimonioLiquido =
    patrimonios.map(p => p.valor).reduce((acc, cur) => acc + cur, 0) -
    totalDividas -
    totalFinanciamentos +
    totalInvestimentos;

  const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "patrimonios" in response.data) {
      setaPatrimonios(response.data.patrimonios);
    }
  };

  const getDividas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/dividas`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "dividas" in response.data) {
      setaDividas(response.data.dividas);
    }
  };

  const getFinanciamentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/financiamentos`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "financiamentos" in response.data) {
      setaFinanciamentos(response.data.financiamentos);
    }
  };

  const getInvestimentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/investimentos`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "investimentos" in response.data) {
      setaInvestimentos(response.data.investimentos);
    }
  };

  const getDados = async () => {
    await getPatrimonios();

    await getDividas();

    await getFinanciamentos();

    await getInvestimentos();
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  // primeiro acesso e quando alterar a data
  useEffect(() => {
    getDados();
  }, [mes, ano]);

  // opções para geração de gráfico
  const paletaCoresA = "cb-Accent";
  const coresA = palette(paletaCoresA, 2).map(hex => "#" + hex);

  const paletaCoresB = "mpn65";
  const coresB = palette(paletaCoresB, 4).map(hex => "#" + hex);

  const datasets = [
    {
      label: "Bens Passivos",
      data: [totalPassivos],
      backgroundColor: coresA[1],
      barPercentage: 0.5
    },
    {
      label: "Bens Ativos",
      data: [totalAtivos],
      backgroundColor: coresA[0],
      barPercentage: 0.5
    },
    {
      label: "Patrimônio Líquido",
      data: [0, totalPatrimonioLiquido],
      backgroundColor: coresB[1],
      barPercentage: 0.5
    },
    {
      label: "Financiamentos",
      data: [0, totalFinanciamentos],
      backgroundColor: coresB[3],
      barPercentage: 0.5
    },
    {
      label: "Dívidas",
      data: [0, totalDividas],
      backgroundColor: coresB[0],
      barPercentage: 0.5
    }
  ];

  const DATA = {
    labels: ["A", "B"],
    datasets
  };

  const OPTIONS = {
    maintainAspectRatio: false,
    // responsive: true,
    tooltips: {
      callbacks: {
        title: function() {},
        label: function(tooltipItem, data) {
          const { index, datasetIndex, xLabel } = tooltipItem;

          if (index === 0 && datasetIndex > 1) {
            return;
          }

          let label = data.datasets[datasetIndex].label || "";

          if (label) {
            label += `: `;
          }
          label += toBRLNegative(xLabel);
          return label;
        },
        footer: function(attrs, data) {
          const tooltipItem = attrs[0];
          const { index } = tooltipItem;
          let total;

          if (index === 0) {
            total = totalAtivos + totalPassivos;
          } else if (index === 1) {
            total = totalPatrimonioLiquido + totalDividas + totalFinanciamentos;
          } else {
            return;
          }

          return `Total: ` + toBRLNegative(total);
        }
      }
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: function(value) {
              return toBRLNegative(value);
            }
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          display: false
        }
      ]
    }
  };

  return (
    <div className="animated fadeIn">
      <Container>
        <Row>
          <Col md={6} sm={12} className="text-center">
            <h4 className="mt-1 mb-2">Balanço Patrimonial</h4>
          </Col>
          <Col md={6} sm={12} className="text-center mt-2 mt-md-0">
            <DateCarousel travaMesAtual mes={mes} ano={ano} onChange={onChangeDate} />
          </Col>
        </Row>
        <div className="chart-wrapper mt-3 mb-5" style={{ minHeight: 300 }}>
          <HorizontalBar data={DATA} options={OPTIONS} />
        </div>
      </Container>
    </div>
  );
}

export default withRouter(BalancoPatrimonial);
