const calcularPorcentagem =(data)=> {
    // Inicializar contadores
    // let totalQuantidade = 0;
    let totalValor = 0;

    // Inicializar contadores por tipo
    const contadoresPorTipo = {
      poupador: { quantidade: 0, soma: 0 },
      gastador: { quantidade: 0, soma: 0 },
      descontrolado: { quantidade: 0, soma: 0 },
      desligado: { quantidade: 0, soma: 0 },
      financista: { quantidade: 0, soma: 0 },
    };

    // Iterar sobre os dados
    data.forEach((item) => {
      if (item.valor !== null) {
        // Incrementar contadores gerais
        // totalQuantidade += 1;
        totalValor += item.valor;

        // Incrementar contadores por tipo
        if (contadoresPorTipo[item.tipo]) {
          contadoresPorTipo[item.tipo].quantidade += 1;
          contadoresPorTipo[item.tipo].soma += item.valor;
        }
      }
    });

    // Calcular percentuais por tipo
    const percentuaisPorTipo = {};
    for (const tipo in contadoresPorTipo) {
      const { quantidade, soma } = contadoresPorTipo[tipo];
      const percentual = quantidade > 0 ? (soma / totalValor) * 100 : 0;
      percentuaisPorTipo[tipo] = percentual.toFixed(2);
    }

    return percentuaisPorTipo;
  }

export default calcularPorcentagem;