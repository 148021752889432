import React from "react";
import { Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { getAxiosAuth, SERVICE_URL } from "../../../utils";

function BtnDownloadApolice(props) {
  const onClick = async (e) => {
    e.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${props.clienteId}/uploads/${props.uploadId}`;

    try {
      let response = await axiosInstance.get(uri);

      if (response && response.data && response.data.downloadToken) {
        const linkUrl =
          SERVICE_URL + `downloads?token=${response.data.downloadToken}`;
        const link = document.createElement("a");
        link.href = linkUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err.message);
      return;
    }
  };

  return (
    <Button
      color="link"
      onClick={onClick}
      className="text-left"
      style={{ paddingLeft: 0 }}
    >
      <i
        className="far fa-file-word"
        style={{ display: "inline-block", marginRight: 5 }}
      />
      Documento da apólice
    </Button>
  );
}

BtnDownloadApolice.propTypes = {
  uploadId: PropTypes.number.isRequired,
  uploadFilename: PropTypes.string.isRequired,
  clienteId: PropTypes.string.isRequired,
};

export default withRouter(BtnDownloadApolice);
