import React, { Fragment } from "react";
import { toBRLColor } from "../../../utils";
import ItemAtivo from "./ItemAtivo";

function LinhaInvestimento(props) {
  return (
    <Fragment>
        <tr key={props.key}>
            <td className="text-small pl-5" style={{
              maxWidth:"200px",
              overflow:"hidden",
              textOverflow:"ellipsis",
            }}>
                {props.instituicao}
            </td>
            <td className="d-none d-sm-table-cell text-right text-nowrap">
            {toBRLColor(props.valor)}
            </td>
            <td className="d-none d-sm-table-cell text-right text-nowrap" >
            {props.tipo}
            </td>
            <td className="d-none d-sm-table-cell text-right text-nowrap" style={{
              maxWidth:"200px",
              overflow:"hidden",
              textOverflow:"ellipsis",
            }}>
            {props.descricao}
            </td>
            <td className="d-none d-sm-table-cell text-right text-nowrap">
            {props.risco}
            </td>
            <td className="d-none d-sm-table-cell text-right text-nowrap">
            {props.liquidez}
            </td>
            <td className="d-none d-sm-table-cell text-center">
            {<ItemAtivo />}
            </td>
        </tr>
    </Fragment>
  );
}

export default LinhaInvestimento;
