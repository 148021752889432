import React, { Fragment, useState } from "react";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";

function ELSFilterSelector(props) {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const tipos = [
    {label: "Essencial", value:"Essencial"},
    {label: "Livre", value:"Livre" },
    {label: "Supérflua", value:"Supérflua"}
    ]

  const onChangeELS = (newValue) => {
    if (newValue === null) {
      newValue = [];
    }
    props.onChange && props.onChange(newValue);
  };    

  const togglePopover = e => {
    setPopoverOpen(!isPopoverOpen);
    e.stopPropagation();
  };

  return (
    <Fragment>
      <Button
        color="link"
        className="dropdown-toggle"
        id="popover-ELS-data"
        onClick={togglePopover}
      >
        {(!props.ELS || props.ELS.length === 0) && "Filtrar por tipo"}
        {props.ELS && props.ELS.length > 0 && (
          <strong>Filtrando por tipo</strong>
        )}
      </Button>
      <Popover
        placement="bottom"
        isOpen={isPopoverOpen}
        toggle={togglePopover}
        target="popover-ELS-data"
      >
        <PopoverHeader className="text-center p2">
          <span className="h5">Tipos</span>
        </PopoverHeader>
        <PopoverBody className="p2" style={{ minWidth: 250 }}>
          <Select
            isMulti
            placeholder="Selecione"
            options={tipos.map((t) => ({ label: t.label, value: t.value }))}
            onChange={onChangeELS}
            value={props.ELS}
            noOptionsMessage={() => "Sem tipo selecionado"}
          />
        </PopoverBody>
      </Popover>
      
    </Fragment>
  );
}

ELSFilterSelector.propTypes = {
  onChange: PropTypes.func,
  ELS: PropTypes.arrayOf(PropTypes.object)
};

export default withRouter(ELSFilterSelector);
