import React from "react";
import { Container, Row, Col } from "reactstrap";

function Page404() {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">404</h1>
              <h4 className="pt-3">Página Não Encontrada</h4>
              <p className="text-muted float-left">
                A página solicitada não foi encontrada.
                <br />
                <br />
                <a href="/">Página inicial</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Page404;
