import React from "react";
import { Badge } from "reactstrap";
import moment from "moment";
import { toBRLColor } from "../../../utils";
import PropTypes from "prop-types";
import BtnModalFormContribuicao from "./BtnModalFormContribuicao";
import BtnModalRemoverContribuicao from "./BtnModalRemoverContribuicao";
import patrimonyLinkedPath from '../../../assets/img/patrimonyLinked.png'

function LinhaContribuicao(props) {
  const m = moment(props.dataContribuicao);
  let diaContribuicao = m.format("DD");
  let mesContribuicao = m.format("MM");
  let anoContribuicao = m.format("YYYY");

  const btns = (
    <div style={{ marginTop: 5 }}>
      <BtnModalFormContribuicao
        id={props.id}
        dia={Number(diaContribuicao)}
        mes={Number(mesContribuicao)}
        ano={Number(anoContribuicao)}
        descricao={props.descricao}
        valor={props.valor}
        recorrente={props.recorrente}
        onEdit={props.onEdit}
        patrimonioId={props.patrimonioId}
        patrimonioUuid={props.patrimonioUuid}
        tags={props.tags}
        projetoId={props.projetoId}
      />
      {!props.projetoId && 
        <BtnModalRemoverContribuicao
          id={props.id}
          descricao={props.descricao}
          valor={props.valor}
          onDelete={props.onDelete}
          recorrente={props.recorrente}
        />
      }
    </div>
  );

  const badgeRecorrente = (
    <Badge color="warning" pill style={{ marginRight: 5 }}>
      <div style={{marginTop:"2px"}}><i className="fas fa-clock" /> Recorrente</div>
    </Badge>
  );

  const tagsPills =
  props.tags &&
  props.tags.map((tag) => (
    <Badge pill className="ml-1" key={`tag_` + props.id + `_` + tag.id}>
      {tag.nome}
    </Badge>
  ));

  const regex = /(Projeto:)/;
  const parts = props.descricao.split(regex);

  return (
  
    <tr>
      <td>
        {diaContribuicao}/{mesContribuicao}
      </td>
      <td>
        <span style={{ marginRight: 5}}>
          <p style={{maxWidth:"300px", overflow:"hidden", textOverflow:"ellipsis"}}>
            {parts.map((part, index) => regex.test(part) 
              ? <strong key={index}>{part}</strong>
              : part
            )}
          </p>
          {tagsPills}
        </span>
        <span className="d-block d-sm-none">
          {" "}
          {props.recorrente ? badgeRecorrente : null}
        </span>
      </td>
      <td className="text-left d-none d-sm-table-cell">
        <div style={{display:"flex", gap:"5px"}}>
      {props.patrimonioUuid ? (
              <Badge pill color='info'  title="Patrimônio vinculado a esta receita">
                <span>
                  <img alt="Patrimônio vinculado" src={patrimonyLinkedPath}  style={{
                    width: '18px',
                    height: '15px',
                    position: 'relative',
                    top:'-2px'
                  }}
                /> 
                Patrimonio
              </span>
            </Badge>
            ):null}
        {props.recorrente ? badgeRecorrente : null}
        </div>
      </td>
      <td className="text-right text-nowrap">
        {toBRLColor(props.valor)}

        <div className="d-block d-sm-none">{btns}</div>
      </td>
      <td className="text-right d-none d-sm-table-cell">{btns}</td>
    </tr>
  );
}

LinhaContribuicao.propTypes = {
  id: PropTypes.number.isRequired,
  dataContribuicao: PropTypes.string.isRequired,
  descricao: PropTypes.string,
  valor: PropTypes.number,
  recorrente: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object),
  patrimonioId: PropTypes.number,
};

export default LinhaContribuicao;
