import React from "react";
import { Badge } from "reactstrap";

function ItemPassivo() {
  return (
    <Badge color="danger" pill className="p-2">
      Passivo
    </Badge>
  );
}

export default ItemPassivo;
