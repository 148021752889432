import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Alert,
} from "reactstrap";
import {
  FormErrMsg,
  DateCarousel,
  Monetary,
  ModalConfirmarAlteracoesNaoSalvas,
} from "../../../components";
import {
  getAxiosAuth,
  getTokenClientId,
  isPositiveBRL,
  fromBRL,
  pad,
  toBRLValueOnly,
} from "../../../utils";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TIPOS_INVESTIMENTOS, LIQUIDEZ_INVESTIMENTOS, TIPOS_RISCOS, maxWidthText } from "../../../options";
import moment from "moment";
import { withRouter } from "react-router-dom";

function Carteira(props) {
  const m = moment();
  const clienteId = getTokenClientId();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [tmpMes, setaTmpMes] = useState();
  const [tmpAno, setaTmpAno] = useState();
  const [investimentos, setaInvestimentos] = useState([]);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [salvar, setaSalvar] = useState();
  const [obter, setaObter] = useState();
  const [modalAvisoAberto, setaModalAvisoAberto] = useState(false);
  const [alteracoesPendentes, setaAlteracoesPendentes] = useState();
  const [refreshDateCaroulsel, setaRefreshDateCaroulsel] = useState();

  const NOVO_INVESTIMENTO = {
    id: null,
    instituicao: "",
    instituicaoErro: null,
    tipo: TIPOS_INVESTIMENTOS[0],
    tipoErro: null,
    descricao: "",
    descricaoErro: null,
    valor: "",
    valorErro: null,
    liquidez: LIQUIDEZ_INVESTIMENTOS[0],
    liquidezErro: "",
  };

  const limpaTodos = () => {
    setaInvestimentos([{ ...NOVO_INVESTIMENTO }]);
  };

  // primeiro acesso: obtem dados de investimentos
  useEffect(() => {
    const getInvestimentos = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/investimentos`;
      const params = { filtroMes: mes, filtroAno: ano };
      let response;

      try {
        response = await axiosInstance.get(uri, { params });
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
        } else {
          console.error(err);
          props.history.push("/500");
          return;
        }
      }

      const dados = response.data;

      if (
        dados.investimentos &&
        Array.isArray(dados.investimentos) &&
        dados.investimentos.length > 0
      ) {
        let novosInvestimentos = dados.investimentos.map((investimento) => ({
          ...NOVO_INVESTIMENTO,
          id: investimento.id,
          instituicao: investimento.instituicao,
          tipo: investimento.tipo,
          descricao: investimento.descricao,
          valor: toBRLValueOnly(investimento.valor),
          liquidez: investimento.liquidez,
          risco: investimento.risco,
        }));

        setaInvestimentos(novosInvestimentos);
      } else {
        limpaTodos();
      }
    };

    getInvestimentos();
  }, [obter]);

  const onChangeDate = (novoMes, novoAno) => {
    if (alteracoesPendentes) {
      setaTmpMes(novoMes);
      setaTmpAno(novoAno);
      setaModalAvisoAberto(true);
      setaRefreshDateCaroulsel(new Date());
    } else {
      setaMes(novoMes);
      setaAno(novoAno);
    }
  };

  const onConfirmModal = () => {
    setaModalAvisoAberto(false);
    setaAlteracoesPendentes(false);
    setaMes(tmpMes);
    setaAno(tmpAno);
  };

  const onCancelModal = () => {
    setaModalAvisoAberto(false);
  };

  // quando mudar a data
  useEffect(() => {
    setaObter(new Date());
  }, [mes, ano]);

  const investimentoLimpo = (idx) => {
    if (!investimentos[idx]) return true;

    const investimento = investimentos[idx];

    return (
      validator.isEmpty(investimento.instituicao, {
        ignore_whitespace: true,
      }) &&
      validator.isEmpty(investimento.descricao, { ignore_whitespace: true }) &&
      validator.isEmpty(investimento.valor, { ignore_whitespace: true })
    );
  };

  const estaLimpo = () => {
    return investimentos.reduce((acc, cur, idx) => {
      if (acc) {
        acc = investimentoLimpo(idx);
      }
      return acc;
    }, true);
  };

  // salvar
  useEffect(() => {
    let anoPad = pad(ano, 4);
    let mesPad = pad(mes, 2);
    let diaPad = pad(1, 2);
    const dataInvestimento = `${anoPad}-${mesPad}-${diaPad}`;

    const putInvestimentos = async () => {
      const dadosInvestimentos = investimentos
        .reduce((acc, cur, idx) => {
          if (!investimentoLimpo(idx)) {
            acc.push(cur);
          }

          return acc;
        }, [])
        .map((investimento) => {
          return {
            id: investimento.id,
            dataInvestimento,
            instituicao: investimento.instituicao,
            tipo: investimento.tipo,
            descricao: investimento.descricao,
            valor: fromBRL(investimento.valor),
            risco: investimento.risco,
            liquidez: investimento.liquidez,
          };
        });

      const dados = { investimentos: dadosInvestimentos, dataInvestimento };

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/investimentos`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err.response);
        } else {
          props.history.push("/500");
          console.error(err);
          return;
        }
      }

      if (response.status === 200) {
        toast("Investimentos salvos com sucesso!");

        setaObter(new Date());
        setaAlteracoesPendentes(false);
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        props.history.push("/500");
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      putInvestimentos();
    }
  }, [salvar]);

  const validaInstituicao = (idx) => {
    if (!investimentos[idx]) return true;

    let novosInvestimentos = [...investimentos];

    const investimento = novosInvestimentos[idx];
    const preenchido = !investimentoLimpo(idx);

    let valido = true;

    if (
      preenchido &&
      validator.isEmpty(investimento.instituicao, { ignore_whitespace: true })
    ) {
      investimento.instituicaoErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido) {
      investimento.instituicaoErro = preenchido ? false : null;
    }

    setaInvestimentos(novosInvestimentos);

    return valido;
  };

  const validaTipo = (idx) => {
    if (!investimentos[idx]) return true;

    let novosInvestimentos = [...investimentos];

    const investimento = novosInvestimentos[idx];
    const preenchido = !investimentoLimpo(idx);

    let valido = true;

    if (
      preenchido &&
      validator.isEmpty(investimento.tipo, { ignore_whitespace: true })
    ) {
      investimento.tipoErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido) {
      investimento.tipoErro = preenchido ? false : null;
    }

    setaInvestimentos(novosInvestimentos);

    return valido;
  };

  const validaDescricao = (idx) => {
    if (!investimentos[idx]) return true;

    let novosInvestimentos = [...investimentos];

    const investimento = novosInvestimentos[idx];
    const preenchido = !investimentoLimpo(idx);

    let valido = true;

    if (
      preenchido &&
      validator.isEmpty(investimento.descricao, { ignore_whitespace: true })
    ) {
      investimento.descricaoErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido) {
      investimento.descricaoErro = preenchido ? false : null;
    }

    setaInvestimentos(novosInvestimentos);

    return valido;
  };

  const validaValor = (idx) => {
    if (!investimentos[idx]) return true;

    let novosInvestimentos = [...investimentos];

    const investimento = novosInvestimentos[idx];
    const preenchido = !investimentoLimpo(idx);

    let valido = true;

    if (
      preenchido &&
      validator.isEmpty(investimento.valor, { ignore_whitespace: true })
    ) {
      investimento.valorErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido && !isPositiveBRL(investimento.valor)) {
      investimento.valorErro = "Valor monetário inválido";
      valido = false;
    }

    if (valido) {
      investimento.valorErro = preenchido ? false : null;
    }

    setaInvestimentos(novosInvestimentos);

    return valido;
  };

  const validaLiquidez = (idx) => {
    if (!investimentos[idx]) return true;

    let novosInvestimentos = [...investimentos];

    const investimento = novosInvestimentos[idx];
    const preenchido = !investimentoLimpo(idx);

    let valido = true;

    if (
      preenchido &&
      validator.isEmpty(investimento.liquidez, { ignore_whitespace: true })
    ) {
      investimento.liquidezErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido) {
      investimento.liquidezErro = preenchido ? false : null;
    }

    setaInvestimentos(novosInvestimentos);

    return valido;
  };

  const validaRisco = (idx) => {
    if (!investimentos[idx]) return true;

    let novosInvestimentos = [...investimentos];

    const investimento = novosInvestimentos[idx];
    const preenchido = !investimentoLimpo(idx);

    let valido = true;

    if (
      preenchido &&
      !TIPOS_RISCOS.includes(investimento.risco)
    ) {
      investimento.riscoErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido) {
      investimento.riscoErro = preenchido ? false : null;
    }

    setaInvestimentos(novosInvestimentos);

    return valido;
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    let valido = true;

    if (estaLimpo()) {
      limpaTodos();
    } else {
      valido = investimentos.reduce((valido, investimento, idx) => {
        const instituicaoValida = validaInstituicao(idx);
        const tipoValido = validaTipo(idx);
        const descricaoValida = validaDescricao(idx);
        const valorValido = validaValor(idx);
        const liquidezValida = validaLiquidez(idx);
        const risco = validaRisco(idx);

        if (
          valido &&
          (!instituicaoValida ||
            !tipoValido ||
            !descricaoValida ||
            !valorValido ||
            !liquidezValida ||
            !risco)
        ) {
          valido = false;
        }

        return valido;
      }, true);
    }

    if (valido) {
      setaFormularioValido(true);
      setaSalvar(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  const onClickBtnAdicionarInvestimento = (e) => {
    e.preventDefault();

    const novosInvestimentos = [...investimentos, { ...NOVO_INVESTIMENTO }];

    setaInvestimentos(novosInvestimentos);
  };

  const onClickBtnRemoverInvestimento = (idx, e) => {
    e.preventDefault();

    let novosInvestimentos = [...investimentos];

    if (novosInvestimentos.length === 1) {
      novosInvestimentos[idx] = { ...NOVO_INVESTIMENTO };
    } else {
      novosInvestimentos.splice(idx, 1);
    }

    setaInvestimentos(novosInvestimentos);
  };

  const onChangeCampo = (idx, campo, valor) => {
    if (!investimentos[idx]) return;

    let novosInvestimentos = [...investimentos];

    novosInvestimentos[idx][campo] = valor;

    setaInvestimentos(novosInvestimentos);

    setaAlteracoesPendentes(new Date());
  };

  const onChangeInstituicao = (idx, e) => {
    const valor = e.target.value.slice(0,maxWidthText)
    onChangeCampo(idx, "instituicao", valor);
  };

  const onChangeTipo = (idx, e) => {
    onChangeCampo(idx, "tipo", e.target.value);
  };

  const onChangeDescricao = (idx, e) => {
    const valor = e.target.value.slice(0,maxWidthText)
    onChangeCampo(idx, "descricao", valor);
  };

  const onChangeValor = (idx, e) => {
    onChangeCampo(idx, "valor", e.target.value);
  };

  const onChangeLiquidez = (idx, e) => {
    onChangeCampo(idx, "liquidez", e.target.value);
  };

  const onChangeRisco = (idx, e) => {
    onChangeCampo(idx, "risco", e.target.value);
  };

  const onBlurInstituicao = (idx) => {
    validaInstituicao(idx);
  };

  const onBlurDescricao = (idx) => {
    validaDescricao(idx);
  };

  const onBlurValor = (idx) => {
    validaValor(idx);
  };

  const classRequired = estaLimpo() ? "" : "required";

  const totalCarteira = toBRLValueOnly(
    investimentos.reduce((acc, cur) => {
      let valor = fromBRL(cur.valor);
      if (!isNaN(valor)) {
        return acc + valor;
      } else {
        return acc;
      }
    }, 0.0)
  );

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <ModalConfirmarAlteracoesNaoSalvas
        aberto={modalAvisoAberto}
        onContinue={onConfirmModal}
        onCancel={onCancelModal}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <div className="text-center">
          <DateCarousel
            travaMesAtual
            ano={ano}
            mes={mes}
            onChange={onChangeDate}
            refresh={refreshDateCaroulsel}
          />
        </div>
        <Card>
          <CardHeader>
            <i className="fas fa-wallet" />
            <strong>
              Relacione o saldo atual de sua carteira de investimentos
            </strong>
          </CardHeader>
          <CardBody>
            {investimentos.map((investimento, idx) => (
              <Row form key={`investimento_${idx}`}>
                <Col md={3} lg={2}>
                  <FormGroup>
                    <Label className={classRequired}>Instituição</Label>
                    <Input
                      type="text"
                      value={investimento.instituicao}
                      valid={investimento.instituicaoErro === false}
                      invalid={!!investimento.instituicaoErro}
                      onChange={onChangeInstituicao.bind(this, idx)}
                      onBlur={onBlurInstituicao.bind(this, idx)}
                    />
                    <FormErrMsg>{investimento.instituicaoErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col md={4} lg={2}>
                  <FormGroup>
                    <Label className={classRequired}>Tipo</Label>
                    <Input
                      type="select"
                      value={investimento.tipo}
                      valid={investimento.tipoErro === false}
                      invalid={!!investimento.tipoErro}
                      onChange={onChangeTipo.bind(this, idx)}
                    >
                      {TIPOS_INVESTIMENTOS.map((tipo) => (
                        <option
                          value={tipo}
                          key={`investimento_tipo_${tipo}_${idx}`}
                        >
                          {tipo}
                        </option>
                      ))}
                    </Input>
                    <FormErrMsg>{investimento.tipoErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col md={5} lg={2}>
                  <FormGroup>
                    <Label className={classRequired}>Descrição</Label>
                    <Input
                      type="text"
                      value={investimento.descricao}
                      valid={investimento.descricaoErro === false}
                      invalid={!!investimento.descricaoErro}
                      onChange={onChangeDescricao.bind(this, idx)}
                      onBlur={onBlurDescricao.bind(this, idx)}
                    />
                    <FormErrMsg>{investimento.descricaoErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col md={2} >
                  <FormGroup>
                    <Label className={classRequired}>Valor</Label>
                    <Monetary
                      value={investimento.valor}
                      valid={investimento.valorErro === false}
                      invalid={!!investimento.valorErro}
                      onChange={onChangeValor.bind(this, idx)}
                      onBlur={onBlurValor.bind(this, idx)}
                    />
                    <FormErrMsg>{investimento.valorErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col md={2} lg={2} style={{maxWidth: '150px', width: '100%'}}>
                  <FormGroup>
                    <Label className={classRequired}>Liquidez</Label>
                    <Input
                      type="select"
                      value={investimento.liquidez}
                      valid={investimento.liquidezErro === false}
                      invalid={!!investimento.liquidezErro}
                      onChange={onChangeLiquidez.bind(this, idx)}
                    >
                      {LIQUIDEZ_INVESTIMENTOS.map((tipo) => (
                        <option value={tipo} key={`liquidez_${tipo}_${idx}`}>
                          {tipo}
                        </option>
                      ))}
                    </Input>
                    <FormErrMsg>{investimento.liquidezErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col md={2} lg={2} style={{maxWidth: '100px', width: '100%'}}>
                  <FormGroup>
                  <Label className={classRequired}>Risco</Label>
                    <Input
                      type="select"
                      value={investimento.risco}
                      valid={investimento.riscoErro === false}
                      invalid={!!investimento.riscoErro}
                      onChange={onChangeRisco.bind(this, idx)}
                    >
                      <option value={0} key={`risco_selecione_${idx}`} style={{display: "none"}}>
                        ----
                      </option> 
                      {TIPOS_RISCOS.map((tipo) => (
                        <option value={tipo} key={`risco_${tipo}_${idx}`}>
                          {tipo}
                        </option>
                      ))}
                    </Input>
                    <FormErrMsg>{investimento.riscoErro}</FormErrMsg>
                  </FormGroup>
                </Col>
                <Col md={1} lg={1} className="d-flex align-items-center">
                  <ButtonGroup>
                    <Button
                      color="primary"
                      onClick={onClickBtnAdicionarInvestimento}
                    >
                      <i className="fas fa-plus" />
                    </Button>
                    <Button
                      color="secondary"
                      onClick={onClickBtnRemoverInvestimento.bind(this, idx)}
                    >
                      <i className="fas fa-minus" />
                    </Button>
                  </ButtonGroup>  
                </Col>
              </Row>
            ))}
            <Row form>
              <Col md={3} lg={3} />
              <Col md={4} lg={2} />
              <Col md={5} lg={2} />
              <Col md={`auto`}>
                <FormGroup>
                  <Label>Total</Label>
                  <Monetary readOnly value={totalCarteira} />
                </FormGroup>
              </Col>
              <Col md={1} lg={1} className="d-flex align-items-center" />
            </Row>
            {formularioValido === false && (
              <Alert color="danger" style={{ display: "inline-block" }}>
                Não foi possível salvar. {formularioErro}
              </Alert>
            )}
          </CardBody>
          <CardFooter>
            <Button type="submit" color="primary" style={{ display: "block" }}>
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </div>
  );
}

export default withRouter(Carteira);
