import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import { LoadingSpinner } from "../../../components";

import {
    getAxiosAuth,
    wasRequestCancelled,
    getTokenClientId
} from "../../../utils";
import palette from "google-palette";
import moment from "moment";

import { DateSpanSelector, GraficoRelatorio, GraficoDespesasEssenciais, GraficoInvestimentosMnesais, GraficoSuperfuloLivre } from "../../../components";
import TabelaResumo from "./TabelaResumo";

const Relatorio50 = (props) => {
    const clienteId = getTokenClientId();

    const dataInicialPadrao = moment().subtract(2, "month");
    const dataFinalPadrao = moment();

    const [loading, isLoading] = useState(true);
    const [data, setData] = useState({})
    const [monthsLabelHook, setMonthsLabelHook] = useState([]);
    const [calcular, setaCalcular] = useState(true);
    const [coresInvestimentos, setCoresInvestimentos] = useState([]);
    const [totalInvestimentos, setTotalInvestimentos] = useState(0.0);
    const [totaisReceitas, setTotaisReceitas] = useState(0.0);
    const [totaisDespesasEssencial, setaTotaisDespesasEssencial] = useState(0.0);
    const [totaisDespesasLivreSuperfula, setaTotaisDespesasLivreSuperfula] = useState(0.0);
    const [erroFuturo, setErroFuturo] = useState(undefined);

    const [mesInicial, setaMesInicial] = useState(
        Number(dataInicialPadrao.format("M"))
    );
    const [anoInicial, setaAnoInicial] = useState(
        Number(dataInicialPadrao.format("YYYY"))
    );
    const [mesFinal, setaMesFinal] = useState(
        Number(dataFinalPadrao.format("M"))
    );
    const [anoFinal, setaAnoFinal] = useState(
        Number(dataFinalPadrao.format("YYYY"))
    );

    const onChangeDateSpan = (mesInicial, anoInicial, mesFinal, anoFinal) => {
        const dataInicial = moment([anoInicial, mesInicial - 1]);
        const dataFinal = moment([anoFinal, mesFinal - 1]);

        // 13/03/2023 - 10/03/2023 > 0
        if(moment().diff(dataInicial) < 0 || moment().diff(dataFinal) < 0) {
            setErroFuturo('Não é possivel visualizar historico de índices futuros!');
            return;
        } 

        setErroFuturo(undefined);
        setaMesInicial(mesInicial);
        setaAnoInicial(anoInicial);
        setaMesFinal(mesFinal);
        setaAnoFinal(anoFinal);
        setaCalcular(true);
    };

    const getInvestimentos = async (mes, ano) => {
        const axiosInstance = getAxiosAuth(() => {
            // callback: redireciona se não conseguir renovar tokens
            props.history.push("/login");
        });

        const uri = `/clientes/${clienteId}/contribuicoes`;
        const dados = { filtroMes: mes, filtroAno: ano };
        let response;

        try {
            response = await axiosInstance.get(uri, { params: dados });
        } catch (err) {
            if (!wasRequestCancelled(err)) {
            console.error(err);
            }
            props.history.push("/500");
            return;
        }
        
        if (response.data && "contribuicoes" in response.data) {
            let compCores = palette("tol-rainbow", 3).map((cor) => "#" + cor);
            let total = 0;

            (response.data.contribuicoes).forEach((contribuicao) => {
                total += contribuicao.valor;
            })

            setCoresInvestimentos(compCores);
            return total;
        }
    };

    const getDespesas = async(mes, ano) => {
        const axiosInstance = getAxiosAuth(() => {
            // callback: redireciona se não conseguir renovar tokens
            props.history.push("/login");
        });

        const uri = `/clientes/${clienteId}/despesas`;
        const dados = { filtroMes: mes, filtroAno: ano };
        let response;

        try {
            response = await axiosInstance.get(uri, { params: dados });
        } catch (err) {
            if (!wasRequestCancelled(err)) {
            console.error(err);
            }
            props.history.push("/500");
            return;
        }
        
        if(response.data && "categorias" in response.data) {
            let totalEssencial = 0;
            let totalLivreSuperfula = 0;

            (response.data.categorias).forEach((categoria) => (
                categoria.despesas.forEach((despesa) => {
                    if(despesa.tipo === "Essencial") {
                        totalEssencial += despesa.valor;
                        return totalEssencial;
                    } else {
                        totalLivreSuperfula += despesa.valor;
                        return totalLivreSuperfula;
                    }
                })
            ))

            return { totalLivreSuperfula, totalEssencial };
        }
    } 


    const getReceitas = async(mes, ano) => {
        const axiosInstance = getAxiosAuth(() => {
            // callback: redireciona se não conseguir renovar tokens
            props.history.push("/login");
        });

        const uri = `/clientes/${clienteId}/receitas`;
        const dados = { filtroMes: mes, filtroAno: ano };
        let response;

        try {
            response = await axiosInstance.get(uri, { params: dados });
        } catch (err) {
            if (!wasRequestCancelled(err)) {
            console.error(err);
            }
            props.history.push("/500");
            return;
        }

        if (response.data && "items" in response.data) {
            return response.data.totalLiquido;
        }
    }
    
    const getDados = async(monthsLabel) => {
        const dates = monthsLabel.reduce((acc, cur) => {
            if(!acc[cur]) {
                acc[cur] = {};
            }
            
            return acc;
        }, {})

        let totalInvestimentos = 0;
        let totalReceitas = 0;
        let totalDespesaEssencial = 0;
        let totalDespesaLivreSuperfula = 0;

        await Promise.all( Object.keys(dates).map(async(date, index) => {
            const numMes = moment().month(date).format('M');
            const numAno = moment(date).year();
      
            const investimentosLocal = await getInvestimentos(numMes, numAno);  //Contribuições
            const receitasLocal = await getReceitas(numMes, numAno);            //Receitas

            const {
                totalEssencial: despesasEssencialLocal,
                totalLivreSuperfula: despesasLivreSuperfula
            } = await getDespesas(numMes, numAno); //Despesas

            totalInvestimentos += Number(investimentosLocal);   
            dates[date]['totalInvestimentos'] = Number(investimentosLocal)

            totalReceitas += Number(receitasLocal);
            dates[date]['totalReceitas'] = Number(receitasLocal);

            totalDespesaEssencial += Number(despesasEssencialLocal);
            dates[date]['totalDespesaEssencial'] = Number(despesasEssencialLocal);

            totalDespesaLivreSuperfula += Number(despesasLivreSuperfula);
            dates[date]['totalDespesaSuperfula'] = Number(despesasLivreSuperfula);

            if(index === Object.keys(dates).length - 1) {
                setTotalInvestimentos(totalInvestimentos)
                setTotaisReceitas(totalReceitas)
                setaTotaisDespesasEssencial(totalDespesaEssencial)
                setaTotaisDespesasLivreSuperfula(totalDespesaLivreSuperfula);    
            }
        }));

        console.log(dates);

        setData(dates);
        isLoading(false);
    }

    useEffect(() => {
        setData({});
        isLoading(true);

        const months = moment.monthsShort();
    
        const dataInicial = moment([anoInicial, mesInicial - 1]);
        const dataFinal = moment([anoFinal, mesFinal - 1]);
        const monthsLabel = []
        let indice = moment(dataInicial).month();
        let dif = dataFinal.diff(dataInicial, "months")
        for(let i = 0; i <= dif; i++) {
            if(indice >= months.length) {
                indice = 0;
                dataInicial.add(1, "year"); // Incrementa o ano apenas quando necessário
            }
        
            monthsLabel.push(months[indice] + '-' + dataInicial.format('YYYY'))
            indice++;
        }
        getDados(monthsLabel);
        setMonthsLabelHook(monthsLabel)
        setaCalcular(false);
    }, [calcular])

    return (
        <Container style={{ maxWidth: "100%" }}>
            <Row className="mb-3">
                <Col lg={12}>
                    <h4 className="text-center">Relatório 50/30/20</h4>
                    <div className="mb-2 text-center">
                    {!!erroFuturo && <div className="alert alert-danger">{erroFuturo}</div>}
                    <DateSpanSelector
                        mesInicial={mesInicial}
                        anoInicial={anoInicial}
                        mesFinal={mesFinal}
                        anoFinal={anoFinal}
                        onChange={onChangeDateSpan}
                    />  
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={12}>
                    {Object.keys(data).length !== 0 && !loading ?
                        <>
                            <React.Suspense key={Object.keys(data).length !== 0} fallback={
                                <LoadingSpinner />
                            }>
                                <GraficoDespesasEssenciais 
                                    investimentosMensais={data}
                                    cores={coresInvestimentos}
                                    valorMetaTotal={(totaisDespesasEssencial/totaisReceitas)}
                                    meses={monthsLabelHook}
                                /> 
                                <GraficoSuperfuloLivre 
                                    investimentosMensais={data}
                                    cores={coresInvestimentos}
                                    valorMetaTotal={(totaisDespesasLivreSuperfula/totaisReceitas)}
                                    meses={monthsLabelHook}
                                />
                                <GraficoInvestimentosMnesais 
                                    investimentosMensais={data}
                                    cores={coresInvestimentos}
                                    valorMetaTotal={(totalInvestimentos/totaisReceitas)}
                                    meses={monthsLabelHook}
                                />

                                <TabelaResumo data={data} />
                                
                                <GraficoRelatorio data={data} meses={monthsLabelHook}/>
                            </React.Suspense>
                        </>
                    : <LoadingSpinner />}
                </Col>
                </Row>
        </Container>
    )
}

export default Relatorio50;