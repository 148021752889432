import React from "react";
import { toBRL } from "../../../utils";
import PropTypes from "prop-types";
import BtnModalFormDespesa from "./BtnModalFormDespesa";
import LinhaDespesa from "./LinhaDespesa";

function BlocoCategoria(props) {
  const {
    nome,
    icone,
    totalDespesas,
    despesas,
    mes,
    ano,
    tags,
    onAdd,
    onEdit,
    onDelete,
    isLoading
  } = props;

  const btnAdd = (
    <BtnModalFormDespesa categoria={nome} mes={mes} ano={ano} onAdd={onAdd} />
  );

  return (
    <tbody>
      <tr className="table-warning">
        <th>Dia</th>
        <th>
          {icone && <i className={icone} style={{ marginRight: 10 }} />}
          {nome}
        </th>
        <th className="d-none d-sm-table-cell" />
        <th className="d-none d-sm-table-cell" />
        <th className="d-none d-sm-table-cell" />
        <th className="text-right text-nowrap">{toBRL(totalDespesas)}</th>
        <th className="text-right d-none d-sm-table-cell">{btnAdd}</th>
      </tr>
      <tr>
        <td colSpan={3} className="text-right d-table-cell d-sm-none">
          {btnAdd}
        </td>
      </tr>
      {isLoading && (
        <tr>
          <td colSpan={6}>
            <div className="text-muted text-center mt-3">
              <div className="spinner-border" role="status">
                <span className="sr-only">Carregando...</span>
              </div>
            </div>
          </td>
        </tr>
      )}
      {!isLoading && despesas.length === 0 && (
        <tr>
          <td colSpan={8}>
            <p className="text-muted text-center mt-3">
              Sem despesas em <em>{nome}</em>.
            </p>
          </td>
        </tr>
      )}
      {!isLoading &&
        despesas.length > 0 &&
        despesas.map(despesa => (
          <LinhaDespesa
            key={`despesa` + despesa.id}
            {...despesa}
            mesSelect={mes}
            anoSelect={ano}
            tagsSelect={tags}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
    </tbody>
  );
}

BlocoCategoria.defaultProps = {
  totalDespesas: 0,
  despesas: []
};

BlocoCategoria.propTypes = {
  nome: PropTypes.string.isRequired,
  icone: PropTypes.string,
  totalDespesas: PropTypes.number.isRequired,
  despesas: PropTypes.arrayOf(PropTypes.object),
  mes: PropTypes.number.isRequired,
  ano: PropTypes.number.isRequired,
  tag:PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  patrimonioId: PropTypes.number
};

export default BlocoCategoria;
