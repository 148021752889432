import React, { Fragment, useState, useEffect, useRef} from "react";
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Alert,
} from "reactstrap";
import validator from "validator";
import moment from "moment";
import {
  isPositiveBRL,
  pad,
  getAxiosAuth,
  fromBRL,
  toBRLValueOnly,
  getTokenClientId,
  getTimestamp,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import { MESES, TIPOS_RECEITAS } from "../../../options";
import { FormErrMsg, Monetary, AutocompleteInput, BtnModalFormPatrimonio } from "../../../components";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

function BtnModalFormReceita(props) {
  const [cascade, setCascade] = useState(false);
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);
  const [dia, setaDia] = useState("");
  const [mes, setaMes] = useState(props.mes.toString());
  const [ano, setaAno] = useState(props.ano.toString());
  const [dataErro, setaDataErro] = useState(null);
  const [descricao, setaDescricao] = useState("");
  const [descricaoErro, setaDescricaoErro] = useState(null);
  const [tipo, setaTipo] = useState(TIPOS_RECEITAS[0]);
  const [valor, setaValor] = useState("");
  const [valorErro, setaValorErro] = useState(null);
  const [rendaFixa, setaRendaFixa] = useState(false);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState(null);
  const [continuarInserindo, setaContinuarInserindo] = useState(false);
  const [wasPatrimonioUuid, setWasPatrimonioUuid] = useState(false)

  const NOVA_DEDUCAO = {
    descricao: "",
    descricaoErro: "",
    valor: "",
    valorErro: "",
  };

  const [deducoes, setaDeducoes] = useState([{ ...NOVA_DEDUCAO }]);
  const diaEl = useRef(null);
  const descricaoEl = useRef(null);
  const [sugestoes, setaSugestoes] = useState([]);
  const [patrimonios, setaPatrimonios] = useState([]);
  const [patrimonio, setaPatrimonio] = useState("");
  const [tagsOpcoes, setaTagsOpcoes] = useState([]);
  const [tags, setaTags] = useState([]);
  const [atualizaPatrimonios, setaAtualizaPatrimonios] = useState(false);
  const [mudaDescricao, setMudaDescricao] = useState(false);

  // --- eventos da lista de sugestoes ----
  useEffect(() => {
    const getSugestoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/sugestoes/receitas`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        return;
      }

      if (response && response.data && "sugestoes" in response.data) {
        setaSugestoes(response.data.sugestoes);
      }
    };

    if (aberto) {
      getSugestoes();
    }
  }, [enviarFormulario, aberto]);

  // --- eventos para o modal ---
  const limparFormulario = () => {
    // limpando mensagens de erro
    setaDataErro(null);
    setaDescricaoErro(null);
    setaValorErro(null);
    setaFormularioValido(null);
    setaFormularioErro("");
    setaEnviarFormulario(null);

    // limpando campos
    setaContinuarInserindo(false);
    setaDescricao("");
    setaValor("");
    setaMes(props.mes.toString());
    setaAno(props.ano.toString());

    // deducoes
    setaDeducoes([{ ...NOVA_DEDUCAO }]);

    // tags
    setaTags([]);
  };

  const popularFormularioEditar = () => {
    setaDia(props.dia.toString());
    setaMes(props.mes.toString());
    setaAno(props.ano.toString());
    setaDescricao(props.descricao);
    setaTipo(props.tipo);
    setaValor(toBRLValueOnly(props.valor));
    setaRendaFixa(!!props.rendaFixa);
    if (props.patrimonioId) {
      setaPatrimonio(props.patrimonioId.toString());
    }

    if (
      props.deducoes &&
      Array.isArray(props.deducoes) &&
      props.deducoes.length >= 1
    ) {
      setaDeducoes(
        props.deducoes.map((deducao) => ({
          ...NOVA_DEDUCAO,
          descricao: deducao.descricao,
          valor: toBRLValueOnly(deducao.valor),
        }))
      );
    }

    if (props.tags && Array.isArray(props.tags) && props.tags.length >= 1) {
      setaTags(props.tags.map((tag) => ({ label: tag.nome, value: tag.id })));
    }
  };

  const toggle = (e) => {
    e && e.stopPropagation();
    setaAberto(!aberto);

    if (!aberto) {
      // limpando estados para props de entrada
      limparFormulario();

      if (props.id) {
        popularFormularioEditar();
      }
    }
    setWasPatrimonioUuid(false);
  };

  useEffect(() => {
    if (aberto) {
      diaEl.current.focus();
    }
  }, [aberto]);

  useEffect(() => {
    if (continuarInserindo) {
      descricaoEl.current.focus();
    }
  }, [continuarInserindo]);

  // --- eventos para o formulário ---
  const onSubmitKeepInsertingForm = (e) => {
    setaContinuarInserindo(true);

    return onSubmitForm(e);
  };

  const onSubmitCloseFormCascade = (e) => {
    setaContinuarInserindo(false);
    setCascade(true);

    return onSubmitForm(e);
  }

  const onSubmitCloseForm = (e) => {
    setaContinuarInserindo(false);

    return onSubmitForm(e);
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    // validando campos
    const dataValida = validaData();
    const descricaoValida = await validaDescricao();
    const valorValido = validaValor();
    let deducoesValidas = true;

    if (!estaLimpoDeducoes()) {
      deducoes.forEach((deducao, idx) => {
        const descricaoValida = validaDeducaoDescricao(idx);
        const valorValido = validaDeducaoValor(idx);

        if (!descricaoValida || !valorValido) {
          deducoesValidas = false;
        }
      });
    }

    if (dataValida && descricaoValida && valorValido && deducoesValidas) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos acima.");
      setaFormularioValido(false);
    }
  };

  useEffect(() => {
    const postReceita = async (axiosInstance, dados) => {
      const uri = `/clientes/${clienteId}/receitas`;
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err, response);
          props.history.push("/500");
          return;
        }
      }

      // limpa ou fecha modal
      if (continuarInserindo) {
        limparFormulario();
      } else {
        setaAberto(false);
      }

      props.onAdd && props.onAdd();
    };

    const putReceita = async (axiosInstance, dados, receitaId) => {
      const uri = `/clientes/${clienteId}/receitas/${receitaId}`;
      let response;
      dados.cascade = cascade;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        props.history.push("/500");
        return;
      }

      setaAberto(false);
      props.onEdit && props.onEdit(props.id);
    };

    const salvaDados = async () => {
      let anoPad = pad(ano, 4);
      let mesPad = pad(mes, 2);
      let diaPad = pad(dia, 2);
      const dataReceita = `${anoPad}-${mesPad}-${diaPad}`;
      const valorLimpo = fromBRL(valor);

      // preparando dados para enviar
      const dados = {
        dataReceita,
        descricao,
        tipo,
        valor: valorLimpo,
        rendaFixa,
        patrimonioId: patrimonio || null,
        tags:
          tags.map((tag) => ({
            id: tag.__isNew__ ? null : tag.value,
            nome: tag.label,
          })) || null,
      };

      if (!estaLimpoDeducoes()) {
        const dadosDeducoes = deducoes.map((deducao) => ({
          descricao: deducao.descricao,
          valor: fromBRL(deducao.valor),
        }));

        dados.deducoes = dadosDeducoes;
      } else {
        dados.deducoes = [];
      }

      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      if (props.id) {
        await putReceita(axiosInstance, dados, props.id);
      } else {
        await postReceita(axiosInstance, dados);
      }
    };

    if (formularioValido) {
      salvaDados();
    }
  }, [enviarFormulario]);

  // ---- eventos para os campos de data ----
  const validaData = () => {
    if (validator.isEmpty(dia, { ignore_whitespace: true })) {
      setaDataErro("Dia obrigatório.");
      return false;
    }

    if (validator.isEmpty(ano, { ignore_whitespace: true })) {
      setaDataErro("Ano é obrigatório.");
      return false;
    }

    if (!validator.isInt(dia, { min: 1, max: 31 })) {
      setaDataErro("Dia inválido.");
      return false;
    }

    if (mes < 1 || mes > 12) {
      setaDataErro("Mês inválido.");
      return false;
    }

    if (!validator.isInt(ano)) {
      setaDataErro("Ano inválido.");
      return false;
    }

    let anoPad = pad(ano, 4);
    let mesPad = pad(mes, 2);
    let diaPad = pad(dia, 2);

    const data = `${anoPad}-${mesPad}-${diaPad}`;
    const m = moment(data, "YYYY-MM-DD", true);
    if (!m.isValid()) {
      setaDataErro("Data inválida.");
      return false;
    }

    setaDataErro(false);
    return true;
  };

  useEffect(() => {
    if (dataErro !== null) {
      validaData();
    }
  }, [dia, mes, ano]);

  // --- eventos para o campo Dia ---
  const onChangeDia = (e) => {
    setaDia(e.target.value);
  };

  const onBlurDia = () => {
    validaData();
  };

  // --- eventos para o campo Mes ---
  const onChangeMes = (e) => {
    setaMes(e.target.value);
  };

  // --- eventos para o campo Ano ---
  const onChangeAno = (e) => {
    setaAno(e.target.value);
  };

  const onBlurAno = (e) => {
    validaData();
  };

  const verificaReceitaExistente = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas?filtroMes=${mes}&filtroAno=${ano}`;
    let response;
    
    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      return;
    }

    if (response && response.data && "items" in response.data) {
      var some = response.data.items.some((receita) => {
        let anoPad = pad(ano, 4);
        let mesPad = pad(mes, 2);
        let diaPad = pad(dia, 2);
        const dataReceita = `${anoPad}-${mesPad}-${diaPad}`;
        return receita.descricao === descricao && dataReceita === receita.dataReceita
      })
      
      return some;
    }

    return false;
  };

  // --- eventos para o campo Descrição ---
  const validaDescricao = async () => {
    if (validator.isEmpty(descricao, { ignore_whitespace: true })) {
      setaDescricaoErro("Descrição obrigatória.");
      return false;
    }

    if (!validator.isLength(descricao, { min: 3 })) {
      setaDescricaoErro("Descrição muito curta. Mínimo 3 caracteres.");
      return false;
    }

    if (!validator.isLength(descricao, { max: 255 })) {
      setaDescricaoErro("Descrição muito longa. Máximo 255 caracteres.");
      return false;
    }

    if ((await verificaReceitaExistente()) && mudaDescricao) {
      setaDescricaoErro("Receita já existente");
      return false;
    } 

    setaDescricaoErro(false);
    return true;
  };

  const onChangeDescricao = (e) => {
    setMudaDescricao(true);
    setaDescricao(e.target.value);
  };

  const onBlurDescricao = () => {
    validaDescricao();
  };

  useEffect(() => {
    if (descricaoErro !== null) {
      validaDescricao();
    }
  }, [descricao, validaDescricao]);

  // --- eventos para o campo Tipo de Receita ---
  const onChangeTipo = (e) => {
    setaTipo(e.target.value);
  };

  // --- eventos para o campo Valor ---
  const validaValor = () => {
    if (validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaValorErro("Valor obrigatório.");
      return false;
    }

    if (!isPositiveBRL(valor)) {
      setaValorErro("Valor mometário inválido.");
      return false;
    }

    setaValorErro(false);
    return true;
  };

  const onChangeValor = (e) => {
    setaValor(e.target.value);
  };

  const onBlurValor = () => {
    validaValor();
  };

  useEffect(() => {
    if (valorErro !== null) {
      validaValor();
    }
  }, [valor]);

  // --- eventos para o campo Renda Fixa ---
  const onChangeRendaFixa = (e) => {
    setaRendaFixa(e.target.checked);
  };

  // --- eventos para o campo Tags ---
  useEffect(() => {
    const getTagsOpcoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/tags`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        return;
      }

      if (response && response.data && "tags" in response.data) {
        const novasTagsOpcoes = response.data.tags.map((tag) => ({
          value: tag.id,
          label: tag.nome,
        }));

        setaTagsOpcoes(novasTagsOpcoes);
      }
    };

    if (aberto) {
      getTagsOpcoes();
    }
  }, [enviarFormulario, aberto]);

  const onChangeTags = (newValue, actionMeta) => {
    if (newValue === null) {
      newValue = [];
    }
    setaTags(newValue);
  };

  // --- eventos para o campo Patrimônio ---
  const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios`;
    let response;
    let dados = {
      filtroMes: mes,
      filtroAno: ano,
      wasPatrimonioUuid: wasPatrimonioUuid,
      patrimonioVinculadoUuid: props.patrimonioUuid
    };

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      return;
    }

    if (response && response.data && "patrimonios" in response.data) {
      const novosPatrimonios = response.data.patrimonios.map((p) => {
        const dataPatrimonio = new Date(p.dataPatrimonio);
        const mesPatrimonio = dataPatrimonio.getUTCMonth() + 1; // Adiciona 1 para corresponder a 1 a 12
        const anoPatrimonio = dataPatrimonio.getUTCFullYear();

        if (!(anoPatrimonio === parseInt(ano, 10) && mesPatrimonio === parseInt(mes, 10))) {
          setaPatrimonio(p.id.toString());
        }

        return {
          id: p.id.toString(),
          descricao: p.descricao,
          uuid: p.uuid.toString(),
          dataPatrimonio: p.dataPatrimonio,
          patrimonioAproximado: !(anoPatrimonio === parseInt(ano, 10) && mesPatrimonio === parseInt(mes, 10)),
        };
      });

      setaPatrimonios(novosPatrimonios);
      if (patrimonio && !novosPatrimonios.find((p) => p.id === patrimonio)) {
        setaPatrimonio("");
      }
    }
  };
  useEffect(() => {
    if (aberto || atualizaPatrimonios) {
      getPatrimonios();
    }
  }, [wasPatrimonioUuid,enviarFormulario, aberto, mes, ano, atualizaPatrimonios]);

  useEffect(() => {
    if (atualizaPatrimonios) {
      setaPatrimonio(patrimonios.find(p => p.descricao === atualizaPatrimonios).id);
    }
  }, [patrimonios])

  const onChangePatrimonio =  async (e) => {
    setaPatrimonio(e.target.value);
    setWasPatrimonioUuid(true);
  };
  // --- eventos para Deduções
  const estaLimpoDeducoes = () => {
    if (deducoes.length > 1) {
      return false;
    } else if (deducoes.length === 1) {
      const deducao = deducoes[0];

      return (
        validator.isEmpty(deducao.descricao, { ignore_whitespace: true }) &&
        validator.isEmpty(deducao.valor, { ignore_whitespace: true })
      );
    } else {
      return true;
    }
  };

  const onClickBtnAdicionarPatrimono = (e) => {
    setaAtualizaPatrimonios(e);
  };
  
  const onClickBtnAdicionarDeducao = (e) => {
    e.preventDefault();

    const novasDeducoes = [...deducoes, { ...NOVA_DEDUCAO }];

    setaDeducoes(novasDeducoes);
  };

  const onClickBtnRemoverDeducao = (idx, e) => {
    e.preventDefault();

    let novasDeducoes = [...deducoes];

    if (deducoes.length === 1) {
      novasDeducoes = [{ ...NOVA_DEDUCAO }];
    } else {
      novasDeducoes.splice(idx, 1);
    }

    setaDeducoes(novasDeducoes);
  };

  const validaDeducaoDescricao = (idx) => {
    if (!deducoes[idx]) return;

    let novasDeducoes = [...deducoes];

    const deducao = novasDeducoes[idx];

    let valido = true;

    const limpo = estaLimpoDeducoes();

    if (
      !limpo &&
      validator.isEmpty(deducao.descricao, { ignore_whitespace: true })
    ) {
      deducao.descricaoErro = "Descrição obrigatória.";
      valido = false;
    }

    if (valido) {
      deducao.descricaoErro = false;
    }

    setaDeducoes(novasDeducoes);

    return valido;
  };

  const onChangeDeducaoDescricao = (idx, e) => {
    if (!deducoes[idx]) return;

    let novasDeducoes = [...deducoes];

    novasDeducoes[idx].descricao = e.target.value;

    setaDeducoes(novasDeducoes);
  };

  const onBlurDeducaoDescricao = (idx) => {
    validaDeducaoDescricao(idx);
  };

  const validaDeducaoValor = (idx) => {
    if (!deducoes[idx]) return;

    let novasDeducoes = [...deducoes];

    const deducao = novasDeducoes[idx];

    let valido = true;

    const limpo = estaLimpoDeducoes();

    if (
      !limpo &&
      validator.isEmpty(deducao.valor, { ignore_whitespace: true })
    ) {
      deducao.valorErro = "Valor obrigatório.";
      valido = false;
    }

    if (!limpo && valido && !isPositiveBRL(deducao.valor)) {
      deducao.valorErro = "Valor mometário inválido.";
      valido = false;
    }

    if (valido) {
      deducao.valorErro = false;
    }

    setaDeducoes(novasDeducoes);

    return valido;
  };

  const onChangeDeducaoValor = (idx, e) => {
    if (!deducoes[idx]) return;

    let novasDeducoes = [...deducoes];

    novasDeducoes[idx].valor = e.target.value;

    setaDeducoes(novasDeducoes);
  };

  const onBlurDeducaoValor = (idx) => {
    validaDeducaoValor(idx);
  };

  // --- elementos ---
  const trigger = props.id ? (
    <Button size="sm" style={{ marginRight: 5 }} onClick={toggle}>
      <i className="fas fa-pen" />
    </Button>
  ) : (
    <Button color="primary" className="float-right" onClick={toggle}>
      <i className="fas fa-plus" /> Adicionar
    </Button>
  );

  return (
    <Fragment>
      {trigger}
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>
          {props.id ? "Editar Receita" : "Adicionar Receita"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <Row form>
              <Col xs={3}>
                <FormGroup>
                  <Label>Dia</Label>
                  <Input
                    type="text"
                    value={dia}
                    onChange={onChangeDia}
                    onBlur={onBlurDia}
                    valid={dataErro === false}
                    invalid={!!dataErro}
                    innerRef={diaEl}
                    
                  />
                  <FormErrMsg>{dataErro}</FormErrMsg>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label>Mês</Label>
                  <Input
                    type="select"
                    value={mes}
                    onChange={onChangeMes}
                    valid={dataErro === false}
                    invalid={!!dataErro}
                    disabled={rendaFixa === true}
                  >
                    {MESES.map((mes, i) => (
                      <option value={i + 1} key={`mes_opt_${i}`}>
                        {mes}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={3}>
                <Label>Ano</Label>
                <Input
                  type="text"
                  value={ano}
                  onChange={onChangeAno}
                  onBlur={onBlurAno}
                  valid={dataErro === false}
                  invalid={!!dataErro}
                  disabled={rendaFixa === true}
                />
              </Col>
            </Row>
            <FormGroup>
              <Label>Descrição</Label>
              <AutocompleteInput
                type="text"
                value={descricao}
                onChange={onChangeDescricao}
                onBlur={onBlurDescricao}
                valid={descricaoErro === false}
                invalid={!!descricaoErro}
                maxLength={255}
                innerRef={descricaoEl}
                options={sugestoes}
              />
              <FormErrMsg>{descricaoErro}</FormErrMsg>
            </FormGroup>

            <Row form>
              <Col sm={4}>
                <FormGroup>
                  <Label>Tipo de receita</Label>
                  <Input type="select" value={tipo} onChange={onChangeTipo}>
                    {TIPOS_RECEITAS.map((tipoReceita) => (
                      <option
                        value={tipoReceita}
                        key={`tipoReceita_opt_${tipoReceita}`}
                      >
                        {tipoReceita}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col sm={8}>
                <FormGroup>
                  <Label>Valor</Label>
                  <Monetary
                    value={valor}
                    onChange={onChangeValor}
                    onBlur={onBlurValor}
                    valid={valorErro === false}
                    invalid={!!valorErro}
                  />
                  <FormErrMsg>{valorErro}</FormErrMsg>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={!!rendaFixa}
                  onChange={onChangeRendaFixa}
                />
                <Label check>Renda Recorrente</Label>
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <Label>Tags</Label>
              <CreatableSelect
                isMulti
                options={tagsOpcoes}
                onChange={onChangeTags}
                value={tags}
                placeholder="Selecione..."
                noOptionsMessage={() => "Crie uma tag!"}
                formatCreateLabel={(t) => (
                  <span>
                    Criar nova tag <strong>{t}</strong>...
                  </span>
                )}
              ></CreatableSelect>
            </FormGroup>

            <FormGroup>
              <Label>Vinculada a um patrimônio</Label>
              <Row>
                <Col sm={10}>
                  <Input
                    type="select"
                    value={patrimonio}
                    onChange={onChangePatrimonio}
                  >
                    <option value="">
                      {props.patrimonioUuid? "Nenhum" : "Selecione"}
                    </option>
                    {patrimonios.map((p) => (
                      <option value={p.id} key={`patrimonio_${p.id}`}>
                        {p.descricao.length > 30 ? `${p.descricao.substring(0, 30)}...` : p.descricao}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col sm={2}>
                  <BtnModalFormPatrimonio 
                    mes={mes} 
                    ano={ano} 
                    onAdd={onClickBtnAdicionarPatrimono} 
                    rendaFixa={rendaFixa}
                  />
                </Col>
              </Row>
            </FormGroup>

            {deducoes.map((deducao, idx) => (
              <Card className="border-secondary" key={`deducao_form_${idx}`}>
                <CardHeader>
                  Dedução
                  <div className="card-header-actions">
                    <Button
                      size="sm"
                      color="primary"
                      style={{ marginRight: 5 }}
                      onClick={onClickBtnAdicionarDeducao}
                    >
                      <i className="fas fa-plus" />
                    </Button>
                    <Button
                      size="sm"
                      color="light"
                      onClick={onClickBtnRemoverDeducao.bind(this, idx)}
                    >
                      <i className="fas fa-minus" />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label>Descrição</Label>
                    <Input
                      type="text"
                      placeholder="Descrição da dedução"
                      value={deducao.descricao}
                      onChange={onChangeDeducaoDescricao.bind(this, idx)}
                      onBlur={onBlurDeducaoDescricao.bind(this, idx)}
                    />
                    <FormErrMsg>{deducao.descricaoErro}</FormErrMsg>
                  </FormGroup>

                  <FormGroup>
                    <Label>Valor</Label>
                    <Monetary
                      value={deducao.valor}
                      onChange={onChangeDeducaoValor.bind(this, idx)}
                      onBlur={onBlurDeducaoValor.bind(this, idx)}
                    />
                    <FormErrMsg>{deducao.valorErro}</FormErrMsg>
                  </FormGroup>
                </CardBody>
              </Card>
            ))}

            {formularioValido === false && (
              <Alert color="danger">
                Não foi possível salvar receita. {formularioErro}
              </Alert>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          {!props.id && (
            <Fragment>
              <Button color="primary" onClick={onSubmitCloseForm}>
                Salvar e fechar
              </Button>
              <Button color="primary" onClick={onSubmitKeepInsertingForm}>
                Salvar e adicionar outra
              </Button>
            </Fragment>
          )}
          {props.id && (
            <Button color="primary" onClick={onSubmitCloseForm}>
              Salvar
            </Button>
          )}
          {(props.id && props.rendaFixa) && (
            <Button color="primary" onClick={onSubmitCloseFormCascade}>
              Salvar Para Todas as Futuras
            </Button>
          )}
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalFormReceita.propTypes = {
  mes: PropTypes.number.isRequired,
  ano: PropTypes.number.isRequired,
  id: PropTypes.number,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  descricao: PropTypes.string,
  valor: PropTypes.number,
  tipo: PropTypes.string,
  deducoes: PropTypes.arrayOf(PropTypes.object),
  rendaFixa: PropTypes.bool,
  patrimonioId: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(BtnModalFormReceita);
