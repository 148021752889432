import React, { useEffect, useState, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Badge, Row, Col } from "reactstrap";
import { getAxiosAuth, getClienteId, wasRequestCancelled } from "../../utils";
import DOMPurify from 'dompurify';
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import backCreateNota from "../../assets/img/backCreateNotaV2.png";
import {maxDescriptionWidthText , maxWidthText} from "./../../options";

const NotasModal = ({ isOpen, closeModal, history}) => {
  const [notas, setaNotas] = useState([]);
  const clienteId = getClienteId();
  const [nomeCompleto, setaNomeCompleto] = useState("");
  const [notaSelecionada, setNotaSelecionada] = useState(null);
  const [createRedirection, setaCreateRedirection] = useState(false);
  const [updateRedirection, setaUpdateRedirection] = useState(false);
  const [descricaoValidationError, setDescricaoValidationError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mostrarDetalhes, setMostrarDetalhes] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notaSerEditada,setaNotaSerEditada] = useState({
    id:null,
    titulo:"",
    descricao:"",
  });
  const [novaNota, setNovaNota] = useState({  
    titulo: "",
    descricao: "",
  });
  const isMobile = windowWidth <= 995;
  const [isMounted, setIsMounted] = useState(true); 

  useEffect(() => {
    setIsMounted(true); // Set the flag to true when component mounts
    return () => {
      setIsMounted(false); // Set the flag to false when component unmounts
    };
  }, []);


  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  

  useEffect(()=>{
    setDescricaoValidationError(false);
  },[notaSelecionada, updateRedirection,createRedirection])

  const axiosInstance = getAxiosAuth(() => {
    history.push("/login");
  });

  const handleFormChange = (name, value) => {
    let limit = name === 'titulo' ? maxWidthText : maxDescriptionWidthText;
    let truncatedValue = value;
    if (value.length > limit) {
      truncatedValue = value.slice(0, maxDescriptionWidthText);
    }

    setNovaNota((prevNota) => ({
      ...prevNota,
      [name]: truncatedValue,
    }));
  };

  const handleUpdateFormChange = (name, value) => {
    let limit = name === 'titulo' ? maxWidthText : maxDescriptionWidthText;
    let truncatedValue = value;    
    if (value.length > limit) {
      truncatedValue = value.slice(0, maxDescriptionWidthText);
    }
      
    // Atualização do estado com o valor possivelmente truncado
    setaNotaSerEditada((prevNota) => ({
      ...prevNota,
      [name]: truncatedValue,
    }));
  };

  const handleSalvarNota = async () => {
    let notaId;
    let uri = null;
    if(notaSerEditada!=null) {
      notaId = notaSerEditada.id;
    }

    uri = createRedirection ? `/clientes/${clienteId}/notas` : `/clientes/${clienteId}/notas/${notaId}`;

    let response;

    const process = createRedirection ? novaNota : notaSerEditada

    if (!process.descricao.trim()) {
      setDescricaoValidationError(true);
      return;
    }
  
    // Clear validation error state
    setDescricaoValidationError(false);

    

    const processedDescricao = process.descricao
    .replace(/\r\n/g, '\n')   // Normalize Windows line breaks to Unix line breaks
    .replace(/\r/g, '\n')      // Normalize remaining carriage returns to line breaks
    .split('\n')               // Split the text into lines
    .map(line => line.replace(/ +/g, match => '&nbsp;'.repeat(match.length))) // Preserve consecutive spaces in each line
    .join('<br>')              // Join the lines using <br> as a separator
    .trim();                   // Trim leading/trailing spaces

    try {
      createRedirection ?
      response = await axiosInstance.post(uri, {
        titulo: process.titulo,
        descricao: `<p>${processedDescricao}</p>`,
      }) : response = await axiosInstance.put(uri, {
        titulo: process.titulo,
        descricao: `<p>${processedDescricao}</p>`,
      })
    } catch (err) {
      if (err.response) {
        response = err.response;
      } else {
        console.error(err, response);
        history.push("/500");
        return;
      }
    }

    

    setNovaNota({
      titulo: "",
      descricao: "",
    });
    setaNotaSerEditada({
      id:null,
      titulo:"",
      descricao:"",
    })
    setaCreateRedirection(false);
    setaUpdateRedirection(false);
    setNotaSelecionada(null)
    getNotas();
  };



  const getNotasWrapper = useCallback(() => {
    getNotas();
  }, []); // Sem dependências para garantir que a função não seja recriada
  
  useEffect(() => {
    getNotasWrapper(); // Chama getNotas sempre que o estado `notas` é atualizado
  }, [getNotasWrapper]);
  



  const getNotas = async () => {
    const clienteId = getClienteId();
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      history.push("/login");
    });
    const uri = `/clientes/${clienteId}/notas`;
    let response;

    let orderValue;
      if(order){
        orderValue = order;
      }

    try {
      response = await axiosInstance.get(uri,{params:{order:orderValue}});
      if (isMounted) {
        if (Array.isArray(response.data)) {
          setaNotas(response.data);
        } else {
          setaNotas([]);
        }
      }
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      history.push("/500");
      return;
    }
    if (Array.isArray(response.data)) {
      setaNotas(response.data);
    } else {
      setaNotas([]); // Inicializa como um array vazio se a resposta não for um array
    }
  };

  function stripHtmlTagsAndLineBreaks(html) {
    const withoutLineBreaks = html.replace(/<br>/g, ' ').replace(/&nbsp;/g, ' ');
    return withoutLineBreaks;
  }

  const deleteNota= async (notaId)=>{
    const clienteId = getClienteId();
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      history.push("/login");
    });
    const uri = `/clientes/${clienteId}/notas/${notaId}`;

    try {
      await axiosInstance.delete(uri);
    } catch (err) {
      console.error(err);
      history.push("/500");
      return;
    }
  }

  useEffect(() => {
    const obtemDados = async () => {
      const uri = `/clientes/${clienteId}`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          history.push("/500");
          return;
        }
      }

      const dados = response.data;

      setaNomeCompleto(dados.nomeCompleto || "");
    };
    obtemDados(); 
    if (isMounted) {
      getNotas();
    }
  }, [isMounted]);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleNotaDelete = () => {
    if (notaSelecionada) {
      toggleDeleteModal(); // Show the deletion confirmation modal
    }
  };


  const handleDelete  = async () =>{
    if(notaSelecionada){
      const notaId = notaSelecionada.id;
      try{
        await deleteNota(notaId);
      } catch (error) {
      console.error("Erro ao excluir a nota:", error);
      }
      if(isMounted){
        if (isMobile) {
          setMostrarDetalhes(false);
        } else {
          setNotaSelecionada(false);
        }
        getNotas();
        setShowDeleteModal(false);
      }
    }
  }

  
  const handleNotaClick = (nota) => {
    if (isMobile) {
      setNotaSelecionada(nota);
      setMostrarDetalhes(true);
    } else {
      setNotaSelecionada(nota);
    }
  };


  function stripHtmlTags(html) {
    const withLineBreaks = html
      .replace(/<br>/g, '\n')
      .replace(/&nbsp;/g, ' ')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '');
  
    return withLineBreaks;
  }

  useEffect(()=>{
    setaUpdateRedirection(false);
    setaNotaSerEditada(null)
  },[notaSelecionada])


  const favoritarNota = async (nota) => {
    let uri =`/clientes/${clienteId}/notas/${nota.id}`;
    let response;
    let favorite = nota.favorito ? false : true;
    try {
      response = await axiosInstance.put(uri, {
        favorito:favorite
      })
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      history.push("/500");
      return;
    }
    getNotas();

  };

  const [filtroFavorito, setFiltroFavorito] = useState(false);

  const handleFiltroFavoritos = () => {
    setFiltroFavorito(!filtroFavorito);
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilter, setSearchFilter] = useState(false);
  const [searchNote , setSearchNote] = useState([])
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSearch = debounce(async (searchValue) => {
      const clienteId = getClienteId();
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona se não conseguir renovar tokens
        history.push("/login");
      });
      const uri = `/clientes/${clienteId}/notas`;
      let response;

      let orderValue;
      if(order){
        orderValue = order;
      }
  
      try {
        response = await axiosInstance.get(uri, { params:{search:searchValue, order:orderValue}});
        if (isMounted) {
          if (Array.isArray(response.data)) {
            setSearchNote(response.data);
          } else {
            setSearchNote([]);
          }
        }
      } catch (err) {
        if (!wasRequestCancelled(err)) {
          console.error(err);
        }
        history.push("/500");
        return;
      }
      if (Array.isArray(response.data)) {
        setSearchNote(response.data);
      } else {
        setSearchNote([]); // Inicializa como um array vazio se a resposta não for um array
      }
  }, 500);

  useEffect(() => {
    // Call debouncedSearch only if there's a change in searchTerm
    if (searchTerm !== '') {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);

 

  const handleFilterSearch = () =>{
    setSearchFilter(!searchFilter);
    setFilterOn(false)
  }


  //FILTROS

  const [filterOn, setFilterOn] = useState(false)
  const [order, setOrder] = useState(null);
  
  const handleFilter = () =>{
    setFilterOn(!filterOn)
    setSearchFilter(false);
  }

  useEffect(()=>{
    if(order !==null || order!==''){
      searchOrder(order)
    }
  },[order])

  const searchOrder = () =>{
    if(searchTerm !== ''){
      debouncedSearch(searchTerm);
    } else {
      getNotas();
    }
  }

  return (
    isOpen ? (
      <div>
        <Modal style={{maxHeight:"80vh", minHeight:"80vh"}} isOpen={isOpen} toggle={closeModal} backdrop={true} size="xl"  className="rounded-modal">
          <div style={{display:"flex", width:"100%", justifyContent:"flex-end", height:"0px"}}>
            {/* , position:'relative',left:"35px",bottom:"35px" */}
            <div style={{zIndex:5, position:'relative',left:"25px",bottom:"25px"}}>
              <button style={{border:"1px solid rgba(0,0,0,.0)",backgroundColor: "#0479B7", borderRadius:"50%", height:"45px", width:"45px", color:"white", fontSize:"24px"}}  onClick={closeModal}>
                X
              </button>
            </div>
          </div>

          {
          isMobile ? ( !mostrarDetalhes ? (
            // SEM DETALHES
          <div style={{width:"100%", borderRight:"1px solid #ccc "}}> {/*div 1*/}
              <ModalHeader className="modal-header" style={{ minHeight: "90px", padding: "20px 20px 0 20px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ marginTop: "7px", alignItems: "flex-start", fontWeight: "700", fontSize: "26px" }}>
                    Notas <Badge style={{ fontSize: "14px", backgroundColor: "#F7F7F7", fontWeight: "400" }}>{nomeCompleto}</Badge>
                  </div>
                  <div style={{display:"flex", gap:"5px"}}>
                    {/*FILTROS*/}
                    <i 
                    onClick={handleFilter}
                    style={{ cursor: "pointer", width: "26px", height: "26px", color: "black" }}
                    className="fa fa-filter"
                    />
                    {/*FILTRO PESQUISA*/}
                    <i
                      onClick={handleFilterSearch}
                      style={{ cursor: "pointer", width: "26px", height: "26px", color: "black" }}
                      className="fa fa-search"
                    />
                    {/* FILTRO FAVORITOS */}
                    <i
                      style={{ cursor: "pointer", width: "26px", height: "26px", color: filtroFavorito ? "#e5e500" : null }}
                      className="fas fa-star"
                      onClick={handleFiltroFavoritos}
                    />
                  </div>
                </div>
                 {/* INPUT PESQUISA */}
                 {filterOn ? 
                      <div style={{display:"flex", gap:"5px"}}>
                        <label style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="order"
                            value="recentes"
                            style={{marginRight:"5px", marginTop:"10px"}}
                            checked={order === 'recentes'}
                            onChange={() => setOrder('recentes')}
                          />
                          Mais recentes
                        </label>
                        <label style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="order"
                            value="antigas"
                            style={{marginRight:"5px", marginTop:"10px"}}
                            checked={order === 'antigas'}
                            onChange={() => setOrder('antigas')}
                          />
                          Mais antigas
                        </label>
                      </div> :
                      searchFilter ? 
                        <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ width: "180px", marginRight: "10px", borderRadius:"7px", marginBottom:"5px"}}
                        />
                      :null}
              </ModalHeader>
              <ModalBody style={{padding:"0", margin:"0"}}>
                <Col className="notas-historico" style={{padding:"0"}}>
                  <ul style={{marginTop:"0",width:"100%"}}>
                    {(searchTerm ? searchNote : notas).filter((nota) => !filtroFavorito || (filtroFavorito && nota.favorito)).map((nota, index) => (
                      <li
                        key={index}
                        onClick={() => handleNotaClick(nota)}
                        className={notaSelecionada && notaSelecionada.id === nota.id ? "nota-selecionada" : ""}
                        style={{
                          cursor: "pointer",
                          maxWidth: "100%",
                          padding: '10px 20px 0px 20px',
                          border: '1px solid #ccc'
                        }}
                      >
                        <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div>
                            <h4 style={{ fontSize: "24px", fontWeight: "600" }}>
                              {nota.titulo.length > 20 ? nota.titulo.slice(0, 20) + "..." : nota.titulo}
                            </h4>
                          </div>
                          <div >
                          <i
                            style={{ cursor: "pointer", width: "26px", height: "26px", color: nota.favorito ? "#cccc00" : null }}
                            className="fas fa-star"
                            onClick={(event) => {
                              // Prevent the click event from propagating to the parent li
                              event.stopPropagation();
                              favoritarNota(nota);
                            }}
                          />
                          </div>
                        </Row>
                        <p
                          style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `${stripHtmlTagsAndLineBreaks(nota.descricao.slice(0, 50))}${nota.descricao.length > 50 ? '...' : ''}`
                            ),
                          }}
                        ></p>
                        <div style={{ gap: "5px", fontSize:"12px", display:"flex", flexWrap:"wrap", width:"100%"}}>
                            <p>Criado em:  <span style={{backgroundColor:"#e5e5e5"}}>{nota.createdAt}</span></p>
                            <p>Editado em:  <span style={{backgroundColor:"#e5e5e5"}}>{nota.updatedAt}</span></p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div style={{textAlign:"center"}}>
                      <div style={{maxWidth: "400px", margin: "auto", marginTop:"40px"}}>
                        <button onClick={() => {
                          setaCreateRedirection(true);
                          setMostrarDetalhes(true);
                        }} 
                          style={{
                            height: "30px",
                            width: "150px",
                            border: "1px solid rgba(0,0,0,.0)",
                            borderRadius: "12px",
                            background: "#0479B7",                           
                            color: "white",
                            margin: '10px', // Adiciona margem conforme necessário
                        }}>
                          Adicionar anotação
                        </button>
                      </div>
                    </div>
                </Col>
              </ModalBody>
            </div>
          ):(
            //DETALHES
            <div style={{width:"100%", backgroundColor:"#F7F7F7"}}>{/*div 2*/}
              {updateRedirection ? (
                <>
                  <ModalHeader>
                      <Row style={{margin:"0 4%"}}>
                        <button
                        style={{
                          fontSize: "30px",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          width: "40px",
                          height: "40px",
                          maxHeight: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          if(notaSelecionada && !updateRedirection){
                            setNotaSelecionada(null);
                          }
                          else if(notaSelecionada && updateRedirection) {
                            setaUpdateRedirection(false);
                          }
                          setMostrarDetalhes(false);
                        }}>
                          <i className="fas fa-angle-left" />
                        </button>
                        <Col style={{ maxWidth: "600px",fontSize:"14px" }}>
                          <div>
                            Criado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.createdAt}</span>
                          </div>
                          <div>
                            Editado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.updatedAt}</span>
                          </div>
                        </Col>
                          <i 
                          style={{ 
                            fontSize:"26px",cursor:"pointer",marginTop:"5px", backgroundColor:"rgba(0,0,0,.0)"
                            }} 
                            class="fa fa-trash" 
                            aria-hidden="true"
                            onClick={() => {
                              handleNotaDelete()
                              setMostrarDetalhes(false);
                            }}
                            />
                      </Row>
                  </ModalHeader>
                  <div  style={{backgroundColor:"#fff"}}>
                    <div style={{margin:"0 4%",display:"flex", justifyContent:"center",alignItems:"center", gap:"10px", flexDirection:"column"}}>
                      <Input
                        type="text"
                        name="titulo"
                        value={notaSerEditada.titulo}
                        placeholder="Título"
                        style={{borderRadius:"8px",marginTop:"20px",maxWidth: "900px", height: "50px", fontSize: "24px", fontWeight:"600" }}
                        onChange={(e) =>handleUpdateFormChange('titulo', e.target.value)}          
                      />
                      {descricaoValidationError && <p style={{ color: "red", margin:"0px" }}>A descrição não pode estar vazia.</p>}
                      <Input
                        type="textarea"
                        name="descricao"
                        placeholder="Descrição"
                        value={
                            stripHtmlTags(notaSerEditada.descricao)
                        }
                        style={{
                          borderRadius:"8px",
                          maxWidth: "900px",
                          height: "350px",
                          minHeight: "200px",
                          fontSize: "16px",
                          resize: "none",
                          borderColor: descricaoValidationError ? "red" : "",
                        }}
                        onChange={(e) => handleUpdateFormChange('descricao', e.target.value)}
                      />
                      <div style={{display:"flex",width:"100%",justifyContent:"right", gap:"15px", marginTop:"20px",paddingBottom:"20px"}}>
                        <button 
                          style={{justifyContent:"center",width:"100px",fontSize:"14px",borderRadius:"20px",borderColor:"rgba(0,0,0,.0)", display:"flex", gap:"5px"}}  
                          onClick={() =>{
                            setaUpdateRedirection(false)
                            setMostrarDetalhes(false);
                            }}>
                          <span style={{fontSize:"16px", fontWeight:"500"}}>X</span> Cancelar
                        </button>
                        <button 
                          style={{
                            width:"70px",
                            fontSize:"16px",
                            background: "#0479B7",                           
                            color:"white",borderRadius:"15px",borderColor:"rgba(0,0,0,.0)"}} 
                            onClick={() => {
                              handleSalvarNota()
                              setMostrarDetalhes(false);
                              }}>Salvar</button>
                      </div>
                      </div>
                    </div>
                  </>
              ):(
                createRedirection ? (
                  <>
                  <ModalHeader>
                    <Row style={{margin:"0 4%"}}>
                      <button
                      style={{
                        fontSize: "30px",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        width: "40px",
                        height: "40px",
                        maxHeight: "40px",
                        borderRadius: "50%",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                       if(createRedirection){
                          setaCreateRedirection(false);
                        }
                        setMostrarDetalhes(false);
                      }}>
                        <i className="fas fa-angle-left" />
                      </button>
                      <h4 style={{marginLeft:"20px", marginTop:"7px"}}>Adicionar anotação</h4>
                    </Row>
                  </ModalHeader>
                  <div  style={{backgroundColor:"#fff"}}>
                  <div style={{
                    margin:"0 4%",display:"flex", justifyContent:"center",alignItems:"center", gap:"10px", flexDirection:"column"}}>
                    <Input
                      type="text"
                      name="titulo"
                      value={novaNota.titulo}
                      placeholder="Título"
                      style={{borderRadius:"8px",marginTop:"20px",maxWidth: "900px", height: "50px", fontSize: "24px", fontWeight:"600" }}
                      onChange={(e) => handleFormChange('titulo', e.target.value)}          
                    />
                    {descricaoValidationError && <p style={{ color: "red", margin:"0px" }}>A descrição não pode estar vazia.</p>}
                    <Input
                      type="textarea"
                      name="descricao"
                      placeholder="Descrição"
                      value={
                          novaNota.descricao
                      }
                      style={{
                        borderRadius:"8px",
                        maxWidth: "900px",
                        height: "350px",
                        minHeight: "200px",
                        fontSize: "16px",
                        resize: "none",
                        borderColor: descricaoValidationError ? "red" : "",
                      }}
                      onChange={(e) => handleFormChange('descricao', e.target.value)}
                    />
                    <div style={{display:"flex",width:"100%",justifyContent:"right", gap:"15px", marginTop:"20px",paddingBottom:"20px"}}>
                      <button 
                        style={{
                          justifyContent:"center",width:"100px",fontSize:"14px",
                          borderRadius:"20px",borderColor:"rgba(0,0,0,.0)", display:"flex", gap:"5px"}}  
                        onClick={() =>{
                          setaCreateRedirection(false)
                          setMostrarDetalhes(false);
                          }}>
                        <span style={{fontSize:"16px", fontWeight:"500"}}>X</span> Cancelar
                      </button>
                      <button 
                        style={{
                          width:"70px",
                          fontSize:"16px",
                          background: "#0479B7",                           
                          color:"white",borderRadius:"15px",borderColor:"rgba(0,0,0,.0)"}} 
                          onClick={() => {
                            handleSalvarNota()
                            setMostrarDetalhes(false);
                            }}>Salvar</button>
                    </div>
                    </div>
                  </div>
                </>
                ):(
                  <Col className="notas-detalhes" style={{width:"100%", height:"100%", padding:"0", margin:"0"}}>
                    <>
                    <ModalHeader>
                        <Row style={{margin:"0 4%"}}>
                          <button
                          style={{
                            fontSize: "30px",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                            width: "40px",
                            height: "40px",
                            maxHeight: "40px",
                            borderRadius: "50%",
                            backgroundColor: "#ffffff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            if(notaSelecionada && !updateRedirection){
                              setNotaSelecionada(null);
                            }
                            else if(notaSelecionada && updateRedirection) {
                              setaUpdateRedirection(false);
                            }
                            setMostrarDetalhes(false);
                          }}>
                            <i className="fas fa-angle-left" />
                          </button>
                          <Col style={{ maxWidth: "600px",fontSize:"14px" }}>
                            <div>
                              Criado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.createdAt}</span>
                            </div>
                            <div>
                              Editado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.updatedAt}</span>
                            </div>
                          </Col>
                            <i 
                            style={{ 
                              fontSize:"26px",cursor:"pointer",marginTop:"5px", backgroundColor:"rgba(0,0,0,.0)"
                              }} 
                              class="fa fa-trash" 
                              aria-hidden="true"
                              onClick={() =>{ 
                                handleNotaDelete()
                                setMostrarDetalhes(false);
                              }}
                              />
                        </Row>
                    </ModalHeader>
                    <div style={{backgroundColor:"#fff"}}>
                      <ModalBody style={{margin:"0 4%"}}>
                        <div style={{ marginTop: "20px"}}>  
                          <Row style={{ 
                            display: 'block', 
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            maxWidth: '100%', 
                            overflow: 'hidden', 
                          }}>                         
                          <h3>{notaSelecionada.titulo}</h3> 
                          </Row>
                          <p
                            style={{
                              overflowX: "hidden",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                              height: "50vh",
                              overflowY: "auto", // Adicione esta linha para controlar o overflowY
                            }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(notaSelecionada.descricao),
                            }}
                          ></p>
                          <div style={{display:"flex",justifyContent:"center"}}>
                            <Button 
                             onClick={() => {
                              setaUpdateRedirection(true);
                              setaNotaSerEditada({
                                id: notaSelecionada.id,
                                titulo: notaSelecionada.titulo,
                                descricao: notaSelecionada.descricao,
                              });
                            }}
                            pill style={{backgroundColor:"#F7F7F7",display:"flex",gap:"10px",alignItems:"center", justifyContent:"center",border:"none", height:"40px"}}>
                              <i
                              class="fas fa-edit"/> <p style={{marginTop:"10px"}}>Editar</p>
                            </Button>
                          </div>
                        </div>
                      </ModalBody>
                    </div>
                  </>
                </Col>
              ))}
            </div>
          )):(
            // SE NAO FOR MOBILE
            <div style={{display:"flex"}}>  
            <div style={{width:"45%", borderRight:"1px solid #ccc "}}> {/*div 1*/}
              <ModalHeader className="modal-header" style={{minHeight:"90px", padding:"20px 20px 0 20px"}}>
                <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  <div style={{marginTop:"7px", alignItems: "flex-start", fontWeight:"700",fontSize:"26px"}}>Notas   <Badge style={{fontSize:"14px",backgroundColor:"#F7F7F7", fontWeight:"400"}}>{nomeCompleto}</Badge></div>
                  <div style={{display:"flex", gap:"5px"}}>
                    {/* INPUT PESQUISA */}
                    {filterOn ? 
                      <div style={{display:"flex", gap:"5px"}}>
                        <label
                          style={{
                            fontSize: "12px",
                            cursor: "pointer",
                            padding: "5px",
                            borderRadius: "5px",
                            transition: "background-color 0.3s",
                          }}
                          onMouseEnter={(e) => (e.target.style.backgroundColor = "#dcdcdc")}
                          onMouseLeave={(e) => (e.target.style.backgroundColor = "")}
                        >
                          <input
                            type="radio"
                            name="order"
                            value="recentes"
                            style={{marginRight:"5px"}}
                            checked={order === 'recentes'}
                            onChange={() => setOrder('recentes')}
                          />
                          Mais recentes
                        </label>
                        <label
                          style={{
                            fontSize: "12px",
                            cursor: "pointer",
                            padding: "5px",
                            borderRadius: "5px",
                            transition: "background-color 0.3s",
                          }}
                          onMouseEnter={(e) => (e.target.style.backgroundColor = "#dcdcdc")}
                          onMouseLeave={(e) => (e.target.style.backgroundColor = "")}
                        >
                          <input
                            type="radio"
                            name="order"
                            value="antigas"
                            style={{marginRight:"5px"}}
                            checked={order === 'antigas'}
                            onChange={() => setOrder('antigas')}
                          />
                          Mais antigas
                        </label>
                      </div> :
                      searchFilter ? 
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input-filter"
                      />
                      :null}
                    {/*FILTROS*/}
                    <i 
                    onClick={handleFilter}
                    style={{ cursor: "pointer", width: "26px", height: "26px", color: "black" }}
                    className="fa fa-filter"
                    />
                    {/*FILTRO PESQUISA*/}
                    <i
                      onClick={handleFilterSearch}
                      style={{ cursor: "pointer", width: "26px", height: "26px", color: "black" }}
                      className="fa fa-search"
                    />
                    {/* FILTRO FAVORITOS */}
                    <i
                      style={{ cursor: "pointer", width: "26px", height: "26px", color: filtroFavorito ? "#e5e500" : null }}
                      className="fas fa-star"
                      onClick={handleFiltroFavoritos}
                    />
                  </div>
                </div>
              </ModalHeader>
              <ModalBody style={{padding:"0", margin:"0"}}>
                <Col className="notas-historico" style={{padding:"0"}}>
                  <ul style={{marginTop:"0",width:"100%"}}>
                    {(searchTerm ? searchNote : notas).filter((nota) => !filtroFavorito || (filtroFavorito && nota.favorito)).map((nota, index) => (
                      <li
                        key={index}
                        onClick={() => handleNotaClick(nota)}
                        className={notaSelecionada && notaSelecionada.id === nota.id ? "nota-selecionada" : ""}
                        style={{
                          cursor: "pointer",
                          maxWidth: "100%",
                          padding: '10px 20px 0px 20px',
                          border: '1px solid #ccc'
                        }}
                      >
                       <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div>
                            <h4 style={{ fontSize: "24px", fontWeight: "600" }}>
                              {nota.titulo.length > 20 ? nota.titulo.slice(0, 20) + "..." : nota.titulo}
                            </h4>
                          </div>
                          <div >
                          <i
                            style={{ cursor: "pointer", width: "26px", height: "26px", color: nota.favorito ? "#e5e500" : null }}
                            className="fas fa-star"
                            onClick={(event) => {
                              // Prevent the click event from propagating to the parent li
                              event.stopPropagation();
                              favoritarNota(nota);
                            }}
                          />
                          </div>
                        </Row>
                        <p
                          style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `${stripHtmlTagsAndLineBreaks(nota.descricao.slice(0, 50))}${nota.descricao.length > 50 ? '...' : ''}`
                            ),
                          }}
                        ></p>
                        <div style={{ gap: "5px", fontSize:"12px", display:"flex", flexWrap:"wrap", width:"100%"}}>
                            <p>Criado em:  <span style={{backgroundColor:"#e5e5e5"}}>{nota.createdAt}</span></p>
                            <p>Editado em:  <span style={{backgroundColor:"#e5e5e5"}}>{nota.updatedAt}</span></p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              </ModalBody>
            </div>
            
            <div style={{width:"55%", backgroundColor:"#fff"}}>{/*div 2*/}
              { 
                updateRedirection ? (
                  <>
                  <ModalHeader>
                      <Row style={{margin:"0 4%"}}>
                        <button
                        style={{
                          fontSize: "30px",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          width: "40px",
                          height: "40px",
                          maxHeight: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          if(notaSelecionada && !updateRedirection){
                            setNotaSelecionada(null);
                          }
                          else if(notaSelecionada && updateRedirection) {
                            setaUpdateRedirection(false);
                          }
                        }}>
                          <i className="fas fa-angle-left" />
                        </button>
                        <Col style={{ maxWidth: "600px",fontSize:"14px" }}>
                          <div>
                            Criado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.createdAt}</span>
                          </div>
                          <div>
                            Editado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.updatedAt}</span>
                          </div>
                        </Col>
                          <i 
                          style={{ 
                            fontSize:"26px",cursor:"pointer",marginTop:"5px", backgroundColor:"rgba(0,0,0,.0)"
                            }} 
                            class="fa fa-trash" 
                            aria-hidden="true"
                            onClick={() => handleNotaDelete()}
                            />
                      </Row>
                  </ModalHeader>
                  <div  style={{backgroundColor:"#fff"}}>
                    <div style={{margin:"0 4%",display:"flex", justifyContent:"center",alignItems:"center", gap:"10px", flexDirection:"column"}}>
                      <Input
                        type="text"
                        name="titulo"
                        value={notaSerEditada.titulo}
                        placeholder="Título"
                        style={{borderRadius:"8px",marginTop:"20px",maxWidth: "900px", height: "50px", fontSize: "24px", fontWeight:"600" }}
                        onChange={(e) =>handleUpdateFormChange('titulo', e.target.value)}          
                      />
                      {descricaoValidationError && <p style={{ color: "red", margin:"0px" }}>A descrição não pode estar vazia.</p>}
                      <Input
                        type="textarea"
                        name="descricao"
                        placeholder="Descrição"
                        value={
                            stripHtmlTags(notaSerEditada.descricao)
                        }
                        style={{
                          borderRadius:"8px",
                          maxWidth: "900px",
                          height: "350px",
                          minHeight: "200px",
                          fontSize: "16px",
                          resize: "none",
                          borderColor: descricaoValidationError ? "red" : "",
                        }}
                        onChange={(e) => handleUpdateFormChange('descricao', e.target.value)}
                      />
                      <div style={{display:"flex",width:"100%",justifyContent:"right", gap:"15px", paddingBottom:"20px"}}>
                        <button 
                          style={{justifyContent:"center",width:"100px",fontSize:"14px",borderRadius:"20px",borderColor:"rgba(0,0,0,.0)", display:"flex", gap:"5px"}}  
                          onClick={() =>setaUpdateRedirection(false)}>
                          <span style={{fontSize:"16px", fontWeight:"500"}}>X</span> Cancelar
                        </button>
                        <button 
                          style={{
                            width:"70px",
                            fontSize:"16px",
                            background: "#0479B7",                           
                            color:"white",borderRadius:"15px",borderColor:"rgba(0,0,0,.0)"}} 
                            onClick={() => handleSalvarNota()}>Salvar</button>
                      </div>
                      </div>
                    </div>
                  </>
                )
                : 
                createRedirection ? (
                  <>
                    <ModalHeader>
                      <Row style={{margin:"0 4%"}}>
                        <button
                        style={{
                          fontSize: "30px",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          width: "40px",
                          height: "40px",
                          maxHeight: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                         if(createRedirection){
                            setaCreateRedirection(false);
                          }
                        }}>
                          <i className="fas fa-angle-left" />
                        </button>
                        <h4 style={{marginLeft:"20px", marginTop:"7px"}}>Adicionar anotação</h4>
                      </Row>
                    </ModalHeader>
                    <div  style={{backgroundColor:"#fff"}}>
                    <div style={{
                      margin:"0 4%",display:"flex", justifyContent:"center",alignItems:"center", gap:"10px", flexDirection:"column"}}>
                      <Input
                        type="text"
                        name="titulo"
                        value={novaNota.titulo}
                        placeholder="Título"
                        style={{borderRadius:"8px",marginTop:"20px",maxWidth: "900px", height: "50px", fontSize: "24px", fontWeight:"600" }}
                        onChange={(e) => handleFormChange('titulo', e.target.value)}          
                      />
                      {descricaoValidationError && <p style={{ color: "red", margin:"0px" }}>A descrição não pode estar vazia.</p>}
                      <Input
                        type="textarea"
                        name="descricao"
                        placeholder="Descrição"
                        value={
                            novaNota.descricao
                        }
                        style={{
                          borderRadius:"8px",
                          maxWidth: "900px",
                          height: "350px",
                          minHeight: "200px",
                          fontSize: "16px",
                          resize: "none",
                          borderColor: descricaoValidationError ? "red" : "",
                        }}
                        onChange={(e) => handleFormChange('descricao', e.target.value)}
                      />
                      <div style={{display:"flex",width:"100%",justifyContent:"right", gap:"15px", paddingBottom:"20px"}}>
                        <button 
                          style={{
                            justifyContent:"center",width:"100px",fontSize:"14px",
                            borderRadius:"20px",borderColor:"rgba(0,0,0,.0)", display:"flex", gap:"5px"}}  
                          onClick={() =>setaCreateRedirection(false)}>
                          <span style={{fontSize:"16px", fontWeight:"500"}}>X</span> Cancelar
                        </button>
                        <button 
                          style={{
                            width:"70px",
                            fontSize:"16px",
                            background: "#0479B7",                           
                            color:"white",borderRadius:"15px",borderColor:"rgba(0,0,0,.0)"}} 
                            onClick={() => handleSalvarNota()}>Salvar</button>
                      </div>
                      </div>
                    </div>
                  </>
                ):(
                  <Col className="notas-detalhes" style={{width:"100%", height:"100%", padding:"0", margin:"0"}}>
                    {notaSelecionada ? (
                      <>
                      <ModalHeader>
                          <Row style={{margin:"0 4%"}}>
                            <button
                            style={{
                              fontSize: "30px",
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                              width: "40px",
                              height: "40px",
                              maxHeight: "40px",
                              borderRadius: "50%",
                              backgroundColor: "#ffffff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              if(notaSelecionada && !updateRedirection){
                                setNotaSelecionada(null);
                              }
                              else if(notaSelecionada && updateRedirection) {
                                setaUpdateRedirection(false);
                              }
                            }}>
                              <i className="fas fa-angle-left" />
                            </button>
                            <Col style={{ maxWidth: "600px",fontSize:"14px" }}>
                              <div>
                                Criado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.createdAt}</span>
                              </div>
                              <div>
                                Editado em:  <span style={{backgroundColor:"#e5e5e5"}}>{notaSelecionada.updatedAt}</span>
                              </div>
                            </Col>
                              <i 
                              style={{ 
                                fontSize:"26px",cursor:"pointer",marginTop:"5px", backgroundColor:"rgba(0,0,0,.0)"
                                }} 
                                class="fa fa-trash" 
                                aria-hidden="true"
                                onClick={() => handleNotaDelete()}
                                />
                          </Row>
                      </ModalHeader>
                      <div style={{backgroundColor:"#fff"}}>
                        <ModalBody style={{margin:"0 4%"}}>
                          <div style={{ marginTop: "20px"}}>  
                          <Row style={{ 
                            display: 'block', 
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            maxWidth: '100%', 
                            overflow: 'hidden', 
                          }}> 
                              <h3>{notaSelecionada.titulo}</h3> 
                            </Row>
                            <p
                              style={{
                                overflowX: "hidden",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                height: "50vh",
                                overflowY: "auto", // Adicione esta linha para controlar o overflowY
                              }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(notaSelecionada.descricao),
                              }}
                            ></p>
                            <div style={{display:"flex",justifyContent:"center"}}>
                              <Button 
                               onClick={() => {
                                setaUpdateRedirection(true);
                                setaNotaSerEditada({
                                  id: notaSelecionada.id,
                                  titulo: notaSelecionada.titulo,
                                  descricao: notaSelecionada.descricao,
                                });
                              }}
                              pill style={{backgroundColor:"#F7F7F7",display:"flex",gap:"10px",alignItems:"center", justifyContent:"center",border:"none", height:"40px"}}>
                                <i
                                class="fas fa-edit"/> <p style={{marginTop:"10px"}}>Editar</p>
                              </Button>
                            </div>
                          </div>
                        </ModalBody>
                      </div>
                    </>
                    ) : (
                      <div style={{textAlign:"center"}}>
                        <img alt="Criar nota" src={backCreateNota} style={{width:"70%", cursor:"pointer"}} onClick={() => setaCreateRedirection(true)}/>
                        <div style={{maxWidth: "400px", margin: "auto", marginBottom:"70px"}}>
                          <p style={{fontSize:"24px", fontWeight:"400"}}>Crie anotações para ajudar a reorganizar suas finanças</p>
                          <button onClick={() => setaCreateRedirection(true)} 
                            style={{
                              height: "30px",
                              width: "150px",
                              border: "1px solid rgba(0,0,0,.0)",
                              borderRadius: "12px",
                              background: "#0479B7",                           
                              color: "white",
                              margin: '10px', // Adiciona margem conforme necessário
                          }}>
                            Adicionar anotação
                          </button>
                        </div>
                      </div>
                    )}
                  </Col>
                )   
              }
            </div>
        </div>

          )
         }

         {/* CONFIRMACAO DE DELETE */}
      <Modal isOpen={showDeleteModal} toggle={toggleDeleteModal} backdrop={true} size="md">
          <ModalHeader>Confirmação</ModalHeader>
          <ModalBody>
            Tem certeza que deseja excluir esta nota?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDelete}>
              Excluir
            </Button>
            <Button color="secondary" onClick={toggleDeleteModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </Modal>
      </div>
    ) : null
  );
};


export default withRouter(NotasModal);