import React from "react";
import "moment/locale/pt-br";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Bar } from "react-chartjs-2";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { toBRL } from "../../utils";

const GraficoSuperfuloLivre = (props) => {
    // let somaValores = 0;

    // Object.keys(props.investimentosMensais).map((data) => {
    //     somaValores += Number(props.investimentosMensais[data]['totalDespesaSuperfula'])
    // })
    
    const graficoDados = {
        labels: props.meses,
        datasets: [{
            label: 'Esperado',
            data: props.meses.map((mes) => 30),
            backgroundColor: "#FFD93D",
            borderColor: props.cores[0],
        }, {
            label: 'Realizado',
            data: Object.keys(props.investimentosMensais).map((data) => {
                const metaMes = (props.investimentosMensais[data]['totalDespesaSuperfula'] * 100)/props.investimentosMensais[data]['totalReceitas']
                
                return metaMes;
            }),
            backgroundColor: "#FEFF86",
            borderColor: "#FFA500",
        }],
    };

    const graficoOpcoes = {
        tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const { yLabel, index } = tooltipItem;

                const indices = Object.keys(props.investimentosMensais)
                
                let label = data.datasets[tooltipItem.datasetIndex].label || "";
      
                if(label) {
                    if(yLabel === 30){
                        label = toBRL(props.investimentosMensais[indices[index]]['totalReceitas']*0.30) + ` (${Math.floor(yLabel)}%)`;
                    } else {
                        label = toBRL(props.investimentosMensais[indices[index]]['totalDespesaSuperfula']) + ` (${Math.floor(yLabel)}%)`;
                    }
                }
      
                return label;
              }
            }
          },
        scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false
                }
              }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: (value) => value + "%",
                    }
                }
            ]
          },
        maintainAspectRatio: false,
        
    }

    return (
        <Card>
            <CardBody>
                <CardTitle className="text-center">Despesas Livre / Supérfluas</CardTitle>

                <div className="chart-wrapper" style={{ height: 300, marginTop: 40 }}>
                    <Bar 
                        data={graficoDados}
                        options={graficoOpcoes}
                        plugins={[ChartAnnotation]}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export default GraficoSuperfuloLivre;