import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function SuccessModal({ isOpen, onClose }) {

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <h2>Dados Enviados com Sucesso</h2>
        <p>Os dados foram enviados com sucesso. Obrigado!</p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onClose}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SuccessModal;