import React, { Component } from "react";
import PropTypes from "prop-types";
import { version } from "../../../package.json";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    const fullYear = new Date().getFullYear();

    return (
      <React.Fragment>
        <span className="ml-auto text-right">
          &copy; 2019-{fullYear} &mdash;{" "}
          <a
            href="http://www.solutionforlife.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            Solution for Life
          </a>{" "}
          &mdash;{" "}
          <small>
            versão {version}
          </small>
        </span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
