import React, { Fragment } from "react";
import { toBRL } from "../../../utils";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import moment from "moment";
import BtnModalFormDespesa from "./BtnModalFormDespesa";
import BtnModalRemoverDespesa from "./BtnModalRemoverDespesa";
import patrimonyLinkedPath from '../../../assets/img/patrimonyLinked.png'

function LinhaDespesa(props) {
  const m = moment(props.dataDespesa);
  let diaDespesa = Number(m.format("D"));
  let mesDespesa = Number(m.format("M"));
  let anoDespesa = Number(m.format("YYYY"));

  const btns = (
    <div style={{ marginTop: 5 }}>
      <BtnModalFormDespesa
        id={props.id}
        categoria={props.categoria}
        tipo={props.tipo}
        dia={diaDespesa}
        mes={mesDespesa}
        ano={anoDespesa}
        descricao={props.descricao}
        valor={props.valor}
        despesaFixa={props.despesaFixa}
        despesaTemporaria={props.despesaTemporaria}
        parcela={props.parcela}
        totalParcelas={props.totalParcelas}
        juros={props.juros}
        credor={props.credor}
        onEdit={props.onEdit}
        patrimonioId={props.patrimonioId}
        patrimonioUuid={props.patrimonioUuid}
        tags={props.tags}
      />
      <BtnModalRemoverDespesa
        mesSelect={props.mesSelect}
        anoSelect={props.anoSelect}
        dia={diaDespesa}
        mes={mesDespesa}
        ano={anoDespesa}
        categoria={props.categoria}
        temporaria={props.despesaTemporaria}
        despesaFixa={props.despesaFixa}
        parcela={props.parcela}
        totalParcelas={props.totalParcelas}
        id={props.id}
        descricao={props.descricao}
        valor={props.valor}
        onDelete={props.onDelete}
      />
    </div>
  );

  const badgeTemporaria = props.despesaTemporaria ? (
    <Fragment>
      <Badge color="warning" pill style={{ marginRight: 5 }}>
        <i className="far fa-clock" /> {props.parcela} de {props.totalParcelas}
      </Badge>
    </Fragment>
  ) : null;

  const tagsPills =
    props.tags &&
    props.tags.map((tag) => (
      <Badge pill className="ml-1" key={`tag_` + props.id + `_` + tag.id}>
        {tag.nome}
      </Badge>
    ));

  return (
    <Fragment>
      <tr>
        <td>{diaDespesa}</td>
        <td>
          {props.valor !== 0 && (
            <Fragment>
              <span style={{ 
                  marginRight: 5
                }}>                
                <p  className="limit-text-line">{props.descricao}</p>
                {tagsPills}
                {props.importacaoId ? (
                  <i title="Gerada por Upload" className="fas fa-upload" style={{marginLeft:8}}></i>
                ): null}
              </span>
              <span className="d-block d-sm-none">
                {" "}
                {props.despesaFixa && (
                  <Fragment>
                    <Badge color="warning" pill style={{ marginRight: 5 }}>
                      <i className="fas fa-thumbtack" /> Recorrente
                    </Badge>
                  </Fragment>
                )}
                {props.despesaTemporaria && badgeTemporaria}{" "}
                <Badge color="light" pill>
                  {props.tipo}
                </Badge>
              </span>
            </Fragment>
          )}

          {props.valor === 0 && (
            <em className="text-muted">{props.descricao}</em>
          )}
        </td>
        <td className="text-left d-none d-sm-table-cell">
        {props.patrimonioUuid ? (
              <Badge pill color='info'  title="Patrimônio vinculado a esta receita">
                <span>
                  <img alt="Patrimônio vinculado" src={patrimonyLinkedPath}  style={{
                    width: '18px',
                    height: '15px',
                    position: 'relative',
                    top:'-2px'
                  }}
                /> 
                Patrimonio
              </span>
            </Badge>
            ):null}
        </td>
        <td className="text-left d-none d-sm-table-cell">
          {props.despesaFixa && (
            <Fragment>
              <Badge color="warning" pill style={{ marginRight: 5 }}>
                <i className="fas fa-thumbtack" /> Recorrente
              </Badge>
            </Fragment>
          )}

          {props.despesaTemporaria && badgeTemporaria}
        </td>
        <td className="text-left d-none d-sm-table-cell">
          <Badge color="light" pill>
            {props.tipo}
          </Badge>
        </td>
        <td className="text-right text-nowrap">
          {props.valor !== 0 && toBRL(props.valor)}

          {props.valor === 0 && (
            <em className="text-muted">{toBRL(props.valor)}</em>
          )}

          <div className="d-block d-sm-none">{btns}</div>
        </td>
        <td className="text-right d-none d-sm-table-cell">{btns}</td>
      </tr>
    </Fragment>
  );
}

LinhaDespesa.propTypes = {
  id: PropTypes.number.isRequired,
  dataDespesa: PropTypes.string.isRequired,
  categoria: PropTypes.string.isRequired,
  tipo: PropTypes.string,
  descricao: PropTypes.string,
  despesaFixa: PropTypes.bool,
  valor: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  patrimonioId: PropTypes.number,
  importcaoId: PropTypes.number,
};

export default LinhaDespesa;
