import React, { Fragment, useCallback } from "react";
import { findDOMNode } from "react-dom";
import ReactDOMServer from "react-dom/server";
import { AppSidebarNav } from "@coreui/react";

function MyAppSidebarNav(props) {

  const content = (
    <Fragment>
      Relatórios <span className="badge badge-warning">PROSPERE</span>
    </Fragment>
  );

  const html = ReactDOMServer.renderToString(content);

  const navRef = useCallback(ref => {
    let navNode = findDOMNode(ref);

    if (!navNode) {
      return;
    }

    let entryNode = navNode.querySelector(".nav-title-relatorios");

    if (entryNode) {
      entryNode.innerHTML = html;
    }
  });

  return <AppSidebarNav ref={navRef} {...props} />;
}

export default MyAppSidebarNav;
